import React from 'react';

const RoundSmall = () => {
  return (
    <svg
      width="392"
      height="555"
      viewBox="0 0 392 555"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_84_37)">
        <path
          d="M362.974 82.9928C362.974 83.8314 363.654 84.5065 364.488 84.5065C365.323 84.5065 366.001 83.8263 366.001 82.9928C366.001 82.1598 365.323 81.4796 364.488 81.4796C363.654 81.4796 362.974 82.1598 362.974 82.9928Z"
          fill="#EF4E05"
        />
        <path
          d="M359.721 90.0749C358.946 90.0749 358.312 90.7079 358.312 91.483C358.312 92.2581 358.946 92.8911 359.721 92.8911C360.496 92.8911 361.129 92.2581 361.129 91.483C361.129 90.7023 360.501 90.0749 359.721 90.0749Z"
          fill="#EF4E05"
        />
        <path
          d="M349.69 91.6465C350.434 91.6465 351.035 91.0452 351.035 90.3019C351.035 89.5582 350.434 88.9569 349.69 88.9569C348.947 88.9569 348.346 89.5582 348.346 90.3019C348.346 91.0452 348.947 91.6465 349.69 91.6465Z"
          fill="#EF4E05"
        />
        <path
          d="M351.562 80.4774C351.562 81.2791 352.211 81.9276 353.012 81.9276C353.814 81.9276 354.462 81.2791 354.462 80.4774C354.462 79.6757 353.814 79.0273 353.012 79.0273C352.211 79.0273 351.562 79.6757 351.562 80.4774Z"
          fill="#EF4E05"
        />
        <path
          d="M349.78 69.8041C349.78 70.653 350.471 71.3439 351.32 71.3439C352.169 71.3439 352.859 70.653 352.859 69.8041C352.859 68.9552 352.169 68.2642 351.32 68.2642C350.471 68.2642 349.78 68.9552 349.78 69.8041Z"
          fill="#EF4E05"
        />
        <path
          d="M361.93 74.6764C362.807 74.6764 363.517 73.9658 363.517 73.0894C363.517 72.2129 362.807 71.5019 361.93 71.5019C361.053 71.5019 360.343 72.2129 360.343 73.0894C360.343 73.9658 361.053 74.6764 361.93 74.6764Z"
          fill="#EF4E05"
        />
        <path
          d="M358.818 65.7438C359.683 65.7438 360.384 65.042 360.384 64.1773C360.384 63.3125 359.683 62.6113 358.818 62.6113C357.954 62.6113 357.252 63.3125 357.252 64.1773C357.247 65.042 357.954 65.7438 358.818 65.7438Z"
          fill="#EF4E05"
        />
        <path
          d="M350.255 61.2718C351.152 61.2718 351.88 60.544 351.88 59.6474C351.88 58.7509 351.152 58.0235 350.255 58.0235C349.359 58.0235 348.631 58.7509 348.631 59.6474C348.631 60.5491 349.359 61.2718 350.255 61.2718Z"
          fill="#EF4E05"
        />
        <path
          d="M341.512 65.7223C342.35 65.7223 343.03 65.042 343.03 64.2039C343.03 63.3653 342.35 62.6851 341.512 62.6851C340.673 62.6851 339.993 63.3653 339.993 64.2039C339.993 65.042 340.673 65.7223 341.512 65.7223Z"
          fill="#EF4E05"
        />
        <path
          d="M341.813 54.6009C342.712 54.6009 343.442 53.8716 343.442 52.9713C343.442 52.0715 342.712 51.3422 341.813 51.3422C340.912 51.3422 340.183 52.0715 340.183 52.9713C340.183 53.8716 340.912 54.6009 341.813 54.6009Z"
          fill="#EF4E05"
        />
        <path
          d="M332.963 49.4697C333.855 49.4697 334.577 48.7475 334.577 47.8561C334.577 46.9652 333.855 46.2429 332.963 46.2429C332.072 46.2429 331.35 46.9652 331.35 47.8561C331.35 48.7475 332.072 49.4697 332.963 49.4697Z"
          fill="#EF4E05"
        />
        <path
          d="M332.515 60.0534C333.354 60.0534 334.034 59.3737 334.034 58.5351C334.034 57.696 333.354 57.0162 332.515 57.0162C331.676 57.0162 330.996 57.696 330.996 58.5351C330.996 59.3737 331.676 60.0534 332.515 60.0534Z"
          fill="#EF4E05"
        />
        <path
          d="M323.202 65.5644C323.982 65.5644 324.615 64.9313 324.615 64.1511C324.615 63.3705 323.982 62.7379 323.202 62.7379C322.422 62.7379 321.789 63.3705 321.789 64.1511C321.789 64.9313 322.422 65.5644 323.202 65.5644Z"
          fill="#EF4E05"
        />
        <path
          d="M313.7 60.3594C314.475 60.3594 315.103 59.732 315.103 58.9569C315.103 58.1814 314.475 57.554 313.7 57.554C312.925 57.554 312.297 58.1814 312.297 58.9569C312.297 59.732 312.925 60.3594 313.7 60.3594Z"
          fill="#EF4E05"
        />
        <path
          d="M304.15 52.7289C303.374 52.7289 302.747 53.3567 302.747 54.1318C302.747 54.9069 303.374 55.5343 304.15 55.5343C304.925 55.5343 305.553 54.9069 305.553 54.1318C305.553 53.3567 304.925 52.7289 304.15 52.7289Z"
          fill="#EF4E05"
        />
        <path
          d="M304.218 44.9717C305.046 44.9717 305.721 44.2966 305.721 43.4687C305.721 42.6409 305.046 41.9658 304.218 41.9658C303.39 41.9658 302.715 42.6409 302.715 43.4687C302.715 44.2966 303.385 44.9717 304.218 44.9717Z"
          fill="#EF4E05"
        />
        <path
          d="M313.847 49.5646C314.681 49.5646 315.356 48.89 315.356 48.0565C315.356 47.2235 314.681 46.5484 313.847 46.5484C313.014 46.5484 312.339 47.2235 312.339 48.0565C312.339 48.89 313.014 49.5646 313.847 49.5646Z"
          fill="#EF4E05"
        />
        <path
          d="M324.763 52.8821C324.763 52.0435 324.083 51.3633 323.244 51.3633C322.406 51.3633 321.726 52.0435 321.726 52.8821C321.726 53.7202 322.406 54.4004 323.244 54.4004C324.083 54.4004 324.763 53.7202 324.763 52.8821Z"
          fill="#EF4E05"
        />
        <path
          d="M323.255 43.643C324.155 43.643 324.885 42.9132 324.885 42.0134C324.885 41.1136 324.155 40.3839 323.255 40.3839C322.355 40.3839 321.625 41.1136 321.625 42.0134C321.625 42.9132 322.355 43.643 323.255 43.643Z"
          fill="#EF4E05"
        />
        <path
          d="M313.9 39.1131C314.796 39.1131 315.524 38.3853 315.524 37.4887C315.524 36.5927 314.796 35.8648 313.9 35.8648C313.003 35.8648 312.276 36.5927 312.276 37.4887C312.276 38.3853 313.003 39.1131 313.9 39.1131Z"
          fill="#EF4E05"
        />
        <path
          d="M304.819 34.773C305.716 34.773 306.443 34.0456 306.443 33.149C306.443 32.2525 305.716 31.5246 304.819 31.5246C303.923 31.5246 303.195 32.2525 303.195 33.149C303.195 34.0456 303.918 34.773 304.819 34.773Z"
          fill="#EF4E05"
        />
        <path
          d="M296.134 30.8864C297.03 30.8864 297.753 30.159 297.753 29.2676C297.753 28.3711 297.025 27.6488 296.134 27.6488C295.238 27.6488 294.515 28.3767 294.515 29.2676C294.515 30.159 295.238 30.8864 296.134 30.8864Z"
          fill="#EF4E05"
        />
        <path
          d="M287.744 26.9579C288.646 26.9579 289.379 26.2248 289.379 25.3232C289.379 24.4215 288.646 23.6885 287.744 23.6885C286.842 23.6885 286.109 24.4215 286.109 25.3232C286.104 26.2248 286.837 26.9579 287.744 26.9579Z"
          fill="#EF4E05"
        />
        <path
          d="M294.616 40.4315C295.449 40.4315 296.124 39.7565 296.124 38.923C296.124 38.09 295.449 37.4149 294.616 37.4149C293.782 37.4149 293.107 38.09 293.107 38.923C293.102 39.7565 293.782 40.4315 294.616 40.4315Z"
          fill="#EF4E05"
        />
        <path
          d="M294.31 50.7461C295.085 50.7461 295.713 50.1187 295.713 49.3436C295.713 48.5681 295.085 47.9406 294.31 47.9406C293.535 47.9406 292.907 48.5681 292.907 49.3436C292.907 50.1187 293.535 50.7461 294.31 50.7461Z"
          fill="#EF4E05"
        />
        <path
          d="M285.651 54.1898C285.651 53.4619 285.06 52.8713 284.333 52.8713C283.605 52.8713 283.014 53.4619 283.014 54.1898C283.014 54.9176 283.605 55.5082 284.333 55.5082C285.06 55.5082 285.651 54.9176 285.651 54.1898Z"
          fill="#EF4E05"
        />
        <path
          d="M274.994 58.693C274.319 58.693 273.77 59.2414 273.77 59.9165C273.77 60.5916 274.319 61.1401 274.994 61.1401C275.669 61.1401 276.217 60.5916 276.217 59.9165C276.217 59.2414 275.669 58.693 274.994 58.693Z"
          fill="#EF4E05"
        />
        <path
          d="M274.44 48.7634C275.183 48.7634 275.784 48.1621 275.784 47.4183C275.784 46.675 275.183 46.0738 274.44 46.0738C273.696 46.0738 273.095 46.675 273.095 47.4183C273.095 48.1621 273.696 48.7634 274.44 48.7634Z"
          fill="#EF4E05"
        />
        <path
          d="M264.631 43.1894C265.388 43.1894 266.002 42.5755 266.002 41.8182C266.002 41.0613 265.388 40.4474 264.631 40.4474C263.874 40.4474 263.26 41.0613 263.26 41.8182C263.26 42.5755 263.874 43.1894 264.631 43.1894Z"
          fill="#EF4E05"
        />
        <path
          d="M254.142 38.5908C254.908 38.5908 255.529 37.9699 255.529 37.2042C255.529 36.438 254.908 35.8171 254.142 35.8171C253.376 35.8171 252.755 36.438 252.755 37.2042C252.755 37.9699 253.376 38.5908 254.142 38.5908Z"
          fill="#EF4E05"
        />
        <path
          d="M242.873 36.118C243.637 36.118 244.26 35.4957 244.26 34.7309C244.26 33.9661 243.637 33.3438 242.873 33.3438C242.108 33.3438 241.486 33.9661 241.486 34.7309C241.486 35.4957 242.108 36.118 242.873 36.118Z"
          fill="#EF4E05"
        />
        <path
          d="M247.334 26.8527C248.162 26.8527 248.837 26.1777 248.837 25.3498C248.837 24.5219 248.162 23.8469 247.334 23.8469C246.506 23.8469 245.831 24.5219 245.831 25.3498C245.831 26.1777 246.501 26.8527 247.334 26.8527Z"
          fill="#EF4E05"
        />
        <path
          d="M256.79 28.3132C257.618 28.3132 258.293 27.6381 258.293 26.8102C258.293 25.9824 257.618 25.3073 256.79 25.3073C255.962 25.3073 255.287 25.9824 255.287 26.8102C255.287 27.6381 255.957 28.3132 256.79 28.3132Z"
          fill="#EF4E05"
        />
        <path
          d="M265.854 31.9731C266.677 31.9731 267.347 31.3032 267.347 30.4804C267.347 29.6577 266.677 28.9882 265.854 28.9882C265.032 28.9882 264.362 29.6577 264.362 30.4804C264.362 31.3032 265.032 31.9731 265.854 31.9731Z"
          fill="#EF4E05"
        />
        <path
          d="M274.83 36.9772C275.647 36.9772 276.307 36.318 276.307 35.5008C276.307 34.6833 275.647 34.0241 274.83 34.0241C274.013 34.0241 273.354 34.6833 273.354 35.5008C273.354 36.3128 274.013 36.9772 274.83 36.9772Z"
          fill="#EF4E05"
        />
        <path
          d="M284.327 44.903C285.107 44.903 285.74 44.2704 285.74 43.4898C285.74 42.7096 285.107 42.0765 284.327 42.0765C283.546 42.0765 282.914 42.7096 282.914 43.4898C282.914 44.2704 283.546 44.903 284.327 44.903Z"
          fill="#EF4E05"
        />
        <path
          d="M285.092 35.2845C285.936 35.2845 286.616 34.6043 286.616 33.7606C286.616 32.9168 285.936 32.2366 285.092 32.2366C284.248 32.2366 283.567 32.9168 283.567 33.7606C283.567 34.6043 284.253 35.2845 285.092 35.2845Z"
          fill="#EF4E05"
        />
        <path
          d="M278.648 25.8558C279.539 25.8558 280.256 25.1335 280.256 24.2472C280.256 23.3563 279.533 22.6392 278.648 22.6392C277.757 22.6392 277.04 23.3614 277.04 24.2472C277.04 25.1386 277.757 25.8558 278.648 25.8558Z"
          fill="#EF4E05"
        />
        <path
          d="M270.758 22.449C271.655 22.449 272.378 21.7216 272.378 20.8302C272.378 19.9337 271.65 19.2114 270.758 19.2114C269.862 19.2114 269.14 19.9393 269.14 20.8302C269.14 21.7216 269.862 22.449 270.758 22.449Z"
          fill="#EF4E05"
        />
        <path
          d="M262.348 19.6702C263.242 19.6702 263.967 18.9451 263.967 18.0513C263.967 17.1571 263.242 16.4323 262.348 16.4323C261.454 16.4323 260.729 17.1571 260.729 18.0513C260.729 18.9451 261.454 19.6702 262.348 19.6702Z"
          fill="#EF4E05"
        />
        <path
          d="M253.245 17.7402C254.142 17.7402 254.865 17.0124 254.865 16.1213C254.865 15.2248 254.137 14.5023 253.245 14.5023C252.349 14.5023 251.626 15.2301 251.626 16.1213C251.626 17.0124 252.349 17.7402 253.245 17.7402Z"
          fill="#EF4E05"
        />
        <path
          d="M243.98 15.9419C244.877 15.9419 245.605 15.2141 245.605 14.3176C245.605 13.4211 244.877 12.6935 243.98 12.6935C243.084 12.6935 242.356 13.4211 242.356 14.3176C242.356 15.2141 243.084 15.9419 243.98 15.9419Z"
          fill="#EF4E05"
        />
        <path
          d="M234.631 14.1963C235.528 14.1963 236.25 13.4686 236.25 12.5774C236.25 11.6809 235.522 10.9585 234.631 10.9585C233.74 10.9585 233.012 11.6862 233.012 12.5774C233.012 13.4686 233.734 14.1963 234.631 14.1963Z"
          fill="#EF4E05"
        />
        <path
          d="M225.281 13.205C226.177 13.205 226.9 12.4773 226.9 11.5861C226.9 10.6896 226.172 9.96718 225.281 9.96718C224.39 9.96718 223.662 10.6949 223.662 11.5861C223.657 12.4826 224.384 13.205 225.281 13.205Z"
          fill="#EF4E05"
        />
        <path
          d="M216.828 12.0026C217.722 12.0026 218.447 11.2778 218.447 10.3837C218.447 9.48958 217.722 8.76478 216.828 8.76478C215.934 8.76478 215.209 9.48958 215.209 10.3837C215.209 11.2778 215.934 12.0026 216.828 12.0026Z"
          fill="#EF4E05"
        />
        <path
          d="M208.722 12.0975C209.619 12.0975 210.341 11.3698 210.341 10.4786C210.341 9.58212 209.614 8.85966 208.722 8.85966C207.826 8.85966 207.104 9.5874 207.104 10.4786C207.104 11.375 207.826 12.0975 208.722 12.0975Z"
          fill="#EF4E05"
        />
        <path
          d="M198.994 11.8075C199.885 11.8075 200.607 11.0851 200.607 10.1939C200.607 9.3027 199.885 8.58024 198.994 8.58024C198.102 8.58024 197.38 9.3027 197.38 10.1939C197.38 11.0851 198.102 11.8075 198.994 11.8075Z"
          fill="#EF4E05"
        />
        <path
          d="M188.542 11.739C189.433 11.739 190.156 11.0165 190.156 10.1253C190.156 9.23412 189.433 8.51166 188.542 8.51166C187.651 8.51166 186.928 9.23412 186.928 10.1253C186.928 11.0165 187.651 11.739 188.542 11.739Z"
          fill="#EF4E05"
        />
        <path
          d="M178.327 12.2505C179.224 12.2505 179.947 11.5227 179.947 10.6316C179.947 9.73508 179.219 9.01262 178.327 9.01262C177.431 9.01262 176.709 9.74036 176.709 10.6316C176.703 11.528 177.431 12.2505 178.327 12.2505Z"
          fill="#EF4E05"
        />
        <path
          d="M168.756 13.0309C169.65 13.0309 170.375 12.3061 170.375 11.412C170.375 10.5179 169.65 9.79301 168.756 9.79301C167.862 9.79301 167.137 10.5179 167.137 11.412C167.137 12.3061 167.862 13.0309 168.756 13.0309Z"
          fill="#EF4E05"
        />
        <path
          d="M172.49 22.1435C173.323 22.1435 173.998 21.4684 173.998 20.6349C173.998 19.8019 173.323 19.1269 172.49 19.1269C171.656 19.1269 170.981 19.8019 170.981 20.6349C170.981 21.4684 171.656 22.1435 172.49 22.1435Z"
          fill="#EF4E05"
        />
        <path
          d="M183.406 21.8903C184.234 21.8903 184.909 21.2152 184.909 20.3873C184.909 19.5595 184.234 18.8844 183.406 18.8844C182.578 18.8844 181.903 19.5595 181.903 20.3873C181.898 21.2152 182.573 21.8903 183.406 21.8903Z"
          fill="#EF4E05"
        />
        <path
          d="M194.284 21.7375C195.112 21.7375 195.782 21.0676 195.782 20.2397C195.782 19.4118 195.112 18.742 194.284 18.742C193.456 18.742 192.787 19.4118 192.787 20.2397C192.787 21.0676 193.461 21.7375 194.284 21.7375Z"
          fill="#EF4E05"
        />
        <path
          d="M204.979 22.2173C205.807 22.2173 206.477 21.5474 206.477 20.7195C206.477 19.8916 205.807 19.2222 204.979 19.2222C204.151 19.2222 203.481 19.8916 203.481 20.7195C203.481 21.5422 204.156 22.2173 204.979 22.2173Z"
          fill="#EF4E05"
        />
        <path
          d="M215.879 22.1169C216.707 22.1169 217.382 21.4422 217.382 20.6139C217.382 19.7861 216.707 19.1114 215.879 19.1114C215.051 19.1114 214.376 19.7861 214.376 20.6139C214.376 21.4422 215.051 22.1169 215.879 22.1169Z"
          fill="#EF4E05"
        />
        <path
          d="M220.657 32.0628C221.427 32.0628 222.054 31.4349 222.054 30.665C222.054 29.8951 221.427 29.2676 220.657 29.2676C219.887 29.2676 219.259 29.8951 219.259 30.665C219.259 31.4405 219.887 32.0628 220.657 32.0628Z"
          fill="#EF4E05"
        />
        <path
          d="M225.46 21.616C225.46 22.4439 226.136 23.119 226.963 23.119C227.791 23.119 228.466 22.4439 228.466 21.616C228.466 20.7882 227.791 20.1131 226.963 20.1131C226.136 20.1131 225.46 20.783 225.46 21.616Z"
          fill="#EF4E05"
        />
        <path
          d="M237.557 24.5266C238.391 24.5266 239.065 23.852 239.065 23.0185C239.065 22.1855 238.391 21.5104 237.557 21.5104C236.724 21.5104 236.049 22.1855 236.049 23.0185C236.049 23.852 236.724 24.5266 237.557 24.5266Z"
          fill="#EF4E05"
        />
        <path
          d="M232.163 33.2598C232.938 33.2598 233.566 32.6323 233.566 31.8568C233.566 31.0817 232.938 30.4543 232.163 30.4543C231.388 30.4543 230.76 31.0817 230.76 31.8568C230.76 32.6323 231.393 33.2598 232.163 33.2598Z"
          fill="#EF4E05"
        />
        <path
          d="M225.234 38.8707C224.517 38.8707 223.937 39.4504 223.937 40.1676C223.937 40.8852 224.517 41.465 225.234 41.465C225.952 41.465 226.531 40.8852 226.531 40.1676C226.531 39.4504 225.952 38.8707 225.234 38.8707Z"
          fill="#EF4E05"
        />
        <path
          d="M234.931 42.3456C234.225 42.3456 233.649 42.9203 233.649 43.6271C233.649 44.3335 234.225 44.9086 234.931 44.9086C235.638 44.9086 236.212 44.3335 236.212 43.6271C236.212 42.9203 235.638 42.3456 234.931 42.3456Z"
          fill="#EF4E05"
        />
        <path
          d="M245.267 47.2866C245.972 47.2866 246.543 46.7152 246.543 46.0107C246.543 45.3057 245.972 44.7343 245.267 44.7343C244.563 44.7343 243.991 45.3057 243.991 46.0107C243.991 46.7152 244.563 47.2866 245.267 47.2866Z"
          fill="#EF4E05"
        />
        <path
          d="M255.435 48.1462C254.738 48.1462 254.169 48.7106 254.169 49.4118C254.169 50.1131 254.733 50.6774 255.435 50.6774C256.131 50.6774 256.7 50.1131 256.7 49.4118C256.7 48.7106 256.136 48.1462 255.435 48.1462Z"
          fill="#EF4E05"
        />
        <path
          d="M265.417 55.724C266.102 55.724 266.656 55.1704 266.656 54.485C266.656 53.7992 266.102 53.2456 265.417 53.2456C264.731 53.2456 264.178 53.7992 264.178 54.485C264.178 55.1704 264.731 55.724 265.417 55.724Z"
          fill="#EF4E05"
        />
        <path
          d="M257.348 61.8465C257.348 61.2083 256.832 60.6916 256.193 60.6916C255.555 60.6916 255.039 61.2083 255.039 61.8465C255.039 62.4847 255.555 63.0014 256.193 63.0014C256.832 63.0014 257.348 62.4847 257.348 61.8465Z"
          fill="#EF4E05"
        />
        <path
          d="M266.961 69.2607C267.582 69.2607 268.085 68.7581 268.085 68.1376C268.085 67.5172 267.582 67.0145 266.961 67.0145C266.341 67.0145 265.838 67.5172 265.838 68.1376C265.838 68.7581 266.341 69.2607 266.961 69.2607Z"
          fill="#EF4E05"
        />
        <path
          d="M258.466 74.0756C258.466 73.4953 257.997 73.0263 257.417 73.0263C256.837 73.0263 256.368 73.4953 256.368 74.0756C256.368 74.6554 256.837 75.1249 257.417 75.1249C257.997 75.1197 258.466 74.6502 258.466 74.0756Z"
          fill="#EF4E05"
        />
        <path
          d="M248.663 83.267C248.663 82.7452 248.241 82.3233 247.719 82.3233C247.197 82.3233 246.775 82.7452 246.775 83.267C246.775 83.7889 247.197 84.2107 247.719 84.2107C248.241 84.2107 248.663 83.7889 248.663 83.267Z"
          fill="#EF4E05"
        />
        <path
          d="M234.551 80.5144C234.551 81.0362 234.973 81.4637 235.501 81.4637C236.028 81.4637 236.45 81.0418 236.45 80.5144C236.45 79.992 236.028 79.565 235.501 79.565C234.973 79.565 234.551 79.992 234.551 80.5144Z"
          fill="#EF4E05"
        />
        <path
          d="M224.364 76.9021C224.364 76.3746 223.937 75.9425 223.404 75.9425C222.872 75.9425 222.444 76.3695 222.444 76.9021C222.444 77.4295 222.872 77.8617 223.404 77.8617C223.937 77.8617 224.364 77.4295 224.364 76.9021Z"
          fill="#EF4E05"
        />
        <path
          d="M212.482 87.2802C212.482 87.76 212.873 88.1552 213.358 88.1552C213.838 88.1552 214.233 87.7651 214.233 87.2802C214.233 86.7999 213.843 86.4047 213.358 86.4047C212.873 86.4047 212.482 86.7948 212.482 87.2802Z"
          fill="#EF4E05"
        />
        <path
          d="M202.706 82.1808C203.21 82.1808 203.619 81.772 203.619 81.2684C203.619 80.7643 203.21 80.356 202.706 80.356C202.203 80.356 201.794 80.7643 201.794 81.2684C201.794 81.772 202.203 82.1808 202.706 82.1808Z"
          fill="#EF4E05"
        />
        <path
          d="M189.038 88.0127C189.038 88.4874 189.423 88.8672 189.892 88.8672C190.361 88.8672 190.746 88.4822 190.746 88.0127C190.746 87.5385 190.361 87.1536 189.892 87.1536C189.423 87.1536 189.038 87.5437 189.038 88.0127Z"
          fill="#EF4E05"
        />
        <path
          d="M177.352 89.0574C177.352 88.5827 176.967 88.2029 176.497 88.2029C176.023 88.2029 175.643 88.5878 175.643 89.0574C175.643 89.5264 176.028 89.9114 176.497 89.9114C176.972 89.9114 177.352 89.5264 177.352 89.0574Z"
          fill="#EF4E05"
        />
        <path
          d="M162.001 84.791C161.505 84.791 161.099 85.1918 161.099 85.6927C161.099 86.1935 161.5 86.5943 162.001 86.5943C162.502 86.5943 162.902 86.1935 162.902 85.6927C162.902 85.1918 162.496 84.791 162.001 84.791Z"
          fill="#EF4E05"
        />
        <path
          d="M169.479 77.0076C170.015 77.0076 170.449 76.5732 170.449 76.0373C170.449 75.5014 170.015 75.067 169.479 75.067C168.943 75.067 168.508 75.5014 168.508 76.0373C168.508 76.5732 168.943 77.0076 169.479 77.0076Z"
          fill="#EF4E05"
        />
        <path
          d="M164.047 66.2656C164.637 66.2656 165.117 65.7858 165.117 65.1948C165.117 64.6043 164.637 64.1245 164.047 64.1245C163.456 64.1245 162.976 64.6043 162.976 65.1948C162.976 65.7858 163.456 66.2656 164.047 66.2656Z"
          fill="#EF4E05"
        />
        <path
          d="M176.17 64.8524C176.756 64.8524 177.235 64.3777 177.235 63.7872C177.235 63.1967 176.761 62.722 176.17 62.722C175.585 62.722 175.105 63.1967 175.105 63.7872C175.105 64.3777 175.585 64.8524 176.17 64.8524Z"
          fill="#EF4E05"
        />
        <path
          d="M182.045 74.703C181.518 74.703 181.085 75.13 181.085 75.6626C181.085 76.1901 181.512 76.6227 182.045 76.6227C182.573 76.6227 183.005 76.1952 183.005 75.6626C183.005 75.13 182.578 74.703 182.045 74.703Z"
          fill="#EF4E05"
        />
        <path
          d="M194.749 73.6271C194.749 73.0893 194.311 72.6516 193.773 72.6516C193.235 72.6516 192.797 73.0893 192.797 73.6271C192.797 74.1653 193.235 74.6026 193.773 74.6026C194.311 74.6026 194.749 74.1653 194.749 73.6271Z"
          fill="#EF4E05"
        />
        <path
          d="M188.615 63.3074C189.207 63.3074 189.686 62.828 189.686 62.2366C189.686 61.6456 189.207 61.1663 188.615 61.1663C188.024 61.1663 187.545 61.6456 187.545 62.2366C187.545 62.828 188.024 63.3074 188.615 63.3074Z"
          fill="#EF4E05"
        />
        <path
          d="M202.348 65.9437C202.927 65.9437 203.397 65.4742 203.397 64.8944C203.397 64.3151 202.927 63.8451 202.348 63.8451C201.768 63.8451 201.298 64.3151 201.298 64.8944C201.298 65.4742 201.768 65.9437 202.348 65.9437Z"
          fill="#EF4E05"
        />
        <path
          d="M200.586 53.2404C200.586 52.5971 200.064 52.0753 199.421 52.0753C198.777 52.0753 198.255 52.5971 198.255 53.2404C198.255 53.8837 198.777 54.4061 199.421 54.4061C200.064 54.4061 200.586 53.8837 200.586 53.2404Z"
          fill="#EF4E05"
        />
        <path
          d="M210.548 55.4656C210.548 56.0931 211.054 56.5995 211.682 56.5995C212.309 56.5995 212.815 56.0931 212.815 55.4656C212.815 54.8382 212.309 54.3318 211.682 54.3318C211.054 54.3318 210.548 54.8382 210.548 55.4656Z"
          fill="#EF4E05"
        />
        <path
          d="M213.132 71.4654C213.132 70.9169 212.688 70.4684 212.135 70.4684C211.581 70.4684 211.138 70.9113 211.138 71.4654C211.138 72.019 211.581 72.4619 212.135 72.4619C212.688 72.4619 213.132 72.0134 213.132 71.4654Z"
          fill="#EF4E05"
        />
        <path
          d="M221 63.1756C221 63.7713 221.484 64.2567 222.081 64.2567C222.676 64.2567 223.161 63.7713 223.161 63.1756C223.161 62.5795 222.676 62.0946 222.081 62.0946C221.484 62.0946 221 62.5795 221 63.1756Z"
          fill="#EF4E05"
        />
        <path
          d="M233.813 68.3067C234.399 68.3067 234.874 67.8321 234.874 67.2467C234.874 66.6613 234.399 66.1867 233.813 66.1867C233.228 66.1867 232.753 66.6613 232.753 67.2467C232.753 67.8321 233.228 68.3067 233.813 68.3067Z"
          fill="#EF4E05"
        />
        <path
          d="M246.596 70.3736C246.596 69.7933 246.127 69.3243 245.547 69.3243C244.967 69.3243 244.498 69.7933 244.498 70.3736C244.498 70.9538 244.967 71.4229 245.547 71.4229C246.127 71.4229 246.596 70.9538 246.596 70.3736Z"
          fill="#EF4E05"
        />
        <path
          d="M245.246 59.2097C245.887 59.2097 246.406 58.6906 246.406 58.0496C246.406 57.4091 245.887 56.8896 245.246 56.8896C244.605 56.8896 244.086 57.4091 244.086 58.0496C244.086 58.6906 244.605 59.2097 245.246 59.2097Z"
          fill="#EF4E05"
        />
        <path
          d="M233.107 55.1442C233.107 55.7875 233.629 56.315 234.278 56.315C234.926 56.315 235.448 55.7927 235.448 55.1442C235.448 54.5009 234.926 53.9734 234.278 53.9734C233.629 53.9734 233.107 54.4953 233.107 55.1442Z"
          fill="#EF4E05"
        />
        <path
          d="M223.525 52.4495C224.179 52.4495 224.712 51.9169 224.712 51.2628C224.712 50.6092 224.179 50.0766 223.525 50.0766C222.871 50.0766 222.339 50.6092 222.339 51.2628C222.339 51.9169 222.871 52.4495 223.525 52.4495Z"
          fill="#EF4E05"
        />
        <path
          d="M215.13 43.9803C215.826 43.9803 216.396 43.4159 216.396 42.7147C216.396 42.0186 215.832 41.4491 215.13 41.4491C214.434 41.4491 213.865 42.0134 213.865 42.7147C213.865 43.4108 214.434 43.9803 215.13 43.9803Z"
          fill="#EF4E05"
        />
        <path
          d="M210.263 32.8323C211.023 32.8323 211.639 32.2161 211.639 31.4559C211.639 30.6958 211.023 30.0796 210.263 30.0796C209.502 30.0796 208.886 30.6958 208.886 31.4559C208.886 32.2161 209.502 32.8323 210.263 32.8323Z"
          fill="#EF4E05"
        />
        <path
          d="M199.753 32.3315C200.519 32.3315 201.14 31.7106 201.14 30.9444C201.14 30.1787 200.519 29.5578 199.753 29.5578C198.987 29.5578 198.366 30.1787 198.366 30.9444C198.366 31.7106 198.987 32.3315 199.753 32.3315Z"
          fill="#EF4E05"
        />
        <path
          d="M188.884 29.0882C188.119 29.0882 187.497 29.7105 187.497 30.4753C187.497 31.2401 188.119 31.8619 188.884 31.8619C189.648 31.8619 190.271 31.2401 190.271 30.4753C190.271 29.7054 189.648 29.0882 188.884 29.0882Z"
          fill="#EF4E05"
        />
        <path
          d="M181.802 41.9237C182.513 41.9237 183.089 41.3477 183.089 40.6371C183.089 39.9265 182.513 39.3505 181.802 39.3505C181.092 39.3505 180.516 39.9265 180.516 40.6371C180.516 41.3477 181.092 41.9237 181.802 41.9237Z"
          fill="#EF4E05"
        />
        <path
          d="M193.936 42.119C194.643 42.119 195.218 41.5439 195.218 40.8375C195.218 40.1307 194.643 39.556 193.936 39.556C193.23 39.556 192.655 40.1307 192.655 40.8375C192.655 41.5439 193.23 42.119 193.936 42.119Z"
          fill="#EF4E05"
        />
        <path
          d="M206.128 42.7885C206.128 42.0924 205.564 41.5285 204.868 41.5285C204.172 41.5285 203.608 42.0924 203.608 42.7885C203.608 43.4846 204.172 44.049 204.868 44.049C205.564 44.049 206.128 43.4846 206.128 42.7885Z"
          fill="#EF4E05"
        />
        <path
          d="M188.447 51.7323C189.102 51.7323 189.634 51.2012 189.634 50.5457C189.634 49.8902 189.102 49.359 188.447 49.359C187.791 49.359 187.26 49.8902 187.26 50.5457C187.26 51.2012 187.791 51.7323 188.447 51.7323Z"
          fill="#EF4E05"
        />
        <path
          d="M177.589 53.0297C178.243 53.0297 178.77 52.5023 178.77 51.8482C178.77 51.1941 178.243 50.6672 177.589 50.6672C176.935 50.6672 176.408 51.1941 176.408 51.8482C176.408 52.5023 176.935 53.0297 177.589 53.0297Z"
          fill="#EF4E05"
        />
        <path
          d="M166.494 54.6116C167.137 54.6116 167.664 54.0893 167.664 53.4408C167.664 52.7924 167.142 52.2701 166.494 52.2701C165.845 52.2701 165.323 52.7924 165.323 53.4408C165.323 54.0893 165.85 54.6116 166.494 54.6116Z"
          fill="#EF4E05"
        />
        <path
          d="M155.109 58.9513C155.741 58.9513 156.253 58.4398 156.253 57.8072C156.253 57.1741 155.741 56.6626 155.109 56.6626C154.476 56.6626 153.964 57.1741 153.964 57.8072C153.964 58.4398 154.476 58.9513 155.109 58.9513Z"
          fill="#EF4E05"
        />
        <path
          d="M144.873 62.7112C145.501 62.7112 146.012 62.1997 146.012 61.5722C146.012 60.9448 145.501 60.4332 144.873 60.4332C144.245 60.4332 143.734 60.9448 143.734 61.5722C143.734 62.1997 144.24 62.7112 144.873 62.7112Z"
          fill="#EF4E05"
        />
        <path
          d="M133.303 63.0275C132.67 63.0275 132.158 63.5391 132.158 64.1721C132.158 64.8047 132.67 65.3163 133.303 65.3163C133.936 65.3163 134.447 64.8047 134.447 64.1721C134.447 63.5391 133.936 63.0275 133.303 63.0275Z"
          fill="#EF4E05"
        />
        <path
          d="M122.529 68.1484C123.162 68.1484 123.679 67.6368 123.679 66.9986C123.679 66.3604 123.168 65.8489 122.529 65.8489C121.897 65.8489 121.38 66.3604 121.38 66.9986C121.38 67.6368 121.897 68.1484 122.529 68.1484Z"
          fill="#EF4E05"
        />
        <path
          d="M110.886 66.5188C110.237 66.5188 109.71 67.0463 109.71 67.6947C109.71 68.3432 110.237 68.8706 110.886 68.8706C111.535 68.8706 112.062 68.3432 112.062 67.6947C112.062 67.0463 111.535 66.5188 110.886 66.5188Z"
          fill="#EF4E05"
        />
        <path
          d="M107.648 78.0359C107.648 77.4239 107.152 76.9282 106.541 76.9282C105.929 76.9282 105.433 77.4239 105.433 78.0359C105.433 78.6475 105.929 79.1432 106.541 79.1432C107.152 79.1432 107.648 78.6475 107.648 78.0359Z"
          fill="#EF4E05"
        />
        <path
          d="M118.58 80.1028C119.16 80.1028 119.635 79.6337 119.635 79.0483C119.635 78.4629 119.165 77.9934 118.58 77.9934C117.999 77.9934 117.525 78.4629 117.525 79.0483C117.525 79.6337 117.994 80.1028 118.58 80.1028Z"
          fill="#EF4E05"
        />
        <path
          d="M131.099 79.6706C131.668 79.6706 132.127 79.2118 132.127 78.6423C132.127 78.0728 131.668 77.6141 131.099 77.6141C130.529 77.6141 130.071 78.0728 130.071 78.6423C130.071 79.2118 130.529 79.6706 131.099 79.6706Z"
          fill="#EF4E05"
        />
        <path
          d="M143.259 73.5743C143.259 73.0048 142.795 72.5409 142.226 72.5409C141.656 72.5409 141.192 73.0048 141.192 73.5743C141.192 74.1438 141.656 74.6082 142.226 74.6082C142.8 74.6082 143.259 74.1438 143.259 73.5743Z"
          fill="#EF4E05"
        />
        <path
          d="M154.845 74.2391C155.404 74.2391 155.857 73.7855 155.857 73.2267C155.857 72.6675 155.404 72.2138 154.845 72.2138C154.285 72.2138 153.832 72.6675 153.832 73.2267C153.832 73.7855 154.285 74.2391 154.845 74.2391Z"
          fill="#EF4E05"
        />
        <path
          d="M146.713 86.378C147.226 86.378 147.641 85.9627 147.641 85.4502C147.641 84.9372 147.226 84.5219 146.713 84.5219C146.2 84.5219 145.785 84.9372 145.785 85.4502C145.785 85.9627 146.2 86.378 146.713 86.378Z"
          fill="#EF4E05"
        />
        <path
          d="M156.179 96.2604C156.179 95.8017 155.81 95.4326 155.351 95.4326C154.892 95.4326 154.523 95.8017 154.523 96.2604C154.523 96.7192 154.892 97.0883 155.351 97.0883C155.804 97.0939 156.179 96.7192 156.179 96.2604Z"
          fill="#EF4E05"
        />
        <path
          d="M156.907 111.005C157.313 111.005 157.64 110.678 157.64 110.272C157.64 109.866 157.313 109.539 156.907 109.539C156.501 109.539 156.174 109.866 156.174 110.272C156.174 110.678 156.501 111.005 156.907 111.005Z"
          fill="#EF4E05"
        />
        <path
          d="M157.35 125.765C157.35 126.118 157.634 126.403 157.987 126.403C158.341 126.403 158.626 126.118 158.626 125.765C158.626 125.412 158.341 125.127 157.987 125.127C157.634 125.127 157.35 125.412 157.35 125.765Z"
          fill="#EF4E05"
        />
        <path
          d="M143.148 133.955C143.492 133.955 143.77 133.676 143.77 133.332C143.77 132.989 143.492 132.71 143.148 132.71C142.805 132.71 142.526 132.989 142.526 133.332C142.526 133.676 142.805 133.955 143.148 133.955Z"
          fill="#EF4E05"
        />
        <path
          d="M142.684 117.391C143.08 117.391 143.402 117.069 143.402 116.674C143.402 116.278 143.08 115.957 142.684 115.957C142.289 115.957 141.967 116.278 141.967 116.674C141.967 117.069 142.289 117.391 142.684 117.391Z"
          fill="#EF4E05"
        />
        <path
          d="M142.241 101.787C142.241 102.24 142.611 102.604 143.059 102.604C143.512 102.604 143.876 102.235 143.876 101.787C143.876 101.334 143.507 100.97 143.059 100.97C142.611 100.97 142.241 101.334 142.241 101.787Z"
          fill="#EF4E05"
        />
        <path
          d="M134.701 90.7285C134.199 90.7285 133.788 91.1349 133.788 91.6409C133.788 92.1417 134.194 92.5533 134.701 92.5533C135.207 92.5533 135.613 92.1473 135.613 91.6409C135.613 91.1349 135.207 90.7285 134.701 90.7285Z"
          fill="#EF4E05"
        />
        <path
          d="M122.203 92.7379C122.725 92.7379 123.152 92.316 123.152 91.789C123.152 91.2667 122.73 90.8397 122.203 90.8397C121.681 90.8397 121.254 91.2615 121.254 91.789C121.259 92.316 121.681 92.7379 122.203 92.7379Z"
          fill="#EF4E05"
        />
        <path
          d="M107.933 89.2839C107.933 89.8427 108.386 90.2963 108.945 90.2963C109.504 90.2963 109.958 89.8427 109.958 89.2839C109.958 88.7247 109.504 88.2715 108.945 88.2715C108.386 88.2715 107.933 88.7247 107.933 89.2839Z"
          fill="#EF4E05"
        />
        <path
          d="M102.401 99.8886C102.401 100.41 102.823 100.838 103.35 100.838C103.872 100.838 104.299 100.416 104.299 99.8886C104.299 99.3663 103.877 98.9393 103.35 98.9393C102.823 98.9445 102.401 99.3663 102.401 99.8886Z"
          fill="#EF4E05"
        />
        <path
          d="M115.537 104.17C116.022 104.17 116.418 103.775 116.418 103.29C116.418 102.805 116.022 102.409 115.537 102.409C115.052 102.409 114.656 102.805 114.656 103.29C114.662 103.78 115.052 104.17 115.537 104.17Z"
          fill="#EF4E05"
        />
        <path
          d="M129.111 107.857C129.559 107.857 129.923 107.493 129.923 107.045C129.923 106.596 129.559 106.233 129.111 106.233C128.662 106.233 128.299 106.596 128.299 107.045C128.299 107.493 128.662 107.857 129.111 107.857Z"
          fill="#EF4E05"
        />
        <path
          d="M129.1 124.631C129.496 124.631 129.812 124.309 129.812 123.919C129.812 123.529 129.491 123.207 129.1 123.207C128.71 123.207 128.388 123.529 128.388 123.919C128.388 124.309 128.705 124.631 129.1 124.631Z"
          fill="#EF4E05"
        />
        <path
          d="M116.866 117.855C116.866 117.423 116.518 117.075 116.086 117.075C115.653 117.075 115.305 117.423 115.305 117.855C115.305 118.287 115.653 118.635 116.086 118.635C116.513 118.635 116.866 118.287 116.866 117.855Z"
          fill="#EF4E05"
        />
        <path
          d="M103.277 113.562C103.754 113.562 104.141 113.175 104.141 112.698C104.141 112.22 103.754 111.833 103.277 111.833C102.799 111.833 102.411 112.22 102.411 112.698C102.411 113.175 102.799 113.562 103.277 113.562Z"
          fill="#EF4E05"
        />
        <path
          d="M90.2825 116.468C89.7925 116.468 89.3916 116.863 89.3916 117.359C89.3916 117.85 89.7868 118.25 90.2825 118.25C90.7731 118.25 91.1739 117.855 91.1739 117.359C91.1688 116.869 90.7731 116.468 90.2825 116.468Z"
          fill="#EF4E05"
        />
        <path
          d="M77.7323 121.989C78.2387 121.989 78.6554 121.578 78.6554 121.066C78.6554 120.555 78.2438 120.144 77.7323 120.144C77.2263 120.144 76.8096 120.555 76.8096 121.066C76.8096 121.578 77.2263 121.989 77.7323 121.989Z"
          fill="#EF4E05"
        />
        <path
          d="M66.5101 121.351C66.5101 120.808 66.0672 120.365 65.5243 120.365C64.981 120.365 64.5381 120.808 64.5381 121.351C64.5381 121.895 64.981 122.337 65.5243 122.337C66.0728 122.337 66.5101 121.895 66.5101 121.351Z"
          fill="#EF4E05"
        />
        <path
          d="M53.3801 118.403C52.7896 118.403 52.3093 118.883 52.3093 119.474C52.3093 120.065 52.7896 120.544 53.3801 120.544C53.9706 120.544 54.4505 120.065 54.4505 119.474C54.4505 118.883 53.9706 118.403 53.3801 118.403Z"
          fill="#EF4E05"
        />
        <path
          d="M68.1558 107.867C67.5755 107.867 67.1013 108.336 67.1013 108.922C67.1013 109.502 67.5704 109.977 68.1558 109.977C68.736 109.977 69.2107 109.507 69.2107 108.922C69.2051 108.336 68.736 107.867 68.1558 107.867Z"
          fill="#EF4E05"
        />
        <path
          d="M80.7592 107.33C80.7592 106.77 80.3055 106.317 79.7468 106.317C79.188 106.317 78.7344 106.77 78.7344 107.33C78.7344 107.888 79.188 108.342 79.7468 108.342C80.3055 108.342 80.7592 107.888 80.7592 107.33Z"
          fill="#EF4E05"
        />
        <path
          d="M90.5728 103.907C90.5728 104.445 91.0105 104.882 91.5482 104.882C92.0864 104.882 92.5242 104.445 92.5242 103.907C92.5242 103.369 92.0864 102.931 91.5482 102.931C91.0105 102.931 90.5728 103.369 90.5728 103.907Z"
          fill="#EF4E05"
        />
        <path
          d="M95.7934 91.1242C95.7934 90.5439 95.3239 90.0697 94.7385 90.0697C94.1588 90.0697 93.6841 90.5388 93.6841 91.1242C93.6841 91.7096 94.1536 92.1791 94.7385 92.1791C95.3188 92.1791 95.7934 91.7096 95.7934 91.1242Z"
          fill="#EF4E05"
        />
        <path
          d="M96.7587 80.1453C96.7587 79.5174 96.2471 79.0058 95.6192 79.0058C94.9918 79.0058 94.4802 79.5174 94.4802 80.1453C94.4802 80.7727 94.9918 81.2843 95.6192 81.2843C96.2471 81.2843 96.7587 80.7727 96.7587 80.1453Z"
          fill="#EF4E05"
        />
        <path
          d="M99.796 68.9445C99.796 68.2694 99.2476 67.7209 98.5725 67.7209C97.8978 67.7209 97.3494 68.2694 97.3494 68.9445C97.3494 69.6196 97.8978 70.168 98.5725 70.168C99.2527 70.168 99.796 69.6196 99.796 68.9445Z"
          fill="#EF4E05"
        />
        <path
          d="M102.654 60.0324C103.377 60.0324 103.962 59.447 103.962 58.7247C103.962 58.0025 103.377 57.4171 102.654 57.4171C101.932 57.4171 101.346 58.0025 101.346 58.7247C101.346 59.447 101.932 60.0324 102.654 60.0324Z"
          fill="#EF4E05"
        />
        <path
          d="M114.229 58.0445C114.925 58.0445 115.495 57.4801 115.495 56.7789C115.495 56.0777 114.93 55.5133 114.229 55.5133C113.533 55.5133 112.964 56.0777 112.964 56.7789C112.964 57.4801 113.528 58.0445 114.229 58.0445Z"
          fill="#EF4E05"
        />
        <path
          d="M126.252 54.079C126.252 53.3829 125.689 52.8134 124.987 52.8134C124.291 52.8134 123.721 53.3773 123.721 54.079C123.721 54.7803 124.286 55.3446 124.987 55.3446C125.689 55.3446 126.252 54.7751 126.252 54.079Z"
          fill="#EF4E05"
        />
        <path
          d="M134.479 51.5422C134.479 52.2332 135.038 52.7975 135.734 52.7975C136.43 52.7975 136.989 52.2383 136.989 51.5422C136.989 50.8517 136.43 50.2873 135.734 50.2873C135.038 50.2873 134.479 50.8517 134.479 51.5422Z"
          fill="#EF4E05"
        />
        <path
          d="M146.65 49.8972C147.346 49.8972 147.91 49.3329 147.91 48.6368C147.91 47.9407 147.346 47.3763 146.65 47.3763C145.954 47.3763 145.389 47.9407 145.389 48.6368C145.389 49.3329 145.954 49.8972 146.65 49.8972Z"
          fill="#EF4E05"
        />
        <path
          d="M157.861 46.4746C158.563 46.4746 159.132 45.9051 159.132 45.2039C159.132 44.5021 158.563 43.9326 157.861 43.9326C157.16 43.9326 156.59 44.5021 156.59 45.2039C156.596 45.9051 157.16 46.4746 157.861 46.4746Z"
          fill="#EF4E05"
        />
        <path
          d="M168.434 41.5439C168.434 42.2559 169.009 42.8305 169.721 42.8305C170.433 42.8305 171.007 42.2559 171.007 41.5439C171.007 40.8319 170.433 40.2573 169.721 40.2573C169.009 40.2624 168.434 40.8375 168.434 41.5439Z"
          fill="#EF4E05"
        />
        <path
          d="M178.069 31.9624C178.839 31.9624 179.461 31.3401 179.461 30.5701C179.461 29.8002 178.839 29.1779 178.069 29.1779C177.299 29.1779 176.677 29.8002 176.677 30.5701C176.677 31.3401 177.299 31.9624 178.069 31.9624Z"
          fill="#EF4E05"
        />
        <path
          d="M168.017 32.0203C168.793 32.0203 169.42 31.3929 169.42 30.6178C169.42 29.8423 168.793 29.2148 168.017 29.2148C167.242 29.2148 166.615 29.8423 166.615 30.6178C166.615 31.3929 167.242 32.0203 168.017 32.0203Z"
          fill="#EF4E05"
        />
        <path
          d="M158.631 35.2584C159.396 35.2584 160.013 34.6412 160.013 33.8764C160.013 33.1121 159.396 32.495 158.631 32.495C157.867 32.495 157.25 33.1121 157.25 33.8764C157.25 34.6412 157.867 35.2584 158.631 35.2584Z"
          fill="#EF4E05"
        />
        <path
          d="M148.38 38.1535C149.134 38.1535 149.745 37.5415 149.745 36.7875C149.745 36.0334 149.134 35.4219 148.38 35.4219C147.625 35.4219 147.014 36.0334 147.014 36.7875C147.014 37.5415 147.625 38.1535 148.38 38.1535Z"
          fill="#EF4E05"
        />
        <path
          d="M137.901 41.038C138.661 41.038 139.272 40.4259 139.272 39.6668C139.272 38.9076 138.661 38.2956 137.901 38.2956C137.142 38.2956 136.531 38.9076 136.531 39.6668C136.531 40.4259 137.147 41.038 137.901 41.038Z"
          fill="#EF4E05"
        />
        <path
          d="M126.907 42.9992C127.67 42.9992 128.288 42.3807 128.288 41.6177C128.288 40.8548 127.67 40.2363 126.907 40.2363C126.144 40.2363 125.525 40.8548 125.525 41.6177C125.525 42.3807 126.144 42.9992 126.907 42.9992Z"
          fill="#EF4E05"
        />
        <path
          d="M115.041 45.3038C115.041 46.0686 115.658 46.6858 116.423 46.6858C117.188 46.6858 117.805 46.0686 117.805 45.3038C117.805 44.5395 117.188 43.9224 116.423 43.9224C115.664 43.9224 115.041 44.5395 115.041 45.3038Z"
          fill="#EF4E05"
        />
        <path
          d="M105.792 49.5333C106.558 49.5333 107.178 48.9124 107.178 48.1462C107.178 47.3805 106.558 46.7596 105.792 46.7596C105.026 46.7596 104.405 47.3805 104.405 48.1462C104.405 48.9124 105.026 49.5333 105.792 49.5333Z"
          fill="#EF4E05"
        />
        <path
          d="M115.173 36.0334C115.996 36.0334 116.66 35.3691 116.66 34.5464C116.66 33.7237 115.996 33.0593 115.173 33.0593C114.351 33.0593 113.686 33.7237 113.686 34.5464C113.686 35.3691 114.351 36.0334 115.173 36.0334Z"
          fill="#EF4E05"
        />
        <path
          d="M123.121 32.1366C123.954 32.1366 124.629 31.4615 124.629 30.6281C124.629 29.7951 123.954 29.12 123.121 29.12C122.287 29.12 121.612 29.7951 121.612 30.6281C121.612 31.4615 122.287 32.1366 123.121 32.1366Z"
          fill="#EF4E05"
        />
        <path
          d="M132.111 31.3032C132.934 31.3032 133.604 30.6332 133.604 29.811C133.604 28.9882 132.934 28.3183 132.111 28.3183C131.289 28.3183 130.619 28.9882 130.619 29.811C130.619 30.6332 131.289 31.3032 132.111 31.3032Z"
          fill="#EF4E05"
        />
        <path
          d="M141.345 28.8668C142.167 28.8668 142.837 28.1973 142.837 27.3746C142.837 26.5519 142.167 25.8819 141.345 25.8819C140.522 25.8819 139.853 26.5519 139.853 27.3746C139.853 28.1973 140.522 28.8668 141.345 28.8668Z"
          fill="#EF4E05"
        />
        <path
          d="M151.095 23.4824C150.273 23.4824 149.603 24.1524 149.603 24.9751C149.603 25.7978 150.273 26.4673 151.095 26.4673C151.918 26.4673 152.588 25.7978 152.588 24.9751C152.588 24.1524 151.923 23.4824 151.095 23.4824Z"
          fill="#EF4E05"
        />
        <path
          d="M159.792 21.9692C159.792 22.8078 160.472 23.4824 161.305 23.4824C162.143 23.4824 162.818 22.8022 162.818 21.9692C162.818 21.1362 162.138 20.4555 161.305 20.4555C160.472 20.4555 159.792 21.1306 159.792 21.9692Z"
          fill="#EF4E05"
        />
        <path
          d="M159.506 13.6744C160.407 13.6744 161.136 12.9448 161.136 12.0449C161.136 11.145 160.407 10.4155 159.506 10.4155C158.606 10.4155 157.877 11.145 157.877 12.0449C157.877 12.9448 158.606 13.6744 159.506 13.6744Z"
          fill="#EF4E05"
        />
        <path
          d="M150.758 15.8047C151.652 15.8047 152.377 15.0798 152.377 14.1857C152.377 13.2916 151.652 12.5668 150.758 12.5668C149.864 12.5668 149.139 13.2916 149.139 14.1857C149.139 15.0798 149.864 15.8047 150.758 15.8047Z"
          fill="#EF4E05"
        />
        <path
          d="M142.004 17.6505C142.895 17.6505 143.618 16.928 143.618 16.0368C143.618 15.1456 142.895 14.4232 142.004 14.4232C141.113 14.4232 140.39 15.1456 140.39 16.0368C140.39 16.928 141.113 17.6505 142.004 17.6505Z"
          fill="#EF4E05"
        />
        <path
          d="M133.235 19.8071C134.126 19.8071 134.848 19.0848 134.848 18.1936C134.848 17.3024 134.126 16.5799 133.235 16.5799C132.343 16.5799 131.621 17.3024 131.621 18.1936C131.621 19.0848 132.343 19.8071 133.235 19.8071Z"
          fill="#EF4E05"
        />
        <path
          d="M124.254 21.6104C125.156 21.6104 125.883 20.883 125.883 19.9813C125.883 19.0792 125.156 18.3517 124.254 18.3517C123.352 18.3517 122.625 19.0792 122.625 19.9813C122.625 20.883 123.352 21.6104 124.254 21.6104Z"
          fill="#EF4E05"
        />
        <path
          d="M115.326 24.3318C116.223 24.3318 116.945 23.6044 116.945 22.713C116.945 21.8216 116.217 21.0942 115.326 21.0942C114.43 21.0942 113.707 21.8216 113.707 22.713C113.707 23.6044 114.43 24.3318 115.326 24.3318Z"
          fill="#EF4E05"
        />
        <path
          d="M106.878 27.7437C107.775 27.7437 108.497 27.0158 108.497 26.1249C108.497 25.2283 107.769 24.5061 106.878 24.5061C105.987 24.5061 105.259 25.2335 105.259 26.1249C105.259 27.0158 105.982 27.7437 106.878 27.7437Z"
          fill="#EF4E05"
        />
        <path
          d="M98.2353 30.7496C99.1318 30.7496 99.8541 30.0217 99.8541 29.1307C99.8541 28.2394 99.1267 27.5115 98.2353 27.5115C97.3439 27.5115 96.6165 28.2394 96.6165 29.1307C96.6165 30.0217 97.3439 30.7496 98.2353 30.7496Z"
          fill="#EF4E05"
        />
        <path
          d="M89.7448 34.003C90.6413 34.003 91.3692 33.2756 91.3692 32.3791C91.3692 31.4826 90.6413 30.7547 89.7448 30.7547C88.8483 30.7547 88.1208 31.4826 88.1208 32.3791C88.1208 33.2756 88.8483 34.003 89.7448 34.003Z"
          fill="#EF4E05"
        />
        <path
          d="M106.298 38.8179C107.126 38.8179 107.801 38.1428 107.801 37.3149C107.801 36.4871 107.126 35.812 106.298 35.812C105.47 35.812 104.795 36.4871 104.795 37.3149C104.795 38.1428 105.47 38.8179 106.298 38.8179Z"
          fill="#EF4E05"
        />
        <path
          d="M96.938 41.892C97.7715 41.892 98.4466 41.2174 98.4466 40.3839C98.4466 39.5509 97.7715 38.8758 96.938 38.8758C96.105 38.8758 95.4299 39.5509 95.4299 40.3839C95.4299 41.2174 96.105 41.892 96.938 41.892Z"
          fill="#EF4E05"
        />
        <path
          d="M94.7707 52.1276C95.5565 52.1276 96.1891 51.495 96.1891 50.7092C96.1891 49.9234 95.5565 49.2908 94.7707 49.2908C93.9849 49.2908 93.3523 49.9234 93.3523 50.7092C93.3523 51.495 93.99 52.1276 94.7707 52.1276Z"
          fill="#EF4E05"
        />
        <path
          d="M89.4077 60.5757C89.4077 61.3139 90.0085 61.9152 90.7471 61.9152C91.4853 61.9152 92.0866 61.3139 92.0866 60.5757C92.0866 59.8376 91.4853 59.2363 90.7471 59.2363C90.0085 59.2363 89.4077 59.832 89.4077 60.5757Z"
          fill="#EF4E05"
        />
        <path
          d="M79.694 63.7082C80.4536 63.7082 81.0703 63.0911 81.0703 62.3314C81.0703 61.5722 80.4536 60.9551 79.694 60.9551C78.9348 60.9551 78.3176 61.5722 78.3176 62.3314C78.3176 63.0911 78.9348 63.7082 79.694 63.7082Z"
          fill="#EF4E05"
        />
        <path
          d="M69.3896 65.4378C70.1754 65.4378 70.8084 64.8047 70.8084 64.0194C70.8084 63.2336 70.1754 62.6005 69.3896 62.6005C68.6042 62.6005 67.9712 63.2336 67.9712 64.0194C67.9712 64.8047 68.6094 65.4378 69.3896 65.4378Z"
          fill="#EF4E05"
        />
        <path
          d="M74.1938 73.4692C74.1938 74.1807 74.7741 74.7609 75.4861 74.7609C76.1981 74.7609 76.7779 74.1807 76.7779 73.4692C76.7779 72.7572 76.1981 72.1769 75.4861 72.1769C74.7741 72.1769 74.1938 72.7572 74.1938 73.4692Z"
          fill="#EF4E05"
        />
        <path
          d="M86.9236 70.0942C86.2326 70.0942 85.6687 70.653 85.6687 71.3491C85.6687 72.0452 86.2275 72.6044 86.9236 72.6044C87.6145 72.6044 88.1789 72.0452 88.1789 71.3491C88.1789 70.653 87.6197 70.0942 86.9236 70.0942Z"
          fill="#EF4E05"
        />
        <path
          d="M82.895 82.6555C82.895 83.3039 83.4225 83.8314 84.0709 83.8314C84.7194 83.8314 85.2468 83.3039 85.2468 82.6555C85.2468 82.0066 84.7194 81.4796 84.0709 81.4796C83.4225 81.4847 82.895 82.0066 82.895 82.6555Z"
          fill="#EF4E05"
        />
        <path
          d="M82.3567 95.3798C82.957 95.3798 83.4434 94.8935 83.4434 94.2936C83.4434 93.6937 82.957 93.2074 82.3567 93.2074C81.7568 93.2074 81.2705 93.6937 81.2705 94.2936C81.2705 94.8935 81.7568 95.3798 82.3567 95.3798Z"
          fill="#EF4E05"
        />
        <path
          d="M69.8484 96.345C69.8484 96.9724 70.3548 97.4789 70.9823 97.4789C71.6097 97.4789 72.1161 96.9724 72.1161 96.345C72.1161 95.7176 71.6097 95.2112 70.9823 95.2112C70.3548 95.2112 69.8484 95.7176 69.8484 96.345Z"
          fill="#EF4E05"
        />
        <path
          d="M59.9401 96.5664C59.2865 96.5664 58.759 97.0939 58.759 97.7475C58.759 98.4016 59.2865 98.929 59.9401 98.929C60.5942 98.929 61.1216 98.4016 61.1216 97.7475C61.1216 97.0939 60.5942 96.5664 59.9401 96.5664Z"
          fill="#EF4E05"
        />
        <path
          d="M57.3717 108.974C57.3717 108.357 56.8709 107.851 56.2486 107.851C55.6315 107.851 55.1255 108.352 55.1255 108.974C55.1255 109.592 55.6263 110.098 56.2486 110.098C56.8709 110.098 57.3717 109.597 57.3717 108.974Z"
          fill="#EF4E05"
        />
        <path
          d="M43.4398 111.147C42.7858 111.147 42.2583 111.674 42.2583 112.328C42.2583 112.982 42.7858 113.509 43.4398 113.509C44.0934 113.509 44.6209 112.982 44.6209 112.328C44.6209 111.674 44.0934 111.147 43.4398 111.147Z"
          fill="#EF4E05"
        />
        <path
          d="M45.2694 125.997C45.2694 125.396 44.7793 124.906 44.178 124.906C43.5767 124.906 43.0862 125.396 43.0862 125.997C43.0862 126.598 43.5767 127.089 44.178 127.089C44.7793 127.089 45.2694 126.598 45.2694 125.997Z"
          fill="#EF4E05"
        />
        <path
          d="M58.5371 131.223C58.5371 130.685 58.0994 130.247 57.5616 130.247C57.0234 130.247 56.5857 130.685 56.5857 131.223C56.5857 131.761 57.0234 132.198 57.5616 132.198C58.0994 132.198 58.5371 131.761 58.5371 131.223Z"
          fill="#EF4E05"
        />
        <path
          d="M45.0376 138.511C45.0376 139.059 45.4805 139.508 46.0341 139.508C46.5826 139.508 47.0311 139.065 47.0311 138.511C47.0311 137.957 46.5882 137.514 46.0341 137.514C45.4805 137.519 45.0376 137.962 45.0376 138.511Z"
          fill="#EF4E05"
        />
        <path
          d="M46.1505 152.628C46.663 152.628 47.0783 152.212 47.0783 151.699C47.0783 151.187 46.663 150.771 46.1505 150.771C45.6375 150.771 45.2222 151.187 45.2222 151.699C45.2222 152.212 45.6375 152.628 46.1505 152.628Z"
          fill="#EF4E05"
        />
        <path
          d="M59.1385 144.417C59.6337 144.417 60.035 144.015 60.035 143.521C60.035 143.025 59.6337 142.624 59.1385 142.624C58.6432 142.624 58.2419 143.025 58.2419 143.521C58.2419 144.015 58.6432 144.417 59.1385 144.417Z"
          fill="#EF4E05"
        />
        <path
          d="M73.1604 135.094C73.1604 134.614 72.7703 134.224 72.2901 134.224C71.8103 134.224 71.4202 134.614 71.4202 135.094C71.4202 135.573 71.8103 135.963 72.2901 135.963C72.7703 135.958 73.1604 135.573 73.1604 135.094Z"
          fill="#EF4E05"
        />
        <path
          d="M72.2745 150.492C72.2745 150.054 71.9208 149.701 71.4836 149.701C71.0458 149.701 70.6921 150.054 70.6921 150.492C70.6921 150.929 71.0458 151.283 71.4836 151.283C71.9208 151.283 72.2745 150.929 72.2745 150.492Z"
          fill="#EF4E05"
        />
        <path
          d="M84.6458 146.278C84.6458 146.69 84.9779 147.017 85.3839 147.017C85.7955 147.017 86.1225 146.684 86.1225 146.278C86.1225 145.867 85.7899 145.54 85.3839 145.54C84.9779 145.535 84.6458 145.867 84.6458 146.278Z"
          fill="#EF4E05"
        />
        <path
          d="M87.393 131.149C86.9445 131.149 86.5806 131.513 86.5806 131.962C86.5806 132.41 86.9445 132.773 87.393 132.773C87.841 132.773 88.205 132.41 88.205 131.962C88.205 131.513 87.8462 131.149 87.393 131.149Z"
          fill="#EF4E05"
        />
        <path
          d="M101.594 127.41C102.027 127.41 102.375 127.062 102.375 126.63C102.375 126.197 102.027 125.849 101.594 125.849C101.162 125.849 100.814 126.197 100.814 126.63C100.814 127.062 101.162 127.41 101.594 127.41Z"
          fill="#EF4E05"
        />
        <path
          d="M114.661 133.011C114.277 133.011 113.966 133.322 113.966 133.707C113.966 134.092 114.277 134.403 114.661 134.403C115.046 134.403 115.358 134.092 115.358 133.707C115.358 133.322 115.046 133.011 114.661 133.011Z"
          fill="#EF4E05"
        />
        <path
          d="M127.967 141.279C128.307 141.279 128.583 141.003 128.583 140.662C128.583 140.321 128.307 140.045 127.967 140.045C127.626 140.045 127.35 140.321 127.35 140.662C127.35 141.003 127.626 141.279 127.967 141.279Z"
          fill="#EF4E05"
        />
        <path
          d="M139.757 152.005C140.063 152.005 140.306 151.758 140.306 151.457C140.306 151.156 140.058 150.908 139.757 150.908C139.452 150.908 139.209 151.156 139.209 151.457C139.209 151.758 139.457 152.005 139.757 152.005Z"
          fill="#EF4E05"
        />
        <path
          d="M115.816 152.923C116.148 152.923 116.417 152.654 116.417 152.322C116.417 151.99 116.148 151.721 115.816 151.721C115.484 151.721 115.215 151.99 115.215 152.322C115.215 152.654 115.484 152.923 115.816 152.923Z"
          fill="#EF4E05"
        />
        <path
          d="M101.03 142.698C101.415 142.698 101.726 142.387 101.726 142.002C101.726 141.617 101.415 141.306 101.03 141.306C100.645 141.306 100.334 141.617 100.334 142.002C100.334 142.387 100.645 142.698 101.03 142.698Z"
          fill="#EF4E05"
        />
        <path
          d="M98.657 157.563C98.657 157.21 98.372 156.925 98.0188 156.925C97.6656 156.925 97.3806 157.21 97.3806 157.563C97.3806 157.917 97.6656 158.202 98.0188 158.202C98.372 158.202 98.657 157.917 98.657 157.563Z"
          fill="#EF4E05"
        />
        <path
          d="M110.912 169.887C110.612 169.887 110.369 170.13 110.369 170.431C110.369 170.731 110.612 170.974 110.912 170.974C111.213 170.974 111.456 170.731 111.456 170.431C111.456 170.13 111.213 169.887 110.912 169.887Z"
          fill="#EF4E05"
        />
        <path
          d="M129.448 166.354C129.448 166.633 129.675 166.86 129.954 166.86C130.234 166.86 130.461 166.633 130.461 166.354C130.461 166.075 130.234 165.848 129.954 165.848C129.675 165.848 129.448 166.075 129.448 166.354Z"
          fill="#EF4E05"
        />
        <path
          d="M124.497 187.332C124.497 187.585 124.702 187.785 124.95 187.785C125.198 187.785 125.404 187.579 125.404 187.332C125.404 187.078 125.198 186.878 124.95 186.878C124.702 186.878 124.497 187.078 124.497 187.332Z"
          fill="#EF4E05"
        />
        <path
          d="M105.602 189.494C105.879 189.494 106.103 189.27 106.103 188.993C106.103 188.716 105.879 188.492 105.602 188.492C105.325 188.492 105.101 188.716 105.101 188.993C105.101 189.27 105.325 189.494 105.602 189.494Z"
          fill="#EF4E05"
        />
        <path
          d="M87.2504 189.932C87.5615 189.932 87.8148 189.678 87.8148 189.367C87.8148 189.056 87.5615 188.803 87.2504 188.803C86.9392 188.803 86.686 189.056 86.686 189.367C86.686 189.678 86.9392 189.932 87.2504 189.932Z"
          fill="#EF4E05"
        />
        <path
          d="M92.5873 173.864C92.5873 173.537 92.3238 173.273 91.9968 173.273C91.6697 173.273 91.4062 173.537 91.4062 173.864C91.4062 174.19 91.6697 174.454 91.9968 174.454C92.3182 174.459 92.5873 174.19 92.5873 173.864Z"
          fill="#EF4E05"
        />
        <path
          d="M81.2286 163.259C81.6079 163.259 81.9139 162.953 81.9139 162.573C81.9139 162.194 81.6079 161.888 81.2286 161.888C80.8487 161.888 80.5427 162.194 80.5427 162.573C80.5427 162.953 80.8487 163.259 81.2286 163.259Z"
          fill="#EF4E05"
        />
        <path
          d="M58.2578 157.985C58.7166 157.985 59.0856 157.611 59.0856 157.157C59.0856 156.699 58.711 156.33 58.2578 156.33C57.8042 156.33 57.4299 156.704 57.4299 157.157C57.4299 157.616 57.799 157.985 58.2578 157.985Z"
          fill="#EF4E05"
        />
        <path
          d="M66.621 167.794C67.0326 167.794 67.3596 167.462 67.3596 167.056C67.3596 166.649 67.0274 166.317 66.621 166.317C66.2099 166.317 65.8828 166.649 65.8828 167.056C65.8828 167.462 66.2099 167.794 66.621 167.794Z"
          fill="#EF4E05"
        />
        <path
          d="M73.134 181.452C73.4975 181.452 73.7876 181.156 73.7876 180.798C73.7876 180.439 73.4924 180.144 73.134 180.144C72.7752 180.144 72.48 180.439 72.48 180.798C72.48 181.156 72.7701 181.452 73.134 181.452Z"
          fill="#EF4E05"
        />
        <path
          d="M74.2885 199.967C74.6156 199.967 74.8795 199.703 74.8795 199.376C74.8795 199.049 74.6156 198.785 74.2885 198.785C73.962 198.785 73.698 199.049 73.698 199.376C73.698 199.703 73.962 199.967 74.2885 199.967Z"
          fill="#EF4E05"
        />
        <path
          d="M61.4165 211.315C61.7631 211.315 62.0439 211.034 62.0439 210.687C62.0439 210.341 61.7631 210.06 61.4165 210.06C61.0698 210.06 60.7891 210.341 60.7891 210.687C60.7891 211.034 61.0698 211.315 61.4165 211.315Z"
          fill="#EF4E05"
        />
        <path
          d="M58.6058 192.805C58.2208 192.805 57.9148 193.116 57.9148 193.496C57.9148 193.881 58.2259 194.187 58.6058 194.187C58.9851 194.187 59.2963 193.876 59.2963 193.496C59.2963 193.116 58.9851 192.805 58.6058 192.805Z"
          fill="#EF4E05"
        />
        <path
          d="M56.4069 177.613C55.9901 177.613 55.658 177.95 55.658 178.362C55.658 178.773 55.9953 179.111 56.4069 179.111C56.8231 179.111 57.1553 178.773 57.1553 178.362C57.1553 177.95 56.818 177.613 56.4069 177.613Z"
          fill="#EF4E05"
        />
        <path
          d="M47.3681 166.818C47.84 166.818 48.2222 166.436 48.2222 165.964C48.2222 165.492 47.84 165.109 47.3681 165.109C46.8963 165.109 46.5137 165.492 46.5137 165.964C46.5137 166.436 46.8963 166.818 47.3681 166.818Z"
          fill="#EF4E05"
        />
        <path
          d="M41.1458 179.216C41.1458 179.68 41.52 180.049 41.9788 180.049C42.4427 180.049 42.8122 179.674 42.8122 179.216C42.8122 178.752 42.4375 178.383 41.9788 178.383C41.52 178.378 41.1458 178.752 41.1458 179.216Z"
          fill="#EF4E05"
        />
        <path
          d="M31.875 170.531C31.875 171.042 32.2917 171.459 32.8033 171.459C33.3149 171.459 33.7316 171.042 33.7316 170.531C33.7316 170.019 33.3149 169.602 32.8033 169.602C32.2917 169.602 31.875 170.019 31.875 170.531Z"
          fill="#EF4E05"
        />
        <path
          d="M19.7942 162.768C19.7942 163.348 20.2632 163.823 20.8486 163.823C21.4289 163.823 21.9035 163.354 21.9035 162.768C21.9035 162.188 21.434 161.713 20.8486 161.713C20.2632 161.713 19.7942 162.188 19.7942 162.768Z"
          fill="#EF4E05"
        />
        <path
          d="M20.875 150.866C20.875 151.473 21.3651 151.968 21.9771 151.968C22.5835 151.968 23.0792 151.478 23.0792 150.866C23.0792 150.26 22.5886 149.764 21.9771 149.764C21.3707 149.769 20.875 150.26 20.875 150.866Z"
          fill="#EF4E05"
        />
        <path
          d="M22.452 137.931C21.8138 137.931 21.2915 138.447 21.2915 139.091C21.2915 139.729 21.8087 140.251 22.452 140.251C23.0897 140.251 23.612 139.734 23.612 139.091C23.612 138.453 23.0897 137.931 22.452 137.931Z"
          fill="#EF4E05"
        />
        <path
          d="M34.0951 159.056C34.6385 159.056 35.0762 158.618 35.0762 158.075C35.0762 157.532 34.6385 157.094 34.0951 157.094C33.5523 157.094 33.1145 157.532 33.1145 158.075C33.1196 158.618 33.5574 159.056 34.0951 159.056Z"
          fill="#EF4E05"
        />
        <path
          d="M35.0812 145.403C35.0812 144.828 34.6173 144.359 34.0371 144.359C33.4625 144.359 32.9929 144.823 32.9929 145.403C32.9929 145.983 33.4573 146.447 34.0371 146.447C34.6173 146.442 35.0812 145.978 35.0812 145.403Z"
          fill="#EF4E05"
        />
        <path
          d="M34.0267 131.982C33.4147 131.982 32.9138 132.478 32.9138 133.095C32.9138 133.712 33.4095 134.208 34.0267 134.208C34.6434 134.208 35.139 133.712 35.139 133.095C35.139 132.478 34.6382 131.982 34.0267 131.982Z"
          fill="#EF4E05"
        />
        <path
          d="M33.737 119.11C33.0778 119.11 32.5396 119.648 32.5396 120.307C32.5396 120.966 33.0778 121.504 33.737 121.504C34.3962 121.504 34.9339 120.966 34.9339 120.307C34.9339 119.648 34.4013 119.11 33.737 119.11Z"
          fill="#EF4E05"
        />
        <path
          d="M23.6276 128.765C24.3004 128.765 24.8456 128.22 24.8456 127.548C24.8456 126.875 24.3004 126.329 23.6276 126.329C22.9549 126.329 22.4092 126.875 22.4092 127.548C22.4092 128.22 22.9549 128.765 23.6276 128.765Z"
          fill="#EF4E05"
        />
        <path
          d="M13.7982 121.573C13.06 121.573 12.4639 122.168 12.4639 122.907C12.4639 123.645 13.06 124.241 13.7982 124.241C14.5363 124.241 15.1324 123.645 15.1324 122.907C15.1324 122.174 14.5363 121.573 13.7982 121.573Z"
          fill="#EF4E05"
        />
        <path
          d="M12.7277 112.687C13.513 112.687 14.1461 112.054 14.1461 111.268C14.1461 110.483 13.513 109.85 12.7277 109.85C11.9419 109.85 11.3088 110.483 11.3088 111.268C11.3088 112.054 11.947 112.687 12.7277 112.687Z"
          fill="#EF4E05"
        />
        <path
          d="M10.8291 102.51C11.6649 102.51 12.3428 101.832 12.3428 100.996C12.3428 100.16 11.6649 99.4827 10.8291 99.4827C9.99334 99.4827 9.31592 100.16 9.31592 100.996C9.31592 101.832 9.99334 102.51 10.8291 102.51Z"
          fill="#EF4E05"
        />
        <path
          d="M22.7681 117.09C23.4904 117.09 24.0758 116.505 24.0758 115.782C24.0758 115.06 23.4904 114.475 22.7681 114.475C22.0458 114.475 21.4604 115.06 21.4604 115.782C21.4604 116.505 22.0458 117.09 22.7681 117.09Z"
          fill="#EF4E05"
        />
        <path
          d="M31.4006 110.135C32.1122 110.135 32.6873 109.56 32.6873 108.848C32.6873 108.136 32.1122 107.561 31.4006 107.561C30.6886 107.561 30.1135 108.136 30.1135 108.848C30.1135 109.56 30.6886 110.135 31.4006 110.135Z"
          fill="#EF4E05"
        />
        <path
          d="M21.2018 105.531C21.9774 105.531 22.6099 104.898 22.6099 104.123C22.6099 103.348 21.9774 102.715 21.2018 102.715C20.4268 102.715 19.7942 103.348 19.7942 104.123C19.7942 104.898 20.4216 105.531 21.2018 105.531Z"
          fill="#EF4E05"
        />
        <path
          d="M18.5066 91.5886C17.6736 91.5886 16.9985 92.2632 16.9985 93.0967C16.9985 93.9297 17.6736 94.6047 18.5066 94.6047C19.3401 94.6047 20.0152 93.9297 20.0152 93.0967C20.0152 92.2632 19.3401 91.5886 18.5066 91.5886Z"
          fill="#EF4E05"
        />
        <path
          d="M26.9077 84.3799C26.0794 84.3799 25.4048 85.0545 25.4048 85.8828C25.4048 86.7107 26.0794 87.3853 26.9077 87.3853C27.7356 87.3853 28.4107 86.7107 28.4107 85.8828C28.4107 85.0545 27.7356 84.3799 26.9077 84.3799Z"
          fill="#EF4E05"
        />
        <path
          d="M27.5403 96.6085C27.5403 97.3784 28.1677 98.0059 28.9376 98.0059C29.7076 98.0059 30.335 97.3784 30.335 96.6085C30.335 95.8386 29.7076 95.2112 28.9376 95.2112C28.1677 95.2112 27.5403 95.8386 27.5403 96.6085Z"
          fill="#EF4E05"
        />
        <path
          d="M38.4403 91.7362C39.2065 91.7362 39.8274 91.1153 39.8274 90.3491C39.8274 89.5834 39.2065 88.9621 38.4403 88.9621C37.6746 88.9621 37.0537 89.5834 37.0537 90.3491C37.0537 91.1153 37.6746 91.7362 38.4403 91.7362Z"
          fill="#EF4E05"
        />
        <path
          d="M38.5196 102.667C39.2357 102.667 39.8165 102.087 39.8165 101.371C39.8165 100.654 39.2357 100.073 38.5196 100.073C37.8029 100.073 37.2222 100.654 37.2222 101.371C37.2222 102.087 37.8029 102.667 38.5196 102.667Z"
          fill="#EF4E05"
        />
        <path
          d="M48.4072 101.518C49.0925 101.518 49.6462 100.964 49.6462 100.279C49.6462 99.5934 49.0925 99.0397 48.4072 99.0397C47.7213 99.0397 47.1677 99.5934 47.1677 100.279C47.1729 100.964 47.7269 101.518 48.4072 101.518Z"
          fill="#EF4E05"
        />
        <path
          d="M50.8643 91.0032C51.5866 91.0032 52.1724 90.4173 52.1724 89.6951C52.1724 88.9728 51.5866 88.3874 50.8643 88.3874C50.142 88.3874 49.5566 88.9728 49.5566 89.6951C49.5566 90.4173 50.142 91.0032 50.8643 91.0032Z"
          fill="#EF4E05"
        />
        <path
          d="M61.8066 87.1849C62.5027 87.1849 63.0722 86.621 63.0722 85.9193C63.0722 85.2231 62.5079 84.6536 61.8066 84.6536C61.1105 84.6536 60.541 85.218 60.541 85.9193C60.541 86.621 61.1054 87.1849 61.8066 87.1849Z"
          fill="#EF4E05"
        />
        <path
          d="M72.5063 85.503C73.1762 85.503 73.7196 84.9596 73.7196 84.2897C73.7196 83.6202 73.1762 83.0769 72.5063 83.0769C71.8368 83.0769 71.2935 83.6202 71.2935 84.2897C71.2935 84.9596 71.8368 85.503 72.5063 85.503Z"
          fill="#EF4E05"
        />
        <path
          d="M63.5731 75.0931C64.322 75.0931 64.9335 74.4867 64.9335 73.7327C64.9335 72.9786 64.3271 72.3722 63.5731 72.3722C62.819 72.3722 62.2126 72.9786 62.2126 73.7327C62.2126 74.4867 62.819 75.0931 63.5731 75.0931Z"
          fill="#EF4E05"
        />
        <path
          d="M52.2354 77.8196C52.2354 78.5844 52.8525 79.2011 53.6173 79.2011C54.3816 79.2011 54.9988 78.5844 54.9988 77.8196C54.9988 77.0548 54.3816 76.4381 53.6173 76.4381C52.8576 76.4381 52.2354 77.0604 52.2354 77.8196Z"
          fill="#EF4E05"
        />
        <path
          d="M42.7327 81.7431C42.7327 82.5289 43.3657 83.1615 44.1511 83.1615C44.9369 83.1615 45.5699 82.5289 45.5699 81.7431C45.5699 80.9573 44.9369 80.3242 44.1511 80.3242C43.3657 80.3242 42.7327 80.9573 42.7327 81.7431Z"
          fill="#EF4E05"
        />
        <path
          d="M42.2639 70.289C41.4253 70.289 40.7451 70.9692 40.7451 71.8078C40.7451 72.6464 41.4253 73.3267 42.2639 73.3267C43.1021 73.3267 43.7823 72.6464 43.7823 71.8078C43.7823 70.9692 43.1021 70.289 42.2639 70.289Z"
          fill="#EF4E05"
        />
        <path
          d="M49.8413 67.5153C49.8413 68.3432 50.5108 69.0131 51.3387 69.0131C52.1665 69.0131 52.8365 68.3432 52.8365 67.5153C52.8365 66.6875 52.1665 66.018 51.3387 66.018C50.5108 66.018 49.8413 66.6875 49.8413 67.5153Z"
          fill="#EF4E05"
        />
        <path
          d="M57.335 62.3211C57.335 63.1439 58.0049 63.8133 58.8276 63.8133C59.6499 63.8133 60.3198 63.1439 60.3198 62.3211C60.3198 61.4984 59.6499 60.8285 58.8276 60.8285C58.0049 60.8285 57.335 61.4984 57.335 62.3211Z"
          fill="#EF4E05"
        />
        <path
          d="M64.7122 56.1566C65.556 56.1566 66.2418 55.4713 66.2418 54.6275C66.2418 53.7838 65.556 53.0979 64.7122 53.0979C63.8685 53.0979 63.1831 53.7838 63.1831 54.6275C63.1831 55.4713 63.8685 56.1566 64.7122 56.1566Z"
          fill="#EF4E05"
        />
        <path
          d="M73.7666 53.8889C74.5945 53.8889 75.2644 53.2194 75.2644 52.3915C75.2644 51.5637 74.5945 50.8937 73.7666 50.8937C72.9387 50.8937 72.2688 51.5637 72.2688 52.3915C72.2688 53.2194 72.9387 53.8889 73.7666 53.8889Z"
          fill="#EF4E05"
        />
        <path
          d="M83.375 53.3142C84.1818 53.3142 84.8354 52.6602 84.8354 51.8533C84.8354 51.0465 84.1818 50.3929 83.375 50.3929C82.5681 50.3929 81.9141 51.0465 81.9141 51.8533C81.9141 52.6602 82.5681 53.3142 83.375 53.3142Z"
          fill="#EF4E05"
        />
        <path
          d="M86.7759 44.5974C87.6197 44.5974 88.3055 43.9116 88.3055 43.0679C88.3055 42.2241 87.6197 41.5388 86.7759 41.5388C85.9322 41.5388 85.2468 42.2241 85.2468 43.0679C85.2468 43.9116 85.9322 44.5974 86.7759 44.5974Z"
          fill="#EF4E05"
        />
        <path
          d="M81.0172 37.5625C81.9189 37.5625 82.6468 36.8351 82.6468 35.933C82.6468 35.0313 81.9189 34.3039 81.0172 34.3039C80.1156 34.3039 79.3877 35.0313 79.3877 35.933C79.3877 36.8351 80.1207 37.5625 81.0172 37.5625Z"
          fill="#EF4E05"
        />
        <path
          d="M74.5206 43.1366C75.412 43.1366 76.1292 42.4143 76.1292 41.528C76.1292 40.6422 75.4064 39.92 74.5206 39.92C73.6292 39.92 72.9121 40.6422 72.9121 41.528C72.9121 42.4143 73.6292 43.1366 74.5206 43.1366Z"
          fill="#EF4E05"
        />
        <path
          d="M65.9306 45.8841C66.8323 45.8841 67.5601 45.1562 67.5601 44.2545C67.5601 43.3529 66.8323 42.625 65.9306 42.625C65.0289 42.625 64.301 43.3529 64.301 44.2545C64.301 45.1562 65.0289 45.8841 65.9306 45.8841Z"
          fill="#EF4E05"
        />
        <path
          d="M56.6862 51.268C57.5879 51.268 58.3157 50.5405 58.3157 49.6389C58.3157 48.7367 57.5879 48.0093 56.6862 48.0093C55.7845 48.0093 55.0566 48.7367 55.0566 49.6389C55.0566 50.5405 55.7897 51.268 56.6862 51.268Z"
          fill="#EF4E05"
        />
        <path
          d="M49.3771 57.4325C50.2736 57.4325 50.9964 56.7051 50.9964 55.8137C50.9964 54.9172 50.2685 54.1949 49.3771 54.1949C48.481 54.1949 47.7583 54.9223 47.7583 55.8137C47.7583 56.7051 48.4862 57.4325 49.3771 57.4325Z"
          fill="#EF4E05"
        />
        <path
          d="M41.7892 62.3842C42.6857 62.3842 43.4132 61.6568 43.4132 60.7603C43.4132 59.8637 42.6857 59.1359 41.7892 59.1359C40.8927 59.1359 40.1648 59.8637 40.1648 60.7603C40.1648 61.6568 40.8927 62.3842 41.7892 62.3842Z"
          fill="#EF4E05"
        />
        <path
          d="M33.2724 67.8531C34.1727 67.8531 34.9019 67.1234 34.9019 66.2236C34.9019 65.3238 34.1727 64.594 33.2724 64.594C32.3726 64.594 31.6433 65.3238 31.6433 66.2236C31.6433 67.1234 32.3726 67.8531 33.2724 67.8531Z"
          fill="#EF4E05"
        />
        <path
          d="M32.9668 78.7741C32.9668 79.6019 33.6419 80.277 34.4697 80.277C35.2976 80.277 35.9727 79.6019 35.9727 78.7741C35.9727 77.9462 35.2976 77.2711 34.4697 77.2711C33.6419 77.2711 32.9668 77.9462 32.9668 78.7741Z"
          fill="#EF4E05"
        />
        <path
          d="M26.4962 74.9931C27.3928 74.9931 28.115 74.2653 28.115 73.3739C28.115 72.4778 27.3876 71.7551 26.4962 71.7551C25.5997 71.7551 24.8774 72.4829 24.8774 73.3739C24.8774 74.2704 25.6053 74.9931 26.4962 74.9931Z"
          fill="#EF4E05"
        />
        <path
          d="M18.1852 80.762C19.085 80.762 19.8148 80.0327 19.8148 79.1324C19.8148 78.2326 19.085 77.5033 18.1852 77.5033C17.2854 77.5033 16.5557 78.2326 16.5557 79.1324C16.5557 80.0327 17.2854 80.762 18.1852 80.762Z"
          fill="#EF4E05"
        />
        <path
          d="M13.1967 86.621C14.097 86.621 14.8262 85.8912 14.8262 84.9914C14.8262 84.0911 14.097 83.3619 13.1967 83.3619C12.2969 83.3619 11.5671 84.0911 11.5671 84.9914C11.5671 85.8912 12.2969 86.621 13.1967 86.621Z"
          fill="#EF4E05"
        />
        <path
          d="M6.23063 91.8362C7.1323 91.8362 7.86017 91.1083 7.86017 90.2066C7.86017 89.3049 7.1323 88.5771 6.23063 88.5771C5.32896 88.5771 4.60107 89.3049 4.60107 90.2066C4.60107 91.1083 5.32896 91.8362 6.23063 91.8362Z"
          fill="#EF4E05"
        />
        <path
          d="M1.13688 99.841C2.03855 99.841 2.76596 99.1136 2.76596 98.2119C2.76596 97.3098 2.03855 96.5823 1.13688 96.5823C0.235205 96.5823 -0.492676 97.3098 -0.492676 98.2119C-0.492676 99.1136 0.235205 99.841 1.13688 99.841Z"
          fill="#EF4E05"
        />
        <path
          d="M0.182373 109.08C0.182373 109.929 0.867736 110.615 1.71662 110.615C2.56596 110.615 3.25133 109.929 3.25133 109.08C3.25133 108.231 2.56596 107.546 1.71662 107.546C0.867736 107.546 0.182373 108.231 0.182373 109.08Z"
          fill="#EF4E05"
        />
        <path
          d="M4.93352 119.864C5.72961 119.864 6.37293 119.221 6.37293 118.424C6.37293 117.628 5.72961 116.985 4.93352 116.985C4.13697 116.985 3.49365 117.628 3.49365 118.424C3.49365 119.221 4.14258 119.864 4.93352 119.864Z"
          fill="#EF4E05"
        />
        <path
          d="M-0.0869009 150.413C0.629297 150.413 1.21048 149.832 1.21048 149.115C1.21048 148.399 0.629297 147.818 -0.0869009 147.818C-0.803567 147.818 -1.38428 148.399 -1.38428 149.115C-1.38428 149.832 -0.803567 150.413 -0.0869009 150.413Z"
          fill="#EF4E05"
        />
        <path
          d="M1.996 129.799C2.77107 129.799 3.39896 129.171 3.39896 128.396C3.39896 127.622 2.77107 126.993 1.996 126.993C1.22141 126.993 0.593506 127.622 0.593506 128.396C0.593506 129.171 1.22141 129.799 1.996 129.799Z"
          fill="#EF4E05"
        />
        <path
          d="M11.6357 134.962C12.3435 134.962 12.9172 134.388 12.9172 133.68C12.9172 132.973 12.3435 132.399 11.6357 132.399C10.928 132.399 10.3542 132.973 10.3542 133.68C10.3542 134.388 10.928 134.962 11.6357 134.962Z"
          fill="#EF4E05"
        />
        <path
          d="M9.38965 144.464C9.38965 145.145 9.93766 145.693 10.6184 145.693C11.2986 145.693 11.8471 145.145 11.8471 144.464C11.8471 143.784 11.2986 143.236 10.6184 143.236C9.94327 143.236 9.38965 143.789 9.38965 144.464Z"
          fill="#EF4E05"
        />
        <path
          d="M9.8695 157.685C10.5161 157.685 11.0398 157.161 11.0398 156.514C11.0398 155.868 10.5161 155.343 9.8695 155.343C9.22291 155.343 8.69873 155.868 8.69873 156.514C8.69873 157.161 9.22291 157.685 9.8695 157.685Z"
          fill="#EF4E05"
        />
        <path
          d="M6.74756 168.785C6.74756 169.413 7.25353 169.919 7.88096 169.919C8.50886 169.919 9.01482 169.413 9.01482 168.785C9.01482 168.158 8.50886 167.651 7.88096 167.651C7.25353 167.651 6.74756 168.163 6.74756 168.785Z"
          fill="#EF4E05"
        />
        <path
          d="M21.6871 174.665C21.6871 174.111 21.2386 173.658 20.6799 173.658C20.1262 173.658 19.6726 174.106 19.6726 174.665C19.6726 175.224 20.1206 175.672 20.6799 175.672C21.2386 175.672 21.6871 175.224 21.6871 174.665Z"
          fill="#EF4E05"
        />
        <path
          d="M12.385 183.361C12.9545 183.361 13.4184 182.897 13.4184 182.327C13.4184 181.758 12.9545 181.294 12.385 181.294C11.8155 181.294 11.3511 181.758 11.3511 182.327C11.3511 182.897 11.8155 183.361 12.385 183.361Z"
          fill="#EF4E05"
        />
        <path
          d="M15.8175 195.448C16.3501 195.448 16.7827 195.015 16.7827 194.482C16.7827 193.95 16.3501 193.517 15.8175 193.517C15.2849 193.517 14.8523 193.95 14.8523 194.482C14.8523 195.015 15.2849 195.448 15.8175 195.448Z"
          fill="#EF4E05"
        />
        <path
          d="M18.2172 207.66C18.2172 207.154 17.8056 206.743 17.2996 206.743C16.7932 206.743 16.3821 207.154 16.3821 207.66C16.3821 208.167 16.7932 208.578 17.2996 208.578C17.8056 208.573 18.2172 208.167 18.2172 207.66Z"
          fill="#EF4E05"
        />
        <path
          d="M29.7922 199.059C29.3227 199.059 28.9485 199.439 28.9485 199.903C28.9485 200.373 29.3283 200.747 29.7922 200.747C30.2613 200.747 30.636 200.368 30.636 199.903C30.636 199.439 30.2613 199.059 29.7922 199.059Z"
          fill="#EF4E05"
        />
        <path
          d="M28.0042 184.821C27.5085 184.821 27.1025 185.222 27.1025 185.723C27.1025 186.219 27.5034 186.625 28.0042 186.625C28.5055 186.625 28.9063 186.224 28.9063 185.723C28.9007 185.222 28.4999 184.821 28.0042 184.821Z"
          fill="#EF4E05"
        />
        <path
          d="M43.4764 191.982C43.4764 191.55 43.1284 191.202 42.6957 191.202C42.2636 191.202 41.9155 191.55 41.9155 191.982C41.9155 192.415 42.2636 192.763 42.6957 192.763C43.1284 192.763 43.4764 192.415 43.4764 191.982Z"
          fill="#EF4E05"
        />
        <path
          d="M45.7128 206.595C46.1136 206.595 46.4351 206.268 46.4351 205.873C46.4351 205.472 46.108 205.15 45.7128 205.15C45.3119 205.15 44.99 205.477 44.99 205.873C44.9849 206.274 45.3119 206.595 45.7128 206.595Z"
          fill="#EF4E05"
        />
        <path
          d="M33.2355 214.031C33.2355 213.593 32.8823 213.245 32.4501 213.245C32.0124 213.245 31.6643 213.598 31.6643 214.031C31.6643 214.468 32.0175 214.816 32.4501 214.816C32.8823 214.822 33.2355 214.468 33.2355 214.031Z"
          fill="#EF4E05"
        />
        <path
          d="M34.3534 229.619C34.7701 229.619 35.1023 229.281 35.1023 228.87C35.1023 228.454 34.765 228.121 34.3534 228.121C33.9371 228.121 33.605 228.459 33.605 228.87C33.605 229.281 33.9423 229.619 34.3534 229.619Z"
          fill="#EF4E05"
        />
        <path
          d="M33.0615 243.556C33.0615 243.957 33.3886 244.284 33.7894 244.284C34.1903 244.284 34.5168 243.957 34.5168 243.556C34.5168 243.155 34.1903 242.828 33.7894 242.828C33.3886 242.828 33.0615 243.155 33.0615 243.556Z"
          fill="#EF4E05"
        />
        <path
          d="M50.1948 239.591C50.5471 239.591 50.833 239.305 50.833 238.953C50.833 238.6 50.5471 238.314 50.1948 238.314C49.8426 238.314 49.5566 238.6 49.5566 238.953C49.5566 239.305 49.8426 239.591 50.1948 239.591Z"
          fill="#EF4E05"
        />
        <path
          d="M48.8184 221.751C48.8184 221.377 48.518 221.076 48.1434 221.076C47.7691 221.076 47.4683 221.377 47.4683 221.751C47.4683 222.125 47.7691 222.426 48.1434 222.426C48.518 222.426 48.8184 222.125 48.8184 221.751Z"
          fill="#EF4E05"
        />
        <path
          d="M65.3133 228.126C65.3133 227.81 65.055 227.552 64.7387 227.552C64.4224 227.552 64.1641 227.81 64.1641 228.126C64.1641 228.443 64.4224 228.701 64.7387 228.701C65.055 228.701 65.3133 228.448 65.3133 228.126Z"
          fill="#EF4E05"
        />
        <path
          d="M79.4884 216.799C79.1983 216.799 78.9609 217.036 78.9609 217.326C78.9609 217.617 79.1983 217.854 79.4884 217.854C79.7785 217.854 80.0158 217.617 80.0158 217.326C80.0158 217.036 79.7837 216.799 79.4884 216.799Z"
          fill="#EF4E05"
        />
        <path
          d="M95.4406 206C95.4406 205.73 95.2191 205.509 94.95 205.509C94.6809 205.509 94.4595 205.73 94.4595 206C94.4595 206.268 94.6809 206.49 94.95 206.49C95.2191 206.49 95.4406 206.273 95.4406 206Z"
          fill="#EF4E05"
        />
        <path
          d="M99.5588 226.455C99.5588 226.697 99.7541 226.893 99.9966 226.893C100.239 226.893 100.434 226.697 100.434 226.455C100.434 226.212 100.239 226.017 99.9966 226.017C99.7541 226.017 99.5588 226.212 99.5588 226.455Z"
          fill="#EF4E05"
        />
        <path
          d="M119.909 239.644C120.115 239.644 120.283 239.474 120.283 239.269C120.283 239.063 120.115 238.895 119.909 238.895C119.703 238.895 119.534 239.063 119.534 239.269C119.534 239.474 119.703 239.644 119.909 239.644Z"
          fill="#EF4E05"
        />
        <path
          d="M117.863 210.065C117.863 209.833 117.678 209.648 117.446 209.648C117.214 209.648 117.03 209.833 117.03 210.065C117.03 210.297 117.214 210.482 117.446 210.482C117.678 210.487 117.863 210.297 117.863 210.065Z"
          fill="#EF4E05"
        />
        <path
          d="M143.629 208.325C143.835 208.325 144.003 208.157 144.003 207.95C144.003 207.744 143.835 207.576 143.629 207.576C143.422 207.576 143.254 207.744 143.254 207.95C143.254 208.157 143.422 208.325 143.629 208.325Z"
          fill="#EF4E05"
        />
        <path
          d="M146.064 182.765C146.064 182.533 145.875 182.343 145.642 182.343C145.411 182.343 145.22 182.533 145.22 182.765C145.22 182.997 145.411 183.187 145.642 183.187C145.875 183.187 146.064 183.002 146.064 182.765Z"
          fill="#EF4E05"
        />
        <path
          d="M154.059 165.194C154.318 165.194 154.529 164.983 154.529 164.725C154.529 164.467 154.318 164.255 154.059 164.255C153.801 164.255 153.59 164.467 153.59 164.725C153.59 164.983 153.801 165.194 154.059 165.194Z"
          fill="#EF4E05"
        />
        <path
          d="M171.419 185.386C171.419 185.17 171.245 184.996 171.029 184.996C170.812 184.996 170.639 185.17 170.639 185.386C170.639 185.602 170.812 185.776 171.029 185.776C171.24 185.781 171.419 185.602 171.419 185.386Z"
          fill="#EF4E05"
        />
        <path
          d="M199.573 187.558C199.573 187.764 199.742 187.933 199.948 187.933C200.153 187.933 200.322 187.764 200.322 187.558C200.322 187.353 200.153 187.184 199.948 187.184C199.742 187.184 199.573 187.353 199.573 187.558Z"
          fill="#EF4E05"
        />
        <path
          d="M230.47 192.758C230.681 192.758 230.85 192.589 230.85 192.378C230.85 192.168 230.681 191.998 230.47 191.998C230.259 191.998 230.09 192.168 230.09 192.378C230.09 192.589 230.259 192.758 230.47 192.758Z"
          fill="#EF4E05"
        />
        <path
          d="M254.928 186.73C255.165 186.73 255.355 186.541 255.355 186.303C255.355 186.066 255.165 185.876 254.928 185.876C254.691 185.876 254.501 186.066 254.501 186.303C254.501 186.536 254.696 186.73 254.928 186.73Z"
          fill="#EF4E05"
        />
        <path
          d="M277.361 179.158C277.635 179.158 277.857 178.936 277.857 178.662C277.857 178.388 277.635 178.166 277.361 178.166C277.087 178.166 276.865 178.388 276.865 178.662C276.865 178.936 277.087 179.158 277.361 179.158Z"
          fill="#EF4E05"
        />
        <path
          d="M262.379 166.649C262.656 166.649 262.881 166.425 262.881 166.148C262.881 165.872 262.656 165.648 262.379 165.648C262.103 165.648 261.878 165.872 261.878 166.148C261.878 166.425 262.103 166.649 262.379 166.649Z"
          fill="#EF4E05"
        />
        <path
          d="M270.132 149.769C270.461 149.769 270.728 149.503 270.728 149.174C270.728 148.844 270.461 148.578 270.132 148.578C269.803 148.578 269.536 148.844 269.536 149.174C269.536 149.503 269.803 149.769 270.132 149.769Z"
          fill="#EF4E05"
        />
        <path
          d="M261.61 134.777C261.256 134.777 260.972 135.062 260.972 135.415C260.972 135.769 261.256 136.053 261.61 136.053C261.963 136.053 262.248 135.769 262.248 135.415C262.248 135.062 261.963 134.777 261.61 134.777Z"
          fill="#EF4E05"
        />
        <path
          d="M268.902 121.683C269.308 121.683 269.635 121.356 269.635 120.95C269.635 120.544 269.308 120.217 268.902 120.217C268.496 120.217 268.169 120.544 268.169 120.95C268.169 121.356 268.496 121.683 268.902 121.683Z"
          fill="#EF4E05"
        />
        <path
          d="M260.276 109.328C260.718 109.328 261.072 108.969 261.072 108.532C261.072 108.094 260.713 107.736 260.276 107.736C259.833 107.736 259.479 108.094 259.479 108.532C259.479 108.969 259.833 109.328 260.276 109.328Z"
          fill="#EF4E05"
        />
        <path
          d="M252.645 122.29C253.029 122.29 253.341 121.978 253.341 121.594C253.341 121.209 253.029 120.898 252.645 120.898C252.26 120.898 251.949 121.209 251.949 121.594C251.949 121.978 252.26 122.29 252.645 122.29Z"
          fill="#EF4E05"
        />
        <path
          d="M243.659 135.806C243.659 136.138 243.928 136.407 244.26 136.407C244.593 136.407 244.861 136.138 244.861 135.806C244.861 135.473 244.593 135.204 244.26 135.204C243.928 135.204 243.659 135.473 243.659 135.806Z"
          fill="#EF4E05"
        />
        <path
          d="M250.736 151.768C251.031 151.768 251.274 151.525 251.274 151.23C251.274 150.935 251.031 150.692 250.736 150.692C250.44 150.692 250.198 150.935 250.198 151.23C250.198 151.525 250.435 151.768 250.736 151.768Z"
          fill="#EF4E05"
        />
        <path
          d="M240.801 168.785C240.801 168.532 240.595 168.332 240.347 168.332C240.094 168.332 239.894 168.537 239.894 168.785C239.894 169.038 240.1 169.239 240.347 169.239C240.595 169.239 240.801 169.038 240.801 168.785Z"
          fill="#EF4E05"
        />
        <path
          d="M229.552 151.088C229.832 151.088 230.058 150.861 230.058 150.582C230.058 150.302 229.832 150.075 229.552 150.075C229.272 150.075 229.046 150.302 229.046 150.582C229.046 150.861 229.272 151.088 229.552 151.088Z"
          fill="#EF4E05"
        />
        <path
          d="M217.086 168.548C216.849 168.548 216.654 168.743 216.654 168.981C216.654 169.218 216.849 169.413 217.086 169.413C217.324 169.413 217.519 169.218 217.519 168.981C217.519 168.743 217.324 168.548 217.086 168.548Z"
          fill="#EF4E05"
        />
        <path
          d="M206.16 150.85C206.16 151.119 206.381 151.341 206.65 151.341C206.919 151.341 207.14 151.119 207.14 150.85C207.14 150.581 206.919 150.36 206.65 150.36C206.381 150.36 206.16 150.576 206.16 150.85Z"
          fill="#EF4E05"
        />
        <path
          d="M191.379 166.059C191.622 166.059 191.817 165.864 191.817 165.621C191.817 165.379 191.622 165.184 191.379 165.184C191.136 165.184 190.942 165.379 190.942 165.621C190.936 165.864 191.136 166.059 191.379 166.059Z"
          fill="#EF4E05"
        />
        <path
          d="M173.286 157.331C173.286 157.068 173.075 156.857 172.811 156.857C172.548 156.857 172.336 157.068 172.336 157.331C172.336 157.595 172.548 157.806 172.811 157.806C173.075 157.806 173.286 157.595 173.286 157.331Z"
          fill="#EF4E05"
        />
        <path
          d="M157.508 143.868C157.207 143.868 156.965 144.111 156.965 144.412C156.965 144.712 157.207 144.955 157.508 144.955C157.809 144.955 158.051 144.712 158.051 144.412C158.051 144.111 157.809 143.868 157.508 143.868Z"
          fill="#EF4E05"
        />
        <path
          d="M172.996 135.136C173.313 135.136 173.571 134.879 173.571 134.561C173.571 134.244 173.313 133.986 172.996 133.986C172.678 133.986 172.421 134.244 172.421 134.561C172.421 134.879 172.678 135.136 172.996 135.136Z"
          fill="#EF4E05"
        />
        <path
          d="M187.914 143.146C187.914 143.436 188.151 143.673 188.441 143.673C188.731 143.673 188.968 143.436 188.968 143.146C188.968 142.856 188.731 142.619 188.441 142.619C188.151 142.619 187.914 142.851 187.914 143.146Z"
          fill="#EF4E05"
        />
        <path
          d="M215.768 136.238C215.768 135.927 215.515 135.679 215.209 135.679C214.898 135.679 214.65 135.932 214.65 136.238C214.65 136.549 214.903 136.797 215.209 136.797C215.515 136.802 215.768 136.549 215.768 136.238Z"
          fill="#EF4E05"
        />
        <path
          d="M201.609 129.498C201.609 129.166 201.34 128.903 201.013 128.903C200.686 128.903 200.417 129.171 200.417 129.498C200.417 129.831 200.686 130.095 201.013 130.095C201.34 130.095 201.609 129.826 201.609 129.498Z"
          fill="#EF4E05"
        />
        <path
          d="M200.596 112.993C200.981 112.993 201.287 112.682 201.287 112.302C201.287 111.917 200.976 111.611 200.596 111.611C200.217 111.611 199.906 111.922 199.906 112.302C199.906 112.682 200.217 112.993 200.596 112.993Z"
          fill="#EF4E05"
        />
        <path
          d="M186.437 122.722C186.787 122.722 187.07 122.439 187.07 122.089C187.07 121.74 186.787 121.457 186.437 121.457C186.088 121.457 185.805 121.74 185.805 122.089C185.805 122.439 186.088 122.722 186.437 122.722Z"
          fill="#EF4E05"
        />
        <path
          d="M172.911 115.841C172.911 115.466 172.605 115.161 172.231 115.161C171.856 115.161 171.551 115.466 171.551 115.841C171.551 116.215 171.856 116.521 172.231 116.521C172.605 116.526 172.911 116.22 172.911 115.841Z"
          fill="#EF4E05"
        />
        <path
          d="M169.61 101.423C170.041 101.423 170.391 101.074 170.391 100.643C170.391 100.211 170.041 99.8625 169.61 99.8625C169.179 99.8625 168.83 100.211 168.83 100.643C168.83 101.074 169.179 101.423 169.61 101.423Z"
          fill="#EF4E05"
        />
        <path
          d="M185.604 104.608C186.021 104.608 186.353 104.271 186.353 103.859C186.353 103.443 186.015 103.111 185.604 103.111C185.188 103.111 184.855 103.448 184.855 103.859C184.855 104.271 185.188 104.608 185.604 104.608Z"
          fill="#EF4E05"
        />
        <path
          d="M199.848 96.5137C199.848 96.9514 200.201 97.3046 200.639 97.3046C201.076 97.3046 201.43 96.9514 201.43 96.5137C201.43 96.0759 201.076 95.7227 200.639 95.7227C200.201 95.7227 199.848 96.0759 199.848 96.5137Z"
          fill="#EF4E05"
        />
        <path
          d="M214.181 101.75C213.759 101.75 213.422 102.093 213.422 102.51C213.422 102.926 213.764 103.269 214.181 103.269C214.598 103.269 214.94 102.926 214.94 102.51C214.94 102.093 214.603 101.75 214.181 101.75Z"
          fill="#EF4E05"
        />
        <path
          d="M226.541 91.3882C226.541 90.9186 226.162 90.5444 225.698 90.5444C225.234 90.5444 224.854 90.9238 224.854 91.3882C224.854 91.8521 225.234 92.2319 225.698 92.2319C226.162 92.2319 226.541 91.8521 226.541 91.3882Z"
          fill="#EF4E05"
        />
        <path
          d="M238.159 94.3884C238.159 94.8528 238.533 95.227 238.997 95.227C239.461 95.227 239.836 94.8528 239.836 94.3884C239.836 93.9245 239.461 93.5503 238.997 93.5503C238.533 93.5503 238.159 93.9194 238.159 94.3884Z"
          fill="#EF4E05"
        />
        <path
          d="M228.092 106.021C228.092 106.438 228.43 106.77 228.841 106.77C229.257 106.77 229.59 106.433 229.59 106.021C229.59 105.61 229.252 105.273 228.841 105.273C228.43 105.273 228.092 105.61 228.092 106.021Z"
          fill="#EF4E05"
        />
        <path
          d="M217.029 117.729C216.66 117.729 216.364 118.029 216.364 118.393C216.364 118.757 216.665 119.057 217.029 119.057C217.393 119.057 217.693 118.757 217.693 118.393C217.693 118.029 217.398 117.729 217.029 117.729Z"
          fill="#EF4E05"
        />
        <path
          d="M229.068 132.14C229.4 132.14 229.669 131.871 229.669 131.539C229.669 131.207 229.4 130.938 229.068 130.938C228.736 130.938 228.467 131.207 228.467 131.539C228.467 131.871 228.736 132.14 229.068 132.14Z"
          fill="#EF4E05"
        />
        <path
          d="M236.972 119.11C236.972 118.735 236.671 118.435 236.297 118.435C235.922 118.435 235.622 118.735 235.622 119.11C235.622 119.484 235.922 119.785 236.297 119.785C236.666 119.785 236.972 119.484 236.972 119.11Z"
          fill="#EF4E05"
        />
        <path
          d="M245.109 106.802C244.687 106.802 244.35 107.145 244.35 107.561C244.35 107.983 244.692 108.321 245.109 108.321C245.531 108.321 245.868 107.978 245.868 107.561C245.874 107.145 245.531 106.802 245.109 106.802Z"
          fill="#EF4E05"
        />
        <path
          d="M253.947 96.1128C253.947 95.6382 253.562 95.2481 253.082 95.2481C252.608 95.2481 252.223 95.633 252.223 96.1128C252.223 96.5926 252.608 96.9776 253.082 96.9776C253.562 96.9776 253.947 96.5875 253.947 96.1128Z"
          fill="#EF4E05"
        />
        <path
          d="M260.201 86.2939C260.728 86.2939 261.161 85.8669 261.161 85.3343C261.161 84.8017 260.734 84.3743 260.201 84.3743C259.669 84.3743 259.241 84.8017 259.241 85.3343C259.241 85.8669 259.674 86.2939 260.201 86.2939Z"
          fill="#EF4E05"
        />
        <path
          d="M272.22 81.7062C272.22 81.1367 271.761 80.6779 271.191 80.6779C270.622 80.6779 270.163 81.1367 270.163 81.7062C270.163 82.2705 270.622 82.7344 271.191 82.7344C271.761 82.7344 272.22 82.2757 272.22 81.7062Z"
          fill="#EF4E05"
        />
        <path
          d="M278.004 73.6958C278.622 73.6958 279.128 73.1949 279.128 72.5726C279.128 71.9503 278.627 71.4495 278.004 71.4495C277.382 71.4495 276.881 71.9503 276.881 72.5726C276.881 73.1949 277.388 73.6958 278.004 73.6958Z"
          fill="#EF4E05"
        />
        <path
          d="M285.277 66.6085C285.946 66.6085 286.495 66.0652 286.495 65.3901C286.495 64.7206 285.952 64.1721 285.277 64.1721C284.607 64.1721 284.058 64.7155 284.058 65.3901C284.058 66.0652 284.607 66.6085 285.277 66.6085Z"
          fill="#EF4E05"
        />
        <path
          d="M295.718 60.3963C295.718 59.6792 295.138 59.099 294.42 59.099C293.703 59.099 293.123 59.6792 293.123 60.3963C293.123 61.1135 293.703 61.6937 294.42 61.6937C295.138 61.6937 295.718 61.1135 295.718 60.3963Z"
          fill="#EF4E05"
        />
        <path
          d="M303.612 66.3502C304.329 66.3502 304.909 65.7699 304.909 65.0528C304.909 64.3357 304.329 63.7554 303.612 63.7554C302.895 63.7554 302.314 64.3357 302.314 65.0528C302.32 65.7648 302.9 66.3502 303.612 66.3502Z"
          fill="#EF4E05"
        />
        <path
          d="M293.292 74.3339C293.94 74.3339 294.468 73.8065 294.468 73.158C294.468 72.5091 293.94 71.9821 293.292 71.9821C292.643 71.9821 292.116 72.5091 292.116 73.158C292.116 73.8065 292.638 74.3339 293.292 74.3339Z"
          fill="#EF4E05"
        />
        <path
          d="M286.658 81.7533C286.658 81.1628 286.178 80.6779 285.582 80.6779C284.991 80.6779 284.506 81.1577 284.506 81.7533C284.506 82.3443 284.986 82.8293 285.582 82.8293C286.178 82.8241 286.658 82.3443 286.658 81.7533Z"
          fill="#EF4E05"
        />
        <path
          d="M279.486 93.1228C279.486 92.5954 279.059 92.1684 278.532 92.1684C278.004 92.1684 277.577 92.5954 277.577 93.1228C277.577 93.6503 278.004 94.0773 278.532 94.0773C279.059 94.0721 279.486 93.6447 279.486 93.1228Z"
          fill="#EF4E05"
        />
        <path
          d="M267.774 95.939C267.774 95.4433 267.374 95.0373 266.872 95.0373C266.377 95.0373 265.971 95.4377 265.971 95.939C265.971 96.4399 266.372 96.8407 266.872 96.8407C267.368 96.8356 267.774 96.4347 267.774 95.939Z"
          fill="#EF4E05"
        />
        <path
          d="M275.531 107.319C275.531 106.849 275.151 106.475 274.687 106.475C274.218 106.475 273.843 106.855 273.843 107.319C273.843 107.783 274.223 108.163 274.687 108.163C275.151 108.168 275.531 107.788 275.531 107.319Z"
          fill="#EF4E05"
        />
        <path
          d="M288.346 105.362C288.346 104.867 287.945 104.466 287.449 104.466C286.954 104.466 286.553 104.867 286.553 105.362C286.553 105.858 286.954 106.259 287.449 106.259C287.945 106.259 288.346 105.858 288.346 105.362Z"
          fill="#EF4E05"
        />
        <path
          d="M298.597 114.512C298.597 114.027 298.201 113.631 297.716 113.631C297.231 113.631 296.835 114.027 296.835 114.512C296.835 114.997 297.231 115.392 297.716 115.392C298.201 115.392 298.597 114.997 298.597 114.512Z"
          fill="#EF4E05"
        />
        <path
          d="M309.328 122.021C309.819 122.021 310.22 121.625 310.22 121.13C310.22 120.634 309.824 120.238 309.328 120.238C308.838 120.238 308.437 120.634 308.437 121.13C308.437 121.625 308.838 122.021 309.328 122.021Z"
          fill="#EF4E05"
        />
        <path
          d="M311.923 134.471C312.377 134.471 312.746 134.103 312.746 133.649C312.746 133.194 312.377 132.826 311.923 132.826C311.469 132.826 311.101 133.194 311.101 133.649C311.101 134.103 311.469 134.471 311.923 134.471Z"
          fill="#EF4E05"
        />
        <path
          d="M301.108 142.867C301.512 142.867 301.841 142.539 301.841 142.134C301.841 141.729 301.512 141.401 301.108 141.401C300.702 141.401 300.375 141.729 300.375 142.134C300.375 142.539 300.702 142.867 301.108 142.867Z"
          fill="#EF4E05"
        />
        <path
          d="M295.317 129.999C295.744 129.999 296.092 129.652 296.092 129.224C296.092 128.797 295.744 128.449 295.317 128.449C294.89 128.449 294.542 128.797 294.542 129.224C294.542 129.652 294.89 129.999 295.317 129.999Z"
          fill="#EF4E05"
        />
        <path
          d="M284.048 120.028C284.487 120.028 284.844 119.671 284.844 119.231C284.844 118.791 284.487 118.435 284.048 118.435C283.607 118.435 283.251 118.791 283.251 119.231C283.251 119.671 283.607 120.028 284.048 120.028Z"
          fill="#EF4E05"
        />
        <path
          d="M278.537 133.855C278.921 133.855 279.233 133.543 279.233 133.158C279.233 132.774 278.921 132.462 278.537 132.462C278.152 132.462 277.841 132.774 277.841 133.158C277.841 133.543 278.152 133.855 278.537 133.855Z"
          fill="#EF4E05"
        />
        <path
          d="M286.885 145.962C287.249 145.962 287.544 145.667 287.544 145.303C287.544 144.939 287.249 144.644 286.885 144.644C286.521 144.644 286.226 144.939 286.226 145.303C286.221 145.667 286.516 145.962 286.885 145.962Z"
          fill="#EF4E05"
        />
        <path
          d="M282.661 162.494C282.977 162.494 283.236 162.236 283.236 161.919C283.236 161.603 282.977 161.344 282.661 161.344C282.345 161.344 282.086 161.603 282.086 161.919C282.086 162.236 282.345 162.494 282.661 162.494Z"
          fill="#EF4E05"
        />
        <path
          d="M299.868 157.226C299.868 156.867 299.578 156.577 299.219 156.577C298.86 156.577 298.57 156.867 298.57 157.226C298.57 157.584 298.86 157.875 299.219 157.875C299.578 157.88 299.868 157.584 299.868 157.226Z"
          fill="#EF4E05"
        />
        <path
          d="M313.115 162.789C313.115 163.169 313.421 163.475 313.801 163.475C314.18 163.475 314.486 163.169 314.486 162.789C314.486 162.41 314.18 162.104 313.801 162.104C313.426 162.104 313.115 162.41 313.115 162.789Z"
          fill="#EF4E05"
        />
        <path
          d="M313.61 148.124C313.61 148.546 313.953 148.889 314.375 148.889C314.797 148.889 315.139 148.546 315.139 148.124C315.139 147.702 314.797 147.36 314.375 147.36C313.953 147.36 313.61 147.702 313.61 148.124Z"
          fill="#EF4E05"
        />
        <path
          d="M328.201 153.123C328.649 153.123 329.008 152.759 329.008 152.316C329.008 151.868 328.644 151.51 328.201 151.51C327.753 151.51 327.395 151.874 327.395 152.316C327.395 152.764 327.758 153.123 328.201 153.123Z"
          fill="#EF4E05"
        />
        <path
          d="M325.929 139.207C326.409 139.207 326.805 138.817 326.805 138.332C326.805 137.852 326.414 137.456 325.929 137.456C325.449 137.456 325.054 137.846 325.054 138.332C325.054 138.817 325.449 139.207 325.929 139.207Z"
          fill="#EF4E05"
        />
        <path
          d="M322.269 125.233C322.269 125.749 322.686 126.166 323.203 126.166C323.719 126.166 324.136 125.749 324.136 125.233C324.136 124.716 323.719 124.299 323.203 124.299C322.686 124.299 322.269 124.716 322.269 125.233Z"
          fill="#EF4E05"
        />
        <path
          d="M321.03 111.727C320.476 111.727 320.022 112.176 320.022 112.734C320.022 113.294 320.47 113.742 321.03 113.742C321.588 113.742 322.037 113.294 322.037 112.734C322.037 112.176 321.583 111.727 321.03 111.727Z"
          fill="#EF4E05"
        />
        <path
          d="M309.808 108.474C310.347 108.474 310.783 108.037 310.783 107.498C310.783 106.959 310.347 106.523 309.808 106.523C309.269 106.523 308.833 106.959 308.833 107.498C308.833 108.037 309.269 108.474 309.808 108.474Z"
          fill="#EF4E05"
        />
        <path
          d="M320.213 100.738C320.819 100.738 321.31 100.246 321.31 99.641C321.31 99.0351 320.819 98.5441 320.213 98.5441C319.607 98.5441 319.116 99.0351 319.116 99.641C319.116 100.246 319.607 100.738 320.213 100.738Z"
          fill="#EF4E05"
        />
        <path
          d="M309.697 94.5786C310.293 94.5786 310.778 94.0932 310.778 93.4975C310.778 92.9014 310.293 92.4164 309.697 92.4164C309.101 92.4164 308.616 92.9014 308.616 93.4975C308.616 94.0932 309.101 94.5786 309.697 94.5786Z"
          fill="#EF4E05"
        />
        <path
          d="M298.729 100.421C298.729 100.965 299.172 101.407 299.715 101.407C300.258 101.407 300.701 100.965 300.701 100.421C300.701 99.8779 300.258 99.435 299.715 99.435C299.172 99.4401 298.729 99.8779 298.729 100.421Z"
          fill="#EF4E05"
        />
        <path
          d="M290.302 93.9932C290.856 93.9932 291.309 93.5447 291.309 92.9859C291.309 92.4318 290.861 91.9787 290.302 91.9787C289.748 91.9787 289.295 92.4267 289.295 92.9859C289.295 93.5447 289.748 93.9932 290.302 93.9932Z"
          fill="#EF4E05"
        />
        <path
          d="M299.114 87.0111C299.719 87.0111 300.211 86.5201 300.211 85.9141C300.211 85.3086 299.719 84.8172 299.114 84.8172C298.508 84.8172 298.017 85.3086 298.017 85.9141C298.017 86.5201 298.508 87.0111 299.114 87.0111Z"
          fill="#EF4E05"
        />
        <path
          d="M304.804 75.8159C304.804 75.1567 304.266 74.6189 303.607 74.6189C302.947 74.6189 302.41 75.1567 302.41 75.8159C302.41 76.4751 302.947 77.0128 303.607 77.0128C304.271 77.0077 304.804 76.4751 304.804 75.8159Z"
          fill="#EF4E05"
        />
        <path
          d="M311.996 83.1353C312.655 83.1353 313.188 82.6018 313.188 81.9435C313.188 81.2852 312.655 80.7517 311.996 80.7517C311.339 80.7517 310.805 81.2852 310.805 81.9435C310.805 82.6018 311.339 83.1353 311.996 83.1353Z"
          fill="#EF4E05"
        />
        <path
          d="M321.325 88.6509C321.986 88.6509 322.522 88.115 322.522 87.454C322.522 86.7929 321.986 86.257 321.325 86.257C320.664 86.257 320.128 86.7929 320.128 87.454C320.128 88.115 320.664 88.6509 321.325 88.6509Z"
          fill="#EF4E05"
        />
        <path
          d="M313.446 71.3229C314.163 71.3229 314.744 70.7427 314.744 70.0255C314.744 69.3084 314.163 68.7282 313.446 68.7282C312.729 68.7282 312.149 69.3084 312.149 70.0255C312.155 70.7427 312.734 71.3229 313.446 71.3229Z"
          fill="#EF4E05"
        />
        <path
          d="M322.406 76.8969C323.118 76.8969 323.698 76.3167 323.698 75.6047C323.698 74.8927 323.118 74.3129 322.406 74.3129C321.694 74.3129 321.114 74.8927 321.114 75.6047C321.114 76.3167 321.694 76.8969 322.406 76.8969Z"
          fill="#EF4E05"
        />
        <path
          d="M331.65 82.207C332.368 82.207 332.947 81.6272 332.947 80.9096C332.947 80.1925 332.368 79.6127 331.65 79.6127C330.933 79.6127 330.353 80.1925 330.353 80.9096C330.353 81.6272 330.933 82.207 331.65 82.207Z"
          fill="#EF4E05"
        />
        <path
          d="M332.421 71.2542C333.195 71.2542 333.823 70.6263 333.823 69.8513C333.823 69.0767 333.195 68.4488 332.421 68.4488C331.646 68.4488 331.018 69.0767 331.018 69.8513C331.018 70.6263 331.646 71.2542 332.421 71.2542Z"
          fill="#EF4E05"
        />
        <path
          d="M342.25 76.6806C343.04 76.6806 343.679 76.0406 343.679 75.2515C343.679 74.4624 343.04 73.8224 342.25 73.8224C341.461 73.8224 340.821 74.4624 340.821 75.2515C340.821 76.0406 341.461 76.6806 342.25 76.6806Z"
          fill="#EF4E05"
        />
        <path
          d="M341.016 86.9321C341.749 86.9321 342.339 86.3411 342.339 85.6081C342.339 84.8751 341.749 84.2846 341.016 84.2846C340.283 84.2846 339.692 84.8751 339.692 85.6081C339.692 86.3411 340.283 86.9321 341.016 86.9321Z"
          fill="#EF4E05"
        />
        <path
          d="M342.228 97.5686C342.228 96.8935 341.68 96.345 341.005 96.345C340.33 96.345 339.782 96.8935 339.782 97.5686C339.782 98.2432 340.33 98.7917 341.005 98.7917C341.68 98.7917 342.228 98.2432 342.228 97.5686Z"
          fill="#EF4E05"
        />
        <path
          d="M330.986 93.8928C331.65 93.8928 332.193 93.355 332.193 92.6851C332.193 92.0207 331.655 91.4774 330.986 91.4774C330.316 91.4774 329.778 92.0156 329.778 92.6851C329.778 93.355 330.316 93.8928 330.986 93.8928Z"
          fill="#EF4E05"
        />
        <path
          d="M331.234 103.728C330.622 103.728 330.121 104.223 330.121 104.84C330.121 105.452 330.617 105.953 331.234 105.953C331.845 105.953 332.347 105.457 332.347 104.84C332.347 104.223 331.845 103.728 331.234 103.728Z"
          fill="#EF4E05"
        />
        <path
          d="M333.649 115.766C333.074 115.766 332.605 116.236 332.605 116.811C332.605 117.386 333.074 117.855 333.649 117.855C334.224 117.855 334.693 117.386 334.693 116.811C334.693 116.231 334.224 115.766 333.649 115.766Z"
          fill="#EF4E05"
        />
        <path
          d="M336.67 129.978C337.215 129.978 337.657 129.537 337.657 128.993C337.657 128.448 337.215 128.006 336.67 128.006C336.126 128.006 335.685 128.448 335.685 128.993C335.685 129.537 336.126 129.978 336.67 129.978Z"
          fill="#EF4E05"
        />
        <path
          d="M339.692 142.619C340.208 142.619 340.626 142.201 340.626 141.686C340.626 141.17 340.208 140.752 339.692 140.752C339.177 140.752 338.759 141.17 338.759 141.686C338.759 142.201 339.177 142.619 339.692 142.619Z"
          fill="#EF4E05"
        />
        <path
          d="M341.865 155.749C342.35 155.749 342.746 155.354 342.746 154.869C342.746 154.384 342.35 153.988 341.865 153.988C341.38 153.988 340.984 154.384 340.984 154.869C340.984 155.354 341.38 155.749 341.865 155.749Z"
          fill="#EF4E05"
        />
        <path
          d="M352.496 161.782C353.002 161.782 353.413 161.371 353.413 160.865C353.413 160.358 353.002 159.947 352.496 159.947C351.989 159.947 351.578 160.358 351.578 160.865C351.578 161.371 351.995 161.782 352.496 161.782Z"
          fill="#EF4E05"
        />
        <path
          d="M342.672 170.953C342.672 170.51 342.313 170.156 341.875 170.156C341.432 170.156 341.079 170.515 341.079 170.953C341.079 171.39 341.437 171.749 341.875 171.749C342.313 171.754 342.672 171.395 342.672 170.953Z"
          fill="#EF4E05"
        />
        <path
          d="M328.027 166.708C328.027 167.124 328.365 167.456 328.776 167.456C329.193 167.456 329.525 167.119 329.525 166.708C329.525 166.291 329.187 165.959 328.776 165.959C328.365 165.959 328.027 166.291 328.027 166.708Z"
          fill="#EF4E05"
        />
        <path
          d="M330.68 182.902C330.295 182.902 329.989 183.213 329.989 183.593C329.989 183.978 330.3 184.284 330.68 184.284C331.065 184.284 331.371 183.973 331.371 183.593C331.371 183.208 331.06 182.902 330.68 182.902Z"
          fill="#EF4E05"
        />
        <path
          d="M346.257 185.866C346.684 185.866 347.027 185.518 347.027 185.096C347.027 184.669 346.679 184.326 346.257 184.326C345.83 184.326 345.487 184.674 345.487 185.096C345.482 185.523 345.83 185.866 346.257 185.866Z"
          fill="#EF4E05"
        />
        <path
          d="M337.361 198.538C337.361 198.917 337.667 199.223 338.047 199.223C338.427 199.223 338.733 198.917 338.733 198.538C338.733 198.158 338.427 197.852 338.047 197.852C337.667 197.852 337.361 198.158 337.361 198.538Z"
          fill="#EF4E05"
        />
        <path
          d="M321.288 198.158C320.956 198.158 320.687 198.427 320.687 198.759C320.687 199.091 320.956 199.36 321.288 199.36C321.62 199.36 321.89 199.091 321.89 198.759C321.89 198.427 321.62 198.158 321.288 198.158Z"
          fill="#EF4E05"
        />
        <path
          d="M307.34 190.554C307.651 190.554 307.904 190.301 307.904 189.989C307.904 189.678 307.651 189.425 307.34 189.425C307.029 189.425 306.776 189.678 306.776 189.989C306.776 190.301 307.029 190.554 307.34 190.554Z"
          fill="#EF4E05"
        />
        <path
          d="M316.394 178.393C316.753 178.393 317.038 178.103 317.038 177.75C317.038 177.396 316.747 177.107 316.394 177.107C316.036 177.107 315.751 177.396 315.751 177.75C315.751 178.103 316.036 178.393 316.394 178.393Z"
          fill="#EF4E05"
        />
        <path
          d="M298.401 174.048C298.401 173.726 298.143 173.468 297.821 173.468C297.5 173.468 297.241 173.726 297.241 174.048C297.241 174.37 297.5 174.628 297.821 174.628C298.138 174.628 298.401 174.37 298.401 174.048Z"
          fill="#EF4E05"
        />
        <path
          d="M290.503 193.243C290.777 193.243 290.998 193.022 290.998 192.747C290.998 192.473 290.777 192.252 290.503 192.252C290.228 192.252 290.007 192.473 290.007 192.747C290.007 193.022 290.228 193.243 290.503 193.243Z"
          fill="#EF4E05"
        />
        <path
          d="M272.868 199.518C273.105 199.518 273.3 199.323 273.3 199.086C273.3 198.849 273.105 198.653 272.868 198.653C272.63 198.653 272.436 198.849 272.436 199.086C272.436 199.323 272.63 199.518 272.868 199.518Z"
          fill="#EF4E05"
        />
        <path
          d="M257.244 215.096C257.244 214.89 257.074 214.721 256.869 214.721C256.663 214.721 256.495 214.89 256.495 215.096C256.495 215.302 256.663 215.47 256.869 215.47C257.074 215.47 257.244 215.302 257.244 215.096Z"
          fill="#EF4E05"
        />
        <path
          d="M284.332 218.561C284.332 218.798 284.522 218.988 284.759 218.988C284.996 218.988 285.187 218.798 285.187 218.561C285.187 218.323 284.996 218.133 284.759 218.133C284.522 218.133 284.332 218.323 284.332 218.561Z"
          fill="#EF4E05"
        />
        <path
          d="M302.114 208.768C302.391 208.768 302.615 208.544 302.615 208.267C302.615 207.99 302.391 207.766 302.114 207.766C301.838 207.766 301.613 207.99 301.613 208.267C301.613 208.544 301.838 208.768 302.114 208.768Z"
          fill="#EF4E05"
        />
        <path
          d="M303.253 233.078C303.509 233.078 303.717 232.87 303.717 232.614C303.717 232.358 303.509 232.15 303.253 232.15C302.997 232.15 302.789 232.358 302.789 232.614C302.789 232.87 302.997 233.078 303.253 233.078Z"
          fill="#EF4E05"
        />
        <path
          d="M276.886 242.723C277.097 242.723 277.266 242.554 277.266 242.343C277.266 242.132 277.097 241.964 276.886 241.964C276.675 241.964 276.507 242.132 276.507 242.343C276.507 242.554 276.675 242.723 276.886 242.723Z"
          fill="#EF4E05"
        />
        <path
          d="M298.081 253.639C298.081 253.876 298.27 254.066 298.508 254.066C298.745 254.066 298.935 253.876 298.935 253.639C298.935 253.402 298.745 253.212 298.508 253.212C298.275 253.212 298.081 253.402 298.081 253.639Z"
          fill="#EF4E05"
        />
        <path
          d="M318.989 252.969C319.269 252.969 319.495 252.743 319.495 252.463C319.495 252.183 319.269 251.957 318.989 251.957C318.709 251.957 318.483 252.183 318.483 252.463C318.483 252.743 318.709 252.969 318.989 252.969Z"
          fill="#EF4E05"
        />
        <path
          d="M324.167 232.619C323.867 232.619 323.624 232.862 323.624 233.162C323.624 233.463 323.867 233.706 324.167 233.706C324.468 233.706 324.71 233.463 324.71 233.162C324.71 232.862 324.468 232.619 324.167 232.619Z"
          fill="#EF4E05"
        />
        <path
          d="M316.326 217.548C316.62 217.548 316.858 217.31 316.858 217.015C316.858 216.721 316.62 216.483 316.326 216.483C316.032 216.483 315.793 216.721 315.793 217.015C315.793 217.31 316.032 217.548 316.326 217.548Z"
          fill="#EF4E05"
        />
        <path
          d="M332.673 214.384C332.673 214.041 332.394 213.767 332.057 213.767C331.714 213.767 331.439 214.046 331.439 214.384C331.439 214.727 331.719 215.001 332.057 215.001C332.394 215.001 332.673 214.727 332.673 214.384Z"
          fill="#EF4E05"
        />
        <path
          d="M346.906 212.137C346.906 212.528 347.223 212.844 347.613 212.844C348.003 212.844 348.319 212.528 348.319 212.137C348.319 211.747 348.003 211.431 347.613 211.431C347.223 211.425 346.906 211.742 346.906 212.137Z"
          fill="#EF4E05"
        />
        <path
          d="M363.222 211.31C363.222 210.867 362.863 210.508 362.42 210.508C361.977 210.508 361.619 210.867 361.619 211.31C361.619 211.752 361.977 212.111 362.42 212.111C362.863 212.111 363.222 211.752 363.222 211.31Z"
          fill="#EF4E05"
        />
        <path
          d="M375.306 212.412C375.801 212.412 376.203 212.011 376.203 211.515C376.203 211.019 375.801 210.619 375.306 210.619C374.811 210.619 374.409 211.019 374.409 211.515C374.409 212.011 374.811 212.412 375.306 212.412Z"
          fill="#EF4E05"
        />
        <path
          d="M387.687 207.466C387.131 207.466 386.683 207.914 386.683 208.467C386.683 209.021 387.131 209.469 387.687 209.469C388.238 209.469 388.687 209.021 388.687 208.467C388.687 207.914 388.238 207.466 387.687 207.466Z"
          fill="#EF4E05"
        />
        <path
          d="M392.541 197.43C393.134 197.43 393.611 196.951 393.611 196.36C393.611 195.769 393.134 195.289 392.541 195.289C391.952 195.289 391.471 195.769 391.471 196.36C391.471 196.951 391.952 197.43 392.541 197.43Z"
          fill="#EF4E05"
        />
        <path
          d="M386.542 186.172C386.542 186.762 387.019 187.242 387.612 187.242C388.201 187.242 388.682 186.762 388.682 186.172C388.682 185.581 388.201 185.101 387.612 185.101C387.019 185.101 386.542 185.581 386.542 186.172Z"
          fill="#EF4E05"
        />
        <path
          d="M374.914 187.026C375.447 187.026 375.877 186.594 375.877 186.061C375.877 185.528 375.447 185.096 374.914 185.096C374.382 185.096 373.947 185.528 373.947 186.061C373.947 186.594 374.382 187.026 374.914 187.026Z"
          fill="#EF4E05"
        />
        <path
          d="M380.385 197.077C379.852 197.077 379.418 197.509 379.418 198.047C379.418 198.58 379.848 199.017 380.385 199.017C380.918 199.017 381.357 198.585 381.357 198.047C381.357 197.514 380.922 197.077 380.385 197.077Z"
          fill="#EF4E05"
        />
        <path
          d="M367.925 197.52C367.444 197.52 367.052 197.91 367.052 198.395C367.052 198.875 367.44 199.271 367.925 199.271C368.407 199.271 368.799 198.88 368.799 198.395C368.799 197.91 368.411 197.52 367.925 197.52Z"
          fill="#EF4E05"
        />
        <path
          d="M353.862 197.551C353.434 197.551 353.086 197.899 353.086 198.326C353.086 198.754 353.434 199.102 353.862 199.102C354.289 199.102 354.637 198.754 354.637 198.326C354.637 197.899 354.294 197.551 353.862 197.551Z"
          fill="#EF4E05"
        />
        <path
          d="M362.199 185.913C362.199 185.433 361.809 185.038 361.323 185.038C360.838 185.038 360.448 185.428 360.448 185.913C360.448 186.393 360.838 186.788 361.323 186.788C361.809 186.788 362.199 186.393 362.199 185.913Z"
          fill="#EF4E05"
        />
        <path
          d="M355.249 173.621C355.249 174.106 355.645 174.501 356.13 174.501C356.615 174.501 357.01 174.106 357.01 173.621C357.01 173.136 356.615 172.74 356.13 172.74C355.645 172.74 355.249 173.136 355.249 173.621Z"
          fill="#EF4E05"
        />
        <path
          d="M365.781 163.802C366.332 163.802 366.776 163.356 366.776 162.805C366.776 162.255 366.332 161.808 365.781 161.808C365.229 161.808 364.783 162.255 364.783 162.805C364.783 163.356 365.229 163.802 365.781 163.802Z"
          fill="#EF4E05"
        />
        <path
          d="M371.046 174.327C371.046 173.785 370.612 173.347 370.065 173.347C369.523 173.347 369.084 173.785 369.084 174.327C369.084 174.871 369.523 175.309 370.065 175.309C370.612 175.303 371.046 174.866 371.046 174.327Z"
          fill="#EF4E05"
        />
        <path
          d="M382.048 174.897C382.048 175.493 382.534 175.978 383.128 175.978C383.726 175.978 384.211 175.493 384.211 174.897C384.211 174.301 383.726 173.816 383.128 173.816C382.53 173.816 382.048 174.301 382.048 174.897Z"
          fill="#EF4E05"
        />
        <path
          d="M378.895 164.377C379.503 164.377 379.993 163.886 379.993 163.28C379.993 162.673 379.503 162.183 378.895 162.183C378.288 162.183 377.797 162.673 377.797 163.28C377.797 163.886 378.293 164.377 378.895 164.377Z"
          fill="#EF4E05"
        />
        <path
          d="M373.821 153.091C374.433 153.091 374.928 152.596 374.928 151.984C374.928 151.373 374.433 150.877 373.821 150.877C373.209 150.877 372.714 151.373 372.714 151.984C372.714 152.596 373.209 153.091 373.821 153.091Z"
          fill="#EF4E05"
        />
        <path
          d="M360.764 150.444C360.2 150.444 359.741 150.903 359.741 151.468C359.741 152.032 360.2 152.491 360.764 152.491C361.329 152.491 361.787 152.032 361.787 151.468C361.787 150.903 361.329 150.444 360.764 150.444Z"
          fill="#EF4E05"
        />
        <path
          d="M352.928 142.471C352.374 142.471 351.926 142.919 351.926 143.473C351.926 144.027 352.374 144.475 352.928 144.475C353.482 144.475 353.93 144.027 353.93 143.473C353.924 142.919 353.476 142.471 352.928 142.471Z"
          fill="#EF4E05"
        />
        <path
          d="M349.933 132.489C350.513 132.489 350.988 132.019 350.988 131.434C350.988 130.854 350.518 130.379 349.933 130.379C349.353 130.379 348.878 130.849 348.878 131.434C348.878 132.014 349.353 132.489 349.933 132.489Z"
          fill="#EF4E05"
        />
        <path
          d="M367.355 141.485C367.355 142.107 367.864 142.613 368.486 142.613C369.107 142.613 369.611 142.107 369.611 141.485C369.611 140.863 369.107 140.356 368.486 140.356C367.855 140.356 367.355 140.863 367.355 141.485Z"
          fill="#EF4E05"
        />
        <path
          d="M361.661 132.23C361.661 132.858 362.167 133.364 362.795 133.364C363.422 133.364 363.928 132.858 363.928 132.23C363.928 131.603 363.422 131.096 362.795 131.096C362.167 131.102 361.661 131.608 361.661 132.23Z"
          fill="#EF4E05"
        />
        <path
          d="M360.269 121.536C360.269 120.882 359.742 120.355 359.088 120.355C358.434 120.355 357.907 120.882 357.907 121.536C357.907 122.19 358.434 122.717 359.088 122.717C359.742 122.717 360.269 122.19 360.269 121.536Z"
          fill="#EF4E05"
        />
        <path
          d="M355.491 112.171C356.166 112.171 356.714 111.622 356.714 110.947C356.714 110.272 356.166 109.723 355.491 109.723C354.816 109.723 354.268 110.272 354.268 110.947C354.268 111.622 354.816 112.171 355.491 112.171Z"
          fill="#EF4E05"
        />
        <path
          d="M346.458 120.987C347.07 120.987 347.566 120.492 347.566 119.88C347.566 119.269 347.07 118.773 346.458 118.773C345.847 118.773 345.351 119.269 345.351 119.88C345.351 120.492 345.847 120.987 346.458 120.987Z"
          fill="#EF4E05"
        />
        <path
          d="M342.118 108.653C342.118 109.291 342.635 109.813 343.278 109.813C343.921 109.813 344.438 109.296 344.438 108.653C344.438 108.01 343.921 107.493 343.278 107.493C342.635 107.493 342.118 108.015 342.118 108.653Z"
          fill="#EF4E05"
        />
        <path
          d="M353.608 100.611C353.608 99.9045 353.034 99.3299 352.327 99.3299C351.621 99.3299 351.045 99.9045 351.045 100.611C351.045 101.318 351.621 101.892 352.327 101.892C353.039 101.892 353.608 101.318 353.608 100.611Z"
          fill="#EF4E05"
        />
        <path
          d="M363.739 100.179C362.99 100.179 362.384 100.785 362.384 101.534C362.384 102.283 362.99 102.889 363.739 102.889C364.488 102.889 365.094 102.283 365.094 101.534C365.094 100.785 364.488 100.179 363.739 100.179Z"
          fill="#EF4E05"
        />
        <path
          d="M371.022 93.9086C371.84 93.9086 372.503 93.2452 372.503 92.4267C372.503 91.6087 371.84 90.9453 371.022 90.9453C370.205 90.9453 369.542 91.6087 369.542 92.4267C369.542 93.2452 370.205 93.9086 371.022 93.9086Z"
          fill="#EF4E05"
        />
        <path
          d="M371.368 76.8072C372.269 76.8072 372.998 76.0775 372.998 75.1777C372.998 74.2779 372.269 73.5481 371.368 73.5481C370.471 73.5481 369.737 74.2779 369.737 75.1777C369.737 76.0775 370.471 76.8072 371.368 76.8072Z"
          fill="#EF4E05"
        />
        <path
          d="M375.835 84.7489C376.718 84.7489 377.428 84.037 377.428 83.1563C377.428 82.2757 376.718 81.5637 375.835 81.5637C374.956 81.5637 374.242 82.2757 374.242 83.1563C374.242 84.037 374.956 84.7489 375.835 84.7489Z"
          fill="#EF4E05"
        />
        <path
          d="M383.959 87.5908C384.842 87.5908 385.561 86.8737 385.561 85.9879C385.561 85.1021 384.842 84.385 383.959 84.385C383.071 84.385 382.352 85.1021 382.352 85.9879C382.352 86.8737 383.071 87.5908 383.959 87.5908Z"
          fill="#EF4E05"
        />
        <path
          d="M381.749 96.5454C382.609 96.5454 383.305 95.8493 383.305 94.9897C383.305 94.1301 382.609 93.434 381.749 93.434C380.889 93.434 380.193 94.1301 380.193 94.9897C380.193 95.8493 380.889 96.5454 381.749 96.5454Z"
          fill="#EF4E05"
        />
        <path
          d="M386.799 105.626C387.64 105.626 388.322 104.944 388.322 104.102C388.322 103.26 387.64 102.578 386.799 102.578C385.958 102.578 385.276 103.26 385.276 104.102C385.276 104.944 385.958 105.626 386.799 105.626Z"
          fill="#EF4E05"
        />
        <path
          d="M390.663 96.3768C391.523 96.3768 392.219 95.6802 392.219 94.821C392.219 93.9619 391.523 93.2653 390.663 93.2653C389.804 93.2653 389.107 93.9619 389.107 94.821C389.107 95.6802 389.804 96.3768 390.663 96.3768Z"
          fill="#EF4E05"
        />
        <path
          d="M390.453 131.36C391.21 131.36 391.817 130.749 391.817 129.994C391.817 129.24 391.21 128.629 390.453 128.629C389.701 128.629 389.089 129.24 389.089 129.994C389.089 130.749 389.701 131.36 390.453 131.36Z"
          fill="#EF4E05"
        />
        <path
          d="M390.463 113.563C390.463 114.391 391.136 115.06 391.963 115.06C392.79 115.06 393.462 114.391 393.462 113.563C393.462 112.734 392.79 112.065 391.963 112.065C391.14 112.065 390.463 112.734 390.463 113.563Z"
          fill="#EF4E05"
        />
        <path
          d="M384.548 120.56C383.786 120.56 383.174 121.172 383.174 121.931C383.174 122.691 383.786 123.302 384.548 123.302C385.305 123.302 385.917 122.691 385.917 121.931C385.917 121.177 385.3 120.56 384.548 120.56Z"
          fill="#EF4E05"
        />
        <path
          d="M379.506 114.116C380.277 114.116 380.899 113.494 380.899 112.724C380.899 111.954 380.277 111.332 379.506 111.332C378.736 111.332 378.114 111.954 378.114 112.724C378.114 113.494 378.736 114.116 379.506 114.116Z"
          fill="#EF4E05"
        />
        <path
          d="M374.881 104.466C375.666 104.466 376.306 103.828 376.306 103.042C376.306 102.256 375.666 101.618 374.881 101.618C374.097 101.618 373.457 102.256 373.457 103.042C373.457 103.828 374.097 104.466 374.881 104.466Z"
          fill="#EF4E05"
        />
        <path
          d="M367.355 113.215C368.08 113.215 368.664 112.629 368.664 111.907C368.664 111.184 368.08 110.599 367.355 110.599C366.636 110.599 366.047 111.184 366.047 111.907C366.047 112.629 366.636 113.215 367.355 113.215Z"
          fill="#EF4E05"
        />
        <path
          d="M372.78 121.926C372.78 121.23 372.214 120.66 371.514 120.66C370.813 120.66 370.248 121.225 370.248 121.926C370.248 122.628 370.813 123.191 371.514 123.191C372.214 123.191 372.78 122.622 372.78 121.926Z"
          fill="#EF4E05"
        />
        <path
          d="M376.545 129.82C375.854 129.82 375.293 130.379 375.293 131.07C375.293 131.761 375.854 132.32 376.545 132.32C377.232 132.32 377.792 131.761 377.792 131.07C377.792 130.379 377.232 129.82 376.545 129.82Z"
          fill="#EF4E05"
        />
        <path
          d="M382.74 140.024C382.74 139.344 382.184 138.79 381.506 138.79C380.824 138.79 380.273 139.344 380.273 140.024C380.273 140.704 380.824 141.258 381.506 141.258C382.184 141.258 382.74 140.704 382.74 140.024Z"
          fill="#EF4E05"
        />
        <path
          d="M384.137 149.78C384.137 150.45 384.679 150.993 385.351 150.993C386.019 150.993 386.561 150.45 386.561 149.78C386.561 149.11 386.019 148.567 385.351 148.567C384.679 148.567 384.137 149.11 384.137 149.78Z"
          fill="#EF4E05"
        />
        <path
          d="M389.724 160.237C390.392 160.237 390.934 159.699 390.934 159.029C390.934 158.36 390.397 157.822 389.724 157.822C389.056 157.822 388.519 158.36 388.519 159.029C388.519 159.699 389.061 160.237 389.724 160.237Z"
          fill="#EF4E05"
        />
        <path
          d="M393.065 167.625C392.411 167.625 391.878 168.158 391.878 168.811C391.878 169.465 392.411 169.998 393.065 169.998C393.719 169.998 394.251 169.465 394.251 168.811C394.247 168.158 393.719 167.625 393.065 167.625Z"
          fill="#EF4E05"
        />
        <path
          d="M389.009 259.303C388.495 259.303 388.08 259.719 388.08 260.231C388.08 260.742 388.495 261.159 389.009 261.159C389.519 261.159 389.939 260.742 389.939 260.231C389.939 259.719 389.519 259.303 389.009 259.303Z"
          fill="#EF4E05"
        />
        <path
          d="M386.43 248.192C386.934 248.192 387.35 247.78 387.35 247.269C387.35 246.757 386.939 246.346 386.43 246.346C385.916 246.346 385.505 246.757 385.505 247.269C385.505 247.78 385.916 248.192 386.43 248.192Z"
          fill="#EF4E05"
        />
        <path
          d="M375.778 253.913C375.325 253.913 374.956 254.282 374.956 254.736C374.956 255.189 375.325 255.558 375.778 255.558C376.231 255.558 376.601 255.189 376.601 254.736C376.601 254.282 376.231 253.913 375.778 253.913Z"
          fill="#EF4E05"
        />
        <path
          d="M375.872 267.85C375.872 268.315 376.246 268.684 376.704 268.684C377.171 268.684 377.54 268.309 377.54 267.85C377.54 267.387 377.166 267.017 376.704 267.017C376.246 267.017 375.872 267.392 375.872 267.85Z"
          fill="#EF4E05"
        />
        <path
          d="M379.768 280.344C379.768 279.874 379.39 279.494 378.923 279.494C378.451 279.494 378.072 279.874 378.072 280.344C378.072 280.813 378.451 281.192 378.923 281.192C379.39 281.192 379.768 280.813 379.768 280.344Z"
          fill="#EF4E05"
        />
        <path
          d="M390.953 273.446C390.953 272.929 390.537 272.507 390.014 272.507C389.495 272.507 389.08 272.924 389.08 273.446C389.08 273.968 389.495 274.385 390.014 274.385C390.537 274.385 390.953 273.963 390.953 273.446Z"
          fill="#EF4E05"
        />
        <path
          d="M389.271 286.682C388.758 286.682 388.342 287.099 388.342 287.615C388.342 288.132 388.758 288.549 389.271 288.549C389.79 288.549 390.206 288.132 390.206 287.615C390.206 287.099 389.79 286.682 389.271 286.682Z"
          fill="#EF4E05"
        />
        <path
          d="M374.583 291.876C374.583 291.428 374.218 291.064 373.77 291.064C373.321 291.064 372.957 291.428 372.957 291.876C372.957 292.325 373.321 292.688 373.77 292.688C374.218 292.688 374.583 292.325 374.583 291.876Z"
          fill="#EF4E05"
        />
        <path
          d="M381.347 303.261C381.347 302.776 380.95 302.381 380.464 302.381C379.978 302.381 379.586 302.776 379.586 303.261C379.586 303.746 379.978 304.142 380.464 304.142C380.95 304.142 381.347 303.746 381.347 303.261Z"
          fill="#EF4E05"
        />
        <path
          d="M390.962 299.227C390.962 299.76 391.392 300.198 391.929 300.198C392.466 300.198 392.901 299.765 392.901 299.227C392.901 298.695 392.466 298.257 391.929 298.257C391.392 298.257 390.962 298.695 390.962 299.227Z"
          fill="#EF4E05"
        />
        <path
          d="M392.032 311.989C392.032 311.451 391.593 311.013 391.056 311.013C390.518 311.013 390.079 311.451 390.079 311.989C390.079 312.527 390.518 312.964 391.056 312.964C391.593 312.964 392.032 312.527 392.032 311.989Z"
          fill="#EF4E05"
        />
        <path
          d="M388.056 325.884C388.594 325.884 389.028 325.446 389.028 324.909C389.028 324.371 388.594 323.933 388.056 323.933C387.515 323.933 387.08 324.371 387.08 324.909C387.08 325.446 387.515 325.884 388.056 325.884Z"
          fill="#EF4E05"
        />
        <path
          d="M392.906 347.937C392.304 347.937 391.813 348.428 391.813 349.029C391.813 349.63 392.304 350.12 392.906 350.12C393.509 350.12 393.999 349.63 393.999 349.029C393.999 348.428 393.514 347.937 392.906 347.937Z"
          fill="#EF4E05"
        />
        <path
          d="M391.303 336.737C391.303 336.172 390.845 335.708 390.275 335.708C389.71 335.708 389.248 336.167 389.248 336.737C389.248 337.306 389.705 337.765 390.275 337.765C390.845 337.765 391.303 337.306 391.303 336.737Z"
          fill="#EF4E05"
        />
        <path
          d="M380.067 344.657C380.6 344.657 381.03 344.225 381.03 343.692C381.03 343.159 380.6 342.727 380.067 342.727C379.53 342.727 379.1 343.159 379.1 343.692C379.1 344.225 379.53 344.657 380.067 344.657Z"
          fill="#EF4E05"
        />
        <path
          d="M373.905 329.892C373.905 330.382 374.302 330.783 374.798 330.783C375.288 330.783 375.69 330.388 375.69 329.892C375.69 329.401 375.293 329.001 374.798 329.001C374.307 329.001 373.905 329.401 373.905 329.892Z"
          fill="#EF4E05"
        />
        <path
          d="M377.862 316.239C377.862 315.759 377.47 315.364 376.984 315.364C376.498 315.364 376.11 315.754 376.11 316.239C376.11 316.724 376.498 317.114 376.984 317.114C377.47 317.114 377.862 316.719 377.862 316.239Z"
          fill="#EF4E05"
        />
        <path
          d="M361.809 318.101C361.809 318.528 362.157 318.871 362.579 318.871C363.001 318.871 363.349 318.523 363.349 318.101C363.349 317.674 363.001 317.331 362.579 317.331C362.157 317.331 361.809 317.674 361.809 318.101Z"
          fill="#EF4E05"
        />
        <path
          d="M366.187 304.791C366.612 304.791 366.953 304.442 366.953 304.021C366.953 303.599 366.607 303.251 366.187 303.251C365.757 303.251 365.416 303.599 365.416 304.021C365.416 304.442 365.757 304.791 366.187 304.791Z"
          fill="#EF4E05"
        />
        <path
          d="M350.777 305.355C351.152 305.355 351.458 305.049 351.458 304.675C351.458 304.3 351.152 303.994 350.777 303.994C350.403 303.994 350.097 304.3 350.097 304.675C350.097 305.049 350.403 305.355 350.777 305.355Z"
          fill="#EF4E05"
        />
        <path
          d="M356.815 290.943C357.205 290.943 357.516 290.626 357.516 290.241C357.516 289.856 357.2 289.54 356.815 289.54C356.43 289.54 356.113 289.856 356.113 290.241C356.113 290.626 356.43 290.943 356.815 290.943Z"
          fill="#EF4E05"
        />
        <path
          d="M364.799 279.436C365.211 279.436 365.543 279.104 365.543 278.692C365.543 278.281 365.211 277.949 364.799 277.949C364.387 277.949 364.055 278.281 364.055 278.692C364.055 279.104 364.387 279.436 364.799 279.436Z"
          fill="#EF4E05"
        />
        <path
          d="M362.241 264.048C361.835 264.048 361.508 264.376 361.508 264.781C361.508 265.187 361.835 265.514 362.241 265.514C362.647 265.514 362.974 265.187 362.974 264.781C362.974 264.376 362.647 264.048 362.241 264.048Z"
          fill="#EF4E05"
        />
        <path
          d="M364.155 250.227C364.155 249.816 363.823 249.483 363.411 249.483C363 249.483 362.668 249.816 362.668 250.227C362.668 250.638 363 250.971 363.411 250.971C363.823 250.971 364.155 250.638 364.155 250.227Z"
          fill="#EF4E05"
        />
        <path
          d="M372.224 240.571C372.677 240.571 373.041 240.206 373.041 239.754C373.041 239.303 372.677 238.937 372.224 238.937C371.771 238.937 371.406 239.303 371.406 239.754C371.406 240.206 371.771 240.571 372.224 240.571Z"
          fill="#EF4E05"
        />
        <path
          d="M384.478 234.913C384.987 234.913 385.403 234.5 385.403 233.991C385.403 233.481 384.987 233.067 384.478 233.067C383.968 233.067 383.557 233.481 383.557 233.991C383.557 234.5 383.968 234.913 384.478 234.913Z"
          fill="#EF4E05"
        />
        <path
          d="M386.014 221.15C386.014 220.628 385.589 220.201 385.066 220.201C384.543 220.201 384.113 220.622 384.113 221.15C384.113 221.677 384.538 222.099 385.066 222.099C385.584 222.099 386.014 221.672 386.014 221.15Z"
          fill="#EF4E05"
        />
        <path
          d="M371.537 226.576C372.004 226.576 372.373 226.202 372.373 225.743C372.373 225.279 371.995 224.909 371.537 224.909C371.079 224.909 370.705 225.284 370.705 225.743C370.705 226.202 371.079 226.576 371.537 226.576Z"
          fill="#EF4E05"
        />
        <path
          d="M357.743 225.215C358.153 225.215 358.487 224.883 358.487 224.472C358.487 224.061 358.153 223.728 357.743 223.728C357.332 223.728 357 224.061 357 224.472C357 224.883 357.332 225.215 357.743 225.215Z"
          fill="#EF4E05"
        />
        <path
          d="M355.549 237.65C355.159 237.65 354.842 237.966 354.842 238.357C354.842 238.747 355.159 239.063 355.549 239.063C355.939 239.063 356.256 238.747 356.256 238.357C356.256 237.966 355.939 237.65 355.549 237.65Z"
          fill="#EF4E05"
        />
        <path
          d="M342.055 228.459C342.414 228.459 342.698 228.169 342.698 227.815C342.698 227.462 342.408 227.172 342.055 227.172C341.702 227.172 341.412 227.462 341.412 227.815C341.412 228.169 341.702 228.459 342.055 228.459Z"
          fill="#EF4E05"
        />
        <path
          d="M338.147 243.003C337.815 243.003 337.546 243.271 337.546 243.603C337.546 243.936 337.815 244.205 338.147 244.205C338.479 244.205 338.748 243.936 338.748 243.603C338.748 243.271 338.479 243.003 338.147 243.003Z"
          fill="#EF4E05"
        />
        <path
          d="M350.033 255.98C350.397 255.98 350.692 255.685 350.692 255.321C350.692 254.958 350.397 254.662 350.033 254.662C349.669 254.662 349.374 254.958 349.374 255.321C349.374 255.685 349.669 255.98 350.033 255.98Z"
          fill="#EF4E05"
        />
        <path
          d="M335.505 261.101C335.827 261.101 336.085 260.842 336.085 260.521C336.085 260.199 335.827 259.941 335.505 259.941C335.183 259.941 334.925 260.199 334.925 260.521C334.92 260.842 335.183 261.101 335.505 261.101Z"
          fill="#EF4E05"
        />
        <path
          d="M327.405 274.548C327.405 274.843 327.648 275.086 327.943 275.086C328.238 275.086 328.481 274.843 328.481 274.548C328.481 274.253 328.238 274.01 327.943 274.01C327.648 274.01 327.405 274.247 327.405 274.548Z"
          fill="#EF4E05"
        />
        <path
          d="M348.108 274.521C348.463 274.521 348.751 274.234 348.751 273.878C348.751 273.523 348.463 273.235 348.108 273.235C347.753 273.235 347.465 273.523 347.465 273.878C347.465 274.234 347.753 274.521 348.108 274.521Z"
          fill="#EF4E05"
        />
        <path
          d="M339.85 289.972C340.185 289.972 340.456 289.701 340.456 289.366C340.456 289.031 340.185 288.759 339.85 288.759C339.515 288.759 339.244 289.031 339.244 289.366C339.244 289.701 339.515 289.972 339.85 289.972Z"
          fill="#EF4E05"
        />
        <path
          d="M334.45 306.42C334.45 306.099 334.187 305.835 333.865 305.835C333.543 305.835 333.279 306.099 333.279 306.42C333.279 306.742 333.543 307.005 333.865 307.005C334.187 307.005 334.45 306.742 334.45 306.42Z"
          fill="#EF4E05"
        />
        <path
          d="M316.289 310.929C316.289 310.644 316.057 310.417 315.777 310.417C315.493 310.417 315.266 310.65 315.266 310.929C315.266 311.208 315.498 311.44 315.777 311.44C316.057 311.44 316.289 311.213 316.289 310.929Z"
          fill="#EF4E05"
        />
        <path
          d="M320.566 292.388C320.851 292.388 321.077 292.155 321.077 291.876C321.077 291.597 320.846 291.364 320.566 291.364C320.281 291.364 320.055 291.597 320.055 291.876C320.055 292.155 320.281 292.388 320.566 292.388Z"
          fill="#EF4E05"
        />
        <path
          d="M299.662 294.333C299.662 294.096 299.467 293.901 299.23 293.901C298.992 293.901 298.797 294.096 298.797 294.333C298.797 294.571 298.992 294.766 299.23 294.766C299.467 294.766 299.662 294.571 299.662 294.333Z"
          fill="#EF4E05"
        />
        <path
          d="M308.436 273.604C308.69 273.604 308.895 273.398 308.895 273.145C308.895 272.892 308.69 272.686 308.436 272.686C308.183 272.686 307.978 272.892 307.978 273.145C307.978 273.398 308.183 273.604 308.436 273.604Z"
          fill="#EF4E05"
        />
        <path
          d="M282.191 273.941C282.402 273.941 282.571 273.773 282.571 273.562C282.571 273.351 282.402 273.182 282.191 273.182C281.98 273.182 281.812 273.351 281.812 273.562C281.812 273.767 281.985 273.941 282.191 273.941Z"
          fill="#EF4E05"
        />
        <path
          d="M274.634 303.103C274.841 303.103 275.009 302.936 275.009 302.729C275.009 302.522 274.841 302.354 274.634 302.354C274.428 302.354 274.26 302.522 274.26 302.729C274.26 302.936 274.428 303.103 274.634 303.103Z"
          fill="#EF4E05"
        />
        <path
          d="M296.361 317.331C296.361 317.578 296.561 317.779 296.809 317.779C297.057 317.779 297.257 317.578 297.257 317.331C297.257 317.083 297.057 316.882 296.809 316.882C296.566 316.882 296.361 317.083 296.361 317.331Z"
          fill="#EF4E05"
        />
        <path
          d="M276.829 327.883C277.056 327.883 277.24 327.699 277.24 327.471C277.24 327.244 277.056 327.06 276.829 327.06C276.602 327.06 276.417 327.244 276.417 327.471C276.417 327.699 276.602 327.883 276.829 327.883Z"
          fill="#EF4E05"
        />
        <path
          d="M252.365 334.195C252.572 334.195 252.74 334.027 252.74 333.821C252.74 333.614 252.572 333.446 252.365 333.446C252.159 333.446 251.991 333.614 251.991 333.821C251.991 334.027 252.159 334.195 252.365 334.195Z"
          fill="#EF4E05"
        />
        <path
          d="M225.492 351.186C225.492 351.397 225.661 351.565 225.872 351.565C226.083 351.565 226.251 351.397 226.251 351.186C226.251 350.975 226.083 350.806 225.872 350.806C225.661 350.806 225.492 350.975 225.492 351.186Z"
          fill="#EF4E05"
        />
        <path
          d="M233.555 377.595C233.555 377.342 233.349 377.136 233.096 377.136C232.843 377.136 232.637 377.342 232.637 377.595C232.637 377.848 232.843 378.054 233.096 378.054C233.349 378.059 233.555 377.854 233.555 377.595Z"
          fill="#EF4E05"
        />
        <path
          d="M250.567 360.941C250.804 360.941 250.994 360.752 250.994 360.514C250.994 360.277 250.804 360.087 250.567 360.087C250.329 360.087 250.14 360.277 250.14 360.514C250.14 360.746 250.329 360.941 250.567 360.941Z"
          fill="#EF4E05"
        />
        <path
          d="M255.408 381.603C255.688 381.603 255.914 381.376 255.914 381.096C255.914 380.817 255.688 380.59 255.408 380.59C255.128 380.59 254.902 380.817 254.902 381.096C254.902 381.376 255.128 381.603 255.408 381.603Z"
          fill="#EF4E05"
        />
        <path
          d="M260.776 399.484C260.776 399.817 261.045 400.086 261.378 400.086C261.71 400.086 261.978 399.817 261.978 399.484C261.978 399.152 261.71 398.883 261.378 398.883C261.045 398.883 260.776 399.152 260.776 399.484Z"
          fill="#EF4E05"
        />
        <path
          d="M242.873 395.635C242.873 395.936 243.116 396.178 243.416 396.178C243.717 396.178 243.959 395.936 243.959 395.635C243.959 395.334 243.717 395.092 243.416 395.092C243.116 395.092 242.873 395.334 242.873 395.635Z"
          fill="#EF4E05"
        />
        <path
          d="M227.87 401.842C228.173 401.842 228.419 401.596 228.419 401.293C228.419 400.99 228.173 400.745 227.87 400.745C227.568 400.745 227.322 400.99 227.322 401.293C227.322 401.596 227.568 401.842 227.87 401.842Z"
          fill="#EF4E05"
        />
        <path
          d="M214.128 389.159C213.859 389.159 213.638 389.381 213.638 389.65C213.638 389.919 213.859 390.14 214.128 390.14C214.397 390.14 214.619 389.919 214.619 389.65C214.619 389.381 214.402 389.159 214.128 389.159Z"
          fill="#EF4E05"
        />
        <path
          d="M210.342 370.586C210.342 370.355 210.158 370.17 209.926 370.17C209.693 370.17 209.509 370.355 209.509 370.586C209.509 370.819 209.693 371.003 209.926 371.003C210.158 371.003 210.342 370.819 210.342 370.586Z"
          fill="#EF4E05"
        />
        <path
          d="M191.553 355.937C191.762 355.937 191.933 355.767 191.933 355.557C191.933 355.348 191.762 355.178 191.553 355.178C191.343 355.178 191.173 355.348 191.173 355.557C191.173 355.767 191.343 355.937 191.553 355.937Z"
          fill="#EF4E05"
        />
        <path
          d="M162.565 347.884C162.565 347.679 162.396 347.51 162.19 347.51C161.985 347.51 161.816 347.679 161.816 347.884C161.816 348.09 161.985 348.259 162.19 348.259C162.396 348.259 162.565 348.09 162.565 347.884Z"
          fill="#EF4E05"
        />
        <path
          d="M140.965 359.85C141.204 359.85 141.398 359.656 141.398 359.417C141.398 359.179 141.204 358.985 140.965 358.985C140.726 358.985 140.533 359.179 140.533 359.417C140.533 359.656 140.726 359.85 140.965 359.85Z"
          fill="#EF4E05"
        />
        <path
          d="M134.695 336.246C134.695 336.463 134.869 336.636 135.085 336.636C135.302 336.636 135.476 336.463 135.476 336.246C135.476 336.03 135.302 335.856 135.085 335.856C134.869 335.856 134.695 336.03 134.695 336.246Z"
          fill="#EF4E05"
        />
        <path
          d="M115.785 354.292C116.044 354.292 116.254 354.082 116.254 353.822C116.254 353.563 116.044 353.353 115.785 353.353C115.526 353.353 115.315 353.563 115.315 353.822C115.315 354.082 115.526 354.292 115.785 354.292Z"
          fill="#EF4E05"
        />
        <path
          d="M127.017 373.186C127.294 373.186 127.518 372.962 127.518 372.685C127.518 372.408 127.294 372.184 127.017 372.184C126.741 372.184 126.516 372.408 126.516 372.685C126.516 372.962 126.741 373.186 127.017 373.186Z"
          fill="#EF4E05"
        />
        <path
          d="M109.547 374.209C109.547 373.898 109.293 373.65 108.987 373.65C108.681 373.65 108.429 373.903 108.429 374.209C108.429 374.52 108.681 374.768 108.987 374.768C109.293 374.768 109.547 374.52 109.547 374.209Z"
          fill="#EF4E05"
        />
        <path
          d="M95.9939 362.186C95.9939 361.875 95.7407 361.627 95.4347 361.627C95.1292 361.627 94.876 361.88 94.876 362.186C94.876 362.497 95.1292 362.745 95.4347 362.745C95.7407 362.745 95.9939 362.492 95.9939 362.186Z"
          fill="#EF4E05"
        />
        <path
          d="M94.7232 344.736C95.0054 344.736 95.2344 344.508 95.2344 344.225C95.2344 343.942 95.0054 343.713 94.7232 343.713C94.4406 343.713 94.2117 343.942 94.2117 344.225C94.2117 344.508 94.4406 344.736 94.7232 344.736Z"
          fill="#EF4E05"
        />
        <path
          d="M110.954 330.852C110.954 330.614 110.76 330.419 110.522 330.419C110.285 330.419 110.09 330.614 110.09 330.852C110.09 331.089 110.285 331.284 110.522 331.284C110.76 331.284 110.954 331.089 110.954 330.852Z"
          fill="#EF4E05"
        />
        <path
          d="M90.1406 322.578C90.1406 322.841 90.3565 323.058 90.6204 323.058C90.8839 323.058 91.1002 322.841 91.1002 322.578C91.1002 322.314 90.8839 322.097 90.6204 322.097C90.3565 322.097 90.1406 322.314 90.1406 322.578Z"
          fill="#EF4E05"
        />
        <path
          d="M70.8295 319.788C71.1383 319.788 71.3882 319.538 71.3882 319.229C71.3882 318.92 71.1383 318.67 70.8295 318.67C70.5207 318.67 70.2703 318.92 70.2703 319.229C70.2703 319.538 70.5207 319.788 70.8295 319.788Z"
          fill="#EF4E05"
        />
        <path
          d="M76.24 337.253C76.24 337.565 76.4932 337.818 76.8044 337.818C77.1155 337.818 77.3683 337.565 77.3683 337.253C77.3683 336.942 77.1155 336.689 76.8044 336.689C76.4932 336.689 76.24 336.942 76.24 337.253Z"
          fill="#EF4E05"
        />
        <path
          d="M78.7763 355.969C78.7763 355.631 78.5021 355.357 78.1647 355.357C77.827 355.357 77.5527 355.631 77.5527 355.969C77.5527 356.306 77.827 356.581 78.1647 356.581C78.5021 356.586 78.7763 356.311 78.7763 355.969Z"
          fill="#EF4E05"
        />
        <path
          d="M79.2718 373.233C79.6408 373.233 79.9361 372.933 79.9361 372.569C79.9361 372.205 79.6357 371.905 79.2718 371.905C78.9078 371.905 78.6074 372.205 78.6074 372.569C78.6074 372.933 78.9027 373.233 79.2718 373.233Z"
          fill="#EF4E05"
        />
        <path
          d="M62.5557 366.684C62.5557 367.085 62.8827 367.407 63.2779 367.407C63.6788 367.407 64.0007 367.08 64.0007 366.684C64.0007 366.289 63.6788 365.962 63.2779 365.962C62.8827 365.956 62.5557 366.283 62.5557 366.684Z"
          fill="#EF4E05"
        />
        <path
          d="M67.4914 382.309C67.9133 382.309 68.2562 381.966 68.2562 381.544C68.2562 381.122 67.9133 380.78 67.4914 380.78C67.0695 380.78 66.7266 381.122 66.7266 381.544C66.7215 381.966 67.0644 382.309 67.4914 382.309Z"
          fill="#EF4E05"
        />
        <path
          d="M82.2938 391.205C82.6946 391.205 83.0217 390.879 83.0217 390.478C83.0217 390.077 82.6946 389.75 82.2938 389.75C81.893 389.75 81.5659 390.077 81.5659 390.478C81.5659 390.879 81.893 391.205 82.2938 391.205Z"
          fill="#EF4E05"
        />
        <path
          d="M93.2783 380.231C93.6315 380.231 93.9165 379.946 93.9165 379.593C93.9165 379.24 93.6315 378.955 93.2783 378.955C92.9251 378.955 92.6401 379.24 92.6401 379.593C92.6401 379.946 92.9251 380.231 93.2783 380.231Z"
          fill="#EF4E05"
        />
        <path
          d="M101.299 393.283C101.299 392.914 100.999 392.613 100.629 392.613C100.26 392.613 99.9595 392.914 99.9595 393.283C99.9595 393.652 100.26 393.953 100.629 393.953C100.999 393.953 101.299 393.652 101.299 393.283Z"
          fill="#EF4E05"
        />
        <path
          d="M87.293 404.631C87.293 405.059 87.641 405.407 88.0685 405.407C88.4955 405.407 88.8436 405.059 88.8436 404.631C88.8436 404.204 88.4955 403.856 88.0685 403.856C87.641 403.851 87.293 404.199 87.293 404.631Z"
          fill="#EF4E05"
        />
        <path
          d="M74.9058 407.099C75.3753 407.099 75.7546 406.72 75.7546 406.25C75.7546 405.781 75.3753 405.401 74.9058 405.401C74.4362 405.401 74.0569 405.781 74.0569 406.25C74.0569 406.714 74.4362 407.099 74.9058 407.099Z"
          fill="#EF4E05"
        />
        <path
          d="M69.495 394.818C69.495 394.364 69.126 394 68.6775 394C68.2243 394 67.8604 394.369 67.8604 394.818C67.8604 395.266 68.2294 395.635 68.6775 395.635C69.1311 395.635 69.495 395.271 69.495 394.818Z"
          fill="#EF4E05"
        />
        <path
          d="M55.5315 394.875C56.024 394.875 56.4229 394.476 56.4229 393.985C56.4229 393.492 56.024 393.093 55.5315 393.093C55.0396 393.093 54.6401 393.492 54.6401 393.985C54.6401 394.476 55.0396 394.875 55.5315 394.875Z"
          fill="#EF4E05"
        />
        <path
          d="M53.3374 380.21C52.8735 380.21 52.5044 380.585 52.5044 381.043C52.5044 381.507 52.8786 381.877 53.3374 381.877C53.8018 381.877 54.1708 381.502 54.1708 381.043C54.176 380.585 53.8018 380.21 53.3374 380.21Z"
          fill="#EF4E05"
        />
        <path
          d="M46.6721 370.866C47.1295 370.866 47.5 370.495 47.5 370.038C47.5 369.581 47.1295 369.21 46.6721 369.21C46.2147 369.21 45.8442 369.581 45.8442 370.038C45.8442 370.495 46.2147 370.866 46.6721 370.866Z"
          fill="#EF4E05"
        />
        <path
          d="M38.2085 360.293C38.6724 360.293 39.0466 359.919 39.0466 359.454C39.0466 358.99 38.6724 358.616 38.2085 358.616C37.7441 358.616 37.3699 358.99 37.3699 359.454C37.3699 359.919 37.7441 360.293 38.2085 360.293Z"
          fill="#EF4E05"
        />
        <path
          d="M32.0859 349.762C32.555 349.762 32.9348 349.382 32.9348 348.913C32.9348 348.444 32.555 348.064 32.0859 348.064C31.6164 348.064 31.2366 348.444 31.2366 348.913C31.2366 349.382 31.6164 349.762 32.0859 349.762Z"
          fill="#EF4E05"
        />
        <path
          d="M29.5598 336.193C29.101 336.193 28.7319 336.563 28.7319 337.022C28.7319 337.481 29.101 337.85 29.5598 337.85C30.0186 337.85 30.3876 337.481 30.3876 337.022C30.3876 336.563 30.0186 336.193 29.5598 336.193Z"
          fill="#EF4E05"
        />
        <path
          d="M24.6401 325.003C24.6401 325.462 25.0144 325.831 25.468 325.831C25.9216 325.831 26.2959 325.457 26.2959 325.003C26.2959 324.545 25.9216 324.176 25.468 324.176C25.0144 324.176 24.6401 324.545 24.6401 325.003Z"
          fill="#EF4E05"
        />
        <path
          d="M10.0801 323.775C10.0801 324.291 10.4968 324.713 11.0187 324.713C11.5358 324.713 11.9577 324.297 11.9577 323.775C11.9577 323.253 11.541 322.836 11.0187 322.836C10.4968 322.841 10.0801 323.258 10.0801 323.775Z"
          fill="#EF4E05"
        />
        <path
          d="M7.80749 313.808C8.32981 313.808 8.75167 313.386 8.75167 312.864C8.75167 312.342 8.32981 311.92 7.80749 311.92C7.28564 311.92 6.86377 312.342 6.86377 312.864C6.86377 313.391 7.28564 313.808 7.80749 313.808Z"
          fill="#EF4E05"
        />
        <path
          d="M3.34118 293.537C3.86817 293.537 4.29565 293.11 4.29565 292.583C4.29565 292.055 3.86817 291.628 3.34118 291.628C2.81373 291.628 2.38672 292.055 2.38672 292.583C2.38672 293.11 2.81373 293.537 3.34118 293.537Z"
          fill="#EF4E05"
        />
        <path
          d="M14.2779 300.899C14.2779 300.408 13.8822 300.013 13.3921 300.013C12.9016 300.013 12.5059 300.408 12.5059 300.899C12.5059 301.389 12.9016 301.785 13.3921 301.785C13.8822 301.785 14.2779 301.389 14.2779 300.899Z"
          fill="#EF4E05"
        />
        <path
          d="M21.4655 313.297C21.9243 313.297 22.2934 312.922 22.2934 312.469C22.2934 312.01 21.9192 311.641 21.4655 311.641C21.0119 311.641 20.6377 312.015 20.6377 312.469C20.6377 312.922 21.0119 313.297 21.4655 313.297Z"
          fill="#EF4E05"
        />
        <path
          d="M27.9834 300.039C27.9834 300.461 28.3263 300.804 28.7482 300.804C29.1701 300.804 29.5125 300.461 29.5125 300.039C29.5125 299.617 29.1701 299.274 28.7482 299.274C28.3263 299.274 27.9834 299.617 27.9834 300.039Z"
          fill="#EF4E05"
        />
        <path
          d="M34.5645 286.793C34.9579 286.793 35.2765 286.474 35.2765 286.081C35.2765 285.688 34.9579 285.369 34.5645 285.369C34.1712 285.369 33.8525 285.688 33.8525 286.081C33.8525 286.474 34.1712 286.793 34.5645 286.793Z"
          fill="#EF4E05"
        />
        <path
          d="M40.2385 271.848C40.2385 272.222 40.5394 272.523 40.9136 272.523C41.2878 272.523 41.5887 272.222 41.5887 271.848C41.5887 271.474 41.2878 271.173 40.9136 271.173C40.5394 271.168 40.2385 271.474 40.2385 271.848Z"
          fill="#EF4E05"
        />
        <path
          d="M58.3369 272.95C58.6531 272.95 58.9115 272.691 58.9115 272.375C58.9115 272.059 58.6531 271.801 58.3369 271.801C58.0206 271.801 57.7622 272.059 57.7622 272.375C57.7622 272.691 58.0206 272.95 58.3369 272.95Z"
          fill="#EF4E05"
        />
        <path
          d="M52.1091 286.956C52.1091 286.613 51.8297 286.339 51.4924 286.339C51.1546 286.339 50.8752 286.619 50.8752 286.956C50.8752 287.299 51.1546 287.573 51.4924 287.573C51.8297 287.573 52.1091 287.299 52.1091 286.956Z"
          fill="#EF4E05"
        />
        <path
          d="M44.5099 301.769C44.8789 301.769 45.1742 301.468 45.1742 301.104C45.1742 300.735 44.8738 300.44 44.5099 300.44C44.1459 300.44 43.8455 300.74 43.8455 301.104C43.8404 301.473 44.1408 301.769 44.5099 301.769Z"
          fill="#EF4E05"
        />
        <path
          d="M35.7246 314.219C35.7246 314.625 36.0516 314.952 36.4576 314.952C36.8636 314.952 37.1906 314.625 37.1906 314.219C37.1906 313.813 36.8636 313.486 36.4576 313.486C36.0516 313.481 35.7246 313.813 35.7246 314.219Z"
          fill="#EF4E05"
        />
        <path
          d="M41.114 328.574C41.114 328.974 41.441 329.302 41.8414 329.302C42.2423 329.302 42.5693 328.974 42.5693 328.574C42.5693 328.173 42.2423 327.846 41.8414 327.846C41.441 327.846 41.114 328.173 41.114 328.574Z"
          fill="#EF4E05"
        />
        <path
          d="M45.8177 344.088C46.2284 344.088 46.5615 343.755 46.5615 343.344C46.5615 342.934 46.2284 342.6 45.8177 342.6C45.4071 342.6 45.0745 342.934 45.0745 343.344C45.0745 343.755 45.4071 344.088 45.8177 344.088Z"
          fill="#EF4E05"
        />
        <path
          d="M51.6925 356.1C51.2758 356.1 50.9436 356.438 50.9436 356.849C50.9436 357.266 51.2809 357.598 51.6925 357.598C52.1036 357.598 52.4409 357.261 52.4409 356.849C52.4465 356.438 52.1088 356.1 51.6925 356.1Z"
          fill="#EF4E05"
        />
        <path
          d="M63.3203 348.992C63.6843 348.992 63.9795 348.697 63.9795 348.333C63.9795 347.969 63.6843 347.674 63.3203 347.674C62.9559 347.674 62.6611 347.969 62.6611 348.333C62.6611 348.697 62.9559 348.992 63.3203 348.992Z"
          fill="#EF4E05"
        />
        <path
          d="M58.648 333.219C59.0063 333.219 59.2913 332.929 59.2913 332.576C59.2913 332.223 59.0012 331.933 58.648 331.933C58.2892 331.933 58.0046 332.223 58.0046 332.576C58.0046 332.929 58.2943 333.219 58.648 333.219Z"
          fill="#EF4E05"
        />
        <path
          d="M52.9421 317.099C53.3005 317.099 53.5855 316.808 53.5855 316.455C53.5855 316.102 53.2953 315.812 52.9421 315.812C52.5833 315.812 52.2988 316.102 52.2988 316.455C52.2988 316.808 52.5889 317.099 52.9421 317.099Z"
          fill="#EF4E05"
        />
        <path
          d="M62.3024 301.273C61.9861 301.273 61.7329 301.526 61.7329 301.843C61.7329 302.159 61.9861 302.412 62.3024 302.412C62.6187 302.412 62.8719 302.159 62.8719 301.843C62.8719 301.531 62.6187 301.273 62.3024 301.273Z"
          fill="#EF4E05"
        />
        <path
          d="M70.5134 284.973C70.5134 285.258 70.7451 285.49 71.0301 285.49C71.3146 285.49 71.5468 285.258 71.5468 284.973C71.5468 284.688 71.3146 284.457 71.0301 284.457C70.7451 284.457 70.5134 284.688 70.5134 284.973Z"
          fill="#EF4E05"
        />
        <path
          d="M77.4635 263.832C77.4635 263.564 77.2421 263.342 76.973 263.342C76.7039 263.342 76.4824 263.564 76.4824 263.832C76.4824 264.101 76.7039 264.323 76.973 264.323C77.2421 264.323 77.4635 264.101 77.4635 263.832Z"
          fill="#EF4E05"
        />
        <path
          d="M91.2849 280.628C91.2849 280.386 91.0901 280.19 90.8472 280.19C90.6047 280.19 90.4094 280.386 90.4094 280.628C90.4094 280.871 90.6047 281.066 90.8472 281.066C91.0901 281.066 91.2849 280.871 91.2849 280.628Z"
          fill="#EF4E05"
        />
        <path
          d="M81.5079 302.961C81.777 302.961 81.9984 302.739 81.9984 302.47C81.9984 302.201 81.777 301.98 81.5079 301.98C81.2388 301.98 81.0173 302.201 81.0173 302.47C81.0173 302.739 81.2336 302.961 81.5079 302.961Z"
          fill="#EF4E05"
        />
        <path
          d="M101.974 302.66C101.974 302.892 102.159 303.077 102.391 303.077C102.623 303.077 102.807 302.892 102.807 302.66C102.807 302.428 102.623 302.243 102.391 302.243C102.159 302.243 101.974 302.428 101.974 302.66Z"
          fill="#EF4E05"
        />
        <path
          d="M124.46 309.779C124.46 309.574 124.296 309.41 124.091 309.41C123.885 309.41 123.721 309.574 123.721 309.779C123.721 309.985 123.885 310.149 124.091 310.149C124.296 310.149 124.46 309.985 124.46 309.779Z"
          fill="#EF4E05"
        />
        <path
          d="M114.303 275.524C114.303 275.318 114.134 275.149 113.928 275.149C113.723 275.149 113.554 275.318 113.554 275.524C113.554 275.729 113.723 275.898 113.928 275.898C114.134 275.898 114.303 275.729 114.303 275.524Z"
          fill="#EF4E05"
        />
        <path
          d="M96.7849 254.715C96.7849 254.947 96.9746 255.136 97.2068 255.136C97.439 255.136 97.6287 254.947 97.6287 254.715C97.6287 254.483 97.439 254.293 97.2068 254.293C96.9746 254.293 96.7849 254.483 96.7849 254.715Z"
          fill="#EF4E05"
        />
        <path
          d="M83.7808 237.075C83.5173 237.075 83.301 237.291 83.301 237.555C83.301 237.819 83.5173 238.035 83.7808 238.035C84.0443 238.035 84.2606 237.819 84.2606 237.555C84.2606 237.291 84.0443 237.075 83.7808 237.075Z"
          fill="#EF4E05"
        />
        <path
          d="M68.3142 245.428C68.3142 245.724 68.5515 245.961 68.8468 245.961C69.1421 245.961 69.3794 245.724 69.3794 245.428C69.3794 245.133 69.1421 244.896 68.8468 244.896C68.5515 244.896 68.3142 245.133 68.3142 245.428Z"
          fill="#EF4E05"
        />
        <path
          d="M58.3108 257.178C58.6322 257.178 58.8906 256.919 58.8906 256.597C58.8906 256.275 58.6322 256.017 58.3108 256.017C57.9889 256.017 57.7305 256.275 57.7305 256.597C57.7254 256.919 57.9889 257.178 58.3108 257.178Z"
          fill="#EF4E05"
        />
        <path
          d="M44.605 255.369C44.605 255.005 44.3098 254.709 43.9458 254.709C43.5824 254.709 43.2866 255.005 43.2866 255.369C43.2866 255.733 43.5824 256.028 43.9458 256.028C44.3098 256.028 44.605 255.738 44.605 255.369Z"
          fill="#EF4E05"
        />
        <path
          d="M30.9629 260.03C30.9629 259.619 30.6307 259.292 30.2242 259.292C29.8131 259.292 29.4861 259.624 29.4861 260.03C29.4861 260.442 29.8183 260.768 30.2242 260.768C30.6307 260.768 30.9629 260.442 30.9629 260.03Z"
          fill="#EF4E05"
        />
        <path
          d="M22.1719 274.464C22.1719 274.901 22.5255 275.254 22.9628 275.254C23.4006 275.254 23.7542 274.901 23.7542 274.464C23.7542 274.026 23.4006 273.673 22.9628 273.673C22.5307 273.667 22.1719 274.021 22.1719 274.464Z"
          fill="#EF4E05"
        />
        <path
          d="M18.8183 289.123C19.2752 289.123 19.6462 288.753 19.6462 288.295C19.6462 287.838 19.2752 287.468 18.8183 287.468C18.3609 287.468 17.99 287.838 17.99 288.295C17.99 288.753 18.3609 289.123 18.8183 289.123Z"
          fill="#EF4E05"
        />
        <path
          d="M7.53304 280.828C8.03433 280.828 8.44546 280.422 8.44546 279.916C8.44546 279.415 8.03947 279.004 7.53304 279.004C7.02708 279.004 6.62109 279.41 6.62109 279.916C6.62109 280.417 7.03222 280.828 7.53304 280.828Z"
          fill="#EF4E05"
        />
        <path
          d="M5.99885 268.499C6.50481 268.499 6.92154 268.088 6.92154 267.577C6.92154 267.07 6.51042 266.654 5.99885 266.654C5.49241 266.654 5.07568 267.065 5.07568 267.577C5.08129 268.088 5.49241 268.499 5.99885 268.499Z"
          fill="#EF4E05"
        />
        <path
          d="M15.9863 261.939C15.9863 262.403 16.361 262.773 16.8198 262.773C17.2786 262.773 17.6528 262.398 17.6528 261.939C17.6528 261.48 17.2786 261.106 16.8198 261.106C16.361 261.106 15.9863 261.475 15.9863 261.939Z"
          fill="#EF4E05"
        />
        <path
          d="M19.6462 249.188C20.1003 249.188 20.4689 248.82 20.4689 248.365C20.4689 247.911 20.1003 247.543 19.6462 247.543C19.1916 247.543 18.8235 247.911 18.8235 248.365C18.8235 248.82 19.1916 249.188 19.6462 249.188Z"
          fill="#EF4E05"
        />
        <path
          d="M19.9572 233.679C19.4929 233.679 19.1187 234.054 19.1187 234.518C19.1187 234.982 19.4929 235.356 19.9572 235.356C20.4212 235.356 20.7954 234.982 20.7954 234.518C20.801 234.054 20.4263 233.679 19.9572 233.679Z"
          fill="#EF4E05"
        />
        <path
          d="M20.2579 220.86C20.2579 220.38 19.8678 219.989 19.388 219.989C18.9081 219.989 18.5176 220.38 18.5176 220.86C18.5176 221.34 18.9081 221.73 19.388 221.73C19.8678 221.725 20.2579 221.335 20.2579 220.86Z"
          fill="#EF4E05"
        />
        <path
          d="M6.4098 226.434C5.88234 226.434 5.4502 226.861 5.4502 227.393C5.4502 227.921 5.87721 228.353 6.4098 228.353C6.93725 228.353 7.3694 227.926 7.3694 227.393C7.36426 226.861 6.93725 226.434 6.4098 226.434Z"
          fill="#EF4E05"
        />
        <path
          d="M6.55748 241.5C7.07606 241.5 7.49606 241.079 7.49606 240.561C7.49606 240.043 7.07606 239.623 6.55748 239.623C6.03937 239.623 5.6189 240.043 5.6189 240.561C5.6189 241.079 6.03937 241.5 6.55748 241.5Z"
          fill="#EF4E05"
        />
        <path
          d="M6.84244 253.301C6.84244 252.79 6.42571 252.374 5.91414 252.374C5.40257 252.374 4.98584 252.79 4.98584 253.301C4.98584 253.813 5.40257 254.23 5.91414 254.23C6.42571 254.23 6.84244 253.813 6.84244 253.301Z"
          fill="#EF4E05"
        />
        <path
          d="M5.0441 213.72C4.49562 213.72 4.04712 214.163 4.04712 214.716C4.04712 215.27 4.49048 215.713 5.0441 215.713C5.59258 215.713 6.04061 215.27 6.04061 214.716C6.04061 214.163 5.59258 213.72 5.0441 213.72Z"
          fill="#EF4E05"
        />
        <path
          d="M3.54659 201.137C2.97195 201.137 2.50757 201.601 2.50757 202.176C2.50757 202.751 2.97195 203.215 3.54659 203.215C4.12123 203.215 4.58562 202.751 4.58562 202.176C4.58001 201.601 4.11609 201.137 3.54659 201.137Z"
          fill="#EF4E05"
        />
        <path
          d="M2.98226 189.916C2.98226 189.309 2.49172 188.819 1.88531 188.819C1.2789 188.819 0.788818 189.309 0.788818 189.916C0.788818 190.522 1.2789 191.013 1.88531 191.013C2.49172 191.013 2.98226 190.522 2.98226 189.916Z"
          fill="#EF4E05"
        />
        <path
          d="M1.48433 178.42C1.48433 177.787 0.973229 177.275 0.340189 177.275C-0.292382 177.275 -0.803955 177.787 -0.803955 178.42C-0.803955 179.053 -0.292382 179.564 0.340189 179.564C0.973229 179.564 1.48433 179.053 1.48433 178.42Z"
          fill="#EF4E05"
        />
        <path
          d="M3.83665 346.535C4.41643 346.535 4.89109 346.065 4.89109 345.48C4.89109 344.895 4.42203 344.425 3.83665 344.425C3.25126 344.425 2.78174 344.895 2.78174 345.48C2.78174 346.065 3.2564 346.535 3.83665 346.535Z"
          fill="#EF4E05"
        />
        <path
          d="M1.28436 332.94C1.85386 332.94 2.31264 332.481 2.31264 331.912C2.31264 331.342 1.85386 330.883 1.28436 330.883C0.714391 330.883 0.255615 331.342 0.255615 331.912C0.255615 332.481 0.719998 332.94 1.28436 332.94Z"
          fill="#EF4E05"
        />
        <path
          d="M14.5045 336.552C15.0268 336.552 15.4487 336.13 15.4487 335.608C15.4487 335.086 15.0268 334.664 14.5045 334.664C13.9827 334.664 13.5608 335.086 13.5608 335.608C13.5659 336.13 13.9878 336.552 14.5045 336.552Z"
          fill="#EF4E05"
        />
        <path
          d="M17.2729 346.972C17.2729 347.494 17.6948 347.916 18.2171 347.916C18.739 347.916 19.1609 347.494 19.1609 346.972C19.1609 346.45 18.739 346.028 18.2171 346.028C17.6948 346.034 17.2729 346.456 17.2729 346.972Z"
          fill="#EF4E05"
        />
        <path
          d="M9.21021 356.749C9.21021 357.324 9.67412 357.788 10.2488 357.788C10.8239 357.788 11.2878 357.324 11.2878 356.749C11.2878 356.174 10.8239 355.71 10.2488 355.71C9.67412 355.71 9.21021 356.18 9.21021 356.749Z"
          fill="#EF4E05"
        />
        <path
          d="M4.03133 367.771C4.65362 367.771 5.16006 367.264 5.16006 366.642C5.16006 366.02 4.65362 365.514 4.03133 365.514C3.40904 365.514 2.90308 366.02 2.90308 366.642C2.90308 367.264 3.40904 367.771 4.03133 367.771Z"
          fill="#EF4E05"
        />
        <path
          d="M15.1106 369.579C15.1106 370.154 15.575 370.623 16.1548 370.623C16.7299 370.623 17.1989 370.154 17.1989 369.579C17.1989 369.004 16.735 368.535 16.1548 368.535C15.575 368.535 15.1106 368.999 15.1106 369.579Z"
          fill="#EF4E05"
        />
        <path
          d="M24.2922 359.28C24.2922 358.753 23.8652 358.326 23.3378 358.326C22.8103 358.326 22.3833 358.753 22.3833 359.28C22.3833 359.807 22.8103 360.234 23.3378 360.234C23.8652 360.234 24.2922 359.807 24.2922 359.28Z"
          fill="#EF4E05"
        />
        <path
          d="M30.5252 371.288C31.0466 371.288 31.469 370.865 31.469 370.344C31.469 369.823 31.0466 369.4 30.5252 369.4C30.0039 369.4 29.5811 369.823 29.5811 370.344C29.5811 370.865 30.0039 371.288 30.5252 371.288Z"
          fill="#EF4E05"
        />
        <path
          d="M38.2557 382.035C38.7724 382.035 39.1943 381.613 39.1943 381.096C39.1943 380.58 38.778 380.158 38.2557 380.158C37.7339 380.158 37.3171 380.58 37.3171 381.096C37.3171 381.618 37.739 382.035 38.2557 382.035Z"
          fill="#EF4E05"
        />
        <path
          d="M42.5223 391.707C41.9949 391.707 41.5623 392.134 41.5623 392.666C41.5623 393.199 41.9897 393.626 42.5223 393.626C43.0493 393.626 43.4819 393.199 43.4819 392.666C43.4819 392.134 43.0549 391.707 42.5223 391.707Z"
          fill="#EF4E05"
        />
        <path
          d="M35.9829 403.308C35.9829 402.717 35.5031 402.237 34.9126 402.237C34.3221 402.237 33.8423 402.717 33.8423 403.308C33.8423 403.898 34.3221 404.378 34.9126 404.378C35.5031 404.378 35.9829 403.898 35.9829 403.308Z"
          fill="#EF4E05"
        />
        <path
          d="M29.0012 393.141C29.581 393.141 30.0556 392.671 30.0556 392.086C30.0556 391.501 29.5866 391.031 29.0012 391.031C28.4209 391.031 27.9463 391.501 27.9463 392.086C27.9463 392.671 28.4209 393.141 29.0012 393.141Z"
          fill="#EF4E05"
        />
        <path
          d="M24.1335 380.078C23.5589 380.078 23.095 380.543 23.095 381.117C23.095 381.692 23.5589 382.156 24.1335 382.156C24.7086 382.156 25.1726 381.692 25.1726 381.117C25.1726 380.543 24.7086 380.078 24.1335 380.078Z"
          fill="#EF4E05"
        />
        <path
          d="M13.4657 380.838C13.4657 380.221 12.9644 379.715 12.3426 379.715C11.7254 379.715 11.219 380.216 11.219 380.838C11.219 381.46 11.7203 381.961 12.3426 381.961C12.9644 381.961 13.4657 381.455 13.4657 380.838Z"
          fill="#EF4E05"
        />
        <path
          d="M15.7489 393.626C16.3819 393.626 16.8986 393.114 16.8986 392.476C16.8986 391.838 16.3871 391.327 15.7489 391.327C15.1163 391.327 14.5996 391.838 14.5996 392.476C14.5996 393.114 15.1163 393.626 15.7489 393.626Z"
          fill="#EF4E05"
        />
        <path
          d="M22.1986 403.993C22.8419 403.993 23.3694 403.471 23.3694 402.822C23.3694 402.179 22.8471 401.652 22.1986 401.652C21.5553 401.652 21.0278 402.174 21.0278 402.822C21.0278 403.471 21.5497 403.993 22.1986 403.993Z"
          fill="#EF4E05"
        />
        <path
          d="M17.8323 414.994C18.5284 414.994 19.0979 414.429 19.0979 413.728C19.0979 413.032 18.5335 412.462 17.8323 412.462C17.1362 412.462 16.5667 413.027 16.5667 413.728C16.5667 414.429 17.1362 414.994 17.8323 414.994Z"
          fill="#EF4E05"
        />
        <path
          d="M29.7553 414.772C30.3986 414.772 30.9256 414.25 30.9256 413.601C30.9256 412.953 30.4037 412.43 29.7553 412.43C29.1119 412.43 28.5845 412.953 28.5845 413.601C28.5845 414.25 29.1119 414.772 29.7553 414.772Z"
          fill="#EF4E05"
        />
        <path
          d="M41.7464 417.071C42.3533 417.071 42.8433 416.581 42.8433 415.975C42.8433 415.368 42.3533 414.878 41.7464 414.878C41.14 414.878 40.6499 415.368 40.6499 415.975C40.6499 416.581 41.14 417.071 41.7464 417.071Z"
          fill="#EF4E05"
        />
        <path
          d="M48.4864 405.085C48.4864 404.531 48.0384 404.083 47.4843 404.083C46.9307 404.083 46.4827 404.531 46.4827 405.085C46.4827 405.639 46.9307 406.087 47.4843 406.087C48.0384 406.087 48.4864 405.639 48.4864 405.085Z"
          fill="#EF4E05"
        />
        <path
          d="M53.9335 418.062C54.503 418.062 54.9618 417.604 54.9618 417.034C54.9618 416.465 54.503 416.006 53.9335 416.006C53.364 416.006 52.9053 416.465 52.9053 417.034C52.9053 417.604 53.364 418.062 53.9335 418.062Z"
          fill="#EF4E05"
        />
        <path
          d="M60.8419 407.374C61.353 407.374 61.7697 406.957 61.7697 406.445C61.7697 405.934 61.353 405.517 60.8419 405.517C60.3303 405.517 59.9136 405.934 59.9136 406.445C59.9136 406.962 60.3303 407.374 60.8419 407.374Z"
          fill="#EF4E05"
        />
        <path
          d="M67.6969 419.191C68.2243 419.191 68.6514 418.764 68.6514 418.237C68.6514 417.709 68.2243 417.282 67.6969 417.282C67.1694 417.282 66.7424 417.709 66.7424 418.237C66.7476 418.764 67.175 419.191 67.6969 419.191Z"
          fill="#EF4E05"
        />
        <path
          d="M73.3185 429.78C72.7649 429.78 72.3113 430.228 72.3113 430.787C72.3113 431.341 72.7593 431.794 73.3185 431.794C73.8722 431.794 74.3258 431.346 74.3258 430.787C74.3202 430.228 73.8722 429.78 73.3185 429.78Z"
          fill="#EF4E05"
        />
        <path
          d="M85.7477 432.032C86.27 432.032 86.697 431.61 86.697 431.083C86.697 430.556 86.2751 430.134 85.7477 430.134C85.2258 430.134 84.7983 430.556 84.7983 431.083C84.7983 431.61 85.2202 432.032 85.7477 432.032Z"
          fill="#EF4E05"
        />
        <path
          d="M82.0616 418.463C82.0616 417.973 81.6664 417.572 81.1707 417.572C80.6802 417.572 80.2793 417.968 80.2793 418.463C80.2793 418.954 80.675 419.355 81.1707 419.355C81.6664 419.355 82.0616 418.954 82.0616 418.463Z"
          fill="#EF4E05"
        />
        <path
          d="M93.4941 420.399C93.4941 420.863 93.8688 421.232 94.3276 421.232C94.7864 421.232 95.1606 420.858 95.1606 420.399C95.1606 419.94 94.7864 419.565 94.3276 419.565C93.8688 419.565 93.4941 419.935 93.4941 420.399Z"
          fill="#EF4E05"
        />
        <path
          d="M100.724 409.356C101.141 409.356 101.473 409.019 101.473 408.607C101.473 408.191 101.136 407.859 100.724 407.859C100.313 407.859 99.9756 408.196 99.9756 408.607C99.9756 409.019 100.313 409.356 100.724 409.356Z"
          fill="#EF4E05"
        />
        <path
          d="M108.06 420.314C108.06 420.741 108.408 421.089 108.835 421.089C109.262 421.089 109.61 420.741 109.61 420.314C109.61 419.887 109.262 419.539 108.835 419.539C108.408 419.534 108.06 419.882 108.06 420.314Z"
          fill="#EF4E05"
        />
        <path
          d="M123.453 419.708C123.047 419.708 122.72 420.034 122.72 420.441C122.72 420.847 123.047 421.174 123.453 421.174C123.859 421.174 124.186 420.847 124.186 420.441C124.191 420.034 123.859 419.708 123.453 419.708Z"
          fill="#EF4E05"
        />
        <path
          d="M134.959 429.706C134.959 429.284 134.616 428.941 134.194 428.941C133.772 428.941 133.43 429.284 133.43 429.706C133.43 430.128 133.772 430.471 134.194 430.471C134.616 430.471 134.959 430.128 134.959 429.706Z"
          fill="#EF4E05"
        />
        <path
          d="M142.094 441.682C142.548 441.682 142.912 441.312 142.912 440.864C142.912 440.415 142.542 440.046 142.094 440.046C141.646 440.046 141.276 440.415 141.276 440.864C141.276 441.312 141.64 441.682 142.094 441.682Z"
          fill="#EF4E05"
        />
        <path
          d="M130.566 447.092C131.058 447.092 131.457 446.695 131.457 446.199C131.457 445.709 131.058 445.312 130.566 445.312C130.074 445.312 129.675 445.709 129.675 446.199C129.675 446.695 130.074 447.092 130.566 447.092Z"
          fill="#EF4E05"
        />
        <path
          d="M121.533 438.012C122.007 438.012 122.387 437.627 122.387 437.157C122.387 436.688 122.002 436.303 121.533 436.303C121.063 436.303 120.678 436.688 120.678 437.157C120.678 437.627 121.058 438.012 121.533 438.012Z"
          fill="#EF4E05"
        />
        <path
          d="M110.591 431.789C110.116 431.789 109.726 432.174 109.726 432.654C109.726 433.129 110.111 433.519 110.591 433.519C111.065 433.519 111.456 433.134 111.456 432.654C111.45 432.174 111.065 431.789 110.591 431.789Z"
          fill="#EF4E05"
        />
        <path
          d="M97.302 433.419C97.302 433.92 97.708 434.331 98.2144 434.331C98.7152 434.331 99.1264 433.925 99.1264 433.419C99.1264 432.912 98.7204 432.507 98.2144 432.507C97.708 432.512 97.302 432.918 97.302 433.419Z"
          fill="#EF4E05"
        />
        <path
          d="M96.1837 444.952C96.1837 444.391 95.7301 443.938 95.1713 443.938C94.6121 443.938 94.1589 444.391 94.1589 444.952C94.1589 445.513 94.6121 445.966 95.1713 445.966C95.7357 445.966 96.1837 445.513 96.1837 444.952Z"
          fill="#EF4E05"
        />
        <path
          d="M84.155 442.607C84.155 442.032 83.686 441.56 83.1113 441.56C82.5362 441.56 82.0671 442.032 82.0671 442.607C82.0671 443.181 82.5362 443.648 83.1113 443.648C83.686 443.644 84.155 443.181 84.155 442.607Z"
          fill="#EF4E05"
        />
        <path
          d="M71.668 445.419C72.2847 445.419 72.786 444.919 72.786 444.302C72.786 443.686 72.2847 443.186 71.668 443.186C71.0509 443.186 70.55 443.686 70.55 444.302C70.55 444.919 71.0509 445.419 71.668 445.419Z"
          fill="#EF4E05"
        />
        <path
          d="M64.4854 437.674C64.4854 437.063 63.9898 436.567 63.3782 436.567C62.7662 436.567 62.2705 437.063 62.2705 437.674C62.2705 438.286 62.7662 438.782 63.3782 438.782C63.9949 438.782 64.4854 438.286 64.4854 437.674Z"
          fill="#EF4E05"
        />
        <path
          d="M60.5569 427.665C60.5569 427.08 60.0822 426.6 59.4917 426.6C58.9063 426.6 58.4265 427.075 58.4265 427.665C58.4265 428.251 58.9012 428.731 59.4917 428.731C60.0822 428.731 60.5569 428.256 60.5569 427.665Z"
          fill="#EF4E05"
        />
        <path
          d="M46.7459 429.068C47.3785 429.068 47.8901 428.556 47.8901 427.924C47.8901 427.291 47.3785 426.779 46.7459 426.779C46.1129 426.779 45.6018 427.291 45.6018 427.924C45.6018 428.556 46.1129 429.068 46.7459 429.068Z"
          fill="#EF4E05"
        />
        <path
          d="M34.2216 425.978C34.8916 425.978 35.4344 425.435 35.4344 424.765C35.4344 424.095 34.8916 423.552 34.2216 423.552C33.5521 423.552 33.0088 424.095 33.0088 424.765C33.0088 425.435 33.5521 425.978 34.2216 425.978Z"
          fill="#EF4E05"
        />
        <path
          d="M37.3803 433.498C36.6898 433.498 36.1255 434.057 36.1255 434.753C36.1255 435.444 36.6842 436.008 37.3803 436.008C38.0713 436.008 38.6357 435.449 38.6357 434.753C38.6357 434.062 38.0713 433.498 37.3803 433.498Z"
          fill="#EF4E05"
        />
        <path
          d="M42.0894 444.317C42.8014 444.317 43.3816 443.737 43.3816 443.027C43.3816 442.317 42.8014 441.733 42.0894 441.733C41.3774 441.733 40.7976 442.317 40.7976 443.027C40.8027 443.737 41.3774 444.317 42.0894 444.317Z"
          fill="#EF4E05"
        />
        <path
          d="M52.0089 439.729C52.6672 439.729 53.2007 439.197 53.2007 438.539C53.2007 437.881 52.6672 437.347 52.0089 437.347C51.3507 437.347 50.8171 437.881 50.8171 438.539C50.8171 439.197 51.3507 439.729 52.0089 439.729Z"
          fill="#EF4E05"
        />
        <path
          d="M61.1686 448.138C61.1686 447.465 60.6257 446.923 59.9557 446.923C59.2863 446.923 58.7429 447.465 58.7429 448.138C58.7429 448.806 59.2863 449.348 59.9557 449.348C60.6257 449.348 61.1686 448.802 61.1686 448.138Z"
          fill="#EF4E05"
        />
        <path
          d="M67.4545 456.533C67.4545 455.847 66.9009 455.291 66.2155 455.291C65.5297 455.291 64.9761 455.847 64.9761 456.533C64.9761 457.216 65.5297 457.772 66.2155 457.772C66.9009 457.772 67.4545 457.216 67.4545 456.533Z"
          fill="#EF4E05"
        />
        <path
          d="M71.6943 466.625C72.4063 466.625 72.981 466.05 72.981 465.34C72.981 464.625 72.4063 464.05 71.6943 464.05C70.9824 464.05 70.4077 464.625 70.4077 465.34C70.4077 466.05 70.9875 466.625 71.6943 466.625Z"
          fill="#EF4E05"
        />
        <path
          d="M68.1347 477.08C68.9046 477.08 69.5269 476.459 69.5269 475.688C69.5269 474.922 68.9046 474.296 68.1347 474.296C67.3647 474.296 66.7424 474.922 66.7424 475.688C66.7424 476.459 67.3647 477.08 68.1347 477.08Z"
          fill="#EF4E05"
        />
        <path
          d="M77.1205 482.631C77.1205 481.846 76.4823 481.206 75.6965 481.206C74.9107 481.206 74.2729 481.846 74.2729 482.631C74.2729 483.415 74.9107 484.055 75.6965 484.055C76.4823 484.055 77.1205 483.415 77.1205 482.631Z"
          fill="#EF4E05"
        />
        <path
          d="M72.0371 492.969C72.8818 492.969 73.5662 492.287 73.5662 491.442C73.5662 490.596 72.8818 489.914 72.0371 489.914C71.1925 489.914 70.5076 490.596 70.5076 491.442C70.5076 492.287 71.1925 492.969 72.0371 492.969Z"
          fill="#EF4E05"
        />
        <path
          d="M83.4907 490.97C83.4907 490.166 82.8366 489.512 82.0298 489.512C81.2229 489.512 80.5693 490.166 80.5693 490.97C80.5693 491.778 81.2229 492.432 82.0298 492.432C82.8366 492.432 83.4907 491.778 83.4907 490.97Z"
          fill="#EF4E05"
        />
        <path
          d="M92.4396 486.732C91.6696 486.732 91.0474 487.354 91.0474 488.125C91.0474 488.895 91.6696 489.517 92.4396 489.517C93.2095 489.517 93.8318 488.895 93.8318 488.125C93.8318 487.354 93.2095 486.732 92.4396 486.732Z"
          fill="#EF4E05"
        />
        <path
          d="M99.7328 496.277C100.519 496.277 101.156 495.642 101.156 494.852C101.156 494.067 100.519 493.427 99.7328 493.427C98.9466 493.427 98.3088 494.067 98.3088 494.852C98.3088 495.642 98.9466 496.277 99.7328 496.277Z"
          fill="#EF4E05"
        />
        <path
          d="M90.1197 498.113C90.1197 497.272 89.4394 496.599 88.606 496.599C87.7674 496.599 87.0928 497.277 87.0928 498.113C87.0928 498.945 87.773 499.627 88.606 499.627C89.4394 499.627 90.1197 498.949 90.1197 498.113Z"
          fill="#EF4E05"
        />
        <path
          d="M98.314 503.257C98.314 502.421 97.6338 501.739 96.7957 501.739C95.9571 501.739 95.2769 502.421 95.2769 503.257C95.2769 504.098 95.9571 504.78 96.7957 504.78C97.6338 504.78 98.314 504.098 98.314 503.257Z"
          fill="#EF4E05"
        />
        <path
          d="M107.532 505.63C107.532 504.794 106.852 504.117 106.019 504.117C105.18 504.117 104.505 504.799 104.505 505.63C104.505 506.471 105.186 507.144 106.019 507.144C106.857 507.144 107.532 506.467 107.532 505.63Z"
          fill="#EF4E05"
        />
        <path
          d="M109.794 495.002C109.024 495.002 108.402 495.623 108.402 496.394C108.402 497.165 109.024 497.786 109.794 497.786C110.564 497.786 111.187 497.165 111.187 496.394C111.187 495.623 110.564 495.002 109.794 495.002Z"
          fill="#EF4E05"
        />
        <path
          d="M120.33 496.922C121.073 496.922 121.675 496.319 121.675 495.576C121.675 494.834 121.073 494.231 120.33 494.231C119.588 494.231 118.986 494.834 118.986 495.576C118.986 496.319 119.588 496.922 120.33 496.922Z"
          fill="#EF4E05"
        />
        <path
          d="M126.416 501.776C125.641 501.776 125.014 502.407 125.014 503.182C125.014 503.953 125.641 504.584 126.416 504.584C127.192 504.584 127.819 503.953 127.819 503.182C127.819 502.407 127.192 501.776 126.416 501.776Z"
          fill="#EF4E05"
        />
        <path
          d="M131.188 493.24C131.188 493.95 131.763 494.525 132.475 494.525C133.187 494.525 133.762 493.95 133.762 493.24C133.762 492.526 133.187 491.951 132.475 491.951C131.763 491.951 131.188 492.535 131.188 493.24Z"
          fill="#EF4E05"
        />
        <path
          d="M136.647 501.463C135.887 501.463 135.275 502.075 135.275 502.832C135.275 503.593 135.887 504.205 136.647 504.205C137.406 504.205 138.018 503.593 138.018 502.832C138.018 502.075 137.406 501.463 136.647 501.463Z"
          fill="#EF4E05"
        />
        <path
          d="M145.674 504.663C146.42 504.663 147.025 504.056 147.025 503.313C147.025 502.565 146.42 501.963 145.674 501.963C144.929 501.963 144.324 502.565 144.324 503.313C144.324 504.056 144.929 504.663 145.674 504.663Z"
          fill="#EF4E05"
        />
        <path
          d="M144.214 492.656C144.899 492.656 145.458 492.096 145.458 491.409C145.458 490.722 144.899 490.166 144.214 490.166C143.528 490.166 142.969 490.722 142.969 491.409C142.969 492.096 143.523 492.656 144.214 492.656Z"
          fill="#EF4E05"
        />
        <path
          d="M136.552 483.107C137.195 483.107 137.717 482.589 137.717 481.944C137.717 481.299 137.195 480.781 136.552 480.781C135.908 480.781 135.386 481.299 135.386 481.944C135.386 482.589 135.908 483.107 136.552 483.107Z"
          fill="#EF4E05"
        />
        <path
          d="M147.298 477.183C146.687 477.183 146.191 477.678 146.191 478.29C146.191 478.902 146.687 479.398 147.298 479.398C147.91 479.398 148.406 478.902 148.406 478.29C148.406 477.678 147.91 477.183 147.298 477.183Z"
          fill="#EF4E05"
        />
        <path
          d="M155.773 484.99C155.129 484.99 154.607 485.513 154.607 486.158C154.607 486.803 155.129 487.321 155.773 487.321C156.416 487.321 156.938 486.803 156.938 486.158C156.938 485.513 156.411 484.99 155.773 484.99Z"
          fill="#EF4E05"
        />
        <path
          d="M164.885 492.717C164.205 492.717 163.656 493.264 163.656 493.946C163.656 494.628 164.205 495.175 164.885 495.175C165.565 495.175 166.114 494.628 166.114 493.946C166.114 493.264 165.565 492.717 164.885 492.717Z"
          fill="#EF4E05"
        />
        <path
          d="M154.55 496.609C153.838 496.609 153.263 497.183 153.263 497.898C153.263 498.608 153.838 499.183 154.55 499.183C155.262 499.183 155.836 498.608 155.836 497.898C155.836 497.183 155.262 496.609 154.55 496.609Z"
          fill="#EF4E05"
        />
        <path
          d="M162.918 505.028C162.175 505.028 161.568 505.63 161.568 506.378C161.568 507.121 162.175 507.723 162.918 507.723C163.662 507.723 164.268 507.121 164.268 506.378C164.268 505.63 163.662 505.028 162.918 505.028Z"
          fill="#EF4E05"
        />
        <path
          d="M153.405 508.41C152.63 508.41 152.002 509.041 152.002 509.812C152.002 510.587 152.63 511.218 153.405 511.218C154.18 511.218 154.808 510.587 154.808 509.812C154.808 509.041 154.18 508.41 153.405 508.41Z"
          fill="#EF4E05"
        />
        <path
          d="M152.192 519.006C152.192 518.165 151.512 517.487 150.674 517.487C149.835 517.487 149.155 518.165 149.155 519.006C149.155 519.842 149.835 520.524 150.674 520.524C151.512 520.524 152.192 519.842 152.192 519.006Z"
          fill="#EF4E05"
        />
        <path
          d="M161.12 520.249C161.941 520.249 162.607 519.585 162.607 518.763C162.607 517.941 161.941 517.277 161.12 517.277C160.299 517.277 159.633 517.941 159.633 518.763C159.633 519.585 160.299 520.249 161.12 520.249Z"
          fill="#EF4E05"
        />
        <path
          d="M170.443 513.311C169.647 513.311 169.004 513.955 169.004 514.75C169.004 515.544 169.647 516.189 170.443 516.189C171.24 516.189 171.883 515.544 171.883 514.75C171.883 513.955 171.24 513.311 170.443 513.311Z"
          fill="#EF4E05"
        />
        <path
          d="M178.375 519.763C177.547 519.763 176.872 520.44 176.872 521.267C176.872 522.094 177.547 522.771 178.375 522.771C179.202 522.771 179.877 522.094 179.877 521.267C179.877 520.44 179.208 519.763 178.375 519.763Z"
          fill="#EF4E05"
        />
        <path
          d="M180.616 508.064C179.862 508.064 179.25 508.676 179.25 509.428C179.25 510.185 179.862 510.793 180.616 510.793C181.37 510.793 181.982 510.185 181.982 509.428C181.982 508.676 181.37 508.064 180.616 508.064Z"
          fill="#EF4E05"
        />
        <path
          d="M172.901 503.411C173.615 503.411 174.193 502.832 174.193 502.122C174.193 501.407 173.615 500.828 172.901 500.828C172.187 500.828 171.609 501.407 171.609 502.122C171.609 502.832 172.187 503.411 172.901 503.411Z"
          fill="#EF4E05"
        />
        <path
          d="M185.362 497.66C185.362 496.973 184.808 496.417 184.122 496.417C183.437 496.417 182.883 496.973 182.883 497.66C182.883 498.342 183.437 498.898 184.122 498.898C184.808 498.898 185.362 498.337 185.362 497.66Z"
          fill="#EF4E05"
        />
        <path
          d="M177.294 489.923C177.294 489.279 176.771 488.751 176.123 488.751C175.48 488.751 174.952 489.274 174.952 489.923C174.952 490.573 175.474 491.091 176.123 491.091C176.771 491.091 177.294 490.573 177.294 489.923Z"
          fill="#EF4E05"
        />
        <path
          d="M168.888 481.808C168.888 481.196 168.392 480.701 167.78 480.701C167.169 480.701 166.673 481.196 166.673 481.808C166.673 482.42 167.169 482.916 167.78 482.916C168.392 482.916 168.888 482.42 168.888 481.808Z"
          fill="#EF4E05"
        />
        <path
          d="M160.176 473.614C160.176 473.035 159.707 472.563 159.127 472.563C158.547 472.563 158.077 473.035 158.077 473.614C158.077 474.193 158.547 474.66 159.127 474.66C159.707 474.66 160.176 474.193 160.176 473.614Z"
          fill="#EF4E05"
        />
        <path
          d="M150.241 466.144C150.788 466.144 151.232 465.7 151.232 465.153C151.232 464.606 150.788 464.163 150.241 464.163C149.693 464.163 149.25 464.606 149.25 465.153C149.25 465.7 149.693 466.144 150.241 466.144Z"
          fill="#EF4E05"
        />
        <path
          d="M138.972 467.765C138.397 467.765 137.928 468.227 137.928 468.806C137.928 469.39 138.392 469.853 138.972 469.853C139.547 469.853 140.016 469.39 140.016 468.806C140.016 468.227 139.547 467.765 138.972 467.765Z"
          fill="#EF4E05"
        />
        <path
          d="M128.541 471.862C127.93 471.862 127.434 472.357 127.434 472.969C127.434 473.581 127.93 474.076 128.541 474.076C129.153 474.076 129.649 473.581 129.649 472.969C129.649 472.357 129.153 471.862 128.541 471.862Z"
          fill="#EF4E05"
        />
        <path
          d="M125.509 486.475C126.189 486.475 126.738 485.924 126.738 485.247C126.738 484.565 126.189 484.018 125.509 484.018C124.829 484.018 124.281 484.565 124.281 485.247C124.281 485.924 124.834 486.475 125.509 486.475Z"
          fill="#EF4E05"
        />
        <path
          d="M119.829 477.094C119.829 476.44 119.302 475.912 118.648 475.912C117.994 475.912 117.467 476.44 117.467 477.094C117.467 477.748 117.994 478.272 118.648 478.272C119.297 478.272 119.829 477.739 119.829 477.094Z"
          fill="#EF4E05"
        />
        <path
          d="M108.703 476.099C109.362 476.099 109.9 475.562 109.9 474.903C109.9 474.245 109.362 473.707 108.703 473.707C108.044 473.707 107.506 474.245 107.506 474.903C107.506 475.562 108.044 476.099 108.703 476.099Z"
          fill="#EF4E05"
        />
        <path
          d="M114.05 487.849C114.758 487.849 115.331 487.274 115.331 486.569C115.331 485.864 114.758 485.289 114.05 485.289C113.342 485.289 112.768 485.864 112.768 486.569C112.768 487.274 113.342 487.849 114.05 487.849Z"
          fill="#EF4E05"
        />
        <path
          d="M105.212 485.597C105.212 484.878 104.626 484.284 103.899 484.284C103.176 484.284 102.586 484.873 102.586 485.597C102.586 486.326 103.171 486.91 103.899 486.91C104.621 486.91 105.212 486.326 105.212 485.597Z"
          fill="#EF4E05"
        />
        <path
          d="M95.6301 477.809C95.6301 478.505 96.1945 479.075 96.8957 479.075C97.5919 479.075 98.1613 478.51 98.1613 477.809C98.1613 477.113 97.597 476.543 96.8957 476.543C96.1945 476.543 95.6301 477.108 95.6301 477.809Z"
          fill="#EF4E05"
        />
        <path
          d="M87.6564 480.024C87.6564 479.286 87.0556 478.688 86.317 478.688C85.5788 478.688 84.9775 479.286 84.9775 480.024C84.9775 480.762 85.5788 481.365 86.317 481.365C87.0556 481.365 87.6564 480.762 87.6564 480.024Z"
          fill="#EF4E05"
        />
        <path
          d="M79.1511 471.717C78.4284 471.717 77.8379 472.306 77.8379 473.035C77.8379 473.754 78.4233 474.347 79.1511 474.347C79.8734 474.347 80.464 473.759 80.464 473.035C80.464 472.306 79.8786 471.717 79.1511 471.717Z"
          fill="#EF4E05"
        />
        <path
          d="M81.9089 462.032C81.2389 462.032 80.696 462.574 80.696 463.247C80.696 463.915 81.2389 464.457 81.9089 464.457C82.5783 464.457 83.1217 463.915 83.1217 463.247C83.1217 462.574 82.5783 462.032 81.9089 462.032Z"
          fill="#EF4E05"
        />
        <path
          d="M76.7674 455.006C77.4079 455.006 77.9275 454.487 77.9275 453.847C77.9275 453.207 77.4079 452.689 76.7674 452.689C76.1265 452.689 75.6069 453.207 75.6069 453.847C75.6069 454.487 76.1265 455.006 76.7674 455.006Z"
          fill="#EF4E05"
        />
        <path
          d="M88.0154 455.716C88.627 455.716 89.1226 455.221 89.1226 454.609C89.1226 453.997 88.627 453.501 88.0154 453.501C87.4039 453.501 86.9077 453.997 86.9077 454.609C86.9077 455.221 87.4039 455.716 88.0154 455.716Z"
          fill="#EF4E05"
        />
        <path
          d="M99.5747 456.776C99.5747 456.178 99.0893 455.693 98.4937 455.693C97.8975 455.693 97.4126 456.178 97.4126 456.776C97.4126 457.37 97.8975 457.856 98.4937 457.856C99.0893 457.856 99.5747 457.37 99.5747 456.776Z"
          fill="#EF4E05"
        />
        <path
          d="M90.7363 469.423C91.4062 469.423 91.9547 468.881 91.9547 468.209C91.9547 467.536 91.4114 466.989 90.7363 466.989C90.0668 466.989 89.5183 467.531 89.5183 468.209C89.5183 468.881 90.0612 469.423 90.7363 469.423Z"
          fill="#EF4E05"
        />
        <path
          d="M101.663 466.424C101.025 466.424 100.503 466.942 100.503 467.582C100.503 468.223 101.02 468.746 101.663 468.746C102.301 468.746 102.823 468.227 102.823 467.582C102.823 466.942 102.301 466.424 101.663 466.424Z"
          fill="#EF4E05"
        />
        <path
          d="M118.105 466.854C118.696 466.854 119.181 466.372 119.181 465.774C119.181 465.181 118.701 464.7 118.105 464.7C117.509 464.7 117.03 465.181 117.03 465.774C117.03 466.372 117.509 466.854 118.105 466.854Z"
          fill="#EF4E05"
        />
        <path
          d="M110.464 459.154C110.464 458.57 109.995 458.099 109.409 458.099C108.829 458.099 108.355 458.566 108.355 459.154C108.355 459.738 108.824 460.206 109.409 460.206C109.989 460.201 110.464 459.734 110.464 459.154Z"
          fill="#EF4E05"
        />
        <path
          d="M107.854 446.643C108.381 446.643 108.809 446.218 108.809 445.69C108.809 445.162 108.381 444.737 107.854 444.737C107.327 444.737 106.899 445.162 106.899 445.69C106.899 446.218 107.332 446.643 107.854 446.643Z"
          fill="#EF4E05"
        />
        <path
          d="M119.049 452.604C119.582 452.604 120.014 452.17 120.014 451.637C120.014 451.105 119.582 450.675 119.049 450.675C118.516 450.675 118.084 451.105 118.084 451.637C118.084 452.17 118.516 452.604 119.049 452.604Z"
          fill="#EF4E05"
        />
        <path
          d="M128.947 461.014C129.498 461.014 129.944 460.57 129.944 460.019C129.944 459.467 129.498 459.019 128.947 459.019C128.397 459.019 127.951 459.467 127.951 460.019C127.951 460.57 128.397 461.014 128.947 461.014Z"
          fill="#EF4E05"
        />
        <path
          d="M141.393 455.328C141.393 454.814 140.976 454.394 140.459 454.394C139.943 454.394 139.526 454.814 139.526 455.328C139.526 455.847 139.943 456.263 140.459 456.263C140.976 456.263 141.393 455.847 141.393 455.328Z"
          fill="#EF4E05"
        />
        <path
          d="M151.623 451.941C151.623 452.427 152.018 452.824 152.509 452.824C152.999 452.824 153.395 452.427 153.395 451.941C153.395 451.45 152.999 451.053 152.509 451.053C152.018 451.058 151.623 451.455 151.623 451.941Z"
          fill="#EF4E05"
        />
        <path
          d="M162.438 461.257C162.954 461.257 163.372 460.841 163.372 460.322C163.372 459.808 162.954 459.388 162.438 459.388C161.923 459.388 161.505 459.808 161.505 460.322C161.505 460.841 161.923 461.257 162.438 461.257Z"
          fill="#EF4E05"
        />
        <path
          d="M171.503 470.059C172.047 470.059 172.49 469.615 172.49 469.073C172.49 468.531 172.047 468.087 171.503 468.087C170.96 468.087 170.517 468.531 170.517 469.073C170.517 469.619 170.96 470.059 171.503 470.059Z"
          fill="#EF4E05"
        />
        <path
          d="M179.292 477.805C179.292 478.384 179.761 478.86 180.346 478.86C180.932 478.86 181.401 478.389 181.401 477.805C181.401 477.221 180.932 476.749 180.346 476.749C179.761 476.749 179.292 477.225 179.292 477.805Z"
          fill="#EF4E05"
        />
        <path
          d="M188.515 485.004C187.887 485.004 187.381 485.508 187.381 486.134C187.381 486.765 187.887 487.27 188.515 487.27C189.143 487.27 189.649 486.765 189.649 486.134C189.649 485.508 189.143 485.004 188.515 485.004Z"
          fill="#EF4E05"
        />
        <path
          d="M196.81 494.707C197.477 494.707 198.018 494.165 198.018 493.497C198.018 492.829 197.477 492.292 196.81 492.292C196.143 492.292 195.603 492.829 195.603 493.497C195.603 494.165 196.143 494.707 196.81 494.707Z"
          fill="#EF4E05"
        />
        <path
          d="M191.753 503.579C191.026 503.579 190.435 504.173 190.435 504.901C190.435 505.625 191.026 506.219 191.753 506.219C192.481 506.219 193.071 505.625 193.071 504.901C193.071 504.173 192.481 503.579 191.753 503.579Z"
          fill="#EF4E05"
        />
        <path
          d="M203.581 503.061C204.289 503.061 204.862 502.486 204.862 501.776C204.862 501.07 204.289 500.496 203.581 500.496C202.873 500.496 202.299 501.07 202.299 501.776C202.299 502.486 202.873 503.061 203.581 503.061Z"
          fill="#EF4E05"
        />
        <path
          d="M209.683 489.176C209.683 488.531 209.161 488.008 208.517 488.008C207.874 488.008 207.352 488.531 207.352 489.176C207.352 489.816 207.874 490.339 208.517 490.339C209.161 490.335 209.683 489.816 209.683 489.176Z"
          fill="#EF4E05"
        />
        <path
          d="M200.666 479.898C200.069 479.898 199.584 480.383 199.584 480.981C199.584 481.575 200.069 482.061 200.666 482.061C201.261 482.061 201.747 481.575 201.747 480.981C201.747 480.383 201.261 479.898 200.666 479.898Z"
          fill="#EF4E05"
        />
        <path
          d="M191.294 472.81C191.294 473.371 191.748 473.824 192.306 473.824C192.866 473.824 193.319 473.371 193.319 472.81C193.319 472.25 192.866 471.797 192.306 471.797C191.748 471.797 191.294 472.25 191.294 472.81Z"
          fill="#EF4E05"
        />
        <path
          d="M183.758 464.98C184.275 464.98 184.697 464.564 184.697 464.041C184.697 463.523 184.281 463.102 183.758 463.102C183.242 463.102 182.82 463.518 182.82 464.041C182.82 464.56 183.242 464.98 183.758 464.98Z"
          fill="#EF4E05"
        />
        <path
          d="M174.883 455.744C175.367 455.744 175.759 455.356 175.759 454.87C175.759 454.389 175.367 453.997 174.883 453.997C174.4 453.997 174.008 454.389 174.008 454.87C174.008 455.356 174.4 455.744 174.883 455.744Z"
          fill="#EF4E05"
        />
        <path
          d="M165.46 445.12C165.006 445.12 164.642 445.489 164.642 445.938C164.642 446.386 165.011 446.755 165.46 446.755C165.914 446.755 166.277 446.386 166.277 445.938C166.277 445.489 165.914 445.12 165.46 445.12Z"
          fill="#EF4E05"
        />
        <path
          d="M176.803 438.634C177.215 438.634 177.547 438.302 177.547 437.89C177.547 437.479 177.215 437.147 176.803 437.147C176.392 437.147 176.06 437.479 176.06 437.89C176.06 438.302 176.392 438.634 176.803 438.634Z"
          fill="#EF4E05"
        />
        <path
          d="M164.116 428.762C164.116 429.153 164.432 429.464 164.817 429.464C165.202 429.464 165.518 429.147 165.518 428.762C165.518 428.377 165.202 428.061 164.817 428.061C164.432 428.061 164.116 428.377 164.116 428.762Z"
          fill="#EF4E05"
        />
        <path
          d="M154.591 437.848C154.159 437.848 153.811 438.196 153.811 438.629C153.811 439.061 154.159 439.411 154.591 439.411C155.024 439.411 155.372 439.061 155.372 438.629C155.372 438.196 155.024 437.848 154.591 437.848Z"
          fill="#EF4E05"
        />
        <path
          d="M149.84 426.083C150.23 426.083 150.541 425.767 150.541 425.382C150.541 424.997 150.225 424.681 149.84 424.681C149.45 424.681 149.139 424.997 149.139 425.382C149.139 425.767 149.45 426.083 149.84 426.083Z"
          fill="#EF4E05"
        />
        <path
          d="M140.295 414.677C140.295 415.041 140.591 415.331 140.949 415.331C141.313 415.331 141.603 415.036 141.603 414.677C141.603 414.318 141.308 414.023 140.949 414.023C140.586 414.023 140.295 414.318 140.295 414.677Z"
          fill="#EF4E05"
        />
        <path
          d="M129.696 405.406C130.042 405.406 130.323 405.126 130.323 404.779C130.323 404.432 130.042 404.151 129.696 404.151C129.349 404.151 129.068 404.432 129.068 404.779C129.068 405.126 129.349 405.406 129.696 405.406Z"
          fill="#EF4E05"
        />
        <path
          d="M114.493 406.461C114.868 406.461 115.173 406.155 115.173 405.781C115.173 405.406 114.868 405.101 114.493 405.101C114.119 405.101 113.813 405.406 113.813 405.781C113.808 406.155 114.114 406.461 114.493 406.461Z"
          fill="#EF4E05"
        />
        <path
          d="M117.282 390.451C117.614 390.451 117.878 390.182 117.878 389.856C117.878 389.523 117.609 389.259 117.282 389.259C116.955 389.259 116.687 389.529 116.687 389.856C116.687 390.182 116.95 390.451 117.282 390.451Z"
          fill="#EF4E05"
        />
        <path
          d="M135.444 389.233C135.444 388.927 135.196 388.684 134.895 388.684C134.595 388.684 134.347 388.932 134.347 389.233C134.347 389.534 134.595 389.781 134.895 389.781C135.196 389.781 135.444 389.534 135.444 389.233Z"
          fill="#EF4E05"
        />
        <path
          d="M149.708 378.755C149.708 379.019 149.925 379.234 150.188 379.234C150.452 379.234 150.668 379.019 150.668 378.755C150.668 378.491 150.452 378.275 150.188 378.275C149.92 378.275 149.708 378.486 149.708 378.755Z"
          fill="#EF4E05"
        />
        <path
          d="M149.155 400.339C149.469 400.339 149.724 400.084 149.724 399.769C149.724 399.455 149.469 399.2 149.155 399.2C148.841 399.2 148.585 399.455 148.585 399.769C148.585 400.084 148.841 400.339 149.155 400.339Z"
          fill="#EF4E05"
        />
        <path
          d="M161.342 411.64C161.342 411.977 161.616 412.246 161.948 412.246C162.28 412.246 162.554 411.972 162.554 411.64C162.554 411.302 162.28 411.033 161.948 411.033C161.616 411.033 161.342 411.302 161.342 411.64Z"
          fill="#EF4E05"
        />
        <path
          d="M177.81 420.963C178.16 420.963 178.443 420.68 178.443 420.33C178.443 419.981 178.16 419.698 177.81 419.698C177.461 419.698 177.178 419.981 177.178 420.33C177.178 420.68 177.461 420.963 177.81 420.963Z"
          fill="#EF4E05"
        />
        <path
          d="M181.106 403.593C181.412 403.593 181.655 403.345 181.655 403.044C181.655 402.743 181.406 402.496 181.106 402.496C180.8 402.496 180.558 402.743 180.558 403.044C180.558 403.345 180.8 403.593 181.106 403.593Z"
          fill="#EF4E05"
        />
        <path
          d="M166.936 392.513C167.216 392.513 167.443 392.286 167.443 392.007C167.443 391.727 167.216 391.501 166.936 391.501C166.657 391.501 166.43 391.727 166.43 392.007C166.43 392.286 166.657 392.513 166.936 392.513Z"
          fill="#EF4E05"
        />
        <path
          d="M170.196 371.451C170.433 371.451 170.623 371.261 170.623 371.024C170.623 370.787 170.433 370.597 170.196 370.597C169.958 370.597 169.768 370.787 169.768 371.024C169.768 371.261 169.958 371.451 170.196 371.451Z"
          fill="#EF4E05"
        />
        <path
          d="M189.116 383.158C189.116 383.411 189.322 383.617 189.575 383.617C189.828 383.617 190.034 383.411 190.034 383.158C190.034 382.905 189.828 382.7 189.575 382.7C189.322 382.7 189.116 382.905 189.116 383.158Z"
          fill="#EF4E05"
        />
        <path
          d="M201.113 400.745C201.113 400.449 200.876 400.212 200.58 400.212C200.285 400.212 200.048 400.449 200.048 400.745C200.048 401.04 200.285 401.277 200.58 401.277C200.876 401.277 201.113 401.035 201.113 400.745Z"
          fill="#EF4E05"
        />
        <path
          d="M194.579 416.729C194.917 416.729 195.191 416.454 195.191 416.117C195.191 415.779 194.917 415.505 194.579 415.505C194.242 415.505 193.968 415.779 193.968 416.117C193.968 416.454 194.242 416.729 194.579 416.729Z"
          fill="#EF4E05"
        />
        <path
          d="M190.851 432.048C190.851 431.657 190.535 431.346 190.15 431.346C189.759 431.346 189.448 431.663 189.448 432.048C189.448 432.433 189.765 432.749 190.15 432.749C190.535 432.749 190.851 432.433 190.851 432.048Z"
          fill="#EF4E05"
        />
        <path
          d="M206.45 427.697C206.45 427.323 206.149 427.022 205.775 427.022C205.4 427.022 205.1 427.323 205.1 427.697C205.1 428.071 205.4 428.372 205.775 428.372C206.149 428.372 206.45 428.071 206.45 427.697Z"
          fill="#EF4E05"
        />
        <path
          d="M214.956 413.701C215.288 413.701 215.552 413.433 215.552 413.106C215.552 412.773 215.283 412.509 214.956 412.509C214.623 412.509 214.36 412.779 214.36 413.106C214.354 413.433 214.623 413.701 214.956 413.701Z"
          fill="#EF4E05"
        />
        <path
          d="M222.17 428.325C221.785 428.325 221.479 428.636 221.479 429.016C221.479 429.401 221.79 429.706 222.17 429.706C222.549 429.706 222.86 429.395 222.86 429.016C222.86 428.636 222.554 428.325 222.17 428.325Z"
          fill="#EF4E05"
        />
        <path
          d="M235.311 435.734C235.728 435.734 236.06 435.396 236.06 434.985C236.06 434.573 235.723 434.236 235.311 434.236C234.9 434.236 234.562 434.573 234.562 434.985C234.562 435.396 234.895 435.734 235.311 435.734Z"
          fill="#EF4E05"
        />
        <path
          d="M234.505 418.759C234.863 418.759 235.148 418.468 235.148 418.115C235.148 417.762 234.858 417.472 234.505 417.472C234.146 417.472 233.861 417.762 233.861 418.115C233.861 418.468 234.146 418.759 234.505 418.759Z"
          fill="#EF4E05"
        />
        <path
          d="M248.959 412.404C249.311 412.404 249.597 412.118 249.597 411.766C249.597 411.414 249.311 411.128 248.959 411.128C248.606 411.128 248.32 411.414 248.32 411.766C248.32 412.118 248.606 412.404 248.959 412.404Z"
          fill="#EF4E05"
        />
        <path
          d="M251.791 427.897C252.196 427.897 252.524 427.569 252.524 427.164C252.524 426.76 252.196 426.431 251.791 426.431C251.386 426.431 251.058 426.76 251.058 427.164C251.058 427.569 251.386 427.897 251.791 427.897Z"
          fill="#EF4E05"
        />
        <path
          d="M247.788 441.518C248.236 441.518 248.595 441.154 248.595 440.71C248.595 440.271 248.231 439.906 247.788 439.906C247.34 439.906 246.981 440.271 246.981 440.71C246.981 441.154 247.34 441.518 247.788 441.518Z"
          fill="#EF4E05"
        />
        <path
          d="M260.455 441.121C260.455 441.593 260.834 441.967 261.298 441.967C261.763 441.967 262.143 441.588 262.143 441.121C262.143 440.658 261.763 440.28 261.298 440.28C260.834 440.28 260.455 440.654 260.455 441.121Z"
          fill="#EF4E05"
        />
        <path
          d="M268.702 430.328C268.702 430.766 269.055 431.119 269.493 431.119C269.931 431.119 270.284 430.766 270.284 430.328C270.284 429.891 269.931 429.538 269.493 429.538C269.055 429.538 268.702 429.891 268.702 430.328Z"
          fill="#EF4E05"
        />
        <path
          d="M265.085 416.939C265.469 416.939 265.781 416.628 265.781 416.243C265.781 415.859 265.469 415.547 265.085 415.547C264.7 415.547 264.389 415.859 264.389 416.243C264.389 416.628 264.7 416.939 265.085 416.939Z"
          fill="#EF4E05"
        />
        <path
          d="M281.079 421.079C281.501 421.079 281.844 420.736 281.844 420.314C281.844 419.892 281.501 419.55 281.079 419.55C280.657 419.55 280.314 419.892 280.314 420.314C280.314 420.736 280.652 421.079 281.079 421.079Z"
          fill="#EF4E05"
        />
        <path
          d="M284.955 435.306C285.438 435.306 285.83 434.915 285.83 434.431C285.83 433.948 285.438 433.556 284.955 433.556C284.471 433.556 284.079 433.948 284.079 434.431C284.079 434.915 284.471 435.306 284.955 435.306Z"
          fill="#EF4E05"
        />
        <path
          d="M288.593 449.058C289.136 449.058 289.574 448.624 289.574 448.077C289.574 447.535 289.136 447.096 288.593 447.096C288.05 447.096 287.612 447.535 287.612 448.077C287.612 448.624 288.055 449.058 288.593 449.058Z"
          fill="#EF4E05"
        />
        <path
          d="M298.57 439.846C299.098 439.846 299.531 439.42 299.531 438.887C299.531 438.36 299.103 437.927 298.57 437.927C298.043 437.927 297.611 438.354 297.611 438.887C297.611 439.42 298.043 439.846 298.57 439.846Z"
          fill="#EF4E05"
        />
        <path
          d="M295.57 425.698C296.039 425.698 296.419 425.319 296.419 424.849C296.419 424.38 296.039 424 295.57 424C295.101 424 294.721 424.38 294.721 424.849C294.716 425.319 295.101 425.698 295.57 425.698Z"
          fill="#EF4E05"
        />
        <path
          d="M293.176 409.325C292.759 409.325 292.427 409.662 292.427 410.074C292.427 410.49 292.765 410.822 293.176 410.822C293.592 410.822 293.925 410.485 293.925 410.074C293.925 409.662 293.587 409.325 293.176 409.325Z"
          fill="#EF4E05"
        />
        <path
          d="M307.382 415.384C307.842 415.384 308.215 415.011 308.215 414.551C308.215 414.09 307.842 413.717 307.382 413.717C306.922 413.717 306.549 414.09 306.549 414.551C306.549 415.011 306.922 415.384 307.382 415.384Z"
          fill="#EF4E05"
        />
        <path
          d="M306.227 400.94C306.643 400.94 306.981 400.603 306.981 400.186C306.981 399.769 306.643 399.432 306.227 399.432C305.81 399.432 305.473 399.769 305.473 400.186C305.473 400.603 305.81 400.94 306.227 400.94Z"
          fill="#EF4E05"
        />
        <path
          d="M318.962 391.696C318.962 392.123 319.31 392.466 319.732 392.466C320.159 392.466 320.502 392.118 320.502 391.696C320.502 391.269 320.154 390.926 319.732 390.926C319.31 390.921 318.962 391.269 318.962 391.696Z"
          fill="#EF4E05"
        />
        <path
          d="M320.677 377.22C321.067 377.22 321.383 376.904 321.383 376.514C321.383 376.123 321.067 375.807 320.677 375.807C320.286 375.807 319.97 376.123 319.97 376.514C319.965 376.904 320.281 377.22 320.677 377.22Z"
          fill="#EF4E05"
        />
        <path
          d="M306.94 368.098C307.277 368.098 307.551 367.823 307.551 367.486C307.551 367.148 307.277 366.874 306.94 366.874C306.602 366.874 306.328 367.148 306.328 367.486C306.328 367.823 306.602 368.098 306.94 368.098Z"
          fill="#EF4E05"
        />
        <path
          d="M305.553 385.02C305.927 385.02 306.228 384.719 306.228 384.345C306.228 383.97 305.927 383.669 305.553 383.669C305.178 383.669 304.877 383.97 304.877 384.345C304.877 384.719 305.183 385.02 305.553 385.02Z"
          fill="#EF4E05"
        />
        <path
          d="M290.107 377.516C290.428 377.516 290.692 377.252 290.692 376.93C290.692 376.609 290.428 376.345 290.107 376.345C289.785 376.345 289.521 376.609 289.521 376.93C289.521 377.257 289.785 377.516 290.107 377.516Z"
          fill="#EF4E05"
        />
        <path
          d="M290.471 394.179C290.471 394.548 290.771 394.844 291.135 394.844C291.499 394.844 291.799 394.543 291.799 394.179C291.799 393.81 291.499 393.515 291.135 393.515C290.771 393.515 290.471 393.81 290.471 394.179Z"
          fill="#EF4E05"
        />
        <path
          d="M277.814 405.607C278.185 405.607 278.484 405.307 278.484 404.937C278.484 404.567 278.185 404.267 277.814 404.267C277.444 404.267 277.145 404.567 277.145 404.937C277.145 405.307 277.444 405.607 277.814 405.607Z"
          fill="#EF4E05"
        />
        <path
          d="M274.672 388.363C274.993 388.363 275.252 388.105 275.252 387.783C275.252 387.461 274.993 387.203 274.672 387.203C274.35 387.203 274.092 387.461 274.092 387.783C274.092 388.105 274.35 388.363 274.672 388.363Z"
          fill="#EF4E05"
        />
        <path
          d="M272.082 369.938C272.358 369.938 272.583 369.714 272.583 369.437C272.583 369.16 272.358 368.936 272.082 368.936C271.805 368.936 271.581 369.16 271.581 369.437C271.581 369.714 271.805 369.938 272.082 369.938Z"
          fill="#EF4E05"
        />
        <path
          d="M272.404 349.91C272.648 349.91 272.847 349.711 272.847 349.467C272.847 349.222 272.648 349.024 272.404 349.024C272.159 349.024 271.961 349.222 271.961 349.467C271.961 349.711 272.159 349.91 272.404 349.91Z"
          fill="#EF4E05"
        />
        <path
          d="M290.872 358.226C290.582 358.226 290.35 358.458 290.35 358.748C290.35 359.038 290.582 359.27 290.872 359.27C291.162 359.27 291.394 359.038 291.394 358.748C291.394 358.458 291.162 358.226 290.872 358.226Z"
          fill="#EF4E05"
        />
        <path
          d="M294.72 339.51C294.72 339.247 294.504 339.031 294.241 339.031C293.977 339.031 293.761 339.247 293.761 339.51C293.761 339.774 293.977 339.99 294.241 339.99C294.504 339.995 294.72 339.78 294.72 339.51Z"
          fill="#EF4E05"
        />
        <path
          d="M312.808 331.658C313.102 331.658 313.341 331.42 313.341 331.126C313.341 330.832 313.102 330.593 312.808 330.593C312.514 330.593 312.276 330.832 312.276 331.126C312.276 331.42 312.514 331.658 312.808 331.658Z"
          fill="#EF4E05"
        />
        <path
          d="M309.893 350.658C310.207 350.658 310.462 350.403 310.462 350.089C310.462 349.774 310.207 349.52 309.893 349.52C309.578 349.52 309.323 349.774 309.323 350.089C309.323 350.403 309.578 350.658 309.893 350.658Z"
          fill="#EF4E05"
        />
        <path
          d="M323.983 359.312C323.614 359.312 323.319 359.613 323.319 359.977C323.319 360.346 323.619 360.641 323.983 360.641C324.347 360.641 324.648 360.341 324.648 359.977C324.648 359.607 324.352 359.312 323.983 359.312Z"
          fill="#EF4E05"
        />
        <path
          d="M326.44 343.017C326.44 343.365 326.72 343.645 327.068 343.645C327.416 343.645 327.695 343.365 327.695 343.017C327.695 342.669 327.416 342.39 327.068 342.39C326.72 342.39 326.44 342.675 326.44 343.017Z"
          fill="#EF4E05"
        />
        <path
          d="M329.783 323.875C329.451 323.875 329.182 324.144 329.182 324.476C329.182 324.809 329.451 325.077 329.783 325.077C330.116 325.077 330.385 324.809 330.385 324.476C330.39 324.144 330.121 323.875 329.783 323.875Z"
          fill="#EF4E05"
        />
        <path
          d="M346.152 320.616C346.521 320.616 346.822 320.316 346.822 319.946C346.822 319.577 346.521 319.277 346.152 319.277C345.783 319.277 345.482 319.577 345.482 319.946C345.477 320.316 345.778 320.616 346.152 320.616Z"
          fill="#EF4E05"
        />
        <path
          d="M342.957 336.641C342.957 336.257 342.646 335.951 342.266 335.951C341.886 335.951 341.575 336.262 341.575 336.641C341.575 337.026 341.886 337.332 342.266 337.332C342.646 337.332 342.957 337.021 342.957 336.641Z"
          fill="#EF4E05"
        />
        <path
          d="M340.277 353.453C339.871 353.453 339.544 353.78 339.544 354.186C339.544 354.592 339.871 354.919 340.277 354.919C340.683 354.919 341.01 354.592 341.01 354.186C341.01 353.785 340.683 353.453 340.277 353.453Z"
          fill="#EF4E05"
        />
        <path
          d="M353.171 347.057C353.171 347.494 353.524 347.842 353.957 347.842C354.394 347.842 354.742 347.489 354.742 347.057C354.742 346.619 354.389 346.271 353.957 346.271C353.519 346.271 353.171 346.624 353.171 347.057Z"
          fill="#EF4E05"
        />
        <path
          d="M357.426 331.928C357.851 331.928 358.196 331.583 358.196 331.158C358.196 330.732 357.851 330.388 357.426 330.388C357.001 330.388 356.656 330.732 356.656 331.158C356.656 331.583 357.001 331.928 357.426 331.928Z"
          fill="#EF4E05"
        />
        <path
          d="M366.809 341.631C367.28 341.631 367.664 341.246 367.664 340.771C367.664 340.296 367.276 339.916 366.809 339.916C366.332 339.916 365.954 340.301 365.954 340.771C365.954 341.246 366.332 341.631 366.809 341.631Z"
          fill="#EF4E05"
        />
        <path
          d="M368.775 353.248C368.775 353.754 369.187 354.17 369.696 354.17C370.21 354.17 370.621 353.759 370.621 353.248C370.621 352.741 370.21 352.325 369.696 352.325C369.187 352.325 368.775 352.736 368.775 353.248Z"
          fill="#EF4E05"
        />
        <path
          d="M358.587 361.189C359.067 361.189 359.457 360.799 359.457 360.319C359.457 359.839 359.067 359.449 358.587 359.449C358.107 359.449 357.717 359.839 357.717 360.319C357.722 360.799 358.107 361.189 358.587 361.189Z"
          fill="#EF4E05"
        />
        <path
          d="M349.063 367.122C349.063 366.669 348.694 366.305 348.245 366.305C347.792 366.305 347.428 366.674 347.428 367.122C347.428 367.57 347.797 367.939 348.245 367.939C348.694 367.939 349.063 367.575 349.063 367.122Z"
          fill="#EF4E05"
        />
        <path
          d="M335.342 371.43C335.764 371.43 336.101 371.088 336.101 370.671C336.101 370.249 335.759 369.911 335.342 369.911C334.92 369.911 334.583 370.254 334.583 370.671C334.583 371.088 334.925 371.43 335.342 371.43Z"
          fill="#EF4E05"
        />
        <path
          d="M334.219 386.096C334.672 386.096 335.041 385.726 335.041 385.273C335.041 384.819 334.672 384.45 334.219 384.45C333.765 384.45 333.396 384.819 333.396 385.273C333.391 385.726 333.76 386.096 334.219 386.096Z"
          fill="#EF4E05"
        />
        <path
          d="M333.412 399.158C333.412 398.672 333.016 398.277 332.531 398.277C332.046 398.277 331.65 398.672 331.65 399.158C331.65 399.643 332.046 400.038 332.531 400.038C333.016 400.038 333.412 399.643 333.412 399.158Z"
          fill="#EF4E05"
        />
        <path
          d="M320.439 407.284C320.914 407.284 321.293 406.899 321.293 406.429C321.293 405.96 320.909 405.575 320.439 405.575C319.965 405.575 319.585 405.96 319.585 406.429C319.585 406.899 319.965 407.284 320.439 407.284Z"
          fill="#EF4E05"
        />
        <path
          d="M320.867 420.351C321.384 420.351 321.805 419.934 321.805 419.413C321.805 418.89 321.384 418.474 320.867 418.474C320.35 418.474 319.928 418.896 319.928 419.413C319.928 419.929 320.35 420.351 320.867 420.351Z"
          fill="#EF4E05"
        />
        <path
          d="M321.509 431.014C320.945 431.014 320.486 431.473 320.486 432.037C320.486 432.601 320.945 433.06 321.509 433.06C322.074 433.06 322.533 432.601 322.533 432.037C322.533 431.473 322.074 431.014 321.509 431.014Z"
          fill="#EF4E05"
        />
        <path
          d="M308.79 426.969C308.273 426.969 307.857 427.386 307.857 427.903C307.857 428.42 308.273 428.836 308.79 428.836C309.307 428.836 309.723 428.42 309.723 427.903C309.723 427.386 309.307 426.969 308.79 426.969Z"
          fill="#EF4E05"
        />
        <path
          d="M311.095 441.298C311.659 441.298 312.118 440.841 312.118 440.275C312.118 439.71 311.659 439.251 311.095 439.251C310.531 439.251 310.072 439.71 310.072 440.275C310.072 440.841 310.531 441.298 311.095 441.298Z"
          fill="#EF4E05"
        />
        <path
          d="M312.661 452.478C313.273 452.478 313.774 451.983 313.774 451.362C313.774 450.745 313.278 450.25 312.661 450.25C312.049 450.25 311.548 450.745 311.548 451.362C311.548 451.983 312.049 452.478 312.661 452.478Z"
          fill="#EF4E05"
        />
        <path
          d="M302.209 452.245C302.209 451.656 301.729 451.175 301.138 451.175C300.548 451.175 300.068 451.656 300.068 452.245C300.068 452.833 300.548 453.315 301.138 453.315C301.729 453.315 302.209 452.833 302.209 452.245Z"
          fill="#EF4E05"
        />
        <path
          d="M302.045 464.667C302.045 465.321 302.572 465.849 303.226 465.849C303.881 465.849 304.408 465.321 304.408 464.667C304.408 464.013 303.881 463.485 303.226 463.485C302.572 463.495 302.045 464.018 302.045 464.667Z"
          fill="#EF4E05"
        />
        <path
          d="M291.404 462.074C292.01 462.074 292.501 461.584 292.501 460.976C292.501 460.374 292.01 459.879 291.404 459.879C290.798 459.879 290.307 460.374 290.307 460.976C290.307 461.584 290.798 462.074 291.404 462.074Z"
          fill="#EF4E05"
        />
        <path
          d="M278.178 457.239C278.178 457.804 278.637 458.262 279.201 458.262C279.765 458.262 280.224 457.804 280.224 457.239C280.224 456.674 279.765 456.216 279.201 456.216C278.637 456.216 278.178 456.674 278.178 457.239Z"
          fill="#EF4E05"
        />
        <path
          d="M275.3 443.153C274.798 443.153 274.387 443.56 274.387 444.064C274.387 444.564 274.793 444.98 275.3 444.98C275.8 444.98 276.212 444.573 276.212 444.064C276.206 443.56 275.8 443.153 275.3 443.153Z"
          fill="#EF4E05"
        />
        <path
          d="M266.261 455.398C266.788 455.398 267.215 454.973 267.215 454.445C267.215 453.917 266.788 453.487 266.261 453.487C265.733 453.487 265.306 453.917 265.306 454.445C265.306 454.973 265.733 455.398 266.261 455.398Z"
          fill="#EF4E05"
        />
        <path
          d="M252.771 454.081C253.272 454.081 253.683 453.674 253.683 453.165C253.683 452.66 253.277 452.254 252.771 452.254C252.27 452.254 251.859 452.66 251.859 453.165C251.859 453.674 252.27 454.081 252.771 454.081Z"
          fill="#EF4E05"
        />
        <path
          d="M244.772 464.817C245.313 464.817 245.752 464.522 245.752 464.158C245.752 463.794 245.313 463.499 244.772 463.499C244.23 463.499 243.791 463.794 243.791 464.158C243.791 464.522 244.23 464.817 244.772 464.817Z"
          fill="#EF4E05"
        />
        <path
          d="M258.066 464.191C257.501 464.191 257.042 464.649 257.042 465.214C257.042 465.774 257.501 466.237 258.066 466.237C258.63 466.237 259.089 465.774 259.089 465.214C259.089 464.649 258.63 464.191 258.066 464.191Z"
          fill="#EF4E05"
        />
        <path
          d="M270.648 468.059C271.238 468.059 271.724 467.578 271.724 466.984C271.724 466.391 271.244 465.91 270.648 465.91C270.057 465.91 269.572 466.386 269.572 466.984C269.572 467.578 270.052 468.059 270.648 468.059Z"
          fill="#EF4E05"
        />
        <path
          d="M264.198 476.805C264.198 476.174 263.692 475.669 263.065 475.669C262.437 475.669 261.931 476.174 261.931 476.805C261.931 477.431 262.437 477.935 263.065 477.935C263.692 477.935 264.198 477.426 264.198 476.805Z"
          fill="#EF4E05"
        />
        <path
          d="M255.83 487.405C256.494 487.405 257.032 486.868 257.032 486.205C257.032 485.541 256.494 485.004 255.83 485.004C255.166 485.004 254.627 485.541 254.627 486.205C254.627 486.868 255.166 487.405 255.83 487.405Z"
          fill="#EF4E05"
        />
        <path
          d="M250.557 476.538C251.158 476.538 251.648 476.048 251.648 475.445C251.648 474.847 251.158 474.357 250.557 474.357C249.955 474.357 249.465 474.847 249.465 475.445C249.465 476.053 249.955 476.538 250.557 476.538Z"
          fill="#EF4E05"
        />
        <path
          d="M238.291 476.099C238.879 476.099 239.356 475.623 239.356 475.034C239.356 474.45 238.879 473.969 238.291 473.969C237.703 473.969 237.226 474.45 237.226 475.034C237.226 475.623 237.703 476.099 238.291 476.099Z"
          fill="#EF4E05"
        />
        <path
          d="M226.025 476.193C226.599 476.193 227.068 475.721 227.068 475.146C227.068 474.572 226.604 474.104 226.025 474.104C225.45 474.104 224.98 474.572 224.98 475.146C224.98 475.721 225.45 476.193 226.025 476.193Z"
          fill="#EF4E05"
        />
        <path
          d="M218.964 464.41C219.48 464.41 219.897 463.994 219.897 463.476C219.897 462.962 219.48 462.541 218.964 462.541C218.447 462.541 218.03 462.962 218.03 463.476C218.035 463.99 218.452 464.41 218.964 464.41Z"
          fill="#EF4E05"
        />
        <path
          d="M209.082 454.431C209.082 454.908 209.467 455.3 209.946 455.3C210.421 455.3 210.806 454.912 210.806 454.431C210.806 453.959 210.421 453.567 209.946 453.567C209.467 453.567 209.082 453.959 209.082 454.431Z"
          fill="#EF4E05"
        />
        <path
          d="M232.569 462.598C232.569 462.074 232.147 461.649 231.62 461.649C231.098 461.649 230.671 462.07 230.671 462.598C230.671 463.125 231.093 463.546 231.62 463.546C232.147 463.541 232.569 463.116 232.569 462.598Z"
          fill="#EF4E05"
        />
        <path
          d="M238.406 451.684C238.886 451.684 239.276 451.296 239.276 450.815C239.276 450.334 238.886 449.946 238.406 449.946C237.927 449.946 237.536 450.334 237.536 450.815C237.541 451.301 237.927 451.684 238.406 451.684Z"
          fill="#EF4E05"
        />
        <path
          d="M224.843 449.283C225.302 449.283 225.671 448.914 225.671 448.451C225.671 447.993 225.297 447.624 224.843 447.624C224.39 447.624 224.016 447.998 224.016 448.451C224.016 448.904 224.385 449.283 224.843 449.283Z"
          fill="#EF4E05"
        />
        <path
          d="M212.841 440.719C212.841 441.14 213.184 441.481 213.606 441.481C214.028 441.481 214.37 441.14 214.37 440.719C214.37 440.294 214.028 439.953 213.606 439.953C213.184 439.953 212.841 440.289 212.841 440.719Z"
          fill="#EF4E05"
        />
        <path
          d="M199.79 444.471C200.228 444.471 200.576 444.12 200.576 443.686C200.576 443.247 200.222 442.901 199.79 442.901C199.352 442.901 199.004 443.251 199.004 443.686C199.004 444.12 199.358 444.471 199.79 444.471Z"
          fill="#EF4E05"
        />
        <path
          d="M186.654 447.708C186.2 447.708 185.836 448.077 185.836 448.526C185.836 448.979 186.206 449.343 186.654 449.343C187.107 449.343 187.471 448.974 187.471 448.526C187.476 448.077 187.107 447.708 186.654 447.708Z"
          fill="#EF4E05"
        />
        <path
          d="M196.204 459.533C196.699 459.533 197.1 459.131 197.1 458.636C197.1 458.141 196.699 457.739 196.204 457.739C195.708 457.739 195.307 458.141 195.307 458.636C195.307 459.131 195.708 459.533 196.204 459.533Z"
          fill="#EF4E05"
        />
        <path
          d="M206.138 468.031C206.138 467.494 205.701 467.059 205.163 467.059C204.625 467.059 204.188 467.494 204.188 468.031C204.188 468.573 204.625 469.007 205.163 469.007C205.701 469.003 206.138 468.564 206.138 468.031Z"
          fill="#EF4E05"
        />
        <path
          d="M212.519 476.655C212.519 477.23 212.983 477.697 213.563 477.697C214.143 477.697 214.607 477.23 214.607 476.655C214.607 476.081 214.143 475.609 213.563 475.609C212.983 475.609 212.519 476.076 212.519 476.655Z"
          fill="#EF4E05"
        />
        <path
          d="M220.335 485.924C219.702 485.924 219.186 486.438 219.186 487.073C219.186 487.714 219.697 488.223 220.335 488.223C220.968 488.223 221.485 487.709 221.485 487.073C221.485 486.443 220.968 485.924 220.335 485.924Z"
          fill="#EF4E05"
        />
        <path
          d="M232.221 487.877C232.859 487.877 233.376 487.359 233.376 486.723C233.376 486.083 232.859 485.569 232.221 485.569C231.583 485.569 231.066 486.083 231.066 486.723C231.066 487.359 231.583 487.877 232.221 487.877Z"
          fill="#EF4E05"
        />
        <path
          d="M243.864 487.541C244.513 487.541 245.04 487.013 245.04 486.363C245.04 485.714 244.513 485.186 243.864 485.186C243.216 485.186 242.688 485.714 242.688 486.363C242.688 487.013 243.216 487.541 243.864 487.541Z"
          fill="#EF4E05"
        />
        <path
          d="M249.518 497.599C250.228 497.599 250.804 497.025 250.804 496.314C250.804 495.604 250.228 495.025 249.518 495.025C248.807 495.025 248.231 495.604 248.231 496.314C248.231 497.025 248.807 497.599 249.518 497.599Z"
          fill="#EF4E05"
        />
        <path
          d="M239.166 497.655C239.166 496.955 238.596 496.385 237.895 496.385C237.194 496.385 236.624 496.955 236.624 497.655C236.624 498.356 237.194 498.926 237.895 498.926C238.596 498.926 239.166 498.361 239.166 497.655Z"
          fill="#EF4E05"
        />
        <path
          d="M225.994 496.357C225.303 496.357 224.739 496.917 224.739 497.613C224.739 498.309 225.298 498.865 225.994 498.865C226.685 498.865 227.249 498.309 227.249 497.613C227.249 496.917 226.685 496.357 225.994 496.357Z"
          fill="#EF4E05"
        />
        <path
          d="M214.545 500.071C215.236 500.071 215.8 499.51 215.8 498.814C215.8 498.123 215.241 497.557 214.545 497.557C213.854 497.557 213.29 498.118 213.29 498.814C213.29 499.505 213.854 500.071 214.545 500.071Z"
          fill="#EF4E05"
        />
        <path
          d="M219.608 507.546C218.859 507.546 218.247 508.158 218.247 508.905C218.247 509.657 218.853 510.269 219.608 510.269C220.356 510.269 220.968 509.662 220.968 508.905C220.968 508.158 220.362 507.546 219.608 507.546Z"
          fill="#EF4E05"
        />
        <path
          d="M209.63 509.461C208.871 509.461 208.254 510.078 208.254 510.835C208.254 511.596 208.871 512.213 209.63 512.213C210.389 512.213 211.007 511.596 211.007 510.835C211.007 510.078 210.389 509.461 209.63 509.461Z"
          fill="#EF4E05"
        />
        <path
          d="M215.937 518.87C215.114 518.87 214.444 519.538 214.444 520.361C214.444 521.183 215.114 521.851 215.937 521.851C216.76 521.851 217.429 521.183 217.429 520.361C217.429 519.534 216.765 518.87 215.937 518.87Z"
          fill="#EF4E05"
        />
        <path
          d="M225.487 517.525C224.664 517.525 224 518.188 224 519.01C224 519.833 224.664 520.496 225.487 520.496C226.31 520.496 226.974 519.833 226.974 519.01C226.974 518.188 226.31 517.525 225.487 517.525Z"
          fill="#EF4E05"
        />
        <path
          d="M235.068 517.216C234.246 517.216 233.576 517.889 233.576 518.711C233.576 519.534 234.246 520.202 235.068 520.202C235.891 520.202 236.561 519.534 236.561 518.711C236.561 517.889 235.891 517.216 235.068 517.216Z"
          fill="#EF4E05"
        />
        <path
          d="M229.442 506.695C228.682 506.695 228.071 507.307 228.071 508.069C228.071 508.826 228.682 509.438 229.442 509.438C230.201 509.438 230.813 508.826 230.813 508.069C230.813 507.312 230.201 506.695 229.442 506.695Z"
          fill="#EF4E05"
        />
        <path
          d="M238.559 507.434C237.795 507.434 237.172 508.06 237.172 508.821C237.172 509.587 237.795 510.209 238.559 510.209C239.324 510.209 239.946 509.587 239.946 508.821C239.946 508.06 239.329 507.434 238.559 507.434Z"
          fill="#EF4E05"
        />
        <path
          d="M247.334 505.411C246.564 505.411 245.942 506.032 245.942 506.803C245.942 507.574 246.564 508.195 247.334 508.195C248.104 508.195 248.726 507.574 248.726 506.803C248.726 506.032 248.104 505.411 247.334 505.411Z"
          fill="#EF4E05"
        />
        <path
          d="M256.768 507.13C257.546 507.13 258.176 506.499 258.176 505.724C258.176 504.943 257.546 504.313 256.768 504.313C255.991 504.313 255.36 504.943 255.36 505.724C255.36 506.499 255.991 507.13 256.768 507.13Z"
          fill="#EF4E05"
        />
        <path
          d="M259.711 495.959C259.711 496.684 260.296 497.272 261.024 497.272C261.746 497.272 262.337 496.688 262.337 495.959C262.337 495.24 261.752 494.647 261.024 494.647C260.296 494.647 259.711 495.235 259.711 495.959Z"
          fill="#EF4E05"
        />
        <path
          d="M266.493 503.593C265.707 503.593 265.074 504.224 265.074 505.009C265.074 505.794 265.707 506.429 266.493 506.429C267.278 506.429 267.911 505.794 267.911 505.009C267.911 504.224 267.273 503.593 266.493 503.593Z"
          fill="#EF4E05"
        />
        <path
          d="M274.223 498.258C274.223 497.501 273.606 496.884 272.847 496.884C272.088 496.884 271.471 497.501 271.471 498.258C271.471 499.019 272.088 499.636 272.847 499.636C273.606 499.636 274.223 499.019 274.223 498.258Z"
          fill="#EF4E05"
        />
        <path
          d="M267.911 486.499C267.22 486.499 266.662 487.06 266.662 487.751C266.662 488.442 267.22 488.998 267.911 488.998C268.602 488.998 269.161 488.442 269.161 487.751C269.161 487.06 268.602 486.499 267.911 486.499Z"
          fill="#EF4E05"
        />
        <path
          d="M279.102 491.815C279.829 491.815 280.42 491.227 280.42 490.498C280.42 489.769 279.829 489.181 279.102 489.181C278.374 489.181 277.783 489.769 277.783 490.498C277.783 491.227 278.374 491.815 279.102 491.815Z"
          fill="#EF4E05"
        />
        <path
          d="M288.831 492.442C288.061 492.442 287.439 493.068 287.439 493.834C287.439 494.605 288.061 495.226 288.831 495.226C289.601 495.226 290.223 494.605 290.223 493.834C290.223 493.068 289.601 492.442 288.831 492.442Z"
          fill="#EF4E05"
        />
        <path
          d="M282.281 501.776C281.48 501.776 280.826 502.43 280.826 503.234C280.826 504.032 281.48 504.687 282.281 504.687C283.083 504.687 283.737 504.032 283.737 503.234C283.737 502.43 283.088 501.776 282.281 501.776Z"
          fill="#EF4E05"
        />
        <path
          d="M292.295 502.537C291.462 502.537 290.787 503.21 290.787 504.046C290.787 504.878 291.462 505.555 292.295 505.555C293.129 505.555 293.804 504.878 293.804 504.046C293.804 503.21 293.129 502.537 292.295 502.537Z"
          fill="#EF4E05"
        />
        <path
          d="M299.314 495.179C298.508 495.179 297.854 495.833 297.854 496.641C297.854 497.45 298.508 498.104 299.314 498.104C300.121 498.104 300.775 497.45 300.775 496.641C300.775 495.833 300.121 495.179 299.314 495.179Z"
          fill="#EF4E05"
        />
        <path
          d="M310.762 497.464C310.762 496.628 310.082 495.945 309.243 495.945C308.405 495.945 307.725 496.628 307.725 497.464C307.725 498.3 308.405 498.982 309.243 498.982C310.082 498.982 310.762 498.3 310.762 497.464Z"
          fill="#EF4E05"
        />
        <path
          d="M319.295 491.325C319.295 490.493 318.62 489.816 317.787 489.816C316.953 489.816 316.279 490.493 316.279 491.325C316.279 492.157 316.953 492.834 317.787 492.834C318.62 492.834 319.295 492.157 319.295 491.325Z"
          fill="#EF4E05"
        />
        <path
          d="M314.971 480.411C314.201 480.411 313.579 481.033 313.579 481.804C313.579 482.57 314.201 483.196 314.971 483.196C315.74 483.196 316.363 482.57 316.363 481.804C316.363 481.033 315.74 480.411 314.971 480.411Z"
          fill="#EF4E05"
        />
        <path
          d="M306.543 489.395C307.327 489.395 307.962 488.76 307.962 487.975C307.962 487.195 307.327 486.56 306.543 486.56C305.76 486.56 305.125 487.195 305.125 487.975C305.125 488.76 305.76 489.395 306.543 489.395Z"
          fill="#EF4E05"
        />
        <path
          d="M296.198 486.817C296.936 486.817 297.532 486.219 297.532 485.48C297.532 484.742 296.936 484.149 296.198 484.149C295.459 484.149 294.863 484.742 294.863 485.48C294.863 486.219 295.459 486.817 296.198 486.817Z"
          fill="#EF4E05"
        />
        <path
          d="M287.28 481.813C287.28 481.117 286.716 480.552 286.02 480.552C285.324 480.552 284.759 481.117 284.759 481.813C284.759 482.509 285.324 483.074 286.02 483.074C286.716 483.074 287.28 482.509 287.28 481.813Z"
          fill="#EF4E05"
        />
        <path
          d="M274.957 480.103C275.611 480.103 276.143 479.57 276.143 478.916C276.143 478.262 275.611 477.73 274.957 477.73C274.303 477.73 273.77 478.262 273.77 478.916C273.77 479.57 274.303 480.103 274.957 480.103Z"
          fill="#EF4E05"
        />
        <path
          d="M281.443 469.788C281.443 470.418 281.954 470.928 282.582 470.928C283.209 470.928 283.721 470.418 283.721 469.788C283.721 469.162 283.209 468.648 282.582 468.648C281.949 468.648 281.443 469.157 281.443 469.788Z"
          fill="#EF4E05"
        />
        <path
          d="M295.074 473.137C295.074 472.469 294.531 471.923 293.861 471.923C293.191 471.923 292.648 472.469 292.648 473.137C292.648 473.805 293.191 474.352 293.861 474.352C294.531 474.352 295.074 473.805 295.074 473.137Z"
          fill="#EF4E05"
        />
        <path
          d="M304.082 478.421C304.798 478.421 305.379 477.842 305.379 477.122C305.379 476.408 304.798 475.828 304.082 475.828C303.365 475.828 302.784 476.408 302.784 477.122C302.784 477.842 303.365 478.421 304.082 478.421Z"
          fill="#EF4E05"
        />
        <path
          d="M314.87 471.441C314.87 470.722 314.29 470.143 313.573 470.143C312.856 470.143 312.276 470.722 312.276 471.441C312.276 472.156 312.856 472.736 313.573 472.736C314.29 472.736 314.87 472.156 314.87 471.441Z"
          fill="#EF4E05"
        />
        <path
          d="M314.892 462.939C315.562 462.939 316.11 462.397 316.11 461.719C316.11 461.051 315.567 460.505 314.892 460.505C314.222 460.505 313.674 461.046 313.674 461.719C313.674 462.397 314.217 462.939 314.892 462.939Z"
          fill="#EF4E05"
        />
        <path
          d="M324.215 456.379C324.885 456.379 325.428 455.837 325.428 455.165C325.428 454.497 324.885 453.955 324.215 453.955C323.545 453.955 323.002 454.497 323.002 455.165C323.007 455.842 323.55 456.379 324.215 456.379Z"
          fill="#EF4E05"
        />
        <path
          d="M323.613 445.274C324.23 445.274 324.736 444.774 324.736 444.148C324.736 443.527 324.236 443.027 323.613 443.027C322.991 443.027 322.49 443.527 322.49 444.148C322.495 444.774 322.996 445.274 323.613 445.274Z"
          fill="#EF4E05"
        />
        <path
          d="M335.811 449.731C336.491 449.731 337.04 449.18 337.04 448.503C337.04 447.82 336.491 447.274 335.811 447.274C335.131 447.274 334.583 447.82 334.583 448.503C334.583 449.18 335.137 449.731 335.811 449.731Z"
          fill="#EF4E05"
        />
        <path
          d="M334.297 436.699C334.297 436.082 333.796 435.575 333.174 435.575C332.557 435.575 332.051 436.076 332.051 436.699C332.051 437.316 332.552 437.822 333.174 437.822C333.796 437.822 334.297 437.321 334.297 436.699Z"
          fill="#EF4E05"
        />
        <path
          d="M333.053 426.031C333.628 426.031 334.092 425.567 334.092 424.992C334.092 424.417 333.628 423.953 333.053 423.953C332.478 423.953 332.014 424.417 332.014 424.992C332.014 425.567 332.478 426.031 333.053 426.031Z"
          fill="#EF4E05"
        />
        <path
          d="M334.245 414.345C334.782 414.345 335.22 413.907 335.22 413.37C335.22 412.831 334.782 412.394 334.245 412.394C333.707 412.394 333.269 412.831 333.269 413.37C333.274 413.912 333.707 414.345 334.245 414.345Z"
          fill="#EF4E05"
        />
        <path
          d="M342.961 405.254C342.961 405.797 343.399 406.235 343.943 406.235C344.486 406.235 344.923 405.797 344.923 405.254C344.923 404.711 344.486 404.273 343.943 404.273C343.399 404.273 342.961 404.711 342.961 405.254Z"
          fill="#EF4E05"
        />
        <path
          d="M348.773 416.887C349.367 416.887 349.849 416.405 349.849 415.811C349.849 415.217 349.367 414.735 348.773 414.735C348.178 414.735 347.697 415.217 347.697 415.811C347.697 416.405 348.178 416.887 348.773 416.887Z"
          fill="#EF4E05"
        />
        <path
          d="M345.893 427.37C345.893 426.748 345.387 426.242 344.765 426.242C344.143 426.242 343.636 426.748 343.636 427.37C343.636 427.992 344.143 428.498 344.765 428.498C345.387 428.498 345.893 427.992 345.893 427.37Z"
          fill="#EF4E05"
        />
        <path
          d="M343.837 440.028C344.498 440.028 345.034 439.49 345.034 438.829C345.034 438.168 344.498 437.632 343.837 437.632C343.176 437.632 342.64 438.168 342.64 438.829C342.64 439.49 343.176 440.028 343.837 440.028Z"
          fill="#EF4E05"
        />
        <path
          d="M355.987 437.785C355.987 437.089 355.423 436.52 354.721 436.52C354.026 436.52 353.456 437.084 353.456 437.785C353.456 438.481 354.02 439.05 354.721 439.05C355.423 439.05 355.987 438.481 355.987 437.785Z"
          fill="#EF4E05"
        />
        <path
          d="M347.85 450.016C348.57 450.016 349.153 449.432 349.153 448.713C349.153 447.993 348.57 447.409 347.85 447.409C347.131 447.409 346.548 447.993 346.548 448.713C346.548 449.432 347.131 450.016 347.85 450.016Z"
          fill="#EF4E05"
        />
        <path
          d="M358.898 450.539C359.661 450.539 360.28 449.923 360.28 449.161C360.28 448.395 359.661 447.778 358.898 447.778C358.135 447.778 357.517 448.395 357.517 449.161C357.517 449.923 358.135 450.539 358.898 450.539Z"
          fill="#EF4E05"
        />
        <path
          d="M370.093 454.235C370.093 453.403 369.425 452.735 368.594 452.735C367.767 452.735 367.099 453.403 367.099 454.235C367.099 455.062 367.767 455.73 368.594 455.73C369.425 455.73 370.093 455.057 370.093 454.235Z"
          fill="#EF4E05"
        />
        <path
          d="M368.219 442.602C368.219 441.831 367.598 441.21 366.827 441.21C366.061 441.21 365.435 441.831 365.435 442.602C365.435 443.368 366.061 443.994 366.827 443.994C367.598 443.994 368.219 443.368 368.219 442.602Z"
          fill="#EF4E05"
        />
        <path
          d="M364.34 431.283C363.623 431.283 363.042 431.863 363.042 432.58C363.042 433.297 363.623 433.877 364.34 433.877C365.057 433.877 365.636 433.297 365.636 432.58C365.631 431.863 365.052 431.283 364.34 431.283Z"
          fill="#EF4E05"
        />
        <path
          d="M356.652 423.858C355.993 423.858 355.46 424.39 355.46 425.05C355.46 425.709 355.993 426.241 356.652 426.241C357.311 426.241 357.844 425.709 357.844 425.05C357.844 424.396 357.311 423.858 356.652 423.858Z"
          fill="#EF4E05"
        />
        <path
          d="M362.605 415.51C363.246 415.51 363.765 414.991 363.765 414.35C363.765 413.709 363.246 413.19 362.605 413.19C361.965 413.19 361.445 413.709 361.445 414.35C361.445 414.991 361.965 415.51 362.605 415.51Z"
          fill="#EF4E05"
        />
        <path
          d="M356.388 404.536C356.968 404.536 357.443 404.067 357.443 403.482C357.443 402.897 356.973 402.427 356.388 402.427C355.808 402.427 355.333 402.897 355.333 403.482C355.333 404.067 355.808 404.536 356.388 404.536Z"
          fill="#EF4E05"
        />
        <path
          d="M346.79 394.333C347.307 394.333 347.729 393.911 347.729 393.394C347.729 392.877 347.312 392.455 346.79 392.455C346.268 392.455 345.852 392.872 345.852 393.394C345.852 393.916 346.268 394.333 346.79 394.333Z"
          fill="#EF4E05"
        />
        <path
          d="M348.673 382.014C349.163 382.014 349.564 381.618 349.564 381.122C349.564 380.632 349.168 380.232 348.673 380.232C348.182 380.232 347.781 380.627 347.781 381.122C347.786 381.618 348.182 382.014 348.673 382.014Z"
          fill="#EF4E05"
        />
        <path
          d="M360.87 391.859C361.434 391.859 361.893 391.401 361.893 390.836C361.893 390.272 361.434 389.813 360.87 389.813C360.306 389.813 359.847 390.272 359.847 390.836C359.852 391.406 360.306 391.859 360.87 391.859Z"
          fill="#EF4E05"
        />
        <path
          d="M375.073 395.223C375.699 395.223 376.208 394.712 376.208 394.084C376.208 393.457 375.699 392.945 375.073 392.945C374.442 392.945 373.933 393.457 373.933 394.084C373.938 394.712 374.442 395.223 375.073 395.223Z"
          fill="#EF4E05"
        />
        <path
          d="M383.721 387.155C384.356 387.155 384.88 386.639 384.88 385.995C384.88 385.352 384.361 384.835 383.721 384.835C383.076 384.835 382.558 385.352 382.558 385.995C382.558 386.639 383.081 387.155 383.721 387.155Z"
          fill="#EF4E05"
        />
        <path
          d="M392.648 379.145C393.307 379.145 393.844 378.607 393.844 377.948C393.844 377.289 393.307 376.751 392.648 376.751C391.99 376.751 391.448 377.289 391.448 377.948C391.448 378.612 391.98 379.145 392.648 379.145Z"
          fill="#EF4E05"
        />
        <path
          d="M381.226 374.626C381.226 374.03 380.74 373.545 380.142 373.545C379.549 373.545 379.063 374.03 379.063 374.626C379.063 375.222 379.549 375.707 380.142 375.707C380.74 375.707 381.226 375.222 381.226 374.626Z"
          fill="#EF4E05"
        />
        <path
          d="M371.878 382.32C371.878 381.74 371.406 381.271 370.827 381.271C370.247 381.271 369.775 381.74 369.775 382.32C369.775 382.9 370.247 383.369 370.827 383.369C371.406 383.364 371.878 382.9 371.878 382.32Z"
          fill="#EF4E05"
        />
        <path
          d="M360.659 376.023C361.176 376.023 361.598 375.601 361.598 375.084C361.598 374.568 361.182 374.146 360.659 374.146C360.137 374.146 359.721 374.568 359.721 375.084C359.726 375.607 360.143 376.023 360.659 376.023Z"
          fill="#EF4E05"
        />
        <path
          d="M371.705 365.577C371.163 365.577 370.724 366.014 370.724 366.557C370.724 367.101 371.163 367.538 371.705 367.538C372.251 367.538 372.686 367.101 372.686 366.557C372.686 366.014 372.251 365.577 371.705 365.577Z"
          fill="#EF4E05"
        />
        <path
          d="M382.459 358.126C383.025 358.126 383.487 357.667 383.487 357.097C383.487 356.528 383.029 356.069 382.459 356.069C381.889 356.069 381.432 356.528 381.432 357.097C381.432 357.667 381.889 358.126 382.459 358.126Z"
          fill="#EF4E05"
        />
        <path
          d="M387.388 366.964C387.388 367.58 387.893 368.087 388.514 368.087C389.135 368.087 389.635 367.586 389.635 366.964C389.635 366.347 389.135 365.84 388.514 365.84C387.893 365.84 387.388 366.342 387.388 366.964Z"
          fill="#EF4E05"
        />
        <path
          d="M392.855 440.967C391.958 440.967 391.233 441.691 391.233 442.583C391.233 443.48 391.962 444.204 392.855 444.204C393.752 444.204 394.471 443.476 394.471 442.583C394.476 441.686 393.752 440.967 392.855 440.967Z"
          fill="#EF4E05"
        />
        <path
          d="M389.724 430.777C388.897 430.777 388.225 431.452 388.225 432.279C388.225 433.108 388.897 433.782 389.724 433.782C390.556 433.782 391.229 433.108 391.229 432.279C391.229 431.446 390.556 430.777 389.724 430.777Z"
          fill="#EF4E05"
        />
        <path
          d="M382.002 426.542C382.768 426.542 383.389 425.921 383.389 425.155C383.389 424.389 382.768 423.768 382.002 423.768C381.236 423.768 380.614 424.389 380.614 425.155C380.614 425.921 381.236 426.542 382.002 426.542Z"
          fill="#EF4E05"
        />
        <path
          d="M389.276 415.726C389.276 414.967 388.664 414.355 387.907 414.355C387.145 414.355 386.533 414.967 386.533 415.726C386.533 416.486 387.145 417.098 387.907 417.098C388.664 417.098 389.276 416.486 389.276 415.726Z"
          fill="#EF4E05"
        />
        <path
          d="M388.449 397.95C389.145 397.95 389.715 397.386 389.715 396.684C389.715 395.988 389.15 395.419 388.449 395.419C387.748 395.419 387.183 395.983 387.183 396.684C387.188 397.381 387.753 397.95 388.449 397.95Z"
          fill="#EF4E05"
        />
        <path
          d="M381.651 406.361C382.343 406.361 382.903 405.802 382.903 405.111C382.903 404.421 382.343 403.861 381.651 403.861C380.964 403.861 380.404 404.421 380.404 405.111C380.404 405.802 380.964 406.361 381.651 406.361Z"
          fill="#EF4E05"
        />
        <path
          d="M368.775 404.705C369.406 404.705 369.92 404.194 369.92 403.56C369.92 402.928 369.406 402.416 368.775 402.416C368.14 402.416 367.631 402.928 367.631 403.56C367.631 404.194 368.14 404.705 368.775 404.705Z"
          fill="#EF4E05"
        />
        <path
          d="M375.755 415.61C376.451 415.61 377.017 415.046 377.017 414.35C377.017 413.654 376.451 413.09 375.755 413.09C375.059 413.09 374.499 413.654 374.499 414.35C374.499 415.046 375.059 415.61 375.755 415.61Z"
          fill="#EF4E05"
        />
        <path
          d="M370.065 424.881C370.775 424.881 371.345 424.312 371.345 423.605C371.345 422.898 370.775 422.329 370.065 422.329C369.36 422.329 368.79 422.898 368.79 423.605C368.79 424.312 369.36 424.881 370.065 424.881Z"
          fill="#EF4E05"
        />
        <path
          d="M375.139 435.739C375.91 435.739 376.536 435.113 376.536 434.342C376.536 433.57 375.91 432.944 375.139 432.944C374.368 432.944 373.742 433.57 373.742 434.342C373.742 435.113 374.368 435.739 375.139 435.739Z"
          fill="#EF4E05"
        />
        <path
          d="M383.207 441.397C384.039 441.397 384.712 440.724 384.712 439.892C384.712 439.064 384.039 438.391 383.207 438.391C382.38 438.391 381.708 439.064 381.708 439.892C381.708 440.724 382.38 441.397 383.207 441.397Z"
          fill="#EF4E05"
        />
        <path
          d="M377.745 447.101C377.745 446.269 377.068 445.597 376.236 445.597C375.404 445.597 374.727 446.269 374.727 447.101C374.727 447.937 375.404 448.61 376.236 448.61C377.068 448.61 377.745 447.937 377.745 447.101Z"
          fill="#EF4E05"
        />
        <path
          d="M385.632 449.773C384.744 449.773 384.024 450.493 384.024 451.38C384.024 452.263 384.749 452.988 385.632 452.988C386.524 452.988 387.243 452.263 387.243 451.38C387.243 450.493 386.524 449.773 385.632 449.773Z"
          fill="#EF4E05"
        />
        <path
          d="M378.283 460.448C379.171 460.448 379.89 459.729 379.89 458.841C379.89 457.954 379.171 457.234 378.283 457.234C377.395 457.234 376.676 457.954 376.676 458.841C376.676 459.729 377.395 460.448 378.283 460.448Z"
          fill="#EF4E05"
        />
        <path
          d="M370.957 463.845C370.065 463.845 369.346 464.569 369.346 465.461C369.346 466.349 370.065 467.073 370.957 467.073C371.85 467.073 372.574 466.349 372.574 465.461C372.574 464.569 371.85 463.845 370.957 463.845Z"
          fill="#EF4E05"
        />
        <path
          d="M361.751 463.742C362.584 463.742 363.259 463.065 363.259 462.233C363.259 461.402 362.584 460.724 361.751 460.724C360.918 460.724 360.243 461.402 360.243 462.233C360.243 463.065 360.918 463.742 361.751 463.742Z"
          fill="#EF4E05"
        />
        <path
          d="M353.577 459.505C354.363 459.505 355.001 458.869 355.001 458.085C355.001 457.295 354.363 456.66 353.577 456.66C352.791 456.66 352.153 457.295 352.153 458.085C352.153 458.869 352.791 459.505 353.577 459.505Z"
          fill="#EF4E05"
        />
        <path
          d="M351.357 467.017C350.534 467.017 349.865 467.685 349.865 468.507C349.865 469.33 350.534 469.998 351.357 469.998C352.18 469.998 352.849 469.33 352.849 468.507C352.849 467.685 352.18 467.017 351.357 467.017Z"
          fill="#EF4E05"
        />
        <path
          d="M362.12 471.18C361.224 471.18 360.501 471.909 360.501 472.801C360.501 473.698 361.229 474.417 362.12 474.417C363.017 474.417 363.739 473.693 363.739 472.801C363.739 471.909 363.017 471.18 362.12 471.18Z"
          fill="#EF4E05"
        />
        <path
          d="M354.405 477.72C353.508 477.72 352.781 478.449 352.781 479.346C352.781 480.239 353.508 480.967 354.405 480.967C355.301 480.967 356.029 480.239 356.029 479.346C356.029 478.449 355.301 477.72 354.405 477.72Z"
          fill="#EF4E05"
        />
        <path
          d="M345.35 484.298C344.454 484.298 343.726 485.023 343.726 485.92C343.726 486.817 344.454 487.545 345.35 487.545C346.247 487.545 346.974 486.817 346.974 485.92C346.974 485.023 346.247 484.298 345.35 484.298Z"
          fill="#EF4E05"
        />
        <path
          d="M346.089 476.777C346.089 475.936 345.409 475.258 344.57 475.258C343.732 475.258 343.051 475.936 343.051 476.777C343.051 477.613 343.732 478.295 344.57 478.295C345.409 478.295 346.089 477.622 346.089 476.777Z"
          fill="#EF4E05"
        />
        <path
          d="M335.869 479.005C335.869 478.188 335.21 477.529 334.392 477.529C333.575 477.529 332.916 478.188 332.916 479.005C332.916 479.823 333.575 480.481 334.392 480.481C335.204 480.491 335.869 479.823 335.869 479.005Z"
          fill="#EF4E05"
        />
        <path
          d="M338.369 467.517C337.594 467.517 336.961 468.148 336.961 468.923C336.961 469.699 337.594 470.334 338.369 470.334C339.144 470.334 339.777 469.699 339.777 468.923C339.777 468.148 339.149 467.517 338.369 467.517Z"
          fill="#EF4E05"
        />
        <path
          d="M345.24 459.346C345.24 458.594 344.628 457.982 343.874 457.982C343.12 457.982 342.509 458.594 342.509 459.346C342.509 460.103 343.12 460.715 343.874 460.715C344.628 460.715 345.24 460.103 345.24 459.346Z"
          fill="#EF4E05"
        />
        <path
          d="M333.865 460.453C334.577 460.453 335.157 459.874 335.157 459.164C335.157 458.454 334.577 457.87 333.865 457.87C333.153 457.87 332.573 458.454 332.573 459.164C332.573 459.874 333.147 460.453 333.865 460.453Z"
          fill="#EF4E05"
        />
        <path
          d="M326.435 468.237C327.168 468.237 327.764 467.643 327.764 466.91C327.764 466.176 327.168 465.583 326.435 465.583C325.702 465.583 325.106 466.176 325.106 466.91C325.106 467.643 325.702 468.237 326.435 468.237Z"
          fill="#EF4E05"
        />
        <path
          d="M323.831 477.417C324.599 477.417 325.222 476.791 325.222 476.025C325.222 475.254 324.599 474.632 323.831 474.632C323.062 474.632 322.438 475.254 322.438 476.025C322.438 476.791 323.062 477.417 323.831 477.417Z"
          fill="#EF4E05"
        />
        <path
          d="M327.843 486.032C327.843 485.205 327.173 484.532 326.345 484.532C325.517 484.532 324.848 485.205 324.848 486.032C324.848 486.859 325.517 487.527 326.345 487.527C327.173 487.527 327.843 486.859 327.843 486.032Z"
          fill="#EF4E05"
        />
        <path
          d="M336.465 488.522C335.584 488.522 334.867 489.237 334.867 490.12C334.867 490.998 335.584 491.717 336.465 491.717C337.345 491.717 338.062 490.998 338.062 490.12C338.062 489.237 337.345 488.522 336.465 488.522Z"
          fill="#EF4E05"
        />
        <path
          d="M328.65 494.67C327.753 494.67 327.031 495.399 327.031 496.286C327.031 497.183 327.759 497.908 328.65 497.908C329.541 497.908 330.268 497.179 330.268 496.286C330.268 495.399 329.541 494.67 328.65 494.67Z"
          fill="#EF4E05"
        />
        <path
          d="M319.986 499.155C319.094 499.155 318.372 499.879 318.372 500.771C318.372 501.659 319.094 502.383 319.986 502.383C320.877 502.383 321.599 501.659 321.599 500.771C321.599 499.879 320.882 499.155 319.986 499.155Z"
          fill="#EF4E05"
        />
        <path
          d="M311.928 503.495C311.032 503.495 310.304 504.224 310.304 505.121C310.304 506.018 311.032 506.747 311.928 506.747C312.824 506.747 313.552 506.018 313.552 505.121C313.552 504.224 312.824 503.495 311.928 503.495Z"
          fill="#EF4E05"
        />
        <path
          d="M303.227 508.966C304.11 508.966 304.825 508.251 304.825 507.368C304.825 506.485 304.11 505.77 303.227 505.77C302.345 505.77 301.629 506.485 301.629 507.368C301.629 508.251 302.345 508.966 303.227 508.966Z"
          fill="#EF4E05"
        />
        <path
          d="M296.261 511.316C295.364 511.316 294.642 512.045 294.642 512.937C294.642 513.834 295.37 514.554 296.261 514.554C297.152 514.554 297.88 513.825 297.88 512.937C297.885 512.045 297.157 511.316 296.261 511.316Z"
          fill="#EF4E05"
        />
        <path
          d="M287.19 512.418C286.31 512.418 285.598 513.129 285.598 514.012C285.598 514.89 286.31 515.605 287.19 515.605C288.071 515.605 288.783 514.89 288.783 514.012C288.783 513.129 288.071 512.418 287.19 512.418Z"
          fill="#EF4E05"
        />
        <path
          d="M279.724 516.539C278.832 516.539 278.11 517.259 278.11 518.151C278.11 519.043 278.832 519.763 279.724 519.763C280.615 519.763 281.337 519.043 281.337 518.151C281.337 517.259 280.615 516.539 279.724 516.539Z"
          fill="#EF4E05"
        />
        <path
          d="M274.914 508.821C274.08 508.821 273.406 509.499 273.406 510.33C273.406 511.162 274.08 511.839 274.914 511.839C275.747 511.839 276.422 511.162 276.422 510.33C276.422 509.499 275.747 508.821 274.914 508.821Z"
          fill="#EF4E05"
        />
        <path
          d="M270.796 519.618C269.899 519.618 269.177 520.347 269.177 521.234C269.177 522.131 269.905 522.855 270.796 522.855C271.687 522.855 272.415 522.127 272.415 521.234C272.415 520.347 271.687 519.618 270.796 519.618Z"
          fill="#EF4E05"
        />
        <path
          d="M261.288 522.528C260.391 522.528 259.669 523.257 259.669 524.145C259.669 525.037 260.396 525.766 261.288 525.766C262.184 525.766 262.907 525.037 262.907 524.145C262.907 523.257 262.179 522.528 261.288 522.528Z"
          fill="#EF4E05"
        />
        <path
          d="M264.257 512.708C263.418 512.708 262.743 513.39 262.743 514.222C262.743 515.053 263.423 515.735 264.257 515.735C265.095 515.735 265.77 515.053 265.77 514.222C265.77 513.39 265.095 512.708 264.257 512.708Z"
          fill="#EF4E05"
        />
        <path
          d="M254.406 514.511C253.573 514.511 252.897 515.189 252.897 516.02C252.897 516.852 253.573 517.53 254.406 517.53C255.239 517.53 255.914 516.852 255.914 516.02C255.914 515.189 255.239 514.511 254.406 514.511Z"
          fill="#EF4E05"
        />
        <path
          d="M244.977 516.198C244.149 516.198 243.479 516.871 243.479 517.698C243.479 518.525 244.149 519.197 244.977 519.197C245.805 519.197 246.475 518.525 246.475 517.698C246.475 516.871 245.805 516.198 244.977 516.198Z"
          fill="#EF4E05"
        />
        <path
          d="M251.611 524.687C250.72 524.687 249.997 525.406 249.997 526.299C249.997 527.191 250.72 527.91 251.611 527.91C252.502 527.91 253.224 527.191 253.224 526.299C253.224 525.406 252.502 524.687 251.611 524.687Z"
          fill="#EF4E05"
        />
        <path
          d="M241.882 526.252C240.985 526.252 240.263 526.981 240.263 527.868C240.263 528.761 240.991 529.49 241.882 529.49C242.779 529.49 243.501 528.761 243.501 527.868C243.501 526.981 242.773 526.252 241.882 526.252Z"
          fill="#EF4E05"
        />
        <path
          d="M232.264 527.532C231.372 527.532 230.65 528.256 230.65 529.148C230.65 530.036 231.372 530.76 232.264 530.76C233.154 530.76 233.877 530.036 233.877 529.148C233.877 528.256 233.154 527.532 232.264 527.532Z"
          fill="#EF4E05"
        />
        <path
          d="M222.908 528.522C222.017 528.522 221.294 529.247 221.294 530.139C221.294 531.027 222.017 531.751 222.908 531.751C223.799 531.751 224.522 531.027 224.522 530.139C224.522 529.247 223.799 528.522 222.908 528.522Z"
          fill="#EF4E05"
        />
        <path
          d="M213.474 529.448C212.578 529.448 211.855 530.176 211.855 531.064C211.855 531.956 212.583 532.685 213.474 532.685C214.371 532.685 215.094 531.956 215.094 531.064C215.094 530.176 214.366 529.448 213.474 529.448Z"
          fill="#EF4E05"
        />
        <path
          d="M203.876 529.919C202.985 529.919 202.263 530.643 202.263 531.536C202.263 532.428 202.985 533.148 203.876 533.148C204.768 533.148 205.49 532.428 205.49 531.536C205.49 530.643 204.768 529.919 203.876 529.919Z"
          fill="#EF4E05"
        />
        <path
          d="M205.944 519.884C205.116 519.884 204.446 520.557 204.446 521.384C204.446 522.211 205.116 522.884 205.944 522.884C206.771 522.884 207.441 522.211 207.441 521.384C207.441 520.557 206.771 519.884 205.944 519.884Z"
          fill="#EF4E05"
        />
        <path
          d="M198.82 511.292C198.05 511.292 197.422 511.919 197.422 512.689C197.422 513.46 198.05 514.086 198.82 514.086C199.59 514.086 200.217 513.46 200.217 512.689C200.217 511.919 199.59 511.292 198.82 511.292Z"
          fill="#EF4E05"
        />
        <path
          d="M187.972 515.296C187.17 515.296 186.522 515.946 186.522 516.749C186.522 517.548 187.17 518.197 187.972 518.197C188.774 518.197 189.422 517.548 189.422 516.749C189.422 515.946 188.774 515.296 187.972 515.296Z"
          fill="#EF4E05"
        />
        <path
          d="M195.577 521.501C194.738 521.501 194.063 522.178 194.063 523.014C194.063 523.846 194.743 524.528 195.577 524.528C196.415 524.528 197.09 523.846 197.09 523.014C197.09 522.178 196.41 521.501 195.577 521.501Z"
          fill="#EF4E05"
        />
        <path
          d="M194.474 533.699C195.365 533.699 196.088 532.975 196.088 532.082C196.088 531.19 195.365 530.471 194.474 530.471C193.583 530.471 192.86 531.19 192.86 532.082C192.86 532.975 193.583 533.699 194.474 533.699Z"
          fill="#EF4E05"
        />
        <path
          d="M185.947 531.078C186.824 531.078 187.534 530.368 187.534 529.49C187.534 528.611 186.824 527.901 185.947 527.901C185.07 527.901 184.36 528.611 184.36 529.49C184.36 530.368 185.07 531.078 185.947 531.078Z"
          fill="#EF4E05"
        />
        <path
          d="M177.056 529.742C176.16 529.742 175.438 530.471 175.438 531.363C175.438 532.251 176.165 532.979 177.056 532.979C177.953 532.979 178.675 532.251 178.675 531.363C178.675 530.471 177.947 529.742 177.056 529.742Z"
          fill="#EF4E05"
        />
        <path
          d="M168.946 526.327C168.065 526.327 167.353 527.037 167.353 527.915C167.353 528.798 168.065 529.508 168.946 529.508C169.826 529.508 170.538 528.798 170.538 527.915C170.538 527.037 169.826 526.327 168.946 526.327Z"
          fill="#EF4E05"
        />
        <path
          d="M160.493 527.892C159.596 527.892 158.874 528.621 158.874 529.508C158.874 530.405 159.601 531.129 160.493 531.129C161.383 531.129 162.111 530.401 162.111 529.508C162.111 528.621 161.383 527.892 160.493 527.892Z"
          fill="#EF4E05"
        />
        <path
          d="M151.591 526.345C150.695 526.345 149.972 527.074 149.972 527.966C149.972 528.854 150.7 529.583 151.591 529.583C152.482 529.583 153.21 528.854 153.21 527.966C153.21 527.074 152.488 526.345 151.591 526.345Z"
          fill="#EF4E05"
        />
        <path
          d="M142.653 527.495C143.544 527.495 144.266 526.775 144.266 525.883C144.266 524.99 143.544 524.266 142.653 524.266C141.762 524.266 141.039 524.99 141.039 525.883C141.039 526.775 141.762 527.495 142.653 527.495Z"
          fill="#EF4E05"
        />
        <path
          d="M143.449 514.142C143.449 513.325 142.79 512.666 141.972 512.666C141.155 512.666 140.496 513.325 140.496 514.142C140.496 514.96 141.155 515.619 141.972 515.619C142.79 515.619 143.449 514.96 143.449 514.142Z"
          fill="#EF4E05"
        />
        <path
          d="M133.994 521.575C133.113 521.575 132.396 522.29 132.396 523.173C132.396 524.051 133.113 524.771 133.994 524.771C134.874 524.771 135.591 524.051 135.591 523.173C135.591 522.29 134.88 521.575 133.994 521.575Z"
          fill="#EF4E05"
        />
        <path
          d="M125.187 523.678C126.079 523.678 126.801 522.954 126.801 522.066C126.801 521.174 126.079 520.449 125.187 520.449C124.296 520.449 123.574 521.174 123.574 522.066C123.574 522.954 124.296 523.678 125.187 523.678Z"
          fill="#EF4E05"
        />
        <path
          d="M133.857 512.759C133.857 511.937 133.187 511.269 132.364 511.269C131.542 511.269 130.872 511.937 130.872 512.759C130.872 513.586 131.542 514.254 132.364 514.254C133.187 514.254 133.857 513.586 133.857 512.759Z"
          fill="#EF4E05"
        />
        <path
          d="M124.302 512.372C124.302 511.526 123.621 510.849 122.778 510.849C121.934 510.849 121.254 511.526 121.254 512.372C121.254 513.217 121.934 513.895 122.778 513.895C123.621 513.895 124.302 513.217 124.302 512.372Z"
          fill="#EF4E05"
        />
        <path
          d="M116.861 506.513C116.861 505.7 116.201 505.042 115.389 505.042C114.577 505.042 113.918 505.7 113.918 506.513C113.918 507.326 114.577 507.985 115.389 507.985C116.201 507.985 116.861 507.326 116.861 506.513Z"
          fill="#EF4E05"
        />
        <path
          d="M115.985 517.455C115.089 517.455 114.366 518.184 114.366 519.076C114.366 519.963 115.094 520.692 115.985 520.692C116.882 520.692 117.604 519.963 117.604 519.076C117.604 518.184 116.882 517.455 115.985 517.455Z"
          fill="#EF4E05"
        />
        <path
          d="M107.701 514.427C106.809 514.427 106.087 515.151 106.087 516.044C106.087 516.931 106.809 517.656 107.701 517.656C108.592 517.656 109.314 516.931 109.314 516.044C109.314 515.151 108.592 514.427 107.701 514.427Z"
          fill="#EF4E05"
        />
        <path
          d="M99.3532 511.213C98.4618 511.213 97.7395 511.933 97.7395 512.825C97.7395 513.717 98.4618 514.437 99.3532 514.437C100.244 514.437 100.967 513.717 100.967 512.825C100.972 511.933 100.25 511.213 99.3532 511.213Z"
          fill="#EF4E05"
        />
        <path
          d="M90.5626 508.279C89.6661 508.279 88.9434 509.008 88.9434 509.9C88.9434 510.788 89.6712 511.517 90.5626 511.517C91.4536 511.517 92.1814 510.788 92.1814 509.9C92.1814 509.008 91.4592 508.279 90.5626 508.279Z"
          fill="#EF4E05"
        />
        <path
          d="M83.2802 504.14C82.3836 504.14 81.6609 504.869 81.6609 505.761C81.6609 506.653 82.3888 507.377 83.2802 507.377C84.1762 507.377 84.899 506.649 84.899 505.761C84.9041 504.864 84.1762 504.14 83.2802 504.14Z"
          fill="#EF4E05"
        />
        <path
          d="M76.7833 499.356C75.9027 499.356 75.1907 500.071 75.1907 500.949C75.1907 501.832 75.9027 502.542 76.7833 502.542C77.664 502.542 78.3755 501.832 78.3755 500.949C78.3755 500.071 77.664 499.356 76.7833 499.356Z"
          fill="#EF4E05"
        />
        <path
          d="M68.7253 497.66C67.8287 497.66 67.1013 498.389 67.1013 499.281C67.1013 500.178 67.8287 500.907 68.7253 500.907C69.6218 500.907 70.3497 500.178 70.3497 499.281C70.3497 498.389 69.6269 497.66 68.7253 497.66Z"
          fill="#EF4E05"
        />
        <path
          d="M61.4638 492.549C60.5728 492.549 59.8501 493.273 59.8501 494.161C59.8501 495.053 60.5728 495.777 61.4638 495.777C62.3552 495.777 63.0774 495.053 63.0774 494.161C63.0774 493.273 62.3603 492.549 61.4638 492.549Z"
          fill="#EF4E05"
        />
        <path
          d="M64.5174 483.495C63.6839 483.495 63.0093 484.168 63.0093 485.004C63.0093 485.835 63.6839 486.513 64.5174 486.513C65.3504 486.513 66.0255 485.835 66.0255 485.004C66.0255 484.168 65.3504 483.495 64.5174 483.495Z"
          fill="#EF4E05"
        />
        <path
          d="M57.1821 477.057C56.3594 477.057 55.6899 477.725 55.6899 478.547C55.6899 479.37 56.3594 480.042 57.1821 480.042C58.0049 480.042 58.6743 479.37 58.6743 478.547C58.6743 477.725 58.0049 477.057 57.1821 477.057Z"
          fill="#EF4E05"
        />
        <path
          d="M54.0336 487.242C53.1427 487.242 52.4255 487.966 52.4255 488.854C52.4255 489.737 53.1478 490.461 54.0336 490.461C54.925 490.461 55.6421 489.737 55.6421 488.854C55.6421 487.966 54.925 487.242 54.0336 487.242Z"
          fill="#EF4E05"
        />
        <path
          d="M48.5917 480.505C47.711 480.505 46.999 481.215 46.999 482.098C46.999 482.976 47.711 483.691 48.5917 483.691C49.4723 483.691 50.1838 482.976 50.1838 482.098C50.1894 481.215 49.4723 480.505 48.5917 480.505Z"
          fill="#EF4E05"
        />
        <path
          d="M40.4496 480C39.5531 480 38.8308 480.724 38.8308 481.617C38.8308 482.514 39.5582 483.238 40.4496 483.238C41.3405 483.238 42.0684 482.509 42.0684 481.617C42.0684 480.724 41.3461 480 40.4496 480Z"
          fill="#EF4E05"
        />
        <path
          d="M34.2585 473.226C33.3624 473.226 32.6396 473.955 32.6396 474.847C32.6396 475.744 33.3675 476.464 34.2585 476.464C35.155 476.464 35.8777 475.74 35.8777 474.847C35.8777 473.955 35.155 473.226 34.2585 473.226Z"
          fill="#EF4E05"
        />
        <path
          d="M27.3239 468.601C26.4273 468.601 25.7051 469.33 25.7051 470.222C25.7051 471.119 26.433 471.839 27.3239 471.839C28.2204 471.839 28.9427 471.114 28.9427 470.222C28.9427 469.33 28.2204 468.601 27.3239 468.601Z"
          fill="#EF4E05"
        />
        <path
          d="M36.2678 462.789C35.44 462.789 34.7649 463.467 34.7649 464.293C34.7649 465.12 35.44 465.798 36.2678 465.798C37.0957 465.798 37.7708 465.12 37.7708 464.293C37.7708 463.467 37.0957 462.789 36.2678 462.789Z"
          fill="#EF4E05"
        />
        <path
          d="M28.1676 461.476C28.1676 460.612 27.4664 459.916 26.6068 459.916C25.742 459.916 25.0459 460.617 25.0459 461.476C25.0459 462.341 25.7471 463.037 26.6068 463.037C27.4664 463.037 28.1676 462.336 28.1676 461.476Z"
          fill="#EF4E05"
        />
        <path
          d="M18.5915 462.948C17.6898 462.948 16.9619 463.677 16.9619 464.578C16.9619 465.48 17.6898 466.209 18.5915 466.209C19.4931 466.209 20.221 465.48 20.221 464.578C20.221 463.681 19.488 462.948 18.5915 462.948Z"
          fill="#EF4E05"
        />
        <path
          d="M13.5555 457.183C14.4409 457.183 15.1585 456.463 15.1585 455.576C15.1585 454.693 14.4409 453.973 13.5555 453.973C12.6702 453.973 11.9521 454.693 11.9521 455.576C11.9521 456.463 12.6702 457.183 13.5555 457.183Z"
          fill="#EF4E05"
        />
        <path
          d="M6.63151 453.492C7.53131 453.492 8.26105 452.763 8.26105 451.866C8.26105 450.965 7.53131 450.236 6.63151 450.236C5.7317 450.236 5.00195 450.965 5.00195 451.866C5.00195 452.763 5.7317 453.492 6.63151 453.492Z"
          fill="#EF4E05"
        />
        <path
          d="M22.1351 451.081C21.2969 451.081 20.6167 451.759 20.6167 452.6C20.6167 453.436 21.2969 454.118 22.1351 454.118C22.9737 454.118 23.6539 453.436 23.6539 452.6C23.6539 451.759 22.9737 451.081 22.1351 451.081Z"
          fill="#EF4E05"
        />
        <path
          d="M18.5073 445.34C19.3192 445.34 19.9785 444.681 19.9785 443.873C19.9785 443.06 19.3192 442.401 18.5073 442.401C17.6948 442.401 17.0356 443.06 17.0356 443.873C17.0356 444.681 17.6948 445.34 18.5073 445.34Z"
          fill="#EF4E05"
        />
        <path
          d="M8.86211 440.121C8.01323 440.121 7.32227 440.813 7.32227 441.663C7.32227 442.509 8.01323 443.2 8.86211 443.2C9.71099 443.2 10.402 442.509 10.402 441.663C10.4071 440.813 9.71613 440.121 8.86211 440.121Z"
          fill="#EF4E05"
        />
        <path
          d="M-0.139477 442.555C-1.04115 442.555 -1.77417 443.289 -1.77417 444.19C-1.77417 445.092 -1.04115 445.826 -0.139477 445.826C0.762195 445.826 1.49521 445.092 1.49521 444.19C1.49521 443.289 0.762195 442.555 -0.139477 442.555Z"
          fill="#EF4E05"
        />
        <path
          d="M2.84526 376.155C2.84526 375.496 2.31267 374.963 1.65347 374.963C0.994268 374.963 0.46167 375.496 0.46167 376.155C0.46167 376.814 0.994268 377.347 1.65347 377.347C2.31267 377.347 2.84526 376.809 2.84526 376.155Z"
          fill="#EF4E05"
        />
        <path
          d="M2.94014 388.173C3.62177 388.173 4.17398 387.621 4.17398 386.939C4.17398 386.258 3.62177 385.706 2.94014 385.706C2.25852 385.706 1.7063 386.258 1.7063 386.939C1.7063 387.621 2.25852 388.173 2.94014 388.173Z"
          fill="#EF4E05"
        />
        <path
          d="M3.43066 396.838C3.43066 397.534 3.99456 398.103 4.69627 398.103C5.39238 398.103 5.96189 397.539 5.96189 396.838C5.96189 396.142 5.39752 395.572 4.69627 395.572C4.00017 395.572 3.43066 396.136 3.43066 396.838Z"
          fill="#EF4E05"
        />
        <path
          d="M10.3542 406.303C11.0555 406.303 11.625 405.733 11.625 405.032C11.625 404.331 11.0555 403.761 10.3542 403.761C9.653 403.761 9.0835 404.331 9.0835 405.032C9.0835 405.733 9.653 406.303 10.3542 406.303Z"
          fill="#EF4E05"
        />
        <path
          d="M5.59287 416.022C6.35205 416.022 6.9636 415.41 6.9636 414.651C6.9636 413.891 6.35205 413.28 5.59287 413.28C4.83323 413.28 4.22168 413.891 4.22168 414.651C4.22168 415.41 4.83837 416.022 5.59287 416.022Z"
          fill="#EF4E05"
        />
        <path
          d="M12.4478 423.341C12.4478 422.576 11.8255 421.954 11.0611 421.954C10.2964 421.954 9.67407 422.576 9.67407 423.341C9.67407 424.106 10.2964 424.728 11.0611 424.728C11.8255 424.728 12.4478 424.111 12.4478 423.341Z"
          fill="#EF4E05"
        />
        <path
          d="M6.37842 432.322C6.37842 431.499 5.70847 430.829 4.88575 430.829C4.06303 430.829 3.39355 431.499 3.39355 432.322C3.39355 433.145 4.06303 433.814 4.88575 433.814C5.70847 433.814 6.37842 433.145 6.37842 432.322Z"
          fill="#EF4E05"
        />
        <path
          d="M15.8757 431.536C15.1006 431.536 14.468 432.169 14.468 432.944C14.468 433.719 15.1006 434.352 15.8757 434.352C16.6512 434.352 17.2838 433.719 17.2838 432.944C17.2838 432.169 16.6512 431.536 15.8757 431.536Z"
          fill="#EF4E05"
        />
        <path
          d="M22.9578 422.434C22.2458 422.434 21.6711 423.009 21.6711 423.721C21.6711 424.433 22.2458 425.007 22.9578 425.007C23.6698 425.007 24.2444 424.433 24.2444 423.721C24.2444 423.009 23.6698 422.434 22.9578 422.434Z"
          fill="#EF4E05"
        />
        <path
          d="M26.2276 435.929C26.9672 435.929 27.567 435.329 27.567 434.589C27.567 433.85 26.9672 433.25 26.2276 433.25C25.4876 433.25 24.8882 433.85 24.8882 434.589C24.8882 435.329 25.4876 435.929 26.2276 435.929Z"
          fill="#EF4E05"
        />
        <path
          d="M31.8379 444.368C31.8379 443.606 31.2212 442.99 30.4615 442.99C29.7024 442.99 29.0852 443.606 29.0852 444.368C29.0852 445.125 29.7024 445.741 30.4615 445.741C31.2212 445.741 31.8379 445.125 31.8379 444.368Z"
          fill="#EF4E05"
        />
        <path
          d="M31.5112 452.847C30.71 452.847 30.061 453.492 30.061 454.296C30.061 455.099 30.71 455.744 31.5112 455.744C32.3129 455.744 32.9613 455.099 32.9613 454.296C32.9613 453.492 32.3129 452.847 31.5112 452.847Z"
          fill="#EF4E05"
        />
        <path
          d="M39.9701 454.497C40.7293 454.497 41.3464 453.88 41.3464 453.118C41.3464 452.361 40.7293 451.745 39.9701 451.745C39.2104 451.745 38.5938 452.361 38.5938 453.118C38.5938 453.885 39.2104 454.497 39.9701 454.497Z"
          fill="#EF4E05"
        />
        <path
          d="M49.8628 451.955C50.5795 451.955 51.1602 451.376 51.1602 450.656C51.1602 449.941 50.5795 449.362 49.8628 449.362C49.1461 449.362 48.5654 449.941 48.5654 450.656C48.5654 451.376 49.1461 451.955 49.8628 451.955Z"
          fill="#EF4E05"
        />
        <path
          d="M55.8267 460.888C56.5597 460.888 57.1559 460.29 57.1559 459.561C57.1559 458.827 56.5597 458.229 55.8267 458.229C55.0937 458.229 54.498 458.827 54.498 459.561C54.498 460.29 55.0937 460.888 55.8267 460.888Z"
          fill="#EF4E05"
        />
        <path
          d="M60.2559 468.003C60.2559 468.75 60.8627 469.362 61.6168 469.362C62.3708 469.362 62.9772 468.755 62.9772 468.003C62.9772 467.246 62.3708 466.639 61.6168 466.639C60.8627 466.639 60.2559 467.251 60.2559 468.003Z"
          fill="#EF4E05"
        />
        <path
          d="M51.893 472.03C52.6966 472.03 53.3483 471.381 53.3483 470.572C53.3483 469.769 52.6966 469.12 51.893 469.12C51.089 469.12 50.4373 469.769 50.4373 470.572C50.4373 471.381 51.089 472.03 51.893 472.03Z"
          fill="#EF4E05"
        />
        <path
          d="M45.8493 460.369C45.0691 460.369 44.436 461.004 44.436 461.785C44.436 462.565 45.0691 463.196 45.8493 463.196C46.63 463.196 47.2625 462.565 47.2625 461.785C47.2625 461.004 46.63 460.369 45.8493 460.369Z"
          fill="#EF4E05"
        />
        <path
          d="M43.2283 471.1C42.3795 471.1 41.6941 471.787 41.6941 472.637C41.6941 473.488 42.3795 474.17 43.2283 474.17C44.0777 474.17 44.763 473.488 44.763 472.637C44.763 471.787 44.0777 471.1 43.2283 471.1Z"
          fill="#EF4E05"
        />
        <path
          d="M197 570.125C362.133 570.125 496 436.259 496 271.125C496 105.992 362.133 -27.8745 197 -27.8745C31.8669 -27.8745 -102 105.992 -102 271.125C-102 436.259 31.8669 570.125 197 570.125Z"
          fill="url(#paint0_radial_84_37)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_84_37"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(197 271.125) rotate(90) scale(299)"
        >
          <stop stop-color="#EF4E05" stop-opacity="0" />
          <stop offset="0.5" stop-color="#0C0400" stop-opacity="0" />
          <stop offset="0.847247" />
        </radialGradient>
        <clipPath id="clip0_84_37">
          <rect
            width="394"
            height="555"
            fill="white"
            transform="translate(-2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSmall;
