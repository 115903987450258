import React from 'react';

const Round = () => {
  return (
    <svg
      width="1076"
      height="991"
      viewBox="0 0 1076 991"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <g clip-path="url(#clip0_6_2259)">
          <path
            d="M836.642 114.487C836.642 115.996 837.866 117.211 839.366 117.211C840.869 117.211 842.088 115.987 842.088 114.487C842.088 112.988 840.869 111.764 839.366 111.764C837.866 111.764 836.642 112.988 836.642 114.487Z"
            fill="#EF4E05"
          />
          <path
            d="M830.788 127.23C829.393 127.23 828.254 128.369 828.254 129.764C828.254 131.158 829.393 132.297 830.788 132.297C832.183 132.297 833.322 131.158 833.322 129.764C833.322 128.359 832.192 127.23 830.788 127.23Z"
            fill="#EF4E05"
          />
          <path
            d="M812.741 130.058C814.079 130.058 815.161 128.976 815.161 127.639C815.161 126.3 814.079 125.218 812.741 125.218C811.403 125.218 810.321 126.3 810.321 127.639C810.321 128.976 811.403 130.058 812.741 130.058Z"
            fill="#EF4E05"
          />
          <path
            d="M816.109 109.961C816.109 111.404 817.277 112.57 818.718 112.57C820.161 112.57 821.327 111.404 821.327 109.961C821.327 108.519 820.161 107.352 818.718 107.352C817.277 107.352 816.109 108.519 816.109 109.961Z"
            fill="#EF4E05"
          />
          <path
            d="M812.902 90.7562C812.902 92.2836 814.145 93.5269 815.673 93.5269C817.2 93.5269 818.443 92.2836 818.443 90.7562C818.443 89.2288 817.2 87.9855 815.673 87.9855C814.145 87.9855 812.902 89.2288 812.902 90.7562Z"
            fill="#EF4E05"
          />
          <path
            d="M834.763 99.5231C836.341 99.5231 837.62 98.2445 837.62 96.6675C837.62 95.0905 836.341 93.811 834.763 93.811C833.186 93.811 831.908 95.0905 831.908 96.6675C831.908 98.2445 833.186 99.5231 834.763 99.5231Z"
            fill="#EF4E05"
          />
          <path
            d="M829.166 83.4503C830.722 83.4503 831.983 82.1877 831.983 80.6317C831.983 79.0757 830.722 77.8139 829.166 77.8139C827.61 77.8139 826.347 79.0757 826.347 80.6317C826.338 82.1877 827.61 83.4503 829.166 83.4503Z"
            fill="#EF4E05"
          />
          <path
            d="M813.756 75.4039C815.369 75.4039 816.679 74.0942 816.679 72.481C816.679 70.8678 815.369 69.559 813.756 69.559C812.143 69.559 810.833 70.8678 810.833 72.481C810.833 74.1034 812.143 75.4039 813.756 75.4039Z"
            fill="#EF4E05"
          />
          <path
            d="M798.024 83.4117C799.533 83.4117 800.757 82.1877 800.757 80.6796C800.757 79.1707 799.533 77.9467 798.024 77.9467C796.516 77.9467 795.291 79.1707 795.291 80.6796C795.291 82.1877 796.516 83.4117 798.024 83.4117Z"
            fill="#EF4E05"
          />
          <path
            d="M798.565 63.4006C800.184 63.4006 801.496 62.0884 801.496 60.4685C801.496 58.8494 800.184 57.5372 798.565 57.5372C796.945 57.5372 795.633 58.8494 795.633 60.4685C795.633 62.0884 796.945 63.4006 798.565 63.4006Z"
            fill="#EF4E05"
          />
          <path
            d="M782.643 54.168C784.247 54.168 785.546 52.8684 785.546 51.2645C785.546 49.6614 784.247 48.3618 782.643 48.3618C781.04 48.3618 779.739 49.6614 779.739 51.2645C779.739 52.8684 781.04 54.168 782.643 54.168Z"
            fill="#EF4E05"
          />
          <path
            d="M781.837 73.2115C783.346 73.2115 784.57 71.9884 784.57 70.4795C784.57 68.9697 783.346 67.7466 781.837 67.7466C780.328 67.7466 779.104 68.9697 779.104 70.4795C779.104 71.9884 780.328 73.2115 781.837 73.2115Z"
            fill="#EF4E05"
          />
          <path
            d="M765.08 83.1275C766.484 83.1275 767.622 81.9885 767.622 80.5846C767.622 79.1799 766.484 78.0417 765.08 78.0417C763.676 78.0417 762.537 79.1799 762.537 80.5846C762.537 81.9885 763.676 83.1275 765.08 83.1275Z"
            fill="#EF4E05"
          />
          <path
            d="M747.982 73.7621C749.376 73.7621 750.506 72.6332 750.506 71.2386C750.506 69.8431 749.376 68.7142 747.982 68.7142C746.587 68.7142 745.458 69.8431 745.458 71.2386C745.458 72.6332 746.587 73.7621 747.982 73.7621Z"
            fill="#EF4E05"
          />
          <path
            d="M730.798 60.0322C729.403 60.0322 728.274 61.162 728.274 62.5566C728.274 63.9512 729.403 65.0801 730.798 65.0801C732.193 65.0801 733.322 63.9512 733.322 62.5566C733.322 61.162 732.193 60.0322 730.798 60.0322Z"
            fill="#EF4E05"
          />
          <path
            d="M730.921 46.0745C732.411 46.0745 733.625 44.8597 733.625 43.3702C733.625 41.8806 732.411 40.6659 730.921 40.6659C729.431 40.6659 728.217 41.8806 728.217 43.3702C728.217 44.8597 729.422 46.0745 730.921 46.0745Z"
            fill="#EF4E05"
          />
          <path
            d="M748.247 54.3386C749.747 54.3386 750.961 53.1248 750.961 51.6251C750.961 50.1263 749.747 48.9116 748.247 48.9116C746.748 48.9116 745.533 50.1263 745.533 51.6251C745.533 53.1248 746.748 54.3386 748.247 54.3386Z"
            fill="#EF4E05"
          />
          <path
            d="M767.888 60.3079C767.888 58.799 766.664 57.575 765.155 57.575C763.647 57.575 762.423 58.799 762.423 60.3079C762.423 61.816 763.647 63.0399 765.155 63.0399C766.664 63.0399 767.888 61.816 767.888 60.3079Z"
            fill="#EF4E05"
          />
          <path
            d="M765.175 43.6837C766.794 43.6837 768.107 42.3707 768.107 40.7516C768.107 39.1326 766.794 37.8195 765.175 37.8195C763.556 37.8195 762.243 39.1326 762.243 40.7516C762.243 42.3707 763.556 43.6837 765.175 43.6837Z"
            fill="#EF4E05"
          />
          <path
            d="M748.342 35.533C749.955 35.533 751.265 34.2233 751.265 32.6102C751.265 30.9978 749.955 29.6882 748.342 29.6882C746.729 29.6882 745.42 30.9978 745.42 32.6102C745.42 34.2233 746.729 35.533 748.342 35.533Z"
            fill="#EF4E05"
          />
          <path
            d="M732.003 27.7236C733.616 27.7236 734.925 26.4148 734.925 24.8016C734.925 23.1884 733.616 21.8787 732.003 21.8787C730.39 21.8787 729.08 23.1884 729.08 24.8016C729.08 26.4148 730.381 27.7236 732.003 27.7236Z"
            fill="#EF4E05"
          />
          <path
            d="M716.375 20.7305C717.988 20.7305 719.288 19.4216 719.288 17.8177C719.288 16.2045 717.979 14.9049 716.375 14.9049C714.762 14.9049 713.462 16.2146 713.462 17.8177C713.462 19.4216 714.762 20.7305 716.375 20.7305Z"
            fill="#EF4E05"
          />
          <path
            d="M701.279 13.6616C702.901 13.6616 704.22 12.3427 704.22 10.7203C704.22 9.09788 702.901 7.77895 701.279 7.77895C699.656 7.77895 698.337 9.09788 698.337 10.7203C698.328 12.3427 699.647 13.6616 701.279 13.6616Z"
            fill="#EF4E05"
          />
          <path
            d="M713.643 37.9053C715.141 37.9053 716.356 36.6906 716.356 35.1909C716.356 33.692 715.141 32.4773 713.643 32.4773C712.143 32.4773 710.929 33.692 710.929 35.1909C710.919 36.6906 712.143 37.9053 713.643 37.9053Z"
            fill="#EF4E05"
          />
          <path
            d="M713.092 56.4646C714.488 56.4646 715.617 55.3356 715.617 53.941C715.617 52.5456 714.488 51.4166 713.092 51.4166C711.698 51.4166 710.569 52.5456 710.569 53.941C710.569 55.3356 711.698 56.4646 713.092 56.4646Z"
            fill="#EF4E05"
          />
          <path
            d="M697.512 62.6608C697.512 61.3511 696.449 60.2886 695.14 60.2886C693.83 60.2886 692.767 61.3511 692.767 62.6608C692.767 63.9705 693.83 65.0331 695.14 65.0331C696.449 65.0331 697.512 63.9705 697.512 62.6608Z"
            fill="#EF4E05"
          />
          <path
            d="M678.336 70.7636C677.121 70.7636 676.134 71.7505 676.134 72.9652C676.134 74.1799 677.121 75.1668 678.336 75.1668C679.55 75.1668 680.537 74.1799 680.537 72.9652C680.537 71.7505 679.55 70.7636 678.336 70.7636Z"
            fill="#EF4E05"
          />
          <path
            d="M677.339 52.897C678.677 52.897 679.759 51.8151 679.759 50.4768C679.759 49.1394 678.677 48.0575 677.339 48.0575C676.001 48.0575 674.92 49.1394 674.92 50.4768C674.92 51.8151 676.001 52.897 677.339 52.897Z"
            fill="#EF4E05"
          />
          <path
            d="M659.691 42.8675C661.053 42.8675 662.158 41.7629 662.158 40.4002C662.158 39.0384 661.053 37.9338 659.691 37.9338C658.328 37.9338 657.223 39.0384 657.223 40.4002C657.223 41.7629 658.328 42.8675 659.691 42.8675Z"
            fill="#EF4E05"
          />
          <path
            d="M640.818 34.5932C642.196 34.5932 643.314 33.476 643.314 32.0982C643.314 30.7196 642.196 29.6024 640.818 29.6024C639.44 29.6024 638.323 30.7196 638.323 32.0982C638.323 33.476 639.44 34.5932 640.818 34.5932Z"
            fill="#EF4E05"
          />
          <path
            d="M620.541 30.1438C621.916 30.1438 623.036 29.0241 623.036 27.648C623.036 26.2719 621.916 25.1521 620.541 25.1521C619.165 25.1521 618.045 26.2719 618.045 27.648C618.045 29.0241 619.165 30.1438 620.541 30.1438Z"
            fill="#EF4E05"
          />
          <path
            d="M628.568 13.4725C630.059 13.4725 631.272 12.2578 631.272 10.7682C631.272 9.27862 630.059 8.06391 628.568 8.06391C627.079 8.06391 625.864 9.27862 625.864 10.7682C625.864 12.2578 627.069 13.4725 628.568 13.4725Z"
            fill="#EF4E05"
          />
          <path
            d="M645.582 16.1003C647.071 16.1003 648.286 14.8856 648.286 13.396C648.286 11.9064 647.071 10.6917 645.582 10.6917C644.092 10.6917 642.877 11.9064 642.877 13.396C642.877 14.8856 644.082 16.1003 645.582 16.1003Z"
            fill="#EF4E05"
          />
          <path
            d="M661.892 22.6857C663.373 22.6857 664.577 21.4803 664.577 19.9999C664.577 18.5196 663.373 17.315 661.892 17.315C660.412 17.315 659.207 18.5196 659.207 19.9999C659.207 21.4803 660.412 22.6857 661.892 22.6857Z"
            fill="#EF4E05"
          />
          <path
            d="M678.042 31.6897C679.513 31.6897 680.699 30.5036 680.699 29.0333C680.699 27.5622 679.513 26.3761 678.042 26.3761C676.571 26.3761 675.385 27.5622 675.385 29.0333C675.385 30.4943 676.571 31.6897 678.042 31.6897Z"
            fill="#EF4E05"
          />
          <path
            d="M695.131 45.9509C696.534 45.9509 697.673 44.8127 697.673 43.408C697.673 42.0042 696.534 40.8651 695.131 40.8651C693.726 40.8651 692.588 42.0042 692.588 43.408C692.588 44.8127 693.726 45.9509 695.131 45.9509Z"
            fill="#EF4E05"
          />
          <path
            d="M696.507 28.6441C698.025 28.6441 699.249 27.4201 699.249 25.902C699.249 24.3838 698.025 23.1599 696.507 23.1599C694.988 23.1599 693.764 24.3838 693.764 25.902C693.764 27.4201 694.998 28.6441 696.507 28.6441Z"
            fill="#EF4E05"
          />
          <path
            d="M684.911 11.6786C686.515 11.6786 687.805 10.379 687.805 8.78433C687.805 7.18126 686.505 5.8909 684.911 5.8909C683.308 5.8909 682.018 7.19051 682.018 8.78433C682.018 10.3882 683.308 11.6786 684.911 11.6786Z"
            fill="#EF4E05"
          />
          <path
            d="M670.716 5.54877C672.329 5.54877 673.63 4.23991 673.63 2.636C673.63 1.02284 672.32 -0.276764 670.716 -0.276764C669.103 -0.276764 667.803 1.03293 667.803 2.636C667.803 4.23991 669.103 5.54877 670.716 5.54877Z"
            fill="#EF4E05"
          />
          <path
            d="M655.582 0.548726C657.191 0.548726 658.495 -0.755925 658.495 -2.36429C658.495 -3.97317 657.191 -5.27731 655.582 -5.27731C653.973 -5.27731 652.669 -3.97317 652.669 -2.36429C652.669 -0.755925 653.973 0.548726 655.582 0.548726Z"
            fill="#EF4E05"
          />
          <path
            d="M493.898 4.99899C495.396 4.99899 496.611 3.78429 496.611 2.28462C496.611 0.785784 495.396 -0.428917 493.898 -0.428917C492.398 -0.428917 491.184 0.785784 491.184 2.28462C491.184 3.78429 492.398 4.99899 493.898 4.99899Z"
            fill="#EF4E05"
          />
          <path
            d="M513.539 4.54338C515.028 4.54338 516.243 3.32868 516.243 1.83909C516.243 0.349502 515.028 -0.865204 513.539 -0.865204C512.049 -0.865204 510.835 0.349502 510.835 1.83909C510.825 3.32868 512.04 4.54338 513.539 4.54338Z"
            fill="#EF4E05"
          />
          <path
            d="M533.113 4.26849C534.603 4.26849 535.808 3.06304 535.808 1.57345C535.808 0.0838612 534.603 -1.12143 533.113 -1.12143C531.624 -1.12143 530.419 0.0838612 530.419 1.57345C530.419 3.06304 531.633 4.26849 533.113 4.26849Z"
            fill="#EF4E05"
          />
          <path
            d="M552.356 5.13181C553.846 5.13181 555.051 3.92635 555.051 2.43677C555.051 0.947178 553.846 -0.257439 552.356 -0.257439C550.867 -0.257439 549.662 0.947178 549.662 2.43677C549.662 3.91711 550.876 5.13181 552.356 5.13181Z"
            fill="#EF4E05"
          />
          <path
            d="M571.969 4.95107C573.458 4.95107 574.673 3.73722 574.673 2.24679C574.673 0.7572 573.458 -0.456665 571.969 -0.456665C570.479 -0.456665 569.264 0.7572 569.264 2.24679C569.264 3.73722 570.479 4.95107 571.969 4.95107Z"
            fill="#EF4E05"
          />
          <path
            d="M580.566 22.8472C581.951 22.8472 583.08 21.7174 583.08 20.332C583.08 18.9467 581.951 17.8177 580.566 17.8177C579.18 17.8177 578.052 18.9467 578.052 20.332C578.052 21.7274 579.18 22.8472 580.566 22.8472Z"
            fill="#EF4E05"
          />
          <path
            d="M589.21 4.04993C589.21 5.53952 590.425 6.75422 591.914 6.75422C593.404 6.75422 594.619 5.53952 594.619 4.04993C594.619 2.56034 593.404 1.34564 591.914 1.34564C590.425 1.34564 589.21 2.55109 589.21 4.04993Z"
            fill="#EF4E05"
          />
          <path
            d="M610.976 9.28702C612.476 9.28702 613.69 8.07316 613.69 6.57348C613.69 5.07465 612.476 3.85995 610.976 3.85995C609.477 3.85995 608.263 5.07465 608.263 6.57348C608.263 8.07316 609.477 9.28702 610.976 9.28702Z"
            fill="#EF4E05"
          />
          <path
            d="M601.27 25.0008C602.664 25.0008 603.794 23.8719 603.794 22.4764C603.794 21.0818 602.664 19.9529 601.27 19.9529C599.875 19.9529 598.746 21.0818 598.746 22.4764C598.746 23.8719 599.884 25.0008 601.27 25.0008Z"
            fill="#EF4E05"
          />
          <path
            d="M588.801 35.0967C587.511 35.0967 586.468 36.1399 586.468 37.4303C586.468 38.7215 587.511 39.7647 588.801 39.7647C590.093 39.7647 591.136 38.7215 591.136 37.4303C591.136 36.1399 590.093 35.0967 588.801 35.0967Z"
            fill="#EF4E05"
          />
          <path
            d="M606.251 41.3493C604.98 41.3493 603.945 42.3833 603.945 43.6551C603.945 44.9262 604.98 45.961 606.251 45.961C607.523 45.961 608.557 44.9262 608.557 43.6551C608.557 42.3833 607.523 41.3493 606.251 41.3493Z"
            fill="#EF4E05"
          />
          <path
            d="M624.848 50.2397C626.117 50.2397 627.145 49.2117 627.145 47.944C627.145 46.6755 626.117 45.6474 624.848 45.6474C623.581 45.6474 622.553 46.6755 622.553 47.944C622.553 49.2117 623.581 50.2397 624.848 50.2397Z"
            fill="#EF4E05"
          />
          <path
            d="M643.143 51.7865C641.89 51.7865 640.865 52.802 640.865 54.0638C640.865 55.3255 641.88 56.341 643.143 56.341C644.395 56.341 645.42 55.3255 645.42 54.0638C645.42 52.802 644.405 51.7865 643.143 51.7865Z"
            fill="#EF4E05"
          />
          <path
            d="M661.104 65.4214C662.338 65.4214 663.335 64.4253 663.335 63.1921C663.335 61.9581 662.338 60.9619 661.104 60.9619C659.871 60.9619 658.875 61.9581 658.875 63.1921C658.875 64.4253 659.871 65.4214 661.104 65.4214Z"
            fill="#EF4E05"
          />
          <path
            d="M646.587 76.4378C646.587 75.2895 645.657 74.3598 644.509 74.3598C643.361 74.3598 642.431 75.2895 642.431 76.4378C642.431 77.5861 643.361 78.5159 644.509 78.5159C645.657 78.5159 646.587 77.5861 646.587 76.4378Z"
            fill="#EF4E05"
          />
          <path
            d="M663.884 89.7785C665.001 89.7785 665.906 88.874 665.906 87.7577C665.906 86.6413 665.001 85.7368 663.884 85.7368C662.768 85.7368 661.864 86.6413 661.864 87.7577C661.864 88.874 662.768 89.7785 663.884 89.7785Z"
            fill="#EF4E05"
          />
          <path
            d="M648.598 98.442C648.598 97.398 647.754 96.554 646.71 96.554C645.666 96.554 644.822 97.398 644.822 98.442C644.822 99.4852 645.666 100.33 646.71 100.33C647.754 100.321 648.598 99.476 648.598 98.442Z"
            fill="#EF4E05"
          />
          <path
            d="M630.96 114.98C630.96 114.042 630.201 113.282 629.261 113.282C628.322 113.282 627.563 114.042 627.563 114.98C627.563 115.919 628.322 116.679 629.261 116.679C630.201 116.679 630.96 115.919 630.96 114.98Z"
            fill="#EF4E05"
          />
          <path
            d="M605.568 110.028C605.568 110.967 606.327 111.736 607.276 111.736C608.225 111.736 608.984 110.977 608.984 110.028C608.984 109.088 608.225 108.319 607.276 108.319C606.327 108.319 605.568 109.088 605.568 110.028Z"
            fill="#EF4E05"
          />
          <path
            d="M587.236 103.528C587.236 102.579 586.468 101.801 585.51 101.801C584.551 101.801 583.782 102.569 583.782 103.528C583.782 104.477 584.551 105.254 585.51 105.254C586.468 105.254 587.236 104.477 587.236 103.528Z"
            fill="#EF4E05"
          />
          <path
            d="M565.858 122.201C565.858 123.065 566.56 123.776 567.434 123.776C568.297 123.776 569.009 123.074 569.009 122.201C569.009 121.337 568.306 120.626 567.434 120.626C566.56 120.626 565.858 121.328 565.858 122.201Z"
            fill="#EF4E05"
          />
          <path
            d="M548.266 113.026C549.174 113.026 549.908 112.29 549.908 111.384C549.908 110.477 549.174 109.743 548.266 109.743C547.36 109.743 546.625 110.477 546.625 111.384C546.625 112.29 547.36 113.026 548.266 113.026Z"
            fill="#EF4E05"
          />
          <path
            d="M523.672 123.52C523.672 124.374 524.365 125.057 525.21 125.057C526.054 125.057 526.747 124.364 526.747 123.52C526.747 122.666 526.054 121.974 525.21 121.974C524.365 121.974 523.672 122.676 523.672 123.52Z"
            fill="#EF4E05"
          />
          <path
            d="M502.646 125.399C502.646 124.545 501.953 123.862 501.108 123.862C500.255 123.862 499.572 124.554 499.572 125.399C499.572 126.243 500.264 126.936 501.108 126.936C501.963 126.936 502.646 126.243 502.646 125.399Z"
            fill="#EF4E05"
          />
          <path
            d="M475.024 117.723C474.133 117.723 473.402 118.444 473.402 119.345C473.402 120.246 474.123 120.967 475.024 120.967C475.926 120.967 476.647 120.246 476.647 119.345C476.647 118.444 475.916 117.723 475.024 117.723Z"
            fill="#EF4E05"
          />
          <path
            d="M488.48 103.718C489.444 103.718 490.226 102.936 490.226 101.972C490.226 101.008 489.444 100.226 488.48 100.226C487.515 100.226 486.734 101.008 486.734 101.972C486.734 102.936 487.515 103.718 488.48 103.718Z"
            fill="#EF4E05"
          />
          <path
            d="M478.707 84.3893C479.769 84.3893 480.632 83.526 480.632 82.4626C480.632 81.4 479.769 80.5367 478.707 80.5367C477.643 80.5367 476.78 81.4 476.78 82.4626C476.78 83.526 477.643 84.3893 478.707 84.3893Z"
            fill="#EF4E05"
          />
          <path
            d="M500.521 81.8464C501.574 81.8464 502.437 80.9923 502.437 79.9298C502.437 78.8672 501.583 78.0132 500.521 78.0132C499.467 78.0132 498.604 78.8672 498.604 79.9298C498.604 80.9923 499.467 81.8464 500.521 81.8464Z"
            fill="#EF4E05"
          />
          <path
            d="M511.091 99.571C510.142 99.571 509.364 100.339 509.364 101.298C509.364 102.247 510.133 103.025 511.091 103.025C512.04 103.025 512.818 102.256 512.818 101.298C512.818 100.339 512.049 99.571 511.091 99.571Z"
            fill="#EF4E05"
          />
          <path
            d="M533.949 97.635C533.949 96.6675 533.161 95.8798 532.193 95.8798C531.225 95.8798 530.438 96.6675 530.438 97.635C530.438 98.6034 531.225 99.3903 532.193 99.3903C533.161 99.3903 533.949 98.6034 533.949 97.635Z"
            fill="#EF4E05"
          />
          <path
            d="M522.913 79.0665C523.977 79.0665 524.84 78.204 524.84 77.1398C524.84 76.0764 523.977 75.2139 522.913 75.2139C521.85 75.2139 520.987 76.0764 520.987 77.1398C520.987 78.204 521.85 79.0665 522.913 79.0665Z"
            fill="#EF4E05"
          />
          <path
            d="M547.622 83.8101C548.664 83.8101 549.51 82.9653 549.51 81.9221C549.51 80.8797 548.664 80.034 547.622 80.034C546.579 80.034 545.733 80.8797 545.733 81.9221C545.733 82.9653 546.579 83.8101 547.622 83.8101Z"
            fill="#EF4E05"
          />
          <path
            d="M544.453 60.9527C544.453 59.7951 543.513 58.8562 542.355 58.8562C541.198 58.8562 540.259 59.7951 540.259 60.9527C540.259 62.1102 541.198 63.05 542.355 63.05C543.513 63.05 544.453 62.1102 544.453 60.9527Z"
            fill="#EF4E05"
          />
          <path
            d="M562.376 64.9566C562.376 66.0855 563.287 66.9968 564.417 66.9968C565.546 66.9968 566.456 66.0855 566.456 64.9566C566.456 63.8276 565.546 62.9164 564.417 62.9164C563.287 62.9164 562.376 63.8276 562.376 64.9566Z"
            fill="#EF4E05"
          />
          <path
            d="M567.026 93.7455C567.026 92.7586 566.228 91.9516 565.232 91.9516C564.236 91.9516 563.439 92.7485 563.439 93.7455C563.439 94.7416 564.236 95.5385 565.232 95.5385C566.228 95.5385 567.026 94.7315 567.026 93.7455Z"
            fill="#EF4E05"
          />
          <path
            d="M581.183 78.8294C581.183 79.9012 582.056 80.7746 583.128 80.7746C584.2 80.7746 585.073 79.9012 585.073 78.8294C585.073 77.7568 584.2 76.8842 583.128 76.8842C582.056 76.8842 581.183 77.7568 581.183 78.8294Z"
            fill="#EF4E05"
          />
          <path
            d="M604.24 88.062C605.293 88.062 606.147 87.2079 606.147 86.1546C606.147 85.1013 605.293 84.2472 604.24 84.2472C603.186 84.2472 602.332 85.1013 602.332 86.1546C602.332 87.2079 603.186 88.062 604.24 88.062Z"
            fill="#EF4E05"
          />
          <path
            d="M627.24 91.7809C627.24 90.7369 626.395 89.8929 625.352 89.8929C624.308 89.8929 623.464 90.7369 623.464 91.7809C623.464 92.825 624.308 93.669 625.352 93.669C626.395 93.669 627.24 92.825 627.24 91.7809Z"
            fill="#EF4E05"
          />
          <path
            d="M624.811 71.6933C625.964 71.6933 626.899 70.7594 626.899 69.6061C626.899 68.4536 625.964 67.5188 624.811 67.5188C623.658 67.5188 622.723 68.4536 622.723 69.6061C622.723 70.7594 623.658 71.6933 624.811 71.6933Z"
            fill="#EF4E05"
          />
          <path
            d="M602.969 64.3782C602.969 65.5358 603.908 66.4848 605.075 66.4848C606.242 66.4848 607.181 65.545 607.181 64.3782C607.181 63.2207 606.242 62.2716 605.075 62.2716C603.908 62.2716 602.969 63.2106 602.969 64.3782Z"
            fill="#EF4E05"
          />
          <path
            d="M585.727 59.5295C586.904 59.5295 587.863 58.5712 587.863 57.3943C587.863 56.2183 586.904 55.26 585.727 55.26C584.551 55.26 583.593 56.2183 583.593 57.3943C583.593 58.5712 584.551 59.5295 585.727 59.5295Z"
            fill="#EF4E05"
          />
          <path
            d="M570.622 44.2906C571.875 44.2906 572.899 43.2752 572.899 42.0134C572.899 40.7609 571.884 39.7361 570.622 39.7361C569.37 39.7361 568.345 40.7516 568.345 42.0134C568.345 43.2659 569.37 44.2906 570.622 44.2906Z"
            fill="#EF4E05"
          />
          <path
            d="M561.864 24.2317C563.231 24.2317 564.34 23.1229 564.34 21.7552C564.34 20.3875 563.231 19.2787 561.864 19.2787C560.496 19.2787 559.387 20.3875 559.387 21.7552C559.387 23.1229 560.496 24.2317 561.864 24.2317Z"
            fill="#EF4E05"
          />
          <path
            d="M542.953 23.3305C544.332 23.3305 545.449 22.2133 545.449 20.8347C545.449 19.4569 544.332 18.3397 542.953 18.3397C541.575 18.3397 540.458 19.4569 540.458 20.8347C540.458 22.2133 541.575 23.3305 542.953 23.3305Z"
            fill="#EF4E05"
          />
          <path
            d="M523.397 17.4949C522.021 17.4949 520.902 18.6146 520.902 19.9907C520.902 21.3668 522.021 22.4857 523.397 22.4857C524.773 22.4857 525.892 21.3668 525.892 19.9907C525.892 18.6054 524.773 17.4949 523.397 17.4949Z"
            fill="#EF4E05"
          />
          <path
            d="M510.654 40.5902C511.933 40.5902 512.97 39.5537 512.97 38.2751C512.97 36.9965 511.933 35.9601 510.654 35.9601C509.376 35.9601 508.339 36.9965 508.339 38.2751C508.339 39.5537 509.376 40.5902 510.654 40.5902Z"
            fill="#EF4E05"
          />
          <path
            d="M532.487 40.9416C533.759 40.9416 534.793 39.9068 534.793 38.6358C534.793 37.3639 533.759 36.3299 532.487 36.3299C531.216 36.3299 530.181 37.3639 530.181 38.6358C530.181 39.9068 531.216 40.9416 532.487 40.9416Z"
            fill="#EF4E05"
          />
          <path
            d="M554.425 42.1462C554.425 40.8937 553.41 39.879 552.157 39.879C550.905 39.879 549.89 40.8937 549.89 42.1462C549.89 43.3987 550.905 44.4142 552.157 44.4142C553.41 44.4142 554.425 43.3987 554.425 42.1462Z"
            fill="#EF4E05"
          />
          <path
            d="M522.61 58.2391C523.789 58.2391 524.745 57.2834 524.745 56.104C524.745 54.9246 523.789 53.9688 522.61 53.9688C521.43 53.9688 520.475 54.9246 520.475 56.104C520.475 57.2834 521.43 58.2391 522.61 58.2391Z"
            fill="#EF4E05"
          />
          <path
            d="M503.073 60.5736C504.25 60.5736 505.198 59.6245 505.198 58.4476C505.198 57.2707 504.25 56.3225 503.073 56.3225C501.896 56.3225 500.948 57.2707 500.948 58.4476C500.948 59.6245 501.896 60.5736 503.073 60.5736Z"
            fill="#EF4E05"
          />
          <path
            d="M483.109 63.4199C484.266 63.4199 485.215 62.4801 485.215 61.3133C485.215 60.1465 484.276 59.2067 483.109 59.2067C481.942 59.2067 481.002 60.1465 481.002 61.3133C481.002 62.4801 481.951 63.4199 483.109 63.4199Z"
            fill="#EF4E05"
          />
          <path
            d="M462.624 71.2285C463.762 71.2285 464.682 70.308 464.682 69.1698C464.682 68.0307 463.762 67.1103 462.624 67.1103C461.485 67.1103 460.564 68.0307 460.564 69.1698C460.564 70.308 461.485 71.2285 462.624 71.2285Z"
            fill="#EF4E05"
          />
          <path
            d="M444.205 77.9938C445.335 77.9938 446.256 77.0733 446.256 75.9444C446.256 74.8154 445.335 73.8949 444.205 73.8949C443.076 73.8949 442.156 74.8154 442.156 75.9444C442.156 77.0733 443.067 77.9938 444.205 77.9938Z"
            fill="#EF4E05"
          />
          <path
            d="M423.388 78.5629C422.249 78.5629 421.329 79.4834 421.329 80.6225C421.329 81.7607 422.249 82.6812 423.388 82.6812C424.527 82.6812 425.447 81.7607 425.447 80.6225C425.447 79.4834 424.527 78.5629 423.388 78.5629Z"
            fill="#EF4E05"
          />
          <path
            d="M404.003 87.777C405.142 87.777 406.071 86.8565 406.071 85.7082C406.071 84.5599 405.151 83.6395 404.003 83.6395C402.864 83.6395 401.935 84.5599 401.935 85.7082C401.935 86.8565 402.864 87.777 404.003 87.777Z"
            fill="#EF4E05"
          />
          <path
            d="M383.053 84.8449C381.885 84.8449 380.936 85.794 380.936 86.9608C380.936 88.1276 381.885 89.0766 383.053 89.0766C384.22 89.0766 385.169 88.1276 385.169 86.9608C385.169 85.794 384.22 84.8449 383.053 84.8449Z"
            fill="#EF4E05"
          />
          <path
            d="M377.226 105.568C377.226 104.467 376.334 103.575 375.234 103.575C374.133 103.575 373.241 104.467 373.241 105.568C373.241 106.668 374.133 107.56 375.234 107.56C376.334 107.56 377.226 106.668 377.226 105.568Z"
            fill="#EF4E05"
          />
          <path
            d="M396.896 109.287C397.94 109.287 398.794 108.443 398.794 107.39C398.794 106.336 397.949 105.492 396.896 105.492C395.852 105.492 394.999 106.336 394.999 107.39C394.999 108.443 395.843 109.287 396.896 109.287Z"
            fill="#EF4E05"
          />
          <path
            d="M419.422 108.509C420.447 108.509 421.272 107.684 421.272 106.659C421.272 105.634 420.447 104.809 419.422 104.809C418.398 104.809 417.572 105.634 417.572 106.659C417.572 107.684 418.398 108.509 419.422 108.509Z"
            fill="#EF4E05"
          />
          <path
            d="M441.302 97.54C441.302 96.5153 440.467 95.6806 439.443 95.6806C438.418 95.6806 437.583 96.5153 437.583 97.54C437.583 98.5647 438.418 99.4003 439.443 99.4003C440.476 99.4003 441.302 98.5647 441.302 97.54Z"
            fill="#EF4E05"
          />
          <path
            d="M462.149 98.7362C463.155 98.7362 463.97 97.92 463.97 96.9146C463.97 95.9084 463.155 95.0921 462.149 95.0921C461.143 95.0921 460.327 95.9084 460.327 96.9146C460.327 97.92 461.143 98.7362 462.149 98.7362Z"
            fill="#EF4E05"
          />
          <path
            d="M447.517 120.578C448.44 120.578 449.187 119.831 449.187 118.909C449.187 117.986 448.44 117.238 447.517 117.238C446.594 117.238 445.847 117.986 445.847 118.909C445.847 119.831 446.594 120.578 447.517 120.578Z"
            fill="#EF4E05"
          />
          <path
            d="M464.55 138.36C464.55 137.534 463.886 136.87 463.06 136.87C462.235 136.87 461.57 137.534 461.57 138.36C461.57 139.185 462.235 139.85 463.06 139.85C463.875 139.86 464.55 139.185 464.55 138.36Z"
            fill="#EF4E05"
          />
          <path
            d="M465.859 164.89C466.59 164.89 467.177 164.302 467.177 163.571C467.177 162.841 466.59 162.252 465.859 162.252C465.128 162.252 464.54 162.841 464.54 163.571C464.54 164.302 465.128 164.89 465.859 164.89Z"
            fill="#EF4E05"
          />
          <path
            d="M466.656 191.448C466.656 192.084 467.168 192.596 467.803 192.596C468.44 192.596 468.952 192.084 468.952 191.448C468.952 190.812 468.44 190.3 467.803 190.3C467.168 190.3 466.656 190.812 466.656 191.448Z"
            fill="#EF4E05"
          />
          <path
            d="M441.103 206.184C441.721 206.184 442.222 205.683 442.222 205.064C442.222 204.447 441.721 203.945 441.103 203.945C440.485 203.945 439.983 204.447 439.983 205.064C439.983 205.683 440.485 206.184 441.103 206.184Z"
            fill="#EF4E05"
          />
          <path
            d="M440.268 176.381C440.98 176.381 441.559 175.802 441.559 175.09C441.559 174.379 440.98 173.8 440.268 173.8C439.557 173.8 438.978 174.379 438.978 175.09C438.978 175.802 439.557 176.381 440.268 176.381Z"
            fill="#EF4E05"
          />
          <path
            d="M439.471 148.305C439.471 149.12 440.135 149.775 440.941 149.775C441.758 149.775 442.413 149.111 442.413 148.305C442.413 147.488 441.748 146.833 440.941 146.833C440.135 146.833 439.471 147.488 439.471 148.305Z"
            fill="#EF4E05"
          />
          <path
            d="M425.903 128.406C425.001 128.406 424.261 129.137 424.261 130.048C424.261 130.949 424.991 131.69 425.903 131.69C426.813 131.69 427.544 130.959 427.544 130.048C427.544 129.137 426.813 128.406 425.903 128.406Z"
            fill="#EF4E05"
          />
          <path
            d="M403.414 132.022C404.354 132.022 405.122 131.263 405.122 130.314C405.122 129.375 404.363 128.606 403.414 128.606C402.475 128.606 401.707 129.365 401.707 130.314C401.716 131.263 402.475 132.022 403.414 132.022Z"
            fill="#EF4E05"
          />
          <path
            d="M377.739 125.807C377.739 126.812 378.554 127.629 379.561 127.629C380.566 127.629 381.382 126.812 381.382 125.807C381.382 124.801 380.566 123.985 379.561 123.985C378.554 123.985 377.739 124.801 377.739 125.807Z"
            fill="#EF4E05"
          />
          <path
            d="M367.785 144.888C367.785 145.827 368.544 146.596 369.493 146.596C370.432 146.596 371.201 145.837 371.201 144.888C371.201 143.948 370.442 143.18 369.493 143.18C368.544 143.189 367.785 143.948 367.785 144.888Z"
            fill="#EF4E05"
          />
          <path
            d="M391.421 152.593C392.294 152.593 393.006 151.881 393.006 151.008C393.006 150.135 392.294 149.423 391.421 149.423C390.548 149.423 389.836 150.135 389.836 151.008C389.847 151.891 390.548 152.593 391.421 152.593Z"
            fill="#EF4E05"
          />
          <path
            d="M415.845 159.225C416.652 159.225 417.306 158.571 417.306 157.764C417.306 156.957 416.652 156.303 415.845 156.303C415.038 156.303 414.384 156.957 414.384 157.764C414.384 158.571 415.038 159.225 415.845 159.225Z"
            fill="#EF4E05"
          />
          <path
            d="M415.826 189.409C416.537 189.409 417.107 188.829 417.107 188.127C417.107 187.426 416.528 186.846 415.826 186.846C415.123 186.846 414.545 187.426 414.545 188.127C414.545 188.829 415.114 189.409 415.826 189.409Z"
            fill="#EF4E05"
          />
          <path
            d="M393.813 177.216C393.813 176.438 393.186 175.811 392.408 175.811C391.63 175.811 391.004 176.438 391.004 177.216C391.004 177.994 391.63 178.62 392.408 178.62C393.176 178.62 393.813 177.994 393.813 177.216Z"
            fill="#EF4E05"
          />
          <path
            d="M369.361 169.492C370.22 169.492 370.917 168.796 370.917 167.936C370.917 167.077 370.22 166.38 369.361 166.38C368.501 166.38 367.804 167.077 367.804 167.936C367.804 168.796 368.501 169.492 369.361 169.492Z"
            fill="#EF4E05"
          />
          <path
            d="M345.98 174.72C345.098 174.72 344.377 175.432 344.377 176.323C344.377 177.206 345.088 177.927 345.98 177.927C346.863 177.927 347.584 177.215 347.584 176.323C347.575 175.442 346.863 174.72 345.98 174.72Z"
            fill="#EF4E05"
          />
          <path
            d="M323.398 184.655C324.309 184.655 325.059 183.914 325.059 182.994C325.059 182.074 324.318 181.334 323.398 181.334C322.487 181.334 321.738 182.074 321.738 182.994C321.738 183.914 322.487 184.655 323.398 184.655Z"
            fill="#EF4E05"
          />
          <path
            d="M303.206 183.507C303.206 182.529 302.409 181.732 301.432 181.732C300.454 181.732 299.657 182.529 299.657 183.507C299.657 184.484 300.454 185.281 301.432 185.281C302.419 185.281 303.206 184.484 303.206 183.507Z"
            fill="#EF4E05"
          />
          <path
            d="M279.58 178.202C278.517 178.202 277.653 179.066 277.653 180.129C277.653 181.191 278.517 182.055 279.58 182.055C280.643 182.055 281.506 181.191 281.506 180.129C281.506 179.066 280.643 178.202 279.58 178.202Z"
            fill="#EF4E05"
          />
          <path
            d="M306.167 159.244C305.123 159.244 304.269 160.088 304.269 161.142C304.269 162.186 305.113 163.04 306.167 163.04C307.211 163.04 308.065 162.195 308.065 161.142C308.055 160.088 307.211 159.244 306.167 159.244Z"
            fill="#EF4E05"
          />
          <path
            d="M328.844 158.277C328.844 157.271 328.028 156.454 327.022 156.454C326.017 156.454 325.201 157.271 325.201 158.277C325.201 159.282 326.017 160.098 327.022 160.098C328.028 160.098 328.844 159.282 328.844 158.277Z"
            fill="#EF4E05"
          />
          <path
            d="M346.502 152.118C346.502 153.086 347.29 153.874 348.257 153.874C349.226 153.874 350.013 153.086 350.013 152.118C350.013 151.151 349.226 150.363 348.257 150.363C347.29 150.363 346.502 151.151 346.502 152.118Z"
            fill="#EF4E05"
          />
          <path
            d="M355.896 129.118C355.896 128.074 355.051 127.221 353.998 127.221C352.955 127.221 352.101 128.065 352.101 129.118C352.101 130.171 352.946 131.016 353.998 131.016C355.042 131.016 355.896 130.171 355.896 129.118Z"
            fill="#EF4E05"
          />
          <path
            d="M357.633 109.363C357.633 108.234 356.712 107.313 355.583 107.313C354.454 107.313 353.533 108.234 353.533 109.363C353.533 110.492 354.454 111.413 355.583 111.413C356.712 111.413 357.633 110.492 357.633 109.363Z"
            fill="#EF4E05"
          />
          <path
            d="M363.098 89.2094C363.098 87.9947 362.111 87.0078 360.896 87.0078C359.682 87.0078 358.696 87.9947 358.696 89.2094C358.696 90.4241 359.682 91.411 360.896 91.411C362.12 91.411 363.098 90.4241 363.098 89.2094Z"
            fill="#EF4E05"
          />
          <path
            d="M368.241 73.1737C369.541 73.1737 370.594 72.1204 370.594 70.8208C370.594 69.5212 369.541 68.4679 368.241 68.4679C366.94 68.4679 365.887 69.5212 365.887 70.8208C365.887 72.1204 366.94 73.1737 368.241 73.1737Z"
            fill="#EF4E05"
          />
          <path
            d="M389.068 69.5968C390.321 69.5968 391.346 68.5813 391.346 67.3196C391.346 66.0578 390.33 65.0423 389.068 65.0423C387.816 65.0423 386.791 66.0578 386.791 67.3196C386.791 68.5813 387.807 69.5968 389.068 69.5968Z"
            fill="#EF4E05"
          />
          <path
            d="M410.701 62.4616C410.701 61.2091 409.687 60.1844 408.424 60.1844C407.172 60.1844 406.147 61.199 406.147 62.4616C406.147 63.7234 407.162 64.7389 408.424 64.7389C409.687 64.7389 410.701 63.7141 410.701 62.4616Z"
            fill="#EF4E05"
          />
          <path
            d="M425.504 57.897C425.504 59.1403 426.51 60.1558 427.762 60.1558C429.015 60.1558 430.021 59.1495 430.021 57.897C430.021 56.6546 429.015 55.6391 427.762 55.6391C426.51 55.6391 425.504 56.6546 425.504 57.897Z"
            fill="#EF4E05"
          />
          <path
            d="M447.403 54.9372C448.656 54.9372 449.671 53.9217 449.671 52.6692C449.671 51.4166 448.656 50.4012 447.403 50.4012C446.151 50.4012 445.135 51.4166 445.135 52.6692C445.135 53.9217 446.151 54.9372 447.403 54.9372Z"
            fill="#EF4E05"
          />
          <path
            d="M467.577 48.7787C468.838 48.7787 469.863 47.754 469.863 46.4922C469.863 45.2296 468.838 44.2049 467.577 44.2049C466.314 44.2049 465.289 45.2296 465.289 46.4922C465.299 47.754 466.314 48.7787 467.577 48.7787Z"
            fill="#EF4E05"
          />
          <path
            d="M486.601 39.9068C486.601 41.1879 487.635 42.2219 488.916 42.2219C490.197 42.2219 491.231 41.1879 491.231 39.9068C491.231 38.6257 490.197 37.5917 488.916 37.5917C487.635 37.601 486.601 38.6358 486.601 39.9068Z"
            fill="#EF4E05"
          />
          <path
            d="M503.936 22.6664C505.322 22.6664 506.441 21.5467 506.441 20.1613C506.441 18.776 505.322 17.6563 503.936 17.6563C502.551 17.6563 501.431 18.776 501.431 20.1613C501.431 21.5467 502.551 22.6664 503.936 22.6664Z"
            fill="#EF4E05"
          />
          <path
            d="M485.851 22.7707C487.246 22.7707 488.375 21.6417 488.375 20.2471C488.375 18.8517 487.246 17.7227 485.851 17.7227C484.456 17.7227 483.327 18.8517 483.327 20.2471C483.327 21.6417 484.456 22.7707 485.851 22.7707Z"
            fill="#EF4E05"
          />
          <path
            d="M468.962 28.597C470.337 28.597 471.448 27.4865 471.448 26.1104C471.448 24.7352 470.337 23.6247 468.962 23.6247C467.586 23.6247 466.476 24.7352 466.476 26.1104C466.476 27.4865 467.586 28.597 468.962 28.597Z"
            fill="#EF4E05"
          />
          <path
            d="M450.516 33.8064C451.873 33.8064 452.973 32.7052 452.973 31.3484C452.973 29.9916 451.873 28.8912 450.516 28.8912C449.158 28.8912 448.058 29.9916 448.058 31.3484C448.058 32.7052 449.158 33.8064 450.516 33.8064Z"
            fill="#EF4E05"
          />
          <path
            d="M431.662 38.9964C433.029 38.9964 434.129 37.8952 434.129 36.5292C434.129 35.1631 433.029 34.0619 431.662 34.0619C430.296 34.0619 429.195 35.1631 429.195 36.5292C429.195 37.8952 430.305 38.9964 431.662 38.9964Z"
            fill="#EF4E05"
          />
          <path
            d="M411.879 42.5253C413.251 42.5253 414.364 41.4123 414.364 40.0396C414.364 38.6669 413.251 37.5539 411.879 37.5539C410.506 37.5539 409.393 38.6669 409.393 40.0396C409.393 41.4123 410.506 42.5253 411.879 42.5253Z"
            fill="#EF4E05"
          />
          <path
            d="M390.529 46.6721C390.529 48.0482 391.64 49.1587 393.016 49.1587C394.391 49.1587 395.502 48.0482 395.502 46.6721C395.502 45.2969 394.391 44.1864 393.016 44.1864C391.649 44.1864 390.529 45.2969 390.529 46.6721Z"
            fill="#EF4E05"
          />
          <path
            d="M373.886 54.2823C375.264 54.2823 376.381 53.1651 376.381 51.7865C376.381 50.4087 375.264 49.2915 373.886 49.2915C372.508 49.2915 371.391 50.4087 371.391 51.7865C371.391 53.1651 372.508 54.2823 373.886 54.2823Z"
            fill="#EF4E05"
          />
          <path
            d="M390.766 29.9916C392.246 29.9916 393.442 28.7962 393.442 27.3159C393.442 25.8356 392.246 24.6402 390.766 24.6402C389.287 24.6402 388.09 25.8356 388.09 27.3159C388.09 28.7962 389.287 29.9916 390.766 29.9916Z"
            fill="#EF4E05"
          />
          <path
            d="M405.066 22.9799C406.565 22.9799 407.78 21.7652 407.78 20.2656C407.78 18.7667 406.565 17.552 405.066 17.552C403.567 17.552 402.352 18.7667 402.352 20.2656C402.352 21.7652 403.567 22.9799 405.066 22.9799Z"
            fill="#EF4E05"
          />
          <path
            d="M421.244 21.4803C422.724 21.4803 423.929 20.2748 423.929 18.7953C423.929 17.315 422.724 16.1095 421.244 16.1095C419.764 16.1095 418.558 17.315 418.558 18.7953C418.558 20.2748 419.764 21.4803 421.244 21.4803Z"
            fill="#EF4E05"
          />
          <path
            d="M437.858 17.0964C439.338 17.0964 440.543 15.8918 440.543 14.4115C440.543 12.9311 439.338 11.7257 437.858 11.7257C436.378 11.7257 435.173 12.9311 435.173 14.4115C435.173 15.8918 436.378 17.0964 437.858 17.0964Z"
            fill="#EF4E05"
          />
          <path
            d="M455.403 7.40823C453.922 7.40823 452.717 8.61368 452.717 10.094C452.717 11.5744 453.922 12.779 455.403 12.779C456.883 12.779 458.088 11.5744 458.088 10.094C458.088 8.61368 456.892 7.40823 455.403 7.40823Z"
            fill="#EF4E05"
          />
          <path
            d="M471.049 4.68544C471.049 6.19437 472.273 7.40823 473.772 7.40823C475.281 7.40823 476.496 6.18428 476.496 4.68544C476.496 3.18661 475.272 1.96181 473.772 1.96181C472.273 1.96181 471.049 3.17652 471.049 4.68544Z"
            fill="#EF4E05"
          />
          <path
            d="M423.265 0.795028C424.869 0.795028 426.168 -0.504576 426.168 -2.10824C426.168 -3.71181 424.869 -5.01176 423.265 -5.01176C421.661 -5.01176 420.361 -3.71181 420.361 -2.10824C420.361 -0.504576 421.661 0.795028 423.265 0.795028Z"
            fill="#EF4E05"
          />
          <path
            d="M407.106 4.03984C408.728 4.03984 410.038 2.73099 410.038 1.10858C410.038 -0.514666 408.728 -1.82368 407.106 -1.82368C405.483 -1.82368 404.174 -0.514666 404.174 1.10858C404.174 2.73099 405.483 4.03984 407.106 4.03984Z"
            fill="#EF4E05"
          />
          <path
            d="M391.042 8.93649C392.655 8.93649 393.955 7.62763 393.955 6.02372C393.955 4.4198 392.645 3.11095 391.042 3.11095C389.429 3.11095 388.128 4.4198 388.128 6.02372C388.128 7.62763 389.429 8.93649 391.042 8.93649Z"
            fill="#EF4E05"
          />
          <path
            d="M375.841 15.0756C377.454 15.0756 378.754 13.7659 378.754 12.1628C378.754 10.5496 377.445 9.25004 375.841 9.25004C374.238 9.25004 372.928 10.5589 372.928 12.1628C372.928 13.7659 374.228 15.0756 375.841 15.0756Z"
            fill="#EF4E05"
          />
          <path
            d="M360.289 20.4841C361.903 20.4841 363.202 19.1745 363.202 17.5714C363.202 15.9675 361.893 14.6578 360.289 14.6578C358.686 14.6578 357.377 15.9675 357.377 17.5714C357.377 19.1745 358.686 20.4841 360.289 20.4841Z"
            fill="#EF4E05"
          />
          <path
            d="M345.013 26.3383C346.626 26.3383 347.935 25.0294 347.935 23.4162C347.935 21.8031 346.626 20.4934 345.013 20.4934C343.399 20.4934 342.091 21.8031 342.091 23.4162C342.091 25.0294 343.399 26.3383 345.013 26.3383Z"
            fill="#EF4E05"
          />
          <path
            d="M374.797 35.0017C376.287 35.0017 377.501 33.787 377.501 32.2974C377.501 30.8079 376.287 29.5932 374.797 29.5932C373.307 29.5932 372.093 30.8079 372.093 32.2974C372.093 33.787 373.307 35.0017 374.797 35.0017Z"
            fill="#EF4E05"
          />
          <path
            d="M357.955 40.533C359.455 40.533 360.669 39.3192 360.669 37.8195C360.669 36.3207 359.455 35.106 357.955 35.106C356.456 35.106 355.241 36.3207 355.241 37.8195C355.241 39.3192 356.456 40.533 357.955 40.533Z"
            fill="#EF4E05"
          />
          <path
            d="M354.055 58.9503C355.469 58.9503 356.607 57.8121 356.607 56.3982C356.607 54.9842 355.469 53.846 354.055 53.846C352.641 53.846 351.503 54.9842 351.503 56.3982C351.503 57.8121 352.651 58.9503 354.055 58.9503Z"
            fill="#EF4E05"
          />
          <path
            d="M344.406 74.1513C344.406 75.4795 345.487 76.5614 346.816 76.5614C348.144 76.5614 349.226 75.4795 349.226 74.1513C349.226 72.8231 348.144 71.7413 346.816 71.7413C345.487 71.7413 344.406 72.8131 344.406 74.1513Z"
            fill="#EF4E05"
          />
          <path
            d="M326.927 79.7877C328.294 79.7877 329.404 78.6772 329.404 77.3104C329.404 75.9444 328.294 74.8339 326.927 74.8339C325.561 74.8339 324.451 75.9444 324.451 77.3104C324.451 78.6772 325.561 79.7877 326.927 79.7877Z"
            fill="#EF4E05"
          />
          <path
            d="M308.387 82.8997C309.801 82.8997 310.94 81.7607 310.94 80.3476C310.94 78.9337 309.801 77.7946 308.387 77.7946C306.974 77.7946 305.834 78.9337 305.834 80.3476C305.834 81.7607 306.983 82.8997 308.387 82.8997Z"
            fill="#EF4E05"
          />
          <path
            d="M317.031 97.3509C317.031 98.6312 318.075 99.6752 319.356 99.6752C320.637 99.6752 321.68 98.6312 321.68 97.3509C321.68 96.0698 320.637 95.0257 319.356 95.0257C318.075 95.0257 317.031 96.0698 317.031 97.3509Z"
            fill="#EF4E05"
          />
          <path
            d="M339.936 91.2782C338.693 91.2782 337.678 92.2836 337.678 93.5361C337.678 94.7887 338.684 95.7949 339.936 95.7949C341.179 95.7949 342.195 94.7887 342.195 93.5361C342.195 92.2836 341.189 91.2782 339.936 91.2782Z"
            fill="#EF4E05"
          />
          <path
            d="M332.688 113.88C332.688 115.047 333.637 115.996 334.803 115.996C335.97 115.996 336.919 115.047 336.919 113.88C336.919 112.712 335.97 111.764 334.803 111.764C333.637 111.773 332.688 112.712 332.688 113.88Z"
            fill="#EF4E05"
          />
          <path
            d="M331.719 136.775C332.799 136.775 333.674 135.9 333.674 134.821C333.674 133.742 332.799 132.866 331.719 132.866C330.639 132.866 329.764 133.742 329.764 134.821C329.764 135.9 330.639 136.775 331.719 136.775Z"
            fill="#EF4E05"
          />
          <path
            d="M309.212 138.512C309.212 139.641 310.123 140.552 311.252 140.552C312.381 140.552 313.293 139.641 313.293 138.512C313.293 137.383 312.381 136.472 311.252 136.472C310.123 136.472 309.212 137.383 309.212 138.512Z"
            fill="#EF4E05"
          />
          <path
            d="M291.383 138.911C290.207 138.911 289.258 139.86 289.258 141.036C289.258 142.213 290.207 143.162 291.383 143.162C292.56 143.162 293.509 142.213 293.509 141.036C293.509 139.86 292.56 138.911 291.383 138.911Z"
            fill="#EF4E05"
          />
          <path
            d="M286.762 161.237C286.762 160.126 285.861 159.216 284.742 159.216C283.631 159.216 282.721 160.117 282.721 161.237C282.721 162.347 283.622 163.258 284.742 163.258C285.861 163.258 286.762 162.356 286.762 161.237Z"
            fill="#EF4E05"
          />
          <path
            d="M261.694 165.146C260.517 165.146 259.568 166.095 259.568 167.272C259.568 168.448 260.517 169.397 261.694 169.397C262.87 169.397 263.819 168.448 263.819 167.272C263.819 166.095 262.87 165.146 261.694 165.146Z"
            fill="#EF4E05"
          />
          <path
            d="M264.986 191.866C264.986 190.784 264.104 189.902 263.022 189.902C261.941 189.902 261.058 190.784 261.058 191.866C261.058 192.948 261.941 193.83 263.022 193.83C264.104 193.83 264.986 192.948 264.986 191.866Z"
            fill="#EF4E05"
          />
          <path
            d="M288.86 201.269C288.86 200.301 288.072 199.514 287.104 199.514C286.136 199.514 285.348 200.301 285.348 201.269C285.348 202.237 286.136 203.024 287.104 203.024C288.072 203.024 288.86 202.237 288.86 201.269Z"
            fill="#EF4E05"
          />
          <path
            d="M264.569 214.382C264.569 215.369 265.366 216.176 266.362 216.176C267.349 216.176 268.156 215.379 268.156 214.382C268.156 213.386 267.359 212.589 266.362 212.589C265.366 212.598 264.569 213.395 264.569 214.382Z"
            fill="#EF4E05"
          />
          <path
            d="M266.571 239.783C267.494 239.783 268.241 239.036 268.241 238.113C268.241 237.191 267.494 236.443 266.571 236.443C265.648 236.443 264.901 237.191 264.901 238.113C264.901 239.036 265.648 239.783 266.571 239.783Z"
            fill="#EF4E05"
          />
          <path
            d="M289.942 225.01C290.833 225.01 291.555 224.287 291.555 223.397C291.555 222.506 290.833 221.784 289.942 221.784C289.05 221.784 288.328 222.506 288.328 223.397C288.328 224.287 289.05 225.01 289.942 225.01Z"
            fill="#EF4E05"
          />
          <path
            d="M315.171 208.234C315.171 207.37 314.469 206.668 313.605 206.668C312.742 206.668 312.04 207.37 312.04 208.234C312.04 209.097 312.742 209.799 313.605 209.799C314.469 209.79 315.171 209.097 315.171 208.234Z"
            fill="#EF4E05"
          />
          <path
            d="M313.578 235.941C313.578 235.153 312.941 234.517 312.154 234.517C311.367 234.517 310.73 235.153 310.73 235.941C310.73 236.727 311.367 237.364 312.154 237.364C312.941 237.364 313.578 236.727 313.578 235.941Z"
            fill="#EF4E05"
          />
          <path
            d="M335.837 228.359C335.837 229.099 336.435 229.687 337.166 229.687C337.906 229.687 338.495 229.089 338.495 228.359C338.495 227.618 337.896 227.031 337.166 227.031C336.435 227.021 335.837 227.618 335.837 228.359Z"
            fill="#EF4E05"
          />
          <path
            d="M340.781 201.136C339.974 201.136 339.319 201.791 339.319 202.598C339.319 203.404 339.974 204.059 340.781 204.059C341.587 204.059 342.242 203.404 342.242 202.598C342.242 201.791 341.596 201.136 340.781 201.136Z"
            fill="#EF4E05"
          />
          <path
            d="M366.334 194.409C367.112 194.409 367.738 193.782 367.738 193.005C367.738 192.226 367.112 191.6 366.334 191.6C365.556 191.6 364.93 192.226 364.93 193.005C364.93 193.782 365.556 194.409 366.334 194.409Z"
            fill="#EF4E05"
          />
          <path
            d="M389.846 204.486C389.154 204.486 388.594 205.046 388.594 205.739C388.594 206.431 389.154 206.991 389.846 206.991C390.538 206.991 391.098 206.431 391.098 205.739C391.098 205.046 390.538 204.486 389.846 204.486Z"
            fill="#EF4E05"
          />
          <path
            d="M413.786 219.363C414.399 219.363 414.896 218.867 414.896 218.254C414.896 217.64 414.399 217.143 413.786 217.143C413.172 217.143 412.676 217.64 412.676 218.254C412.676 218.867 413.172 219.363 413.786 219.363Z"
            fill="#EF4E05"
          />
          <path
            d="M435.002 238.663C435.552 238.663 435.989 238.218 435.989 237.676C435.989 237.136 435.543 236.69 435.002 236.69C434.452 236.69 434.016 237.136 434.016 237.676C434.016 238.218 434.461 238.663 435.002 238.663Z"
            fill="#EF4E05"
          />
          <path
            d="M391.924 240.314C392.521 240.314 393.005 239.83 393.005 239.232C393.005 238.636 392.521 238.151 391.924 238.151C391.327 238.151 390.843 238.636 390.843 239.232C390.843 239.83 391.327 240.314 391.924 240.314Z"
            fill="#EF4E05"
          />
          <path
            d="M365.318 221.916C366.011 221.916 366.571 221.357 366.571 220.664C366.571 219.971 366.011 219.411 365.318 219.411C364.625 219.411 364.065 219.971 364.065 220.664C364.065 221.357 364.625 221.916 365.318 221.916Z"
            fill="#EF4E05"
          />
          <path
            d="M361.049 248.664C361.049 248.029 360.536 247.516 359.9 247.516C359.265 247.516 358.752 248.029 358.752 248.664C358.752 249.3 359.265 249.813 359.9 249.813C360.536 249.813 361.049 249.3 361.049 248.664Z"
            fill="#EF4E05"
          />
          <path
            d="M383.1 270.839C382.559 270.839 382.122 271.275 382.122 271.817C382.122 272.357 382.559 272.794 383.1 272.794C383.64 272.794 384.077 272.357 384.077 271.817C384.077 271.275 383.64 270.839 383.1 270.839Z"
            fill="#EF4E05"
          />
          <path
            d="M416.451 264.481C416.451 264.984 416.86 265.393 417.363 265.393C417.865 265.393 418.274 264.984 418.274 264.481C418.274 263.979 417.865 263.57 417.363 263.57C416.86 263.57 416.451 263.979 416.451 264.481Z"
            fill="#EF4E05"
          />
          <path
            d="M407.543 302.227C407.543 302.683 407.913 303.043 408.358 303.043C408.805 303.043 409.174 302.673 409.174 302.227C409.174 301.772 408.805 301.411 408.358 301.411C407.913 301.411 407.543 301.772 407.543 302.227Z"
            fill="#EF4E05"
          />
          <path
            d="M373.545 306.118C374.043 306.118 374.446 305.714 374.446 305.216C374.446 304.718 374.043 304.314 373.545 304.314C373.047 304.314 372.643 304.718 372.643 305.216C372.643 305.714 373.047 306.118 373.545 306.118Z"
            fill="#EF4E05"
          />
          <path
            d="M340.524 306.905C341.084 306.905 341.54 306.45 341.54 305.89C341.54 305.33 341.084 304.874 340.524 304.874C339.965 304.874 339.509 305.33 339.509 305.89C339.509 306.45 339.965 306.905 340.524 306.905Z"
            fill="#EF4E05"
          />
          <path
            d="M350.127 277.994C350.127 277.405 349.653 276.93 349.064 276.93C348.476 276.93 348.002 277.405 348.002 277.994C348.002 278.581 348.476 279.056 349.064 279.056C349.643 279.065 350.127 278.581 350.127 277.994Z"
            fill="#EF4E05"
          />
          <path
            d="M329.689 258.912C330.371 258.912 330.922 258.362 330.922 257.678C330.922 256.996 330.371 256.445 329.689 256.445C329.005 256.445 328.455 256.996 328.455 257.678C328.455 258.362 329.005 258.912 329.689 258.912Z"
            fill="#EF4E05"
          />
          <path
            d="M288.357 249.423C289.183 249.423 289.847 248.75 289.847 247.934C289.847 247.108 289.172 246.444 288.357 246.444C287.541 246.444 286.867 247.117 286.867 247.934C286.867 248.759 287.532 249.423 288.357 249.423Z"
            fill="#EF4E05"
          />
          <path
            d="M303.405 267.072C304.146 267.072 304.734 266.475 304.734 265.744C304.734 265.013 304.136 264.415 303.405 264.415C302.665 264.415 302.077 265.013 302.077 265.744C302.077 266.475 302.665 267.072 303.405 267.072Z"
            fill="#EF4E05"
          />
          <path
            d="M315.124 291.647C315.778 291.647 316.3 291.116 316.3 290.471C316.3 289.825 315.769 289.294 315.124 289.294C314.479 289.294 313.947 289.825 313.947 290.471C313.947 291.116 314.469 291.647 315.124 291.647Z"
            fill="#EF4E05"
          />
          <path
            d="M317.201 324.962C317.79 324.962 318.265 324.487 318.265 323.899C318.265 323.311 317.79 322.836 317.201 322.836C316.614 322.836 316.139 323.311 316.139 323.899C316.139 324.487 316.614 324.962 317.201 324.962Z"
            fill="#EF4E05"
          />
          <path
            d="M294.041 345.381C294.664 345.381 295.17 344.875 295.17 344.252C295.17 343.628 294.664 343.123 294.041 343.123C293.417 343.123 292.912 343.628 292.912 344.252C292.912 344.875 293.417 345.381 294.041 345.381Z"
            fill="#EF4E05"
          />
          <path
            d="M288.983 312.076C288.291 312.076 287.74 312.636 287.74 313.319C287.74 314.012 288.3 314.563 288.983 314.563C289.666 314.563 290.226 314.003 290.226 313.319C290.226 312.636 289.666 312.076 288.983 312.076Z"
            fill="#EF4E05"
          />
          <path
            d="M285.026 284.74C284.277 284.74 283.679 285.347 283.679 286.087C283.679 286.827 284.286 287.435 285.026 287.435C285.775 287.435 286.373 286.827 286.373 286.087C286.373 285.347 285.766 284.74 285.026 284.74Z"
            fill="#EF4E05"
          />
          <path
            d="M268.763 265.317C269.612 265.317 270.299 264.629 270.299 263.78C270.299 262.93 269.612 262.242 268.763 262.242C267.914 262.242 267.225 262.93 267.225 263.78C267.225 264.629 267.914 265.317 268.763 265.317Z"
            fill="#EF4E05"
          />
          <path
            d="M257.567 287.624C257.567 288.459 258.24 289.124 259.066 289.124C259.9 289.124 260.565 288.449 260.565 287.624C260.565 286.789 259.891 286.125 259.066 286.125C258.24 286.116 257.567 286.789 257.567 287.624Z"
            fill="#EF4E05"
          />
          <path
            d="M240.885 271.997C240.885 272.917 241.635 273.667 242.556 273.667C243.476 273.667 244.226 272.917 244.226 271.997C244.226 271.076 243.476 270.326 242.556 270.326C241.635 270.326 240.885 271.076 240.885 271.997Z"
            fill="#EF4E05"
          />
          <path
            d="M219.148 258.03C219.148 259.073 219.992 259.927 221.045 259.927C222.089 259.927 222.943 259.083 222.943 258.03C222.943 256.986 222.098 256.132 221.045 256.132C219.992 256.132 219.148 256.986 219.148 258.03Z"
            fill="#EF4E05"
          />
          <path
            d="M221.093 236.614C221.093 237.705 221.975 238.597 223.076 238.597C224.167 238.597 225.059 237.714 225.059 236.614C225.059 235.523 224.176 234.631 223.076 234.631C221.985 234.64 221.093 235.523 221.093 236.614Z"
            fill="#EF4E05"
          />
          <path
            d="M223.93 213.339C222.782 213.339 221.842 214.268 221.842 215.426C221.842 216.574 222.772 217.513 223.93 217.513C225.077 217.513 226.017 216.583 226.017 215.426C226.017 214.278 225.077 213.339 223.93 213.339Z"
            fill="#EF4E05"
          />
          <path
            d="M244.88 251.349C245.858 251.349 246.645 250.562 246.645 249.585C246.645 248.607 245.858 247.819 244.88 247.819C243.903 247.819 243.115 248.607 243.115 249.585C243.125 250.562 243.912 251.349 244.88 251.349Z"
            fill="#EF4E05"
          />
          <path
            d="M246.655 226.784C246.655 225.75 245.82 224.905 244.776 224.905C243.742 224.905 242.897 225.74 242.897 226.784C242.897 227.828 243.733 228.662 244.776 228.662C245.82 228.653 246.655 227.818 246.655 226.784Z"
            fill="#EF4E05"
          />
          <path
            d="M244.757 202.635C243.656 202.635 242.755 203.527 242.755 204.637C242.755 205.748 243.647 206.64 244.757 206.64C245.867 206.64 246.759 205.748 246.759 204.637C246.759 203.527 245.858 202.635 244.757 202.635Z"
            fill="#EF4E05"
          />
          <path
            d="M244.235 179.474C243.049 179.474 242.081 180.442 242.081 181.628C242.081 182.814 243.049 183.781 244.235 183.781C245.421 183.781 246.389 182.814 246.389 181.628C246.389 180.442 245.431 179.474 244.235 179.474Z"
            fill="#EF4E05"
          />
          <path
            d="M226.046 196.847C227.256 196.847 228.237 195.866 228.237 194.656C228.237 193.445 227.256 192.463 226.046 192.463C224.835 192.463 223.853 193.445 223.853 194.656C223.853 195.866 224.835 196.847 226.046 196.847Z"
            fill="#EF4E05"
          />
          <path
            d="M208.359 183.905C207.031 183.905 205.958 184.977 205.958 186.306C205.958 187.634 207.031 188.706 208.359 188.706C209.687 188.706 210.76 187.634 210.76 186.306C210.76 184.987 209.687 183.905 208.359 183.905Z"
            fill="#EF4E05"
          />
          <path
            d="M206.433 167.917C207.846 167.917 208.985 166.778 208.985 165.364C208.985 163.95 207.846 162.812 206.433 162.812C205.019 162.812 203.88 163.95 203.88 165.364C203.88 166.778 205.028 167.917 206.433 167.917Z"
            fill="#EF4E05"
          />
          <path
            d="M203.017 149.604C204.521 149.604 205.741 148.384 205.741 146.881C205.741 145.377 204.521 144.158 203.017 144.158C201.513 144.158 200.294 145.377 200.294 146.881C200.294 148.384 201.513 149.604 203.017 149.604Z"
            fill="#EF4E05"
          />
          <path
            d="M224.499 175.84C225.799 175.84 226.852 174.786 226.852 173.486C226.852 172.187 225.799 171.133 224.499 171.133C223.199 171.133 222.146 172.187 222.146 173.486C222.146 174.786 223.199 175.84 224.499 175.84Z"
            fill="#EF4E05"
          />
          <path
            d="M240.032 163.325C241.312 163.325 242.347 162.29 242.347 161.009C242.347 159.729 241.312 158.694 240.032 158.694C238.751 158.694 237.716 159.729 237.716 161.009C237.716 162.29 238.751 163.325 240.032 163.325Z"
            fill="#EF4E05"
          />
          <path
            d="M221.681 155.04C223.076 155.04 224.214 153.902 224.214 152.508C224.214 151.112 223.076 149.974 221.681 149.974C220.286 149.974 219.148 151.112 219.148 152.508C219.148 153.902 220.277 155.04 221.681 155.04Z"
            fill="#EF4E05"
          />
          <path
            d="M216.831 129.954C215.333 129.954 214.118 131.168 214.118 132.667C214.118 134.166 215.333 135.381 216.831 135.381C218.331 135.381 219.546 134.166 219.546 132.667C219.546 131.168 218.331 129.954 216.831 129.954Z"
            fill="#EF4E05"
          />
          <path
            d="M231.948 116.983C230.458 116.983 229.244 118.197 229.244 119.687C229.244 121.177 230.458 122.391 231.948 122.391C233.438 122.391 234.652 121.177 234.652 119.687C234.652 118.197 233.438 116.983 231.948 116.983Z"
            fill="#EF4E05"
          />
          <path
            d="M233.086 138.986C233.086 140.372 234.215 141.501 235.6 141.501C236.986 141.501 238.115 140.372 238.115 138.986C238.115 137.601 236.986 136.472 235.6 136.472C234.215 136.472 233.086 137.601 233.086 138.986Z"
            fill="#EF4E05"
          />
          <path
            d="M252.699 130.219C254.077 130.219 255.194 129.102 255.194 127.724C255.194 126.346 254.077 125.228 252.699 125.228C251.321 125.228 250.204 126.346 250.204 127.724C250.204 129.102 251.321 130.219 252.699 130.219Z"
            fill="#EF4E05"
          />
          <path
            d="M252.842 149.888C254.13 149.888 255.175 148.843 255.175 147.555C255.175 146.265 254.13 145.22 252.842 145.22C251.552 145.22 250.507 146.265 250.507 147.555C250.507 148.843 251.552 149.888 252.842 149.888Z"
            fill="#EF4E05"
          />
          <path
            d="M270.633 147.82C271.866 147.82 272.862 146.823 272.862 145.59C272.862 144.357 271.866 143.361 270.633 143.361C269.398 143.361 268.402 144.357 268.402 145.59C268.412 146.823 269.409 147.82 270.633 147.82Z"
            fill="#EF4E05"
          />
          <path
            d="M275.053 128.9C276.353 128.9 277.407 127.846 277.407 126.547C277.407 125.247 276.353 124.194 275.053 124.194C273.754 124.194 272.7 125.247 272.7 126.547C272.7 127.846 273.754 128.9 275.053 128.9Z"
            fill="#EF4E05"
          />
          <path
            d="M294.743 122.03C295.995 122.03 297.02 121.015 297.02 119.753C297.02 118.5 296.004 117.475 294.743 117.475C293.49 117.475 292.465 118.491 292.465 119.753C292.465 121.015 293.481 122.03 294.743 122.03Z"
            fill="#EF4E05"
          />
          <path
            d="M313.995 119.004C315.2 119.004 316.178 118.026 316.178 116.821C316.178 115.616 315.2 114.638 313.995 114.638C312.79 114.638 311.812 115.616 311.812 116.821C311.812 118.026 312.79 119.004 313.995 119.004Z"
            fill="#EF4E05"
          />
          <path
            d="M297.921 100.273C299.268 100.273 300.369 99.1818 300.369 97.825C300.369 96.4682 299.278 95.3771 297.921 95.3771C296.564 95.3771 295.473 96.4682 295.473 97.825C295.473 99.1818 296.564 100.273 297.921 100.273Z"
            fill="#EF4E05"
          />
          <path
            d="M277.521 105.179C277.521 106.555 278.631 107.665 280.007 107.665C281.382 107.665 282.493 106.555 282.493 105.179C282.493 103.803 281.382 102.693 280.007 102.693C278.64 102.693 277.521 103.813 277.521 105.179Z"
            fill="#EF4E05"
          />
          <path
            d="M260.422 112.238C260.422 113.652 261.561 114.791 262.974 114.791C264.388 114.791 265.527 113.652 265.527 112.238C265.527 110.824 264.388 109.685 262.974 109.685C261.561 109.685 260.422 110.824 260.422 112.238Z"
            fill="#EF4E05"
          />
          <path
            d="M259.578 91.6288C258.069 91.6288 256.845 92.8527 256.845 94.3616C256.845 95.8706 258.069 97.0945 259.578 97.0945C261.086 97.0945 262.31 95.8706 262.31 94.3616C262.31 92.8527 261.086 91.6288 259.578 91.6288Z"
            fill="#EF4E05"
          />
          <path
            d="M273.213 86.638C273.213 88.1276 274.418 89.333 275.907 89.333C277.397 89.333 278.602 88.1276 278.602 86.638C278.602 85.1484 277.397 83.9438 275.907 83.9438C274.418 83.9438 273.213 85.1484 273.213 86.638Z"
            fill="#EF4E05"
          />
          <path
            d="M286.696 77.2919C286.696 78.7723 287.902 79.9769 289.382 79.9769C290.861 79.9769 292.067 78.7723 292.067 77.2919C292.067 75.8116 290.861 74.6061 289.382 74.6061C287.902 74.6061 286.696 75.8116 286.696 77.2919Z"
            fill="#EF4E05"
          />
          <path
            d="M299.97 66.1999C301.489 66.1999 302.723 64.9667 302.723 63.4485C302.723 61.9303 301.489 60.6963 299.97 60.6963C298.452 60.6963 297.219 61.9303 297.219 63.4485C297.219 64.9667 298.452 66.1999 299.97 66.1999Z"
            fill="#EF4E05"
          />
          <path
            d="M316.262 62.1195C317.752 62.1195 318.957 60.9148 318.957 59.4253C318.957 57.9357 317.752 56.7302 316.262 56.7302C314.773 56.7302 313.567 57.9357 313.567 59.4253C313.567 60.9148 314.773 62.1195 316.262 62.1195Z"
            fill="#EF4E05"
          />
          <path
            d="M333.551 61.0855C335.003 61.0855 336.179 59.9086 336.179 58.4569C336.179 57.0051 335.003 55.8291 333.551 55.8291C332.099 55.8291 330.922 57.0051 330.922 58.4569C330.922 59.9086 332.099 61.0855 333.551 61.0855Z"
            fill="#EF4E05"
          />
          <path
            d="M339.67 45.4011C341.188 45.4011 342.423 44.1671 342.423 42.6489C342.423 41.1307 341.188 39.8975 339.67 39.8975C338.152 39.8975 336.919 41.1307 336.919 42.6489C336.919 44.1671 338.152 45.4011 339.67 45.4011Z"
            fill="#EF4E05"
          />
          <path
            d="M329.309 32.743C330.931 32.743 332.241 31.4341 332.241 29.8109C332.241 28.1885 330.931 26.8796 329.309 26.8796C327.686 26.8796 326.377 28.1885 326.377 29.8109C326.377 31.4341 327.696 32.743 329.309 32.743Z"
            fill="#EF4E05"
          />
          <path
            d="M317.619 42.7725C319.223 42.7725 320.514 41.4729 320.514 39.8782C320.514 38.2844 319.213 36.9848 317.619 36.9848C316.015 36.9848 314.725 38.2844 314.725 39.8782C314.725 41.4729 316.015 42.7725 317.619 42.7725Z"
            fill="#EF4E05"
          />
          <path
            d="M302.163 47.7162C303.785 47.7162 305.095 46.4065 305.095 44.7841C305.095 43.1617 303.785 41.852 302.163 41.852C300.54 41.852 299.23 43.1617 299.23 44.7841C299.23 46.4065 300.54 47.7162 302.163 47.7162Z"
            fill="#EF4E05"
          />
          <path
            d="M285.529 57.4035C287.152 57.4035 288.461 56.0947 288.461 54.4723C288.461 52.849 287.152 51.5402 285.529 51.5402C283.907 51.5402 282.597 52.849 282.597 54.4723C282.597 56.0947 283.916 57.4035 285.529 57.4035Z"
            fill="#EF4E05"
          />
          <path
            d="M272.378 68.4956C273.991 68.4956 275.291 67.1867 275.291 65.5828C275.291 63.9697 273.982 62.6701 272.378 62.6701C270.765 62.6701 269.465 63.9789 269.465 65.5828C269.465 67.1867 270.775 68.4956 272.378 68.4956Z"
            fill="#EF4E05"
          />
          <path
            d="M258.724 77.4054C260.338 77.4054 261.646 76.0965 261.646 74.4834C261.646 72.8702 260.338 71.5605 258.724 71.5605C257.111 71.5605 255.802 72.8702 255.802 74.4834C255.802 76.0965 257.111 77.4054 258.724 77.4054Z"
            fill="#EF4E05"
          />
          <path
            d="M243.4 87.2457C245.02 87.2457 246.332 85.9327 246.332 84.3137C246.332 82.6946 245.02 81.3815 243.4 81.3815C241.781 81.3815 240.469 82.6946 240.469 84.3137C240.469 85.9327 241.781 87.2457 243.4 87.2457Z"
            fill="#EF4E05"
          />
          <path
            d="M242.85 106.896C242.85 108.386 244.065 109.6 245.554 109.6C247.044 109.6 248.258 108.386 248.258 106.896C248.258 105.407 247.044 104.192 245.554 104.192C244.065 104.192 242.85 105.407 242.85 106.896Z"
            fill="#EF4E05"
          />
          <path
            d="M231.207 100.093C232.82 100.093 234.12 98.7833 234.12 97.1794C234.12 95.5671 232.811 94.2666 231.207 94.2666C229.594 94.2666 228.294 95.5763 228.294 97.1794C228.294 98.7926 229.604 100.093 231.207 100.093Z"
            fill="#EF4E05"
          />
          <path
            d="M216.253 110.473C217.872 110.473 219.185 109.161 219.185 107.541C219.185 105.922 217.872 104.61 216.253 104.61C214.634 104.61 213.321 105.922 213.321 107.541C213.321 109.161 214.634 110.473 216.253 110.473Z"
            fill="#EF4E05"
          />
          <path
            d="M207.277 121.015C208.897 121.015 210.209 119.702 210.209 118.083C210.209 116.463 208.897 115.151 207.277 115.151C205.658 115.151 204.345 116.463 204.345 118.083C204.345 119.702 205.658 121.015 207.277 121.015Z"
            fill="#EF4E05"
          />
          <path
            d="M194.743 130.399C196.365 130.399 197.675 129.09 197.675 127.467C197.675 125.845 196.365 124.535 194.743 124.535C193.12 124.535 191.811 125.845 191.811 127.467C191.811 129.09 193.12 130.399 194.743 130.399Z"
            fill="#EF4E05"
          />
          <path
            d="M185.577 144.802C187.2 144.802 188.508 143.494 188.508 141.871C188.508 140.248 187.2 138.939 185.577 138.939C183.955 138.939 182.645 140.248 182.645 141.871C182.645 143.494 183.955 144.802 185.577 144.802Z"
            fill="#EF4E05"
          />
          <path
            d="M172.673 154.254C174.285 154.254 175.595 152.944 175.595 151.331C175.595 149.718 174.285 148.409 172.673 148.409C171.06 148.409 169.75 149.718 169.75 151.331C169.75 152.944 171.06 154.254 172.673 154.254Z"
            fill="#EF4E05"
          />
          <path
            d="M183.86 161.427C183.86 162.954 185.093 164.188 186.62 164.188C188.149 164.188 189.382 162.954 189.382 161.427C189.382 159.899 188.149 158.666 186.62 158.666C185.093 158.666 183.86 159.899 183.86 161.427Z"
            fill="#EF4E05"
          />
          <path
            d="M192.409 180.831C193.841 180.831 194.999 179.673 194.999 178.24C194.999 176.808 193.841 175.65 192.409 175.65C190.976 175.65 189.818 176.808 189.818 178.24C189.818 179.673 190.986 180.831 192.409 180.831Z"
            fill="#EF4E05"
          />
          <path
            d="M171.742 179.93C171.742 181.438 172.967 182.663 174.475 182.663C175.984 182.663 177.208 181.438 177.208 179.93C177.208 178.421 175.984 177.197 174.475 177.197C172.967 177.197 171.742 178.421 171.742 179.93Z"
            fill="#EF4E05"
          />
          <path
            d="M165.48 169.122C167.099 169.122 168.413 167.81 168.413 166.19C168.413 164.571 167.099 163.258 165.48 163.258C163.861 163.258 162.548 164.571 162.548 166.19C162.548 167.81 163.861 169.122 165.48 169.122Z"
            fill="#EF4E05"
          />
          <path
            d="M153.791 181.789C155.413 181.789 156.732 180.47 156.732 178.848C156.732 177.225 155.413 175.906 153.791 175.906C152.167 175.906 150.848 177.225 150.848 178.848C150.848 180.47 152.167 181.789 153.791 181.789Z"
            fill="#EF4E05"
          />
          <path
            d="M166.249 193.887C164.759 193.887 163.554 195.092 163.554 196.582C163.554 198.071 164.759 199.277 166.249 199.277C167.738 199.277 168.944 198.071 168.944 196.582C168.944 195.092 167.738 193.887 166.249 193.887Z"
            fill="#EF4E05"
          />
          <path
            d="M159.74 216.27C161.212 216.27 162.406 215.076 162.406 213.604C162.406 212.132 161.212 210.938 159.74 210.938C158.267 210.938 157.073 212.132 157.073 213.604C157.073 215.076 158.267 216.27 159.74 216.27Z"
            fill="#EF4E05"
          />
          <path
            d="M163.469 231.927C162.083 231.927 160.964 233.046 160.964 234.432C160.964 235.817 162.083 236.937 163.469 236.937C164.854 236.937 165.973 235.817 165.973 234.432C165.973 233.046 164.854 231.927 163.469 231.927Z"
            fill="#EF4E05"
          />
          <path
            d="M156.732 256.464C158.098 256.464 159.198 255.363 159.198 253.996C159.198 252.63 158.098 251.53 156.732 251.53C155.365 251.53 154.265 252.63 154.265 253.996C154.265 255.363 155.375 256.464 156.732 256.464Z"
            fill="#EF4E05"
          />
          <path
            d="M179.315 255.515C180.567 255.515 181.583 254.5 181.583 253.247C181.583 251.995 180.567 250.979 179.315 250.979C178.062 250.979 177.047 251.995 177.047 253.247C177.047 254.5 178.062 255.515 179.315 255.515Z"
            fill="#EF4E05"
          />
          <path
            d="M183.376 235.798C184.664 235.798 185.71 234.753 185.71 233.463C185.71 232.175 184.664 231.13 183.376 231.13C182.086 231.13 181.041 232.175 181.041 233.463C181.041 234.753 182.086 235.798 183.376 235.798Z"
            fill="#EF4E05"
          />
          <path
            d="M180.984 216.878C182.332 216.878 183.433 215.787 183.433 214.43C183.433 213.082 182.342 211.982 180.984 211.982C179.637 211.982 178.536 213.073 178.536 214.43C178.536 215.787 179.637 216.878 180.984 216.878Z"
            fill="#EF4E05"
          />
          <path
            d="M187.123 198.707C188.518 198.707 189.648 197.577 189.648 196.183C189.648 194.789 188.518 193.659 187.123 193.659C185.729 193.659 184.6 194.789 184.6 196.183C184.6 197.577 185.729 198.707 187.123 198.707Z"
            fill="#EF4E05"
          />
          <path
            d="M204.468 207.996C205.742 207.996 206.774 206.964 206.774 205.691C206.774 204.417 205.742 203.385 204.468 203.385C203.195 203.385 202.163 204.417 202.163 205.691C202.163 206.964 203.195 207.996 204.468 207.996Z"
            fill="#EF4E05"
          />
          <path
            d="M200.427 225.095C200.427 226.319 201.413 227.306 202.638 227.306C203.862 227.306 204.848 226.319 204.848 225.095C204.848 223.871 203.862 222.884 202.638 222.884C201.423 222.884 200.427 223.88 200.427 225.095Z"
            fill="#EF4E05"
          />
          <path
            d="M201.29 248.883C202.454 248.883 203.396 247.94 203.396 246.776C203.396 245.613 202.454 244.67 201.29 244.67C200.127 244.67 199.184 245.613 199.184 246.776C199.184 247.94 200.127 248.883 201.29 248.883Z"
            fill="#EF4E05"
          />
          <path
            d="M195.673 268.856C195.673 269.985 196.584 270.896 197.712 270.896C198.842 270.896 199.753 269.985 199.753 268.856C199.753 267.727 198.842 266.816 197.712 266.816C196.584 266.816 195.673 267.736 195.673 268.856Z"
            fill="#EF4E05"
          />
          <path
            d="M222.554 279.435C222.554 278.439 221.747 277.623 220.741 277.623C219.745 277.623 218.929 278.43 218.929 279.435C218.929 280.442 219.735 281.248 220.741 281.248C221.747 281.248 222.554 280.442 222.554 279.435Z"
            fill="#EF4E05"
          />
          <path
            d="M205.816 295.082C206.841 295.082 207.676 294.247 207.676 293.222C207.676 292.198 206.841 291.363 205.816 291.363C204.791 291.363 203.956 292.198 203.956 293.222C203.956 294.247 204.791 295.082 205.816 295.082Z"
            fill="#EF4E05"
          />
          <path
            d="M211.993 316.831C212.951 316.831 213.73 316.052 213.73 315.094C213.73 314.135 212.951 313.357 211.993 313.357C211.035 313.357 210.256 314.135 210.256 315.094C210.256 316.052 211.035 316.831 211.993 316.831Z"
            fill="#EF4E05"
          />
          <path
            d="M216.311 338.805C216.311 337.894 215.57 337.154 214.66 337.154C213.748 337.154 213.009 337.894 213.009 338.805C213.009 339.717 213.748 340.456 214.66 340.456C215.57 340.447 216.311 339.717 216.311 338.805Z"
            fill="#EF4E05"
          />
          <path
            d="M237.138 323.329C236.293 323.329 235.62 324.013 235.62 324.848C235.62 325.692 236.303 326.366 237.138 326.366C237.982 326.366 238.656 325.683 238.656 324.848C238.656 324.013 237.982 323.329 237.138 323.329Z"
            fill="#EF4E05"
          />
          <path
            d="M233.921 297.711C233.029 297.711 232.298 298.432 232.298 299.333C232.298 300.225 233.02 300.955 233.921 300.955C234.823 300.955 235.544 300.235 235.544 299.333C235.534 298.432 234.813 297.711 233.921 297.711Z"
            fill="#EF4E05"
          />
          <path
            d="M261.76 310.596C261.76 309.818 261.134 309.192 260.356 309.192C259.578 309.192 258.952 309.818 258.952 310.596C258.952 311.374 259.578 312 260.356 312C261.134 312 261.76 311.374 261.76 310.596Z"
            fill="#EF4E05"
          />
          <path
            d="M265.784 336.889C266.505 336.889 267.084 336.3 267.084 335.589C267.084 334.868 266.495 334.289 265.784 334.289C265.063 334.289 264.484 334.877 264.484 335.589C264.474 336.31 265.063 336.889 265.784 336.889Z"
            fill="#EF4E05"
          />
          <path
            d="M243.333 350.268C243.333 349.48 242.698 348.854 241.92 348.854C241.132 348.854 240.506 349.49 240.506 350.268C240.506 351.055 241.142 351.681 241.92 351.681C242.698 351.691 243.333 351.055 243.333 350.268Z"
            fill="#EF4E05"
          />
          <path
            d="M245.345 378.316C246.095 378.316 246.693 377.709 246.693 376.968C246.693 376.219 246.086 375.62 245.345 375.62C244.596 375.62 243.998 376.228 243.998 376.968C243.998 377.709 244.605 378.316 245.345 378.316Z"
            fill="#EF4E05"
          />
          <path
            d="M243.021 403.394C243.021 404.115 243.609 404.704 244.33 404.704C245.051 404.704 245.639 404.115 245.639 403.394C245.639 402.673 245.051 402.084 244.33 402.084C243.609 402.084 243.021 402.673 243.021 403.394Z"
            fill="#EF4E05"
          />
          <path
            d="M273.849 396.259C274.483 396.259 274.997 395.744 274.997 395.11C274.997 394.477 274.483 393.962 273.849 393.962C273.215 393.962 272.7 394.477 272.7 395.11C272.7 395.744 273.215 396.259 273.849 396.259Z"
            fill="#EF4E05"
          />
          <path
            d="M271.372 364.159C271.372 363.485 270.832 362.945 270.158 362.945C269.484 362.945 268.943 363.485 268.943 364.159C268.943 364.833 269.484 365.373 270.158 365.373C270.832 365.373 271.372 364.833 271.372 364.159Z"
            fill="#EF4E05"
          />
          <path
            d="M301.052 375.631C301.052 375.061 300.587 374.597 300.018 374.597C299.449 374.597 298.984 375.061 298.984 375.631C298.984 376.2 299.449 376.665 300.018 376.665C300.587 376.665 301.052 376.21 301.052 375.631Z"
            fill="#EF4E05"
          />
          <path
            d="M326.558 355.249C326.036 355.249 325.609 355.676 325.609 356.198C325.609 356.72 326.036 357.147 326.558 357.147C327.08 357.147 327.507 356.72 327.507 356.198C327.507 355.676 327.089 355.249 326.558 355.249Z"
            fill="#EF4E05"
          />
          <path
            d="M355.261 335.817C355.261 335.333 354.862 334.934 354.378 334.934C353.894 334.934 353.496 335.333 353.496 335.817C353.496 336.3 353.894 336.699 354.378 336.699C354.862 336.699 355.261 336.309 355.261 335.817Z"
            fill="#EF4E05"
          />
          <path
            d="M362.671 372.623C362.671 373.059 363.022 373.41 363.459 373.41C363.895 373.41 364.246 373.059 364.246 372.623C364.246 372.186 363.895 371.835 363.459 371.835C363.022 371.835 362.671 372.186 362.671 372.623Z"
            fill="#EF4E05"
          />
          <path
            d="M399.288 396.354C399.658 396.354 399.961 396.049 399.961 395.679C399.961 395.31 399.658 395.006 399.288 395.006C398.917 395.006 398.614 395.31 398.614 395.679C398.614 396.049 398.917 396.354 399.288 396.354Z"
            fill="#EF4E05"
          />
          <path
            d="M395.606 343.132C395.606 342.714 395.273 342.382 394.856 342.382C394.439 342.382 394.107 342.714 394.107 343.132C394.107 343.55 394.439 343.882 394.856 343.882C395.273 343.891 395.606 343.55 395.606 343.132Z"
            fill="#EF4E05"
          />
          <path
            d="M441.967 340.001C442.338 340.001 442.64 339.7 442.64 339.327C442.64 338.955 442.338 338.654 441.967 338.654C441.595 338.654 441.293 338.955 441.293 339.327C441.293 339.7 441.595 340.001 441.967 340.001Z"
            fill="#EF4E05"
          />
          <path
            d="M446.35 294.01C446.35 293.592 446.009 293.251 445.591 293.251C445.174 293.251 444.832 293.592 444.832 294.01C444.832 294.428 445.174 294.769 445.591 294.769C446.009 294.769 446.35 294.437 446.35 294.01Z"
            fill="#EF4E05"
          />
          <path
            d="M460.735 262.394C461.2 262.394 461.58 262.015 461.58 261.55C461.58 261.085 461.2 260.705 460.735 260.705C460.27 260.705 459.891 261.085 459.891 261.55C459.891 262.015 460.27 262.394 460.735 262.394Z"
            fill="#EF4E05"
          />
          <path
            d="M491.971 298.726C491.971 298.337 491.658 298.024 491.269 298.024C490.88 298.024 490.567 298.337 490.567 298.726C490.567 299.115 490.88 299.428 491.269 299.428C491.649 299.437 491.971 299.115 491.971 298.726Z"
            fill="#EF4E05"
          />
          <path
            d="M542.63 302.635C542.63 303.006 542.934 303.309 543.304 303.309C543.674 303.309 543.978 303.006 543.978 302.635C543.978 302.265 543.674 301.962 543.304 301.962C542.934 301.962 542.63 302.265 542.63 302.635Z"
            fill="#EF4E05"
          />
          <path
            d="M598.224 311.991C598.603 311.991 598.908 311.688 598.908 311.308C598.908 310.929 598.603 310.624 598.224 310.624C597.844 310.624 597.541 310.929 597.541 311.308C597.541 311.688 597.844 311.991 598.224 311.991Z"
            fill="#EF4E05"
          />
          <path
            d="M642.232 301.145C642.659 301.145 643 300.804 643 300.377C643 299.95 642.659 299.609 642.232 299.609C641.805 299.609 641.463 299.95 641.463 300.377C641.463 300.795 641.814 301.145 642.232 301.145Z"
            fill="#EF4E05"
          />
          <path
            d="M682.596 287.52C683.089 287.52 683.488 287.12 683.488 286.628C683.488 286.135 683.089 285.736 682.596 285.736C682.103 285.736 681.704 286.135 681.704 286.628C681.704 287.12 682.103 287.52 682.596 287.52Z"
            fill="#EF4E05"
          />
          <path
            d="M655.639 265.013C656.137 265.013 656.541 264.609 656.541 264.112C656.541 263.614 656.137 263.21 655.639 263.21C655.141 263.21 654.738 263.614 654.738 264.112C654.738 264.609 655.141 265.013 655.639 265.013Z"
            fill="#EF4E05"
          />
          <path
            d="M669.587 234.64C670.179 234.64 670.66 234.16 670.66 233.568C670.66 232.976 670.179 232.496 669.587 232.496C668.995 232.496 668.515 232.976 668.515 233.568C668.515 234.16 668.995 234.64 669.587 234.64Z"
            fill="#EF4E05"
          />
          <path
            d="M654.254 207.664C653.618 207.664 653.106 208.176 653.106 208.813C653.106 209.448 653.618 209.96 654.254 209.96C654.89 209.96 655.402 209.448 655.402 208.813C655.402 208.176 654.89 207.664 654.254 207.664Z"
            fill="#EF4E05"
          />
          <path
            d="M667.376 184.104C668.107 184.104 668.695 183.516 668.695 182.785C668.695 182.055 668.107 181.466 667.376 181.466C666.646 181.466 666.057 182.055 666.057 182.785C666.057 183.516 666.646 184.104 667.376 184.104Z"
            fill="#EF4E05"
          />
          <path
            d="M651.853 161.872C652.65 161.872 653.286 161.227 653.286 160.44C653.286 159.652 652.641 159.007 651.853 159.007C651.056 159.007 650.42 159.652 650.42 160.44C650.42 161.227 651.056 161.872 651.853 161.872Z"
            fill="#EF4E05"
          />
          <path
            d="M638.123 185.195C638.815 185.195 639.376 184.635 639.376 183.943C639.376 183.251 638.815 182.69 638.123 182.69C637.432 182.69 636.871 183.251 636.871 183.943C636.871 184.635 637.432 185.195 638.123 185.195Z"
            fill="#EF4E05"
          />
          <path
            d="M621.955 209.515C621.955 210.112 622.439 210.597 623.037 210.597C623.635 210.597 624.118 210.112 624.118 209.515C624.118 208.917 623.635 208.433 623.037 208.433C622.439 208.433 621.955 208.917 621.955 209.515Z"
            fill="#EF4E05"
          />
          <path
            d="M634.689 238.236C635.22 238.236 635.656 237.8 635.656 237.269C635.656 236.737 635.22 236.301 634.689 236.301C634.157 236.301 633.72 236.737 633.72 237.269C633.72 237.8 634.147 238.236 634.689 238.236Z"
            fill="#EF4E05"
          />
          <path
            d="M616.812 268.856C616.812 268.4 616.442 268.04 615.996 268.04C615.541 268.04 615.18 268.41 615.18 268.856C615.18 269.312 615.55 269.672 615.996 269.672C616.442 269.672 616.812 269.312 616.812 268.856Z"
            fill="#EF4E05"
          />
          <path
            d="M596.573 237.012C597.076 237.012 597.483 236.605 597.483 236.102C597.483 235.598 597.076 235.191 596.573 235.191C596.07 235.191 595.662 235.598 595.662 236.102C595.662 236.605 596.07 237.012 596.573 237.012Z"
            fill="#EF4E05"
          />
          <path
            d="M574.142 268.429C573.715 268.429 573.364 268.78 573.364 269.207C573.364 269.634 573.715 269.985 574.142 269.985C574.569 269.985 574.92 269.634 574.92 269.207C574.92 268.78 574.569 268.429 574.142 268.429Z"
            fill="#EF4E05"
          />
          <path
            d="M554.482 236.585C554.482 237.069 554.88 237.468 555.364 237.468C555.848 237.468 556.246 237.069 556.246 236.585C556.246 236.101 555.848 235.703 555.364 235.703C554.88 235.703 554.482 236.092 554.482 236.585Z"
            fill="#EF4E05"
          />
          <path
            d="M527.886 263.95C528.322 263.95 528.673 263.6 528.673 263.163C528.673 262.726 528.322 262.376 527.886 262.376C527.448 262.376 527.098 262.726 527.098 263.163C527.089 263.6 527.448 263.95 527.886 263.95Z"
            fill="#EF4E05"
          />
          <path
            d="M495.33 248.246C495.33 247.772 494.951 247.393 494.476 247.393C494.002 247.393 493.622 247.772 493.622 248.246C493.622 248.721 494.002 249.101 494.476 249.101C494.951 249.101 495.33 248.721 495.33 248.246Z"
            fill="#EF4E05"
          />
          <path
            d="M466.94 224.022C466.4 224.022 465.964 224.459 465.964 225C465.964 225.54 466.4 225.977 466.94 225.977C467.482 225.977 467.918 225.54 467.918 225C467.918 224.459 467.482 224.022 466.94 224.022Z"
            fill="#EF4E05"
          />
          <path
            d="M494.809 208.309C495.38 208.309 495.843 207.847 495.843 207.275C495.843 206.704 495.38 206.241 494.809 206.241C494.237 206.241 493.774 206.704 493.774 207.275C493.774 207.847 494.237 208.309 494.809 208.309Z"
            fill="#EF4E05"
          />
          <path
            d="M521.652 222.723C521.652 223.245 522.079 223.672 522.601 223.672C523.123 223.672 523.549 223.245 523.549 222.723C523.549 222.201 523.123 221.774 522.601 221.774C522.079 221.774 521.652 222.191 521.652 222.723Z"
            fill="#EF4E05"
          />
          <path
            d="M571.77 210.293C571.77 209.733 571.315 209.287 570.764 209.287C570.204 209.287 569.758 209.742 569.758 210.293C569.758 210.853 570.214 211.298 570.764 211.298C571.315 211.308 571.77 210.853 571.77 210.293Z"
            fill="#EF4E05"
          />
          <path
            d="M546.293 198.166C546.293 197.569 545.81 197.094 545.221 197.094C544.633 197.094 544.148 197.578 544.148 198.166C544.148 198.764 544.633 199.239 545.221 199.239C545.81 199.239 546.293 198.755 546.293 198.166Z"
            fill="#EF4E05"
          />
          <path
            d="M544.471 168.468C545.164 168.468 545.714 167.908 545.714 167.224C545.714 166.532 545.154 165.981 544.471 165.981C543.788 165.981 543.229 166.541 543.229 167.224C543.229 167.908 543.788 168.468 544.471 168.468Z"
            fill="#EF4E05"
          />
          <path
            d="M518.994 185.974C519.623 185.974 520.133 185.464 520.133 184.835C520.133 184.206 519.623 183.697 518.994 183.697C518.366 183.697 517.856 184.206 517.856 184.835C517.856 185.464 518.366 185.974 518.994 185.974Z"
            fill="#EF4E05"
          />
          <path
            d="M494.656 173.591C494.656 172.917 494.106 172.367 493.432 172.367C492.758 172.367 492.208 172.917 492.208 173.591C492.208 174.265 492.758 174.815 493.432 174.815C494.106 174.825 494.656 174.274 494.656 173.591Z"
            fill="#EF4E05"
          />
          <path
            d="M488.717 147.65C489.493 147.65 490.122 147.021 490.122 146.245C490.122 145.469 489.493 144.841 488.717 144.841C487.941 144.841 487.313 145.469 487.313 146.245C487.313 147.021 487.941 147.65 488.717 147.65Z"
            fill="#EF4E05"
          />
          <path
            d="M517.495 153.38C518.245 153.38 518.843 152.773 518.843 152.033C518.843 151.284 518.235 150.686 517.495 150.686C516.746 150.686 516.148 151.293 516.148 152.033C516.148 152.773 516.746 153.38 517.495 153.38Z"
            fill="#EF4E05"
          />
          <path
            d="M543.124 138.816C543.124 139.603 543.76 140.239 544.548 140.239C545.334 140.239 545.971 139.603 545.971 138.816C545.971 138.028 545.334 137.392 544.548 137.392C543.76 137.392 543.124 138.028 543.124 138.816Z"
            fill="#EF4E05"
          />
          <path
            d="M568.914 148.238C568.155 148.238 567.548 148.854 567.548 149.604C567.548 150.354 568.164 150.97 568.914 150.97C569.664 150.97 570.28 150.354 570.28 149.604C570.28 148.854 569.673 148.238 568.914 148.238Z"
            fill="#EF4E05"
          />
          <path
            d="M591.155 129.593C591.155 128.748 590.472 128.075 589.637 128.075C588.802 128.075 588.119 128.757 588.119 129.593C588.119 130.428 588.802 131.111 589.637 131.111C590.472 131.111 591.155 130.428 591.155 129.593Z"
            fill="#EF4E05"
          />
          <path
            d="M612.058 134.992C612.058 135.827 612.733 136.5 613.567 136.5C614.402 136.5 615.076 135.827 615.076 134.992C615.076 134.157 614.402 133.483 613.567 133.483C612.733 133.483 612.058 134.148 612.058 134.992Z"
            fill="#EF4E05"
          />
          <path
            d="M593.944 155.923C593.944 156.673 594.552 157.271 595.292 157.271C596.042 157.271 596.639 156.664 596.639 155.923C596.639 155.183 596.032 154.576 595.292 154.576C594.552 154.576 593.944 155.183 593.944 155.923Z"
            fill="#EF4E05"
          />
          <path
            d="M574.038 176.988C573.374 176.988 572.842 177.529 572.842 178.184C572.842 178.839 573.383 179.379 574.038 179.379C574.692 179.379 575.233 178.839 575.233 178.184C575.233 177.529 574.702 176.988 574.038 176.988Z"
            fill="#EF4E05"
          />
          <path
            d="M595.7 202.92C596.297 202.92 596.782 202.436 596.782 201.838C596.782 201.241 596.297 200.757 595.7 200.757C595.103 200.757 594.619 201.241 594.619 201.838C594.619 202.436 595.103 202.92 595.7 202.92Z"
            fill="#EF4E05"
          />
          <path
            d="M609.923 179.474C609.923 178.8 609.382 178.259 608.709 178.259C608.035 178.259 607.494 178.8 607.494 179.474C607.494 180.147 608.035 180.688 608.709 180.688C609.373 180.688 609.923 180.147 609.923 179.474Z"
            fill="#EF4E05"
          />
          <path
            d="M624.564 157.328C623.805 157.328 623.198 157.944 623.198 158.694C623.198 159.453 623.814 160.061 624.564 160.061C625.323 160.061 625.93 159.444 625.93 158.694C625.94 157.944 625.323 157.328 624.564 157.328Z"
            fill="#EF4E05"
          />
          <path
            d="M640.467 138.094C640.467 137.24 639.775 136.538 638.911 136.538C638.057 136.538 637.365 137.231 637.365 138.094C637.365 138.958 638.057 139.65 638.911 139.65C639.775 139.65 640.467 138.948 640.467 138.094Z"
            fill="#EF4E05"
          />
          <path
            d="M651.721 120.427C652.669 120.427 653.447 119.659 653.447 118.7C653.447 117.742 652.679 116.973 651.721 116.973C650.762 116.973 649.993 117.742 649.993 118.7C649.993 119.659 650.772 120.427 651.721 120.427Z"
            fill="#EF4E05"
          />
          <path
            d="M673.345 112.172C673.345 111.147 672.519 110.322 671.495 110.322C670.47 110.322 669.645 111.147 669.645 112.172C669.645 113.187 670.47 114.022 671.495 114.022C672.519 114.022 673.345 113.197 673.345 112.172Z"
            fill="#EF4E05"
          />
          <path
            d="M683.753 97.7586C684.864 97.7586 685.774 96.8574 685.774 95.7377C685.774 94.618 684.873 93.7169 683.753 93.7169C682.634 93.7169 681.732 94.618 681.732 95.7377C681.732 96.8574 682.644 97.7586 683.753 97.7586Z"
            fill="#EF4E05"
          />
          <path
            d="M696.839 85.0063C698.043 85.0063 699.03 84.0287 699.03 82.814C699.03 81.6094 698.053 80.6225 696.839 80.6225C695.633 80.6225 694.646 81.6001 694.646 82.814C694.646 84.0287 695.633 85.0063 696.839 85.0063Z"
            fill="#EF4E05"
          />
          <path
            d="M715.626 73.8285C715.626 72.5382 714.582 71.4941 713.291 71.4941C712.001 71.4941 710.957 72.5382 710.957 73.8285C710.957 75.1189 712.001 76.1629 713.291 76.1629C714.582 76.1629 715.626 75.1189 715.626 73.8285Z"
            fill="#EF4E05"
          />
          <path
            d="M729.83 84.5415C731.12 84.5415 732.164 83.4974 732.164 82.207C732.164 80.9167 731.12 79.8726 729.83 79.8726C728.539 79.8726 727.495 80.9167 727.495 82.207C727.505 83.4882 728.548 84.5415 729.83 84.5415Z"
            fill="#EF4E05"
          />
          <path
            d="M711.261 98.9069C712.428 98.9069 713.377 97.9578 713.377 96.791C713.377 95.6234 712.428 94.6752 711.261 94.6752C710.094 94.6752 709.146 95.6234 709.146 96.791C709.146 97.9578 710.084 98.9069 711.261 98.9069Z"
            fill="#EF4E05"
          />
          <path
            d="M699.324 112.257C699.324 111.194 698.461 110.322 697.388 110.322C696.326 110.322 695.453 111.185 695.453 112.257C695.453 113.32 696.316 114.193 697.388 114.193C698.461 114.184 699.324 113.32 699.324 112.257Z"
            fill="#EF4E05"
          />
          <path
            d="M686.42 132.714C686.42 131.765 685.652 130.997 684.703 130.997C683.754 130.997 682.985 131.765 682.985 132.714C682.985 133.663 683.754 134.432 684.703 134.432C685.652 134.422 686.42 133.653 686.42 132.714Z"
            fill="#EF4E05"
          />
          <path
            d="M665.346 137.782C665.346 136.89 664.625 136.159 663.723 136.159C662.831 136.159 662.101 136.88 662.101 137.782C662.101 138.683 662.822 139.404 663.723 139.404C664.615 139.395 665.346 138.674 665.346 137.782Z"
            fill="#EF4E05"
          />
          <path
            d="M679.303 158.258C679.303 157.413 678.621 156.739 677.785 156.739C676.941 156.739 676.267 157.423 676.267 158.258C676.267 159.092 676.95 159.776 677.785 159.776C678.621 159.785 679.303 159.102 679.303 158.258Z"
            fill="#EF4E05"
          />
          <path
            d="M702.361 154.737C702.361 153.845 701.64 153.125 700.748 153.125C699.856 153.125 699.135 153.845 699.135 154.737C699.135 155.629 699.856 156.35 700.748 156.35C701.64 156.35 702.361 155.629 702.361 154.737Z"
            fill="#EF4E05"
          />
          <path
            d="M720.807 171.2C720.807 170.327 720.095 169.615 719.222 169.615C718.349 169.615 717.637 170.327 717.637 171.2C717.637 172.073 718.349 172.784 719.222 172.784C720.095 172.784 720.807 172.073 720.807 171.2Z"
            fill="#EF4E05"
          />
          <path
            d="M740.116 184.712C740.998 184.712 741.72 184 741.72 183.108C741.72 182.216 741.008 181.504 740.116 181.504C739.233 181.504 738.512 182.216 738.512 183.108C738.512 184 739.233 184.712 740.116 184.712Z"
            fill="#EF4E05"
          />
          <path
            d="M744.784 207.114C745.602 207.114 746.264 206.451 746.264 205.633C746.264 204.816 745.602 204.154 744.784 204.154C743.967 204.154 743.304 204.816 743.304 205.633C743.304 206.451 743.967 207.114 744.784 207.114Z"
            fill="#EF4E05"
          />
          <path
            d="M725.323 222.22C726.051 222.22 726.642 221.63 726.642 220.901C726.642 220.173 726.051 219.582 725.323 219.582C724.595 219.582 724.004 220.173 724.004 220.901C724.004 221.63 724.595 222.22 725.323 222.22Z"
            fill="#EF4E05"
          />
          <path
            d="M714.904 199.067C715.673 199.067 716.299 198.442 716.299 197.673C716.299 196.904 715.673 196.278 714.904 196.278C714.136 196.278 713.51 196.904 713.51 197.673C713.51 198.442 714.136 199.067 714.904 199.067Z"
            fill="#EF4E05"
          />
          <path
            d="M694.628 181.125C695.419 181.125 696.06 180.484 696.06 179.692C696.06 178.901 695.419 178.259 694.628 178.259C693.836 178.259 693.194 178.901 693.194 179.692C693.194 180.484 693.836 181.125 694.628 181.125Z"
            fill="#EF4E05"
          />
          <path
            d="M684.712 206.004C685.404 206.004 685.965 205.444 685.965 204.752C685.965 204.06 685.404 203.499 684.712 203.499C684.02 203.499 683.459 204.06 683.459 204.752C683.459 205.444 684.02 206.004 684.712 206.004Z"
            fill="#EF4E05"
          />
          <path
            d="M699.732 227.79C700.387 227.79 700.918 227.259 700.918 226.604C700.918 225.949 700.387 225.418 699.732 225.418C699.078 225.418 698.547 225.949 698.547 226.604C698.537 227.259 699.068 227.79 699.732 227.79Z"
            fill="#EF4E05"
          />
          <path
            d="M692.132 257.536C692.701 257.536 693.167 257.071 693.167 256.502C693.167 255.932 692.701 255.467 692.132 255.467C691.563 255.467 691.098 255.932 691.098 256.502C691.098 257.071 691.563 257.536 692.132 257.536Z"
            fill="#EF4E05"
          />
          <path
            d="M723.093 248.057C723.093 247.412 722.571 246.89 721.926 246.89C721.281 246.89 720.759 247.412 720.759 248.057C720.759 248.702 721.281 249.224 721.926 249.224C722.571 249.233 723.093 248.702 723.093 248.057Z"
            fill="#EF4E05"
          />
          <path
            d="M746.928 258.067C746.928 258.751 747.479 259.301 748.162 259.301C748.845 259.301 749.395 258.751 749.395 258.067C749.395 257.384 748.845 256.834 748.162 256.834C747.488 256.834 746.928 257.384 746.928 258.067Z"
            fill="#EF4E05"
          />
          <path
            d="M747.82 231.68C747.82 232.439 748.437 233.055 749.196 233.055C749.955 233.055 750.571 232.439 750.571 231.68C750.571 230.921 749.955 230.304 749.196 230.304C748.437 230.304 747.82 230.921 747.82 231.68Z"
            fill="#EF4E05"
          />
          <path
            d="M774.075 240.675C774.881 240.675 775.527 240.02 775.527 239.223C775.527 238.417 774.872 237.771 774.075 237.771C773.268 237.771 772.624 238.426 772.624 239.223C772.624 240.029 773.278 240.675 774.075 240.675Z"
            fill="#EF4E05"
          />
          <path
            d="M769.986 215.634C770.849 215.634 771.561 214.932 771.561 214.06C771.561 213.197 770.858 212.485 769.986 212.485C769.122 212.485 768.41 213.187 768.41 214.06C768.41 214.932 769.122 215.634 769.986 215.634Z"
            fill="#EF4E05"
          />
          <path
            d="M763.4 190.49C763.4 191.42 764.15 192.17 765.08 192.17C766.009 192.17 766.759 191.42 766.759 190.49C766.759 189.561 766.009 188.811 765.08 188.811C764.15 188.811 763.4 189.561 763.4 190.49Z"
            fill="#EF4E05"
          />
          <path
            d="M761.171 166.19C760.175 166.19 759.358 166.997 759.358 168.002C759.358 169.008 760.165 169.814 761.171 169.814C762.176 169.814 762.983 169.008 762.983 168.002C762.983 166.997 762.167 166.19 761.171 166.19Z"
            fill="#EF4E05"
          />
          <path
            d="M740.979 160.336C741.948 160.336 742.734 159.55 742.734 158.58C742.734 157.611 741.948 156.825 740.979 156.825C740.01 156.825 739.224 157.611 739.224 158.58C739.224 159.55 740.01 160.336 740.979 160.336Z"
            fill="#EF4E05"
          />
          <path
            d="M759.7 146.416C760.79 146.416 761.674 145.532 761.674 144.443C761.674 143.352 760.79 142.469 759.7 142.469C758.61 142.469 757.727 143.352 757.727 144.443C757.727 145.532 758.61 146.416 759.7 146.416Z"
            fill="#EF4E05"
          />
          <path
            d="M740.78 135.334C741.852 135.334 742.725 134.46 742.725 133.388C742.725 132.316 741.852 131.443 740.78 131.443C739.707 131.443 738.835 132.316 738.835 133.388C738.835 134.46 739.707 135.334 740.78 135.334Z"
            fill="#EF4E05"
          />
          <path
            d="M721.044 145.847C721.044 146.824 721.841 147.621 722.818 147.621C723.795 147.621 724.592 146.824 724.592 145.847C724.592 144.869 723.795 144.072 722.818 144.072C721.841 144.081 721.044 144.869 721.044 145.847Z"
            fill="#EF4E05"
          />
          <path
            d="M705.881 134.28C706.877 134.28 707.694 133.473 707.694 132.468C707.694 131.471 706.887 130.656 705.881 130.656C704.884 130.656 704.069 131.462 704.069 132.468C704.069 133.473 704.884 134.28 705.881 134.28Z"
            fill="#EF4E05"
          />
          <path
            d="M721.736 121.717C722.827 121.717 723.71 120.834 723.71 119.743C723.71 118.654 722.827 117.77 721.736 117.77C720.646 117.77 719.763 118.654 719.763 119.743C719.763 120.834 720.646 121.717 721.736 121.717Z"
            fill="#EF4E05"
          />
          <path
            d="M731.974 101.573C731.974 100.387 731.007 99.4197 729.82 99.4197C728.634 99.4197 727.667 100.387 727.667 101.573C727.667 102.759 728.634 103.727 729.82 103.727C731.016 103.718 731.974 102.759 731.974 101.573Z"
            fill="#EF4E05"
          />
          <path
            d="M744.916 114.743C746.101 114.743 747.061 113.783 747.061 112.599C747.061 111.415 746.101 110.455 744.916 110.455C743.733 110.455 742.772 111.415 742.772 112.599C742.772 113.783 743.733 114.743 744.916 114.743Z"
            fill="#EF4E05"
          />
          <path
            d="M761.702 124.668C762.892 124.668 763.856 123.704 763.856 122.514C763.856 121.325 762.892 120.36 761.702 120.36C760.513 120.36 759.548 121.325 759.548 122.514C759.548 123.704 760.513 124.668 761.702 124.668Z"
            fill="#EF4E05"
          />
          <path
            d="M747.526 93.4891C748.816 93.4891 749.86 92.445 749.86 91.1547C749.86 89.8643 748.816 88.8202 747.526 88.8202C746.235 88.8202 745.191 89.8643 745.191 91.1547C745.201 92.445 746.245 93.4891 747.526 93.4891Z"
            fill="#EF4E05"
          />
          <path
            d="M763.647 103.519C764.928 103.519 765.972 102.475 765.972 101.193C765.972 99.9123 764.928 98.8691 763.647 98.8691C762.366 98.8691 761.322 99.9123 761.322 101.193C761.322 102.475 762.366 103.519 763.647 103.519Z"
            fill="#EF4E05"
          />
          <path
            d="M780.281 113.073C781.571 113.073 782.614 112.03 782.614 110.739C782.614 109.448 781.571 108.405 780.281 108.405C778.99 108.405 777.946 109.448 777.946 110.739C777.946 112.03 778.99 113.073 780.281 113.073Z"
            fill="#EF4E05"
          />
          <path
            d="M781.666 93.3655C783.06 93.3655 784.19 92.2357 784.19 90.8411C784.19 89.4473 783.06 88.3175 781.666 88.3175C780.272 88.3175 779.142 89.4473 779.142 90.8411C779.142 92.2357 780.272 93.3655 781.666 93.3655Z"
            fill="#EF4E05"
          />
          <path
            d="M799.353 103.129C800.773 103.129 801.923 101.978 801.923 100.558C801.923 99.1381 800.773 97.9864 799.353 97.9864C797.932 97.9864 796.781 99.1381 796.781 100.558C796.781 101.978 797.932 103.129 799.353 103.129Z"
            fill="#EF4E05"
          />
          <path
            d="M797.132 121.575C798.451 121.575 799.513 120.512 799.513 119.193C799.513 117.874 798.451 116.811 797.132 116.811C795.813 116.811 794.75 117.874 794.75 119.193C794.75 120.512 795.813 121.575 797.132 121.575Z"
            fill="#EF4E05"
          />
          <path
            d="M799.314 140.714C799.314 139.499 798.327 138.512 797.113 138.512C795.899 138.512 794.912 139.499 794.912 140.714C794.912 141.928 795.899 142.914 797.113 142.914C798.327 142.914 799.314 141.928 799.314 140.714Z"
            fill="#EF4E05"
          />
          <path
            d="M779.085 134.1C780.281 134.1 781.258 133.132 781.258 131.927C781.258 130.731 780.29 129.754 779.085 129.754C777.88 129.754 776.912 130.722 776.912 131.927C776.912 133.132 777.88 134.1 779.085 134.1Z"
            fill="#EF4E05"
          />
          <path
            d="M779.531 151.796C778.43 151.796 777.528 152.688 777.528 153.798C777.528 154.898 778.42 155.8 779.531 155.8C780.631 155.8 781.533 154.908 781.533 153.798C781.533 152.688 780.631 151.796 779.531 151.796Z"
            fill="#EF4E05"
          />
          <path
            d="M783.876 173.458C782.842 173.458 781.998 174.303 781.998 175.337C781.998 176.371 782.842 177.215 783.876 177.215C784.911 177.215 785.755 176.371 785.755 175.337C785.755 174.293 784.911 173.458 783.876 173.458Z"
            fill="#EF4E05"
          />
          <path
            d="M789.313 199.03C790.293 199.03 791.088 198.235 791.088 197.256C791.088 196.276 790.293 195.481 789.313 195.481C788.334 195.481 787.54 196.276 787.54 197.256C787.54 198.235 788.334 199.03 789.313 199.03Z"
            fill="#EF4E05"
          />
          <path
            d="M794.75 221.773C795.678 221.773 796.43 221.022 796.43 220.095C796.43 219.167 795.678 218.415 794.75 218.415C793.823 218.415 793.071 219.167 793.071 220.095C793.071 221.022 793.823 221.773 794.75 221.773Z"
            fill="#EF4E05"
          />
          <path
            d="M798.66 245.4C799.533 245.4 800.245 244.689 800.245 243.816C800.245 242.943 799.533 242.231 798.66 242.231C797.787 242.231 797.075 242.943 797.075 243.816C797.075 244.689 797.787 245.4 798.66 245.4Z"
            fill="#EF4E05"
          />
          <path
            d="M817.789 256.255C818.699 256.255 819.44 255.514 819.44 254.604C819.44 253.693 818.699 252.953 817.789 252.953C816.877 252.953 816.138 253.693 816.138 254.604C816.138 255.514 816.888 256.255 817.789 256.255Z"
            fill="#EF4E05"
          />
          <path
            d="M800.112 272.756C800.112 271.959 799.466 271.323 798.679 271.323C797.882 271.323 797.246 271.968 797.246 272.756C797.246 273.543 797.891 274.188 798.679 274.188C799.466 274.198 800.112 273.553 800.112 272.756Z"
            fill="#EF4E05"
          />
          <path
            d="M773.762 265.118C773.762 265.867 774.369 266.464 775.109 266.464C775.859 266.464 776.457 265.858 776.457 265.118C776.457 264.368 775.849 263.77 775.109 263.77C774.369 263.77 773.762 264.368 773.762 265.118Z"
            fill="#EF4E05"
          />
          <path
            d="M778.535 294.256C777.842 294.256 777.292 294.816 777.292 295.5C777.292 296.192 777.851 296.743 778.535 296.743C779.227 296.743 779.777 296.183 779.777 295.5C779.777 294.807 779.217 294.256 778.535 294.256Z"
            fill="#EF4E05"
          />
          <path
            d="M806.564 299.589C807.332 299.589 807.949 298.963 807.949 298.204C807.949 297.436 807.323 296.819 806.564 296.819C805.795 296.819 805.178 297.445 805.178 298.204C805.169 298.972 805.795 299.589 806.564 299.589Z"
            fill="#EF4E05"
          />
          <path
            d="M790.557 322.39C790.557 323.074 791.107 323.624 791.79 323.624C792.473 323.624 793.024 323.074 793.024 322.39C793.024 321.707 792.473 321.157 791.79 321.157C791.107 321.157 790.557 321.707 790.557 322.39Z"
            fill="#EF4E05"
          />
          <path
            d="M761.636 321.707C761.038 321.707 760.554 322.191 760.554 322.789C760.554 323.387 761.038 323.87 761.636 323.87C762.233 323.87 762.718 323.387 762.718 322.789C762.718 322.191 762.233 321.707 761.636 321.707Z"
            fill="#EF4E05"
          />
          <path
            d="M736.538 308.025C737.098 308.025 737.553 307.569 737.553 307.01C737.553 306.45 737.098 305.994 736.538 305.994C735.978 305.994 735.523 306.45 735.523 307.01C735.523 307.569 735.978 308.025 736.538 308.025Z"
            fill="#EF4E05"
          />
          <path
            d="M752.83 286.144C753.475 286.144 753.988 285.622 753.988 284.987C753.988 284.35 753.466 283.829 752.83 283.829C752.185 283.829 751.673 284.35 751.673 284.987C751.673 285.622 752.185 286.144 752.83 286.144Z"
            fill="#EF4E05"
          />
          <path
            d="M720.455 278.326C720.455 277.747 719.99 277.282 719.412 277.282C718.833 277.282 718.368 277.747 718.368 278.326C718.368 278.904 718.833 279.37 719.412 279.37C719.981 279.37 720.455 278.904 720.455 278.326Z"
            fill="#EF4E05"
          />
          <path
            d="M706.242 312.864C706.735 312.864 707.134 312.465 707.134 311.972C707.134 311.478 706.735 311.08 706.242 311.08C705.749 311.08 705.35 311.478 705.35 311.972C705.35 312.465 705.749 312.864 706.242 312.864Z"
            fill="#EF4E05"
          />
          <path
            d="M674.512 324.155C674.939 324.155 675.29 323.804 675.29 323.377C675.29 322.95 674.939 322.599 674.512 322.599C674.085 322.599 673.734 322.95 673.734 323.377C673.734 323.804 674.085 324.155 674.512 324.155Z"
            fill="#EF4E05"
          />
          <path
            d="M646.398 352.184C646.398 351.814 646.093 351.51 645.724 351.51C645.354 351.51 645.05 351.814 645.05 352.184C645.05 352.555 645.354 352.858 645.724 352.858C646.093 352.858 646.398 352.555 646.398 352.184Z"
            fill="#EF4E05"
          />
          <path
            d="M695.14 358.419C695.14 358.845 695.481 359.187 695.908 359.187C696.335 359.187 696.677 358.845 696.677 358.419C696.677 357.992 696.335 357.65 695.908 357.65C695.481 357.65 695.14 357.992 695.14 358.419Z"
            fill="#EF4E05"
          />
          <path
            d="M727.135 340.798C727.633 340.798 728.037 340.395 728.037 339.896C728.037 339.399 727.633 338.995 727.135 338.995C726.637 338.995 726.233 339.399 726.233 339.896C726.233 340.395 726.637 340.798 727.135 340.798Z"
            fill="#EF4E05"
          />
          <path
            d="M729.185 384.54C729.646 384.54 730.02 384.166 730.02 383.706C730.02 383.244 729.646 382.87 729.185 382.87C728.723 382.87 728.349 383.244 728.349 383.706C728.349 384.166 728.723 384.54 729.185 384.54Z"
            fill="#EF4E05"
          />
          <path
            d="M681.742 401.894C682.122 401.894 682.425 401.591 682.425 401.212C682.425 400.832 682.122 400.528 681.742 400.528C681.363 400.528 681.059 400.832 681.059 401.212C681.059 401.591 681.363 401.894 681.742 401.894Z"
            fill="#EF4E05"
          />
          <path
            d="M719.877 421.536C719.877 421.963 720.218 422.305 720.645 422.305C721.072 422.305 721.413 421.963 721.413 421.536C721.413 421.109 721.072 420.767 720.645 420.767C720.227 420.767 719.877 421.109 719.877 421.536Z"
            fill="#EF4E05"
          />
          <path
            d="M757.499 420.331C758.002 420.331 758.41 419.923 758.41 419.42C758.41 418.917 758.002 418.509 757.499 418.509C756.996 418.509 756.588 418.917 756.588 419.42C756.588 419.923 756.996 420.331 757.499 420.331Z"
            fill="#EF4E05"
          />
          <path
            d="M766.817 383.715C766.276 383.715 765.839 384.151 765.839 384.692C765.839 385.233 766.276 385.669 766.817 385.669C767.357 385.669 767.793 385.233 767.793 384.692C767.793 384.151 767.357 383.715 766.817 383.715Z"
            fill="#EF4E05"
          />
          <path
            d="M752.706 356.596C753.236 356.596 753.665 356.168 753.665 355.638C753.665 355.108 753.236 354.68 752.706 354.68C752.178 354.68 751.748 355.108 751.748 355.638C751.748 356.168 752.178 356.596 752.706 356.596Z"
            fill="#EF4E05"
          />
          <path
            d="M782.121 350.904C782.121 350.287 781.618 349.793 781.011 349.793C780.394 349.793 779.901 350.296 779.901 350.904C779.901 351.52 780.403 352.013 781.011 352.013C781.618 352.013 782.121 351.52 782.121 350.904Z"
            fill="#EF4E05"
          />
          <path
            d="M807.73 346.861C807.73 347.564 808.3 348.133 809.002 348.133C809.704 348.133 810.273 347.564 810.273 346.861C810.273 346.159 809.704 345.59 809.002 345.59C808.3 345.58 807.73 346.15 807.73 346.861Z"
            fill="#EF4E05"
          />
          <path
            d="M837.089 345.371C837.089 344.574 836.443 343.929 835.646 343.929C834.849 343.929 834.204 344.574 834.204 345.371C834.204 346.168 834.849 346.814 835.646 346.814C836.443 346.814 837.089 346.168 837.089 345.371Z"
            fill="#EF4E05"
          />
          <path
            d="M858.833 347.354C859.724 347.354 860.447 346.633 860.447 345.741C860.447 344.849 859.724 344.129 858.833 344.129C857.942 344.129 857.219 344.849 857.219 345.741C857.219 346.633 857.942 347.354 858.833 347.354Z"
            fill="#EF4E05"
          />
          <path
            d="M881.11 338.455C880.109 338.455 879.302 339.261 879.302 340.257C879.302 341.253 880.109 342.06 881.11 342.06C882.102 342.06 882.909 341.253 882.909 340.257C882.909 339.261 882.102 338.455 881.11 338.455Z"
            fill="#EF4E05"
          />
          <path
            d="M902.058 331.699C900.965 331.699 900.074 332.58 900.074 333.682C900.074 334.773 900.957 335.665 902.058 335.665C903.159 335.665 904.042 334.782 904.042 333.682C904.033 332.591 903.151 331.699 902.058 331.699Z"
            fill="#EF4E05"
          />
          <path
            d="M889.844 320.398C890.911 320.398 891.768 319.536 891.768 318.471C891.768 317.408 890.911 316.546 889.844 316.546C888.784 316.546 887.918 317.408 887.918 318.471C887.918 319.536 888.784 320.398 889.844 320.398Z"
            fill="#EF4E05"
          />
          <path
            d="M879.05 300.14C879.05 301.202 879.907 302.066 880.975 302.066C882.034 302.066 882.9 301.202 882.9 300.14C882.9 299.077 882.034 298.213 880.975 298.213C879.907 298.213 879.05 299.077 879.05 300.14Z"
            fill="#EF4E05"
          />
          <path
            d="M858.127 301.677C859.085 301.677 859.859 300.899 859.859 299.941C859.859 298.982 859.085 298.204 858.127 298.204C857.169 298.204 856.387 298.982 856.387 299.941C856.387 300.899 857.169 301.677 858.127 301.677Z"
            fill="#EF4E05"
          />
          <path
            d="M867.971 319.762C867.012 319.762 866.23 320.54 866.23 321.508C866.23 322.466 867.004 323.254 867.971 323.254C868.929 323.254 869.719 322.475 869.719 321.508C869.719 320.549 868.937 319.762 867.971 319.762Z"
            fill="#EF4E05"
          />
          <path
            d="M845.551 320.559C844.685 320.559 843.979 321.261 843.979 322.134C843.979 322.997 844.677 323.709 845.551 323.709C846.417 323.709 847.123 323.007 847.123 322.134C847.123 321.261 846.425 320.559 845.551 320.559Z"
            fill="#EF4E05"
          />
          <path
            d="M820.247 320.616C819.478 320.616 818.851 321.242 818.851 322.01C818.851 322.78 819.478 323.406 820.247 323.406C821.015 323.406 821.641 322.78 821.641 322.01C821.641 321.242 821.024 320.616 820.247 320.616Z"
            fill="#EF4E05"
          />
          <path
            d="M835.247 299.675C835.247 298.811 834.546 298.1 833.672 298.1C832.8 298.1 832.098 298.802 832.098 299.675C832.098 300.538 832.8 301.25 833.672 301.25C834.546 301.25 835.247 300.538 835.247 299.675Z"
            fill="#EF4E05"
          />
          <path
            d="M822.742 277.557C822.742 278.43 823.454 279.141 824.326 279.141C825.2 279.141 825.911 278.43 825.911 277.557C825.911 276.684 825.2 275.972 824.326 275.972C823.454 275.972 822.742 276.684 822.742 277.557Z"
            fill="#EF4E05"
          />
          <path
            d="M841.693 259.889C842.685 259.889 843.483 259.086 843.483 258.096C843.483 257.106 842.685 256.302 841.693 256.302C840.701 256.302 839.897 257.106 839.897 258.096C839.897 259.086 840.701 259.889 841.693 259.889Z"
            fill="#EF4E05"
          />
          <path
            d="M851.166 278.828C851.166 277.852 850.385 277.064 849.401 277.064C848.426 277.064 847.636 277.852 847.636 278.828C847.636 279.806 848.426 280.594 849.401 280.594C850.385 280.584 851.166 279.797 851.166 278.828Z"
            fill="#EF4E05"
          />
          <path
            d="M870.963 279.853C870.963 280.926 871.837 281.798 872.905 281.798C873.981 281.798 874.855 280.926 874.855 279.853C874.855 278.781 873.981 277.908 872.905 277.908C871.829 277.908 870.963 278.781 870.963 279.853Z"
            fill="#EF4E05"
          />
          <path
            d="M865.289 260.924C866.382 260.924 867.264 260.041 867.264 258.95C867.264 257.859 866.382 256.976 865.289 256.976C864.196 256.976 863.313 257.859 863.313 258.95C863.313 260.041 864.205 260.924 865.289 260.924Z"
            fill="#EF4E05"
          />
          <path
            d="M856.16 240.618C857.261 240.618 858.152 239.726 858.152 238.625C858.152 237.525 857.261 236.633 856.16 236.633C855.059 236.633 854.167 237.525 854.167 238.625C854.167 239.726 855.059 240.618 856.16 240.618Z"
            fill="#EF4E05"
          />
          <path
            d="M832.667 235.855C831.651 235.855 830.826 236.68 830.826 237.696C830.826 238.711 831.651 239.537 832.667 239.537C833.682 239.537 834.508 238.711 834.508 237.696C834.508 236.68 833.682 235.855 832.667 235.855Z"
            fill="#EF4E05"
          />
          <path
            d="M818.567 221.508C817.571 221.508 816.764 222.315 816.764 223.311C816.764 224.307 817.571 225.114 818.567 225.114C819.563 225.114 820.369 224.307 820.369 223.311C820.36 222.315 819.554 221.508 818.567 221.508Z"
            fill="#EF4E05"
          />
          <path
            d="M813.177 203.546C814.221 203.546 815.075 202.702 815.075 201.649C815.075 200.605 814.23 199.751 813.177 199.751C812.134 199.751 811.28 200.596 811.28 201.649C811.28 202.692 812.134 203.546 813.177 203.546Z"
            fill="#EF4E05"
          />
          <path
            d="M844.526 219.734C844.526 220.854 845.442 221.764 846.56 221.764C847.678 221.764 848.586 220.854 848.586 219.734C848.586 218.614 847.678 217.703 846.56 217.703C845.425 217.703 844.526 218.614 844.526 219.734Z"
            fill="#EF4E05"
          />
          <path
            d="M834.28 203.081C834.28 204.21 835.19 205.122 836.319 205.122C837.449 205.122 838.36 204.21 838.36 203.081C838.36 201.952 837.449 201.041 836.319 201.041C835.19 201.051 834.28 201.962 834.28 203.081Z"
            fill="#EF4E05"
          />
          <path
            d="M831.775 183.839C831.775 182.663 830.826 181.714 829.649 181.714C828.473 181.714 827.524 182.663 827.524 183.839C827.524 185.015 828.473 185.965 829.649 185.965C830.826 185.965 831.775 185.015 831.775 183.839Z"
            fill="#EF4E05"
          />
          <path
            d="M823.178 166.987C824.392 166.987 825.379 166.001 825.379 164.786C825.379 163.571 824.392 162.584 823.178 162.584C821.964 162.584 820.977 163.571 820.977 164.786C820.977 166.001 821.964 166.987 823.178 166.987Z"
            fill="#EF4E05"
          />
          <path
            d="M806.924 182.852C808.025 182.852 808.917 181.96 808.917 180.859C808.917 179.759 808.025 178.867 806.924 178.867C805.824 178.867 804.932 179.759 804.932 180.859C804.932 181.96 805.824 182.852 806.924 182.852Z"
            fill="#EF4E05"
          />
          <path
            d="M799.115 160.658C799.115 161.807 800.046 162.746 801.203 162.746C802.361 162.746 803.29 161.816 803.29 160.658C803.29 159.501 802.361 158.571 801.203 158.571C800.046 158.571 799.115 159.51 799.115 160.658Z"
            fill="#EF4E05"
          />
          <path
            d="M819.791 146.188C819.791 144.917 818.757 143.883 817.485 143.883C816.214 143.883 815.179 144.917 815.179 146.188C815.179 147.46 816.214 148.494 817.485 148.494C818.766 148.494 819.791 147.46 819.791 146.188Z"
            fill="#EF4E05"
          />
          <path
            d="M838.018 145.41C836.671 145.41 835.58 146.501 835.58 147.849C835.58 149.196 836.671 150.288 838.018 150.288C839.366 150.288 840.457 149.196 840.457 147.849C840.457 146.501 839.366 145.41 838.018 145.41Z"
            fill="#EF4E05"
          />
          <path
            d="M851.124 134.128C852.596 134.128 853.789 132.935 853.789 131.462C853.789 129.99 852.596 128.796 851.124 128.796C849.653 128.796 848.46 129.99 848.46 131.462C848.46 132.935 849.653 134.128 851.124 134.128Z"
            fill="#EF4E05"
          />
          <path
            d="M851.747 103.357C853.369 103.357 854.68 102.044 854.68 100.425C854.68 98.806 853.369 97.493 851.747 97.493C850.133 97.493 848.813 98.806 848.813 100.425C848.813 102.044 850.133 103.357 851.747 103.357Z"
            fill="#EF4E05"
          />
          <path
            d="M859.783 117.647C861.372 117.647 862.649 116.366 862.649 114.781C862.649 113.197 861.372 111.916 859.783 111.916C858.202 111.916 856.916 113.197 856.916 114.781C856.916 116.366 858.202 117.647 859.783 117.647Z"
            fill="#EF4E05"
          />
          <path
            d="M874.401 122.76C875.99 122.76 877.285 121.47 877.285 119.876C877.285 118.282 875.99 116.992 874.401 116.992C872.804 116.992 871.51 118.282 871.51 119.876C871.51 121.47 872.804 122.76 874.401 122.76Z"
            fill="#EF4E05"
          />
          <path
            d="M870.425 138.873C871.972 138.873 873.225 137.62 873.225 136.073C873.225 134.527 871.972 133.274 870.425 133.274C868.878 133.274 867.626 134.527 867.626 136.073C867.626 137.62 868.878 138.873 870.425 138.873Z"
            fill="#EF4E05"
          />
          <path
            d="M879.512 155.212C881.026 155.212 882.253 153.984 882.253 152.47C882.253 150.955 881.026 149.727 879.512 149.727C877.999 149.727 876.772 150.955 876.772 152.47C876.772 153.984 877.999 155.212 879.512 155.212Z"
            fill="#EF4E05"
          />
          <path
            d="M886.464 138.569C888.011 138.569 889.264 137.316 889.264 135.77C889.264 134.224 888.011 132.971 886.464 132.971C884.918 132.971 883.665 134.224 883.665 135.77C883.665 137.316 884.918 138.569 886.464 138.569Z"
            fill="#EF4E05"
          />
          <path
            d="M899.544 156.901C901.141 156.901 902.444 155.601 902.444 153.997C902.444 152.393 901.141 151.094 899.544 151.094C897.939 151.094 896.636 152.393 896.636 153.997C896.636 155.601 897.939 156.901 899.544 156.901Z"
            fill="#EF4E05"
          />
          <path
            d="M913.944 169.9C915.567 169.9 916.878 168.587 916.878 166.968C916.878 165.349 915.567 164.036 913.944 164.036C912.33 164.036 911.01 165.349 911.01 166.968C911.01 168.587 912.33 169.9 913.944 169.9Z"
            fill="#EF4E05"
          />
          <path
            d="M922.989 187.957C924.595 187.957 925.89 186.657 925.89 185.053C925.89 183.449 924.595 182.15 922.989 182.15C921.384 182.15 920.081 183.449 920.081 185.053C920.081 186.657 921.384 187.957 922.989 187.957Z"
            fill="#EF4E05"
          />
          <path
            d="M932.194 204.447C933.792 204.447 935.086 203.148 935.086 201.554C935.086 199.959 933.783 198.66 932.194 198.66C930.589 198.66 929.294 199.959 929.294 201.554C929.294 203.148 930.597 204.447 932.194 204.447Z"
            fill="#EF4E05"
          />
          <path
            d="M945.804 220.066C947.418 220.066 948.729 218.756 948.729 217.143C948.729 215.53 947.418 214.221 945.804 214.221C944.198 214.221 942.887 215.53 942.887 217.143C942.879 218.756 944.182 220.066 945.804 220.066Z"
            fill="#EF4E05"
          />
          <path
            d="M930.782 224.848C932.295 224.848 933.531 223.614 933.531 222.096C933.531 220.578 932.295 219.345 930.782 219.345C929.261 219.345 928.025 220.578 928.025 222.096C928.025 223.614 929.252 224.848 930.782 224.848Z"
            fill="#EF4E05"
          />
          <path
            d="M912.961 219.544C914.39 219.544 915.558 218.377 915.558 216.944C915.558 215.512 914.39 214.344 912.961 214.344C911.523 214.344 910.355 215.512 910.355 216.944C910.355 218.387 911.523 219.544 912.961 219.544Z"
            fill="#EF4E05"
          />
          <path
            d="M889.558 216.271C889.558 217.599 890.634 218.671 891.962 218.671C893.29 218.671 894.358 217.599 894.358 216.271C894.358 214.942 893.29 213.87 891.962 213.87C890.634 213.87 889.558 214.942 889.558 216.271Z"
            fill="#EF4E05"
          />
          <path
            d="M886.086 201.516C887.448 201.516 888.541 200.416 888.541 199.058C888.541 197.701 887.448 196.601 886.086 196.601C884.733 196.601 883.632 197.701 883.632 199.058C883.632 200.416 884.733 201.516 886.086 201.516Z"
            fill="#EF4E05"
          />
          <path
            d="M908.867 202.616C910.347 202.616 911.549 201.421 911.549 199.941C911.549 198.46 910.347 197.265 908.867 197.265C907.388 197.265 906.194 198.46 906.194 199.941C906.194 201.421 907.396 202.616 908.867 202.616Z"
            fill="#EF4E05"
          />
          <path
            d="M900.814 185.585C902.31 185.585 903.529 184.37 903.529 182.871C903.529 181.372 902.31 180.157 900.814 180.157C899.318 180.157 898.099 181.372 898.099 182.871C898.099 184.37 899.318 185.585 900.814 185.585Z"
            fill="#EF4E05"
          />
          <path
            d="M886.103 169.492C886.103 170.982 887.314 172.187 888.802 172.187C890.289 172.187 891.5 170.982 891.5 169.492C891.5 168.002 890.289 166.797 888.802 166.797C887.322 166.797 886.103 168.002 886.103 169.492Z"
            fill="#EF4E05"
          />
          <path
            d="M875.46 182.083C874.09 182.083 872.989 183.184 872.989 184.551C872.989 185.917 874.09 187.017 875.46 187.017C876.822 187.017 877.923 185.917 877.923 184.551C877.923 183.194 876.814 182.083 875.46 182.083Z"
            fill="#EF4E05"
          />
          <path
            d="M866.39 170.489C867.777 170.489 868.895 169.369 868.895 167.984C868.895 166.598 867.777 165.479 866.39 165.479C865.003 165.479 863.885 166.598 863.885 167.984C863.885 169.369 865.003 170.489 866.39 170.489Z"
            fill="#EF4E05"
          />
          <path
            d="M858.068 153.125C859.48 153.125 860.632 151.977 860.632 150.562C860.632 149.148 859.48 148 858.068 148C856.656 148 855.504 149.148 855.504 150.562C855.504 151.977 856.656 153.125 858.068 153.125Z"
            fill="#EF4E05"
          />
          <path
            d="M844.526 168.866C845.829 168.866 846.879 167.813 846.879 166.512C846.879 165.213 845.829 164.16 844.526 164.16C843.231 164.16 842.172 165.213 842.172 166.512C842.172 167.813 843.231 168.866 844.526 168.866Z"
            fill="#EF4E05"
          />
          <path
            d="M854.285 184.541C854.285 183.288 853.268 182.263 852.007 182.263C850.746 182.263 849.729 183.279 849.729 184.541C849.729 185.803 850.746 186.818 852.007 186.818C853.268 186.818 854.285 185.793 854.285 184.541Z"
            fill="#EF4E05"
          />
          <path
            d="M861.061 198.745C859.816 198.745 858.808 199.751 858.808 200.994C858.808 202.237 859.816 203.243 861.061 203.243C862.296 203.243 863.305 202.237 863.305 200.994C863.305 199.751 862.296 198.745 861.061 198.745Z"
            fill="#EF4E05"
          />
          <path
            d="M872.207 217.105C872.207 215.882 871.207 214.885 869.988 214.885C868.761 214.885 867.769 215.882 867.769 217.105C867.769 218.329 868.761 219.326 869.988 219.326C871.207 219.326 872.207 218.329 872.207 217.105Z"
            fill="#EF4E05"
          />
          <path
            d="M874.721 234.659C874.721 235.864 875.696 236.842 876.906 236.842C878.108 236.842 879.083 235.864 879.083 234.659C879.083 233.454 878.108 232.477 876.906 232.477C875.696 232.477 874.721 233.454 874.721 234.659Z"
            fill="#EF4E05"
          />
          <path
            d="M884.775 253.475C885.977 253.475 886.952 252.508 886.952 251.302C886.952 250.098 885.985 249.13 884.775 249.13C883.572 249.13 882.606 250.098 882.606 251.302C882.606 252.508 883.581 253.475 884.775 253.475Z"
            fill="#EF4E05"
          />
          <path
            d="M890.785 266.769C889.608 266.769 888.65 267.727 888.65 268.903C888.65 270.08 889.608 271.038 890.785 271.038C891.962 271.038 892.92 270.08 892.92 268.903C892.912 267.727 891.962 266.769 890.785 266.769Z"
            fill="#EF4E05"
          />
          <path
            d="M897.619 289.342C898.779 289.342 899.729 288.393 899.729 287.225C899.729 286.059 898.779 285.11 897.619 285.11C896.451 285.11 895.501 286.059 895.501 287.225C895.509 288.393 896.451 289.342 897.619 289.342Z"
            fill="#EF4E05"
          />
          <path
            d="M906.067 303.565C904.899 303.565 903.958 304.514 903.958 305.68C903.958 306.848 904.899 307.797 906.067 307.797C907.236 307.797 908.186 306.848 908.186 305.68C908.186 304.514 907.236 303.565 906.067 303.565Z"
            fill="#EF4E05"
          />
          <path
            d="M920.022 291.998C921.291 291.998 922.325 290.966 922.325 289.693C922.325 288.419 921.291 287.387 920.022 287.387C918.744 287.387 917.71 288.419 917.71 289.693C917.71 290.966 918.744 291.998 920.022 291.998Z"
            fill="#EF4E05"
          />
          <path
            d="M914.76 271.465C914.76 270.184 913.718 269.14 912.44 269.14C911.154 269.14 910.111 270.184 910.111 271.465C910.111 272.746 911.154 273.79 912.44 273.79C913.718 273.79 914.76 272.746 914.76 271.465Z"
            fill="#EF4E05"
          />
          <path
            d="M905.488 255.155C906.782 255.155 907.825 254.111 907.825 252.82C907.825 251.53 906.782 250.486 905.488 250.486C904.202 250.486 903.159 251.53 903.159 252.82C903.159 254.111 904.202 255.155 905.488 255.155Z"
            fill="#EF4E05"
          />
          <path
            d="M898.368 236.386C899.687 236.386 900.747 235.324 900.747 234.005C900.747 232.686 899.687 231.623 898.368 231.623C897.048 231.623 895.98 232.686 895.98 234.005C895.98 235.324 897.056 236.386 898.368 236.386Z"
            fill="#EF4E05"
          />
          <path
            d="M915.398 236.775C915.398 238.17 916.533 239.309 917.929 239.309C919.324 239.309 920.467 238.17 920.467 236.775C920.467 235.381 919.324 234.242 917.929 234.242C916.533 234.242 915.398 235.371 915.398 236.775Z"
            fill="#EF4E05"
          />
          <path
            d="M937.347 243.408C938.835 243.408 940.046 242.193 940.046 240.704C940.046 239.214 938.835 237.999 937.347 237.999C935.851 237.999 934.64 239.214 934.64 240.704C934.64 242.193 935.851 243.408 937.347 243.408Z"
            fill="#EF4E05"
          />
          <path
            d="M952.016 235.589C953.63 235.589 954.925 234.279 954.925 232.676C954.925 231.063 953.614 229.763 952.016 229.763C950.402 229.763 949.099 231.072 949.099 232.676C949.091 234.279 950.402 235.589 952.016 235.589Z"
            fill="#EF4E05"
          />
          <path
            d="M961.129 251.056C962.743 251.056 964.046 249.746 964.046 248.142C964.046 246.53 962.734 245.229 961.129 245.229C959.523 245.229 958.22 246.539 958.22 248.142C958.22 249.755 959.523 251.056 961.129 251.056Z"
            fill="#EF4E05"
          />
          <path
            d="M946.317 261.037C947.821 261.037 949.032 259.823 949.032 258.324C949.032 256.825 947.821 255.61 946.317 255.61C944.82 255.61 943.61 256.825 943.61 258.324C943.61 259.823 944.82 261.037 946.317 261.037Z"
            fill="#EF4E05"
          />
          <path
            d="M923.216 255.582C923.216 256.967 924.343 258.096 925.73 258.096C927.117 258.096 928.243 256.967 928.243 255.582C928.243 254.196 927.117 253.067 925.73 253.067C924.343 253.067 923.216 254.187 923.216 255.582Z"
            fill="#EF4E05"
          />
          <path
            d="M933.362 270.915C931.975 270.915 930.857 272.034 930.857 273.42C930.857 274.805 931.975 275.925 933.362 275.925C934.741 275.925 935.868 274.805 935.868 273.42C935.868 272.034 934.741 270.915 933.362 270.915Z"
            fill="#EF4E05"
          />
          <path
            d="M938.541 290.955C938.541 292.341 939.659 293.459 941.046 293.459C942.433 293.459 943.551 292.341 943.551 290.955C943.551 289.57 942.433 288.45 941.046 288.45C939.659 288.45 938.541 289.57 938.541 290.955Z"
            fill="#EF4E05"
          />
          <path
            d="M951.226 275.991C951.226 277.481 952.428 278.686 953.924 278.686C955.412 278.686 956.614 277.481 956.614 275.991C956.614 274.502 955.412 273.296 953.924 273.296C952.428 273.296 951.226 274.502 951.226 275.991Z"
            fill="#EF4E05"
          />
          <path
            d="M967.837 266.864C969.442 266.864 970.737 265.563 970.737 263.97C970.737 262.366 969.434 261.075 967.837 261.075C966.24 261.075 964.945 262.376 964.945 263.97C964.945 265.573 966.248 266.864 967.837 266.864Z"
            fill="#EF4E05"
          />
          <path
            d="M974.343 283.137C975.957 283.137 977.252 281.827 977.252 280.223C977.252 278.611 975.94 277.31 974.343 277.31C972.729 277.31 971.426 278.62 971.426 280.223C971.426 281.836 972.729 283.137 974.343 283.137Z"
            fill="#EF4E05"
          />
          <path
            d="M961.053 296.477C962.549 296.477 963.76 295.263 963.76 293.773C963.76 292.283 962.549 291.069 961.053 291.069C959.565 291.069 958.354 292.283 958.354 293.773C958.354 295.272 959.557 296.477 961.053 296.477Z"
            fill="#EF4E05"
          />
          <path
            d="M968.593 307.607C967.08 307.607 965.87 308.831 965.87 310.331C965.87 311.83 967.089 313.054 968.593 313.054C970.09 313.054 971.317 311.83 971.317 310.331C971.317 308.831 970.09 307.607 968.593 307.607Z"
            fill="#EF4E05"
          />
          <path
            d="M981.169 300.927C982.775 300.927 984.078 299.627 984.078 298.023C984.078 296.42 982.775 295.121 981.169 295.121C979.572 295.121 978.269 296.42 978.269 298.023C978.269 299.627 979.572 300.927 981.169 300.927Z"
            fill="#EF4E05"
          />
          <path
            d="M987.415 318.12C989.029 318.12 990.332 316.811 990.332 315.207C990.332 313.604 989.021 312.295 987.415 312.295C985.801 312.295 984.507 313.604 984.507 315.207C984.507 316.811 985.801 318.12 987.415 318.12Z"
            fill="#EF4E05"
          />
          <path
            d="M987.987 332.372C987.987 333.976 989.281 335.266 990.878 335.266C992.484 335.266 993.77 333.967 993.77 332.372C993.77 330.778 992.475 329.479 990.878 329.479C989.281 329.479 987.987 330.778 987.987 332.372Z"
            fill="#EF4E05"
          />
          <path
            d="M976.066 328.273C976.066 326.784 974.856 325.569 973.36 325.569C971.872 325.569 970.661 326.784 970.661 328.273C970.661 329.763 971.872 330.977 973.36 330.977C974.856 330.977 976.066 329.763 976.066 328.273Z"
            fill="#EF4E05"
          />
          <path
            d="M953.244 325.095C953.244 326.489 954.379 327.618 955.774 327.618C957.169 327.618 958.296 326.489 958.296 325.095C958.296 323.699 957.169 322.57 955.774 322.57C954.379 322.57 953.244 323.699 953.244 325.095Z"
            fill="#EF4E05"
          />
          <path
            d="M948.183 306.506C946.787 306.506 945.653 307.636 945.653 309.03C945.653 310.425 946.787 311.554 948.183 311.554C949.578 311.554 950.705 310.425 950.705 309.03C950.705 307.645 949.562 306.506 948.183 306.506Z"
            fill="#EF4E05"
          />
          <path
            d="M926.881 307.636C926.881 308.926 927.924 309.97 929.21 309.97C930.504 309.97 931.547 308.926 931.547 307.636C931.547 306.345 930.504 305.301 929.21 305.301C927.924 305.301 926.881 306.345 926.881 307.636Z"
            fill="#EF4E05"
          />
          <path
            d="M915.424 322.182C915.424 323.377 916.391 324.345 917.593 324.345C918.786 324.345 919.753 323.377 919.753 322.182C919.753 320.986 918.786 320.018 917.593 320.018C916.391 320.018 915.424 320.986 915.424 322.182Z"
            fill="#EF4E05"
          />
          <path
            d="M937.389 331.433C938.684 331.433 939.726 330.388 939.726 329.099C939.726 327.81 938.684 326.764 937.389 326.764C936.103 326.764 935.061 327.81 935.061 329.099C935.061 330.388 936.103 331.433 937.389 331.433Z"
            fill="#EF4E05"
          />
          <path
            d="M922.838 342.544C922.838 341.358 921.88 340.4 920.694 340.4C919.509 340.4 918.551 341.358 918.551 342.544C918.551 343.73 919.509 344.688 920.694 344.688C921.88 344.688 922.838 343.72 922.838 342.544Z"
            fill="#EF4E05"
          />
          <path
            d="M899.216 356.056C900.276 356.056 901.133 355.197 901.133 354.139C901.133 353.081 900.276 352.223 899.216 352.223C898.157 352.223 897.3 353.081 897.3 354.139C897.3 355.197 898.157 356.056 899.216 356.056Z"
            fill="#EF4E05"
          />
          <path
            d="M921.132 362.536C921.132 361.388 920.199 360.458 919.047 360.458C917.904 360.458 916.97 361.388 916.97 362.536C916.97 363.684 917.904 364.614 919.047 364.614C920.199 364.614 921.132 363.675 921.132 362.536Z"
            fill="#EF4E05"
          />
          <path
            d="M917.601 380.394C916.491 380.394 915.575 381.295 915.575 382.414C915.575 383.534 916.483 384.436 917.601 384.436C918.711 384.436 919.619 383.534 919.619 382.414C919.619 381.295 918.711 380.394 917.601 380.394Z"
            fill="#EF4E05"
          />
          <path
            d="M933.295 393.146C933.295 394.342 934.262 395.319 935.464 395.319C936.675 395.319 937.641 394.351 937.641 393.146C937.641 391.95 936.675 390.974 935.464 390.974C934.262 390.974 933.295 391.95 933.295 393.146Z"
            fill="#EF4E05"
          />
          <path
            d="M953.395 387.225C954.706 387.225 955.765 386.168 955.765 384.863C955.765 383.558 954.706 382.5 953.395 382.5C952.092 382.5 951.032 383.558 951.032 384.863C951.032 386.168 952.092 387.225 953.395 387.225Z"
            fill="#EF4E05"
          />
          <path
            d="M967.568 379.218C967.568 380.631 968.711 381.779 970.123 381.779C971.544 381.779 972.687 380.631 972.687 379.218C972.687 377.804 971.544 376.655 970.123 376.655C968.703 376.655 967.568 377.804 967.568 379.218Z"
            fill="#EF4E05"
          />
          <path
            d="M987.062 379.046C988.583 379.046 989.802 377.822 989.802 376.304C989.802 374.786 988.583 373.562 987.062 373.562C985.549 373.562 984.321 374.786 984.321 376.304C984.321 377.822 985.549 379.046 987.062 379.046Z"
            fill="#EF4E05"
          />
          <path
            d="M978.874 364.054C980.362 364.054 981.564 362.852 981.564 361.369C981.564 359.887 980.362 358.684 978.874 358.684C977.395 358.684 976.193 359.887 976.193 361.369C976.193 362.852 977.395 364.054 978.874 364.054Z"
            fill="#EF4E05"
          />
          <path
            d="M960.59 362.802C960.59 361.436 959.489 360.335 958.127 360.335C956.757 360.335 955.656 361.436 955.656 362.802C955.656 364.169 956.757 365.269 958.127 365.269C959.481 365.26 960.59 364.159 960.59 362.802Z"
            fill="#EF4E05"
          />
          <path
            d="M940.491 371.74C940.491 370.507 939.491 369.501 938.255 369.501C937.019 369.501 936.019 370.507 936.019 371.74C936.019 372.973 937.019 373.98 938.255 373.98C939.491 373.98 940.491 372.983 940.491 371.74Z"
            fill="#EF4E05"
          />
          <path
            d="M938.987 353.674C940.256 353.674 941.281 352.646 941.281 351.378C941.281 350.109 940.256 349.081 938.987 349.081C937.717 349.081 936.692 350.109 936.692 351.378C936.692 352.646 937.717 353.674 938.987 353.674Z"
            fill="#EF4E05"
          />
          <path
            d="M956.245 340.58C954.866 340.58 953.748 341.7 953.748 343.076C953.748 344.451 954.866 345.571 956.245 345.571C957.623 345.571 958.741 344.451 958.741 343.076C958.741 341.7 957.623 340.58 956.245 340.58Z"
            fill="#EF4E05"
          />
          <path
            d="M975.125 342.923C973.645 342.923 972.452 344.119 972.452 345.599C972.452 347.08 973.645 348.275 975.125 348.275C976.604 348.275 977.806 347.08 977.806 345.599C977.806 344.119 976.604 342.923 975.125 342.923Z"
            fill="#EF4E05"
          />
          <path
            d="M995.956 352.09C997.57 352.09 998.881 350.781 998.881 349.167C998.881 347.553 997.57 346.245 995.956 346.245C994.342 346.245 993.03 347.553 993.03 349.167C993.03 350.781 994.342 352.09 995.956 352.09Z"
            fill="#EF4E05"
          />
          <path
            d="M997.049 365.373C997.049 366.996 998.352 368.305 999.982 368.305C1001.6 368.305 1002.91 366.996 1002.91 365.373C1002.91 363.751 1001.6 362.441 999.982 362.441C998.352 362.441 997.049 363.751 997.049 365.373Z"
            fill="#EF4E05"
          />
          <path
            d="M999.646 383.885C999.646 385.499 1000.96 386.798 1002.56 386.798C1004.16 386.798 1005.47 385.489 1005.47 383.885C1005.47 382.272 1004.16 380.973 1002.56 380.973C1000.96 380.973 999.646 382.272 999.646 383.885Z"
            fill="#EF4E05"
          />
          <path
            d="M1003.19 403.612C1004.79 403.612 1006.08 402.322 1006.08 400.727C1006.08 399.134 1004.79 397.843 1003.19 397.843C1001.6 397.843 1000.31 399.134 1000.31 400.727C1000.31 402.322 1001.6 403.612 1003.19 403.612Z"
            fill="#EF4E05"
          />
          <path
            d="M1005.84 421.849C1007.45 421.849 1008.76 420.545 1008.76 418.936C1008.76 417.327 1007.45 416.023 1005.84 416.023C1004.24 416.023 1002.93 417.327 1002.93 418.936C1002.93 420.545 1004.24 421.849 1005.84 421.849Z"
            fill="#EF4E05"
          />
          <path
            d="M1007.23 440.408C1008.83 440.408 1010.13 439.109 1010.13 437.505C1010.13 435.902 1008.83 434.601 1007.23 434.601C1005.62 434.601 1004.32 435.902 1004.32 437.505C1004.32 439.109 1005.62 440.408 1007.23 440.408Z"
            fill="#EF4E05"
          />
          <path
            d="M993.938 449.973C995.46 449.973 996.695 448.74 996.695 447.212C996.695 445.684 995.46 444.451 993.938 444.451C992.408 444.451 991.172 445.684 991.172 447.212C991.172 448.74 992.408 449.973 993.938 449.973Z"
            fill="#EF4E05"
          />
          <path
            d="M992.618 428.197C992.618 426.688 991.391 425.474 989.895 425.474C988.39 425.474 987.171 426.698 987.171 428.197C987.171 429.705 988.39 430.92 989.895 430.92C991.391 430.92 992.618 429.705 992.618 428.197Z"
            fill="#EF4E05"
          />
          <path
            d="M974.36 440.646C974.36 442.06 975.503 443.208 976.924 443.208C978.336 443.208 979.479 442.06 979.479 440.646C979.479 439.232 978.336 438.084 976.924 438.084C975.503 438.084 974.36 439.232 974.36 440.646Z"
            fill="#EF4E05"
          />
          <path
            d="M982.38 463.209C983.826 463.209 985.002 462.033 985.002 460.582C985.002 459.13 983.826 457.953 982.38 457.953C980.925 457.953 979.749 459.13 979.749 460.582C979.749 462.033 980.925 463.209 982.38 463.209Z"
            fill="#EF4E05"
          />
          <path
            d="M994.174 478.515C995.695 478.515 996.939 477.271 996.939 475.744C996.939 474.217 995.695 472.973 994.174 472.973C992.644 472.973 991.399 474.217 991.399 475.744C991.399 477.281 992.644 478.515 994.174 478.515Z"
            fill="#EF4E05"
          />
          <path
            d="M1007 462.498C1008.61 462.498 1009.9 461.199 1009.9 459.595C1009.9 457.992 1008.61 456.691 1007 456.691C1005.4 456.691 1004.09 457.992 1004.09 459.595C1004.09 461.199 1005.4 462.498 1007 462.498Z"
            fill="#EF4E05"
          />
          <path
            d="M1008.62 475.753C1006.99 475.753 1005.68 477.063 1005.68 478.685C1005.68 480.308 1006.99 481.617 1008.62 481.617C1010.24 481.617 1011.54 480.308 1011.54 478.685C1011.54 477.072 1010.24 475.753 1008.62 475.753Z"
            fill="#EF4E05"
          />
          <path
            d="M1003.94 493.896C1002.34 493.896 1001.05 495.196 1001.05 496.79C1001.05 498.393 1002.35 499.684 1003.94 499.684C1005.55 499.684 1006.84 498.384 1006.84 496.79C1006.84 495.186 1005.54 493.896 1003.94 493.896Z"
            fill="#EF4E05"
          />
          <path
            d="M985.288 496.941C986.759 496.941 987.953 495.746 987.953 494.275C987.953 492.804 986.759 491.609 985.288 491.609C983.817 491.609 982.623 492.804 982.623 494.275C982.615 495.746 983.809 496.941 985.288 496.941Z"
            fill="#EF4E05"
          />
          <path
            d="M980.127 478.211C980.127 476.797 978.984 475.649 977.563 475.649C976.151 475.649 975.007 476.797 975.007 478.211C975.007 479.625 976.151 480.773 977.563 480.773C978.984 480.773 980.127 479.625 980.127 478.211Z"
            fill="#EF4E05"
          />
          <path
            d="M961.473 474.549C962.793 474.549 963.861 473.476 963.861 472.157C963.861 470.838 962.793 469.766 961.473 469.766C960.154 469.766 959.086 470.838 959.086 472.157C959.086 473.486 960.154 474.549 961.473 474.549Z"
            fill="#EF4E05"
          />
          <path
            d="M960.162 454.281C960.162 455.609 961.246 456.691 962.574 456.691C963.903 456.691 964.987 455.609 964.987 454.281C964.987 452.953 963.903 451.871 962.574 451.871C961.238 451.871 960.162 452.953 960.162 454.281Z"
            fill="#EF4E05"
          />
          <path
            d="M943.324 466.777C944.543 466.777 945.535 465.79 945.535 464.566C945.535 463.342 944.543 462.355 943.324 462.355C942.097 462.355 941.113 463.342 941.113 464.566C941.122 465.781 942.105 466.777 943.324 466.777Z"
            fill="#EF4E05"
          />
          <path
            d="M923.805 457.574C924.915 457.574 925.822 456.672 925.822 455.552C925.822 454.432 924.923 453.531 923.805 453.531C922.687 453.531 921.779 454.432 921.779 455.552C921.796 456.672 922.695 457.574 923.805 457.574Z"
            fill="#EF4E05"
          />
          <path
            d="M904.773 446.035C905.799 446.035 906.631 445.201 906.631 444.176C906.631 443.151 905.799 442.316 904.773 442.316C903.747 442.316 902.907 443.151 902.907 444.176C902.907 445.201 903.747 446.035 904.773 446.035Z"
            fill="#EF4E05"
          />
          <path
            d="M883.489 431.727C882.564 431.727 881.816 432.476 881.816 433.397C881.816 434.317 882.564 435.067 883.489 435.067C884.405 435.067 885.161 434.317 885.161 433.397C885.161 432.476 884.405 431.727 883.489 431.727Z"
            fill="#EF4E05"
          />
          <path
            d="M903.857 422.495C904.882 422.495 905.706 421.666 905.706 420.644C905.706 419.622 904.882 418.794 903.857 418.794C902.84 418.794 902.007 419.622 902.007 420.644C902.007 421.666 902.84 422.495 903.857 422.495Z"
            fill="#EF4E05"
          />
          <path
            d="M924.057 435.096C925.167 435.096 926.083 434.194 926.083 433.074C926.083 431.954 925.184 431.053 924.057 431.053C922.947 431.053 922.04 431.954 922.04 433.074C922.04 434.194 922.947 435.096 924.057 435.096Z"
            fill="#EF4E05"
          />
          <path
            d="M942.366 446.985C943.585 446.985 944.576 445.998 944.576 444.774C944.576 443.55 943.585 442.563 942.366 442.563C941.138 442.563 940.155 443.55 940.155 444.774C940.155 445.988 941.138 446.985 942.366 446.985Z"
            fill="#EF4E05"
          />
          <path
            d="M943.904 424.629C943.904 425.872 944.904 426.888 946.157 426.888C947.401 426.888 948.418 425.881 948.418 424.629C948.418 423.386 947.41 422.371 946.157 422.371C944.913 422.371 943.904 423.386 943.904 424.629Z"
            fill="#EF4E05"
          />
          <path
            d="M959.985 438.15C961.305 438.15 962.373 437.079 962.373 435.76C962.373 434.441 961.305 433.368 959.985 433.368C958.666 433.368 957.59 434.441 957.59 435.76C957.59 437.079 958.666 438.15 959.985 438.15Z"
            fill="#EF4E05"
          />
          <path
            d="M969.266 417.238C967.887 417.238 966.777 418.348 966.777 419.724C966.777 421.1 967.887 422.21 969.266 422.21C970.636 422.21 971.745 421.1 971.745 419.724C971.745 418.348 970.627 417.238 969.266 417.238Z"
            fill="#EF4E05"
          />
          <path
            d="M983.968 410.814C983.968 412.304 985.179 413.508 986.667 413.508C988.155 413.508 989.357 412.304 989.357 410.814C989.357 409.324 988.155 408.119 986.667 408.119C985.179 408.119 983.968 409.334 983.968 410.814Z"
            fill="#EF4E05"
          />
          <path
            d="M986.372 390.414C984.876 390.414 983.657 391.628 983.657 393.127C983.657 394.626 984.876 395.841 986.372 395.841C987.869 395.841 989.088 394.626 989.088 393.127C989.088 391.628 987.869 390.414 986.372 390.414Z"
            fill="#EF4E05"
          />
          <path
            d="M966.643 399.494C966.643 400.88 967.761 401.999 969.148 401.999C970.535 401.999 971.653 400.88 971.653 399.494C971.653 398.109 970.535 396.989 969.148 396.989C967.761 396.989 966.643 398.109 966.643 399.494Z"
            fill="#EF4E05"
          />
          <path
            d="M949.267 406.212C949.267 407.484 950.301 408.517 951.579 408.517C952.848 408.517 953.882 407.484 953.882 406.212C953.882 404.941 952.848 403.907 951.579 403.907C950.301 403.907 949.267 404.941 949.267 406.212Z"
            fill="#EF4E05"
          />
          <path
            d="M930.488 416.061C931.648 416.061 932.589 415.118 932.589 413.955C932.589 412.791 931.648 411.848 930.488 411.848C929.319 411.848 928.378 412.791 928.378 413.955C928.378 415.118 929.319 416.061 930.488 416.061Z"
            fill="#EF4E05"
          />
          <path
            d="M914.02 402.768C914.02 403.859 914.903 404.741 915.995 404.741C917.088 404.741 917.971 403.859 917.971 402.768C917.971 401.676 917.088 400.794 915.995 400.794C914.903 400.804 914.02 401.686 914.02 402.768Z"
            fill="#EF4E05"
          />
          <path
            d="M897.216 377.016C898.241 377.016 899.074 376.183 899.074 375.156C899.074 374.129 898.241 373.296 897.216 373.296C896.19 373.296 895.358 374.129 895.358 375.156C895.358 376.183 896.19 377.016 897.216 377.016Z"
            fill="#EF4E05"
          />
          <path
            d="M896.779 399.171C897.788 399.171 898.603 398.355 898.603 397.35C898.603 396.344 897.788 395.528 896.779 395.528C895.779 395.528 894.963 396.344 894.963 397.35C894.972 398.355 895.779 399.171 896.779 399.171Z"
            fill="#EF4E05"
          />
          <path
            d="M878.848 411.735C879.756 411.735 880.504 410.994 880.504 410.074C880.504 409.154 879.765 408.413 878.848 408.413C877.924 408.413 877.184 409.154 877.184 410.074C877.184 410.994 877.924 411.735 878.848 411.735Z"
            fill="#EF4E05"
          />
          <path
            d="M859.682 422.029C858.866 422.029 858.202 422.694 858.202 423.509C858.202 424.325 858.866 424.99 859.682 424.99C860.497 424.99 861.161 424.325 861.161 423.509C861.161 422.694 860.497 422.029 859.682 422.029Z"
            fill="#EF4E05"
          />
          <path
            d="M859.85 447.107C859.85 447.943 860.523 448.607 861.346 448.607C862.187 448.607 862.851 447.933 862.851 447.107C862.851 446.272 862.179 445.608 861.346 445.608C860.523 445.608 859.85 446.282 859.85 447.107Z"
            fill="#EF4E05"
          />
          <path
            d="M866.861 469.586C866.861 468.742 866.18 468.058 865.34 468.058C864.491 468.058 863.81 468.742 863.81 469.586C863.81 470.43 864.491 471.114 865.34 471.114C866.18 471.114 866.861 470.43 866.861 469.586Z"
            fill="#EF4E05"
          />
          <path
            d="M886.986 457.175C886.986 456.246 886.237 455.487 885.296 455.487C884.363 455.487 883.615 456.236 883.615 457.175C883.615 458.114 884.363 458.864 885.296 458.864C886.237 458.864 886.986 458.105 886.986 457.175Z"
            fill="#EF4E05"
          />
          <path
            d="M904.916 470.269C905.942 470.269 906.774 469.437 906.774 468.409C906.774 467.382 905.942 466.55 904.916 466.55C903.882 466.55 903.05 467.382 903.05 468.409C903.05 469.437 903.882 470.269 904.916 470.269Z"
            fill="#EF4E05"
          />
          <path
            d="M925.536 479.312C926.671 479.312 927.579 478.401 927.579 477.271C927.579 476.142 926.671 475.232 925.536 475.232C924.41 475.232 923.502 476.142 923.502 477.271C923.502 478.401 924.418 479.312 925.536 479.312Z"
            fill="#EF4E05"
          />
          <path
            d="M927.369 497.018C926.226 497.018 925.293 497.947 925.293 499.096C925.293 500.244 926.226 501.174 927.369 501.174C928.521 501.174 929.454 500.244 929.454 499.096C929.454 497.947 928.521 497.018 927.369 497.018Z"
            fill="#EF4E05"
          />
          <path
            d="M934.136 520.559C935.33 520.559 936.313 519.591 936.313 518.386C936.313 517.181 935.338 516.213 934.136 516.213C932.934 516.213 931.968 517.181 931.968 518.386C931.968 519.591 932.943 520.559 934.136 520.559Z"
            fill="#EF4E05"
          />
          <path
            d="M923.326 532.248C923.326 531.11 922.409 530.179 921.258 530.179C920.123 530.179 919.19 531.1 919.19 532.248C919.19 533.397 920.114 534.317 921.258 534.317C922.401 534.317 923.326 533.387 923.326 532.248Z"
            fill="#EF4E05"
          />
          <path
            d="M911.12 514.694C911.12 513.632 910.254 512.758 909.178 512.758C908.119 512.758 907.245 513.623 907.245 514.694C907.245 515.767 908.111 516.63 909.178 516.63C910.254 516.63 911.12 515.767 911.12 514.694Z"
            fill="#EF4E05"
          />
          <path
            d="M907.253 492.756C908.304 492.756 909.144 491.912 909.144 490.868C909.144 489.825 908.304 488.98 907.253 488.98C906.21 488.98 905.37 489.825 905.37 490.868C905.37 491.912 906.21 492.756 907.253 492.756Z"
            fill="#EF4E05"
          />
          <path
            d="M883.959 480.991C883.035 480.991 882.286 481.741 882.286 482.671C882.286 483.6 883.035 484.35 883.959 484.35C884.892 484.35 885.64 483.6 885.64 482.671C885.64 481.741 884.892 480.991 883.959 480.991Z"
            fill="#EF4E05"
          />
          <path
            d="M857.53 490.337C857.53 489.531 856.874 488.876 856.067 488.876C855.26 488.876 854.604 489.531 854.604 490.337C854.604 491.144 855.26 491.799 856.067 491.799C856.874 491.799 857.53 491.144 857.53 490.337Z"
            fill="#EF4E05"
          />
          <path
            d="M869.702 510.823C869.702 509.95 868.988 509.239 868.113 509.239C867.239 509.239 866.533 509.95 866.533 510.823C866.533 511.696 867.239 512.408 868.113 512.408C868.988 512.408 869.702 511.696 869.702 510.823Z"
            fill="#EF4E05"
          />
          <path
            d="M887.002 503.564C887.002 504.523 887.776 505.31 888.742 505.31C889.709 505.31 890.491 504.532 890.491 503.564C890.491 502.606 889.709 501.818 888.742 501.818C887.776 501.818 887.002 502.606 887.002 503.564Z"
            fill="#EF4E05"
          />
          <path
            d="M888.927 526.527C888.927 525.559 888.137 524.772 887.17 524.772C886.204 524.772 885.414 525.559 885.414 526.527C885.414 527.494 886.204 528.282 887.17 528.282C888.137 528.282 888.927 527.494 888.927 526.527Z"
            fill="#EF4E05"
          />
          <path
            d="M881.773 551.529C882.74 551.529 883.522 550.741 883.522 549.774C883.522 548.806 882.74 548.018 881.773 548.018C880.798 548.018 880.017 548.806 880.017 549.774C880.017 550.741 880.798 551.529 881.773 551.529Z"
            fill="#EF4E05"
          />
          <path
            d="M905.538 540.959C905.538 539.896 904.672 539.033 903.613 539.033C902.554 539.033 901.688 539.896 901.688 540.959C901.688 542.021 902.554 542.885 903.613 542.885C904.672 542.885 905.538 542.021 905.538 540.959Z"
            fill="#EF4E05"
          />
          <path
            d="M906.421 563.836C907.522 563.836 908.422 562.944 908.422 561.834C908.422 560.733 907.53 559.832 906.421 559.832C905.32 559.832 904.42 560.724 904.42 561.834C904.42 562.944 905.311 563.836 906.421 563.836Z"
            fill="#EF4E05"
          />
          <path
            d="M911.86 582.101C911.86 580.953 910.926 580.023 909.783 580.023C908.632 580.023 907.698 580.953 907.698 582.101C907.698 583.249 908.632 584.179 909.783 584.179C910.926 584.179 911.86 583.249 911.86 582.101Z"
            fill="#EF4E05"
          />
          <path
            d="M890.499 591.21C889.415 591.21 888.532 592.092 888.532 593.174C888.532 594.256 889.415 595.138 890.499 595.138C891.584 595.138 892.466 594.256 892.466 593.174C892.466 592.092 891.592 591.21 890.499 591.21Z"
            fill="#EF4E05"
          />
          <path
            d="M887.616 571.057C887.616 570.041 886.792 569.206 885.767 569.206C884.749 569.206 883.917 570.032 883.917 571.057C883.917 572.081 884.741 572.907 885.767 572.907C886.792 572.907 887.616 572.081 887.616 571.057Z"
            fill="#EF4E05"
          />
          <path
            d="M867.399 585.309C868.357 585.309 869.13 584.531 869.13 583.572C869.13 582.613 868.357 581.835 867.399 581.835C866.432 581.835 865.659 582.613 865.659 583.572C865.659 584.531 866.432 585.309 867.399 585.309Z"
            fill="#EF4E05"
          />
          <path
            d="M856.311 558.741C856.311 559.622 857.026 560.344 857.917 560.344C858.799 560.344 859.522 559.632 859.522 558.741C859.522 557.858 858.808 557.137 857.917 557.137C857.034 557.137 856.311 557.858 856.311 558.741Z"
            fill="#EF4E05"
          />
          <path
            d="M863.431 534.175C863.431 533.311 862.725 532.6 861.851 532.6C860.977 532.6 860.279 533.302 860.279 534.175C860.279 535.048 860.977 535.749 861.851 535.749C862.725 535.749 863.431 535.038 863.431 534.175Z"
            fill="#EF4E05"
          />
          <path
            d="M834.546 537.524C834.546 538.292 835.172 538.909 835.931 538.909C836.69 538.909 837.316 538.283 837.316 537.524C837.316 536.756 836.69 536.139 835.931 536.139C835.172 536.139 834.546 536.756 834.546 537.524Z"
            fill="#EF4E05"
          />
          <path
            d="M842.424 513.575C843.189 513.575 843.803 512.948 843.803 512.189C843.803 511.43 843.18 510.804 842.424 510.804C841.651 510.804 841.037 511.43 841.037 512.189C841.037 512.948 841.651 513.575 842.424 513.575Z"
            fill="#EF4E05"
          />
          <path
            d="M814.695 514.59C815.369 514.59 815.92 514.039 815.92 513.366C815.92 512.693 815.369 512.142 814.695 512.142C814.022 512.142 813.471 512.693 813.471 513.366C813.471 514.039 814.022 514.59 814.695 514.59Z"
            fill="#EF4E05"
          />
          <path
            d="M825.56 488.658C826.262 488.658 826.822 488.089 826.822 487.396C826.822 486.703 826.253 486.134 825.56 486.134C824.868 486.134 824.298 486.703 824.298 487.396C824.298 488.089 824.868 488.658 825.56 488.658Z"
            fill="#EF4E05"
          />
          <path
            d="M839.926 467.954C840.667 467.954 841.264 467.356 841.264 466.616C841.264 465.876 840.667 465.278 839.926 465.278C839.185 465.278 838.587 465.876 838.587 466.616C838.587 467.356 839.185 467.954 839.926 467.954Z"
            fill="#EF4E05"
          />
          <path
            d="M835.323 440.266C834.593 440.266 834.004 440.855 834.004 441.585C834.004 442.316 834.593 442.904 835.323 442.904C836.054 442.904 836.642 442.316 836.642 441.585C836.642 440.855 836.054 440.266 835.323 440.266Z"
            fill="#EF4E05"
          />
          <path
            d="M838.768 415.397C838.768 414.657 838.17 414.059 837.43 414.059C836.69 414.059 836.092 414.657 836.092 415.397C836.092 416.137 836.69 416.735 837.43 416.735C838.17 416.735 838.768 416.137 838.768 415.397Z"
            fill="#EF4E05"
          />
          <path
            d="M853.285 398.023C854.1 398.023 854.756 397.365 854.756 396.553C854.756 395.74 854.1 395.082 853.285 395.082C852.469 395.082 851.814 395.74 851.814 396.553C851.814 397.365 852.469 398.023 853.285 398.023Z"
            fill="#EF4E05"
          />
          <path
            d="M875.334 387.842C876.251 387.842 876.999 387.099 876.999 386.182C876.999 385.265 876.251 384.521 875.334 384.521C874.418 384.521 873.678 385.265 873.678 386.182C873.678 387.099 874.418 387.842 875.334 387.842Z"
            fill="#EF4E05"
          />
          <path
            d="M878.1 363.077C878.1 362.138 877.335 361.369 876.394 361.369C875.452 361.369 874.679 362.128 874.679 363.077C874.679 364.026 875.444 364.785 876.394 364.785C877.327 364.785 878.1 364.016 878.1 363.077Z"
            fill="#EF4E05"
          />
          <path
            d="M852.049 372.841C852.89 372.841 853.554 372.167 853.554 371.342C853.554 370.507 852.873 369.842 852.049 369.842C851.225 369.842 850.553 370.516 850.553 371.342C850.553 372.167 851.225 372.841 852.049 372.841Z"
            fill="#EF4E05"
          />
          <path
            d="M827.23 370.393C827.969 370.393 828.568 369.794 828.568 369.055C828.568 368.316 827.969 367.717 827.23 367.717C826.491 367.717 825.892 368.316 825.892 369.055C825.892 369.794 826.491 370.393 827.23 370.393Z"
            fill="#EF4E05"
          />
          <path
            d="M823.283 392.767C822.58 392.767 822.011 393.336 822.011 394.039C822.011 394.74 822.58 395.31 823.283 395.31C823.985 395.31 824.554 394.74 824.554 394.039C824.554 393.336 823.985 392.767 823.283 392.767Z"
            fill="#EF4E05"
          />
          <path
            d="M799.001 376.228C799.647 376.228 800.159 375.706 800.159 375.071C800.159 374.435 799.637 373.913 799.001 373.913C798.366 373.913 797.844 374.435 797.844 375.071C797.844 375.706 798.366 376.228 799.001 376.228Z"
            fill="#EF4E05"
          />
          <path
            d="M791.971 402.398C791.373 402.398 790.889 402.881 790.889 403.479C790.889 404.077 791.373 404.561 791.971 404.561C792.568 404.561 793.052 404.077 793.052 403.479C793.052 402.881 792.568 402.398 791.971 402.398Z"
            fill="#EF4E05"
          />
          <path
            d="M813.358 425.749C814.012 425.749 814.544 425.217 814.544 424.563C814.544 423.908 814.012 423.376 813.358 423.376C812.703 423.376 812.171 423.908 812.171 424.563C812.171 425.217 812.703 425.749 813.358 425.749Z"
            fill="#EF4E05"
          />
          <path
            d="M787.217 434.963C787.795 434.963 788.26 434.497 788.26 433.919C788.26 433.34 787.795 432.875 787.217 432.875C786.638 432.875 786.173 433.34 786.173 433.919C786.163 434.497 786.638 434.963 787.217 434.963Z"
            fill="#EF4E05"
          />
          <path
            d="M772.642 459.158C772.642 459.69 773.079 460.126 773.61 460.126C774.142 460.126 774.578 459.69 774.578 459.158C774.578 458.627 774.142 458.19 773.61 458.19C773.079 458.19 772.642 458.617 772.642 459.158Z"
            fill="#EF4E05"
          />
          <path
            d="M809.894 459.11C810.533 459.11 811.052 458.593 811.052 457.953C811.052 457.314 810.533 456.795 809.894 456.795C809.254 456.795 808.737 457.314 808.737 457.953C808.737 458.593 809.254 459.11 809.894 459.11Z"
            fill="#EF4E05"
          />
          <path
            d="M795.035 486.912C795.638 486.912 796.127 486.423 796.127 485.821C796.127 485.218 795.638 484.729 795.035 484.729C794.433 484.729 793.944 485.218 793.944 485.821C793.944 486.423 794.433 486.912 795.035 486.912Z"
            fill="#EF4E05"
          />
          <path
            d="M785.319 516.507C785.319 515.928 784.845 515.453 784.265 515.453C783.687 515.453 783.212 515.928 783.212 516.507C783.212 517.086 783.687 517.56 784.265 517.56C784.845 517.56 785.319 517.086 785.319 516.507Z"
            fill="#EF4E05"
          />
          <path
            d="M752.64 524.62C752.64 524.107 752.223 523.699 751.72 523.699C751.208 523.699 750.8 524.117 750.8 524.62C750.8 525.122 751.217 525.54 751.72 525.54C752.223 525.54 752.64 525.131 752.64 524.62Z"
            fill="#EF4E05"
          />
          <path
            d="M760.336 491.258C760.848 491.258 761.256 490.84 761.256 490.337C761.256 489.835 760.839 489.417 760.336 489.417C759.823 489.417 759.416 489.835 759.416 490.337C759.416 490.84 759.823 491.258 760.336 491.258Z"
            fill="#EF4E05"
          />
          <path
            d="M722.723 494.759C722.723 494.332 722.372 493.98 721.945 493.98C721.518 493.98 721.167 494.332 721.167 494.759C721.167 495.186 721.518 495.537 721.945 495.537C722.372 495.537 722.723 495.186 722.723 494.759Z"
            fill="#EF4E05"
          />
          <path
            d="M738.512 457.46C738.967 457.46 739.337 457.09 739.337 456.634C739.337 456.178 738.967 455.809 738.512 455.809C738.056 455.809 737.686 456.178 737.686 456.634C737.686 457.09 738.056 457.46 738.512 457.46Z"
            fill="#EF4E05"
          />
          <path
            d="M691.288 458.066C691.667 458.066 691.971 457.763 691.971 457.384C691.971 457.004 691.667 456.7 691.288 456.7C690.908 456.7 690.604 457.004 690.604 457.384C690.604 457.754 690.917 458.066 691.288 458.066Z"
            fill="#EF4E05"
          />
          <path
            d="M677.69 510.538C678.062 510.538 678.364 510.237 678.364 509.865C678.364 509.493 678.062 509.191 677.69 509.191C677.318 509.191 677.017 509.493 677.017 509.865C677.017 510.237 677.318 510.538 677.69 510.538Z"
            fill="#EF4E05"
          />
          <path
            d="M716.783 536.139C716.783 536.584 717.144 536.945 717.589 536.945C718.036 536.945 718.396 536.584 718.396 536.139C718.396 535.692 718.036 535.332 717.589 535.332C717.153 535.332 716.783 535.692 716.783 536.139Z"
            fill="#EF4E05"
          />
          <path
            d="M681.638 555.125C682.046 555.125 682.378 554.794 682.378 554.385C682.378 553.976 682.046 553.645 681.638 553.645C681.229 553.645 680.898 553.976 680.898 554.385C680.898 554.794 681.229 555.125 681.638 555.125Z"
            fill="#EF4E05"
          />
          <path
            d="M637.62 566.483C637.993 566.483 638.294 566.181 638.294 565.809C638.294 565.437 637.993 565.135 637.62 565.135C637.249 565.135 636.947 565.437 636.947 565.809C636.947 566.181 637.249 566.483 637.62 566.483Z"
            fill="#EF4E05"
          />
          <path
            d="M589.267 597.055C589.267 597.434 589.571 597.738 589.95 597.738C590.33 597.738 590.633 597.434 590.633 597.055C590.633 596.675 590.33 596.372 589.95 596.372C589.571 596.372 589.267 596.675 589.267 597.055Z"
            fill="#EF4E05"
          />
          <path
            d="M603.775 644.574C603.775 644.119 603.405 643.749 602.949 643.749C602.494 643.749 602.124 644.119 602.124 644.574C602.124 645.029 602.494 645.4 602.949 645.4C603.405 645.409 603.775 645.039 603.775 644.574Z"
            fill="#EF4E05"
          />
          <path
            d="M634.385 614.608C634.812 614.608 635.154 614.267 635.154 613.84C635.154 613.413 634.812 613.072 634.385 613.072C633.958 613.072 633.616 613.413 633.616 613.84C633.616 614.258 633.958 614.608 634.385 614.608Z"
            fill="#EF4E05"
          />
          <path
            d="M643.095 651.785C643.599 651.785 644.006 651.377 644.006 650.874C644.006 650.371 643.599 649.964 643.095 649.964C642.592 649.964 642.185 650.371 642.185 650.874C642.185 651.377 642.592 651.785 643.095 651.785Z"
            fill="#EF4E05"
          />
          <path
            d="M652.755 683.96C652.755 684.559 653.239 685.042 653.837 685.042C654.434 685.042 654.918 684.559 654.918 683.96C654.918 683.362 654.434 682.879 653.837 682.879C653.239 682.879 652.755 683.362 652.755 683.96Z"
            fill="#EF4E05"
          />
          <path
            d="M620.541 677.034C620.541 677.575 620.977 678.011 621.519 678.011C622.059 678.011 622.495 677.575 622.495 677.034C622.495 676.493 622.059 676.057 621.519 676.057C620.977 676.057 620.541 676.493 620.541 677.034Z"
            fill="#EF4E05"
          />
          <path
            d="M593.546 688.202C594.092 688.202 594.533 687.76 594.533 687.215C594.533 686.67 594.092 686.228 593.546 686.228C593.001 686.228 592.559 686.67 592.559 687.215C592.559 687.76 593.001 688.202 593.546 688.202Z"
            fill="#EF4E05"
          />
          <path
            d="M568.819 665.382C568.335 665.382 567.937 665.781 567.937 666.264C567.937 666.748 568.335 667.147 568.819 667.147C569.303 667.147 569.702 666.748 569.702 666.264C569.702 665.781 569.313 665.382 568.819 665.382Z"
            fill="#EF4E05"
          />
          <path
            d="M562.007 631.963C562.007 631.546 561.674 631.214 561.257 631.214C560.839 631.214 560.507 631.546 560.507 631.963C560.507 632.381 560.839 632.713 561.257 632.713C561.674 632.713 562.007 632.381 562.007 631.963Z"
            fill="#EF4E05"
          />
          <path
            d="M528.198 605.605C528.576 605.605 528.882 605.299 528.882 604.921C528.882 604.544 528.576 604.238 528.198 604.238C527.821 604.238 527.516 604.544 527.516 604.921C527.516 605.299 527.821 605.605 528.198 605.605Z"
            fill="#EF4E05"
          />
          <path
            d="M476.04 591.115C476.04 590.745 475.736 590.441 475.366 590.441C474.996 590.441 474.692 590.745 474.692 591.115C474.692 591.485 474.996 591.789 475.366 591.789C475.736 591.789 476.04 591.485 476.04 591.115Z"
            fill="#EF4E05"
          />
          <path
            d="M437.175 612.645C437.605 612.645 437.953 612.296 437.953 611.866C437.953 611.437 437.605 611.089 437.175 611.089C436.745 611.089 436.397 611.437 436.397 611.866C436.397 612.296 436.745 612.645 437.175 612.645Z"
            fill="#EF4E05"
          />
          <path
            d="M425.893 570.174C425.893 570.563 426.206 570.876 426.595 570.876C426.984 570.876 427.297 570.563 427.297 570.174C427.297 569.785 426.984 569.472 426.595 569.472C426.206 569.472 425.893 569.785 425.893 570.174Z"
            fill="#EF4E05"
          />
          <path
            d="M391.867 602.644C392.334 602.644 392.711 602.266 392.711 601.799C392.711 601.333 392.334 600.954 391.867 600.954C391.401 600.954 391.022 601.333 391.022 601.799C391.022 602.266 391.401 602.644 391.867 602.644Z"
            fill="#EF4E05"
          />
          <path
            d="M412.078 636.641C412.576 636.641 412.979 636.238 412.979 635.74C412.979 635.242 412.576 634.838 412.078 634.838C411.58 634.838 411.177 635.242 411.177 635.74C411.177 636.238 411.58 636.641 412.078 636.641Z"
            fill="#EF4E05"
          />
          <path
            d="M380.642 638.481C380.642 637.922 380.187 637.476 379.636 637.476C379.086 637.476 378.631 637.932 378.631 638.481C378.631 639.041 379.086 639.488 379.636 639.488C380.187 639.488 380.642 639.041 380.642 638.481Z"
            fill="#EF4E05"
          />
          <path
            d="M356.257 616.848C356.257 616.288 355.801 615.843 355.25 615.843C354.701 615.843 354.245 616.298 354.245 616.848C354.245 617.408 354.701 617.854 355.25 617.854C355.801 617.854 356.257 617.399 356.257 616.848Z"
            fill="#EF4E05"
          />
          <path
            d="M353.97 585.451C354.478 585.451 354.89 585.039 354.89 584.53C354.89 584.022 354.478 583.61 353.97 583.61C353.462 583.61 353.05 584.022 353.05 584.53C353.05 585.039 353.462 585.451 353.97 585.451Z"
            fill="#EF4E05"
          />
          <path
            d="M383.175 560.467C383.175 560.04 382.825 559.689 382.398 559.689C381.971 559.689 381.619 560.04 381.619 560.467C381.619 560.894 381.971 561.245 382.398 561.245C382.825 561.245 383.175 560.894 383.175 560.467Z"
            fill="#EF4E05"
          />
          <path
            d="M345.725 545.58C345.725 546.054 346.113 546.443 346.588 546.443C347.062 546.443 347.451 546.054 347.451 545.58C347.451 545.105 347.062 544.716 346.588 544.716C346.113 544.716 345.725 545.105 345.725 545.58Z"
            fill="#EF4E05"
          />
          <path
            d="M310.977 540.56C311.533 540.56 311.983 540.11 311.983 539.554C311.983 538.998 311.533 538.549 310.977 538.549C310.422 538.549 309.971 538.998 309.971 539.554C309.971 540.11 310.422 540.56 310.977 540.56Z"
            fill="#EF4E05"
          />
          <path
            d="M320.713 571.986C320.713 572.546 321.169 573.002 321.728 573.002C322.288 573.002 322.743 572.546 322.743 571.986C322.743 571.426 322.288 570.971 321.728 570.971C321.169 570.971 320.713 571.426 320.713 571.986Z"
            fill="#EF4E05"
          />
          <path
            d="M325.276 605.661C325.276 605.054 324.783 604.56 324.176 604.56C323.568 604.56 323.075 605.054 323.075 605.661C323.075 606.269 323.568 606.762 324.176 606.762C324.783 606.771 325.276 606.278 325.276 605.661Z"
            fill="#EF4E05"
          />
          <path
            d="M326.168 636.726C326.832 636.726 327.364 636.186 327.364 635.531C327.364 634.876 326.823 634.335 326.168 634.335C325.513 634.335 324.973 634.876 324.973 635.531C324.973 636.186 325.504 636.726 326.168 636.726Z"
            fill="#EF4E05"
          />
          <path
            d="M296.09 624.941C296.09 625.663 296.679 626.242 297.39 626.242C298.111 626.242 298.69 625.653 298.69 624.941C298.69 624.23 298.111 623.642 297.39 623.642C296.679 623.632 296.09 624.22 296.09 624.941Z"
            fill="#EF4E05"
          />
          <path
            d="M304.971 653.056C305.73 653.056 306.347 652.439 306.347 651.68C306.347 650.921 305.73 650.305 304.971 650.305C304.212 650.305 303.595 650.921 303.595 651.68C303.586 652.439 304.203 653.056 304.971 653.056Z"
            fill="#EF4E05"
          />
          <path
            d="M331.606 669.063C332.327 669.063 332.915 668.476 332.915 667.754C332.915 667.033 332.327 666.445 331.606 666.445C330.884 666.445 330.296 667.033 330.296 667.754C330.296 668.476 330.884 669.063 331.606 669.063Z"
            fill="#EF4E05"
          />
          <path
            d="M351.37 649.318C352.006 649.318 352.519 648.805 352.519 648.17C352.519 647.534 352.006 647.021 351.37 647.021C350.735 647.021 350.222 647.534 350.222 648.17C350.222 648.805 350.735 649.318 351.37 649.318Z"
            fill="#EF4E05"
          />
          <path
            d="M365.802 672.802C365.802 672.137 365.262 671.597 364.597 671.597C363.933 671.597 363.392 672.137 363.392 672.802C363.392 673.466 363.933 674.007 364.597 674.007C365.262 674.007 365.802 673.466 365.802 672.802Z"
            fill="#EF4E05"
          />
          <path
            d="M340.6 693.221C340.6 693.99 341.227 694.617 341.996 694.617C342.764 694.617 343.39 693.99 343.39 693.221C343.39 692.453 342.764 691.827 341.996 691.827C341.227 691.817 340.6 692.444 340.6 693.221Z"
            fill="#EF4E05"
          />
          <path
            d="M318.312 697.661C319.157 697.661 319.839 696.979 319.839 696.134C319.839 695.29 319.157 694.607 318.312 694.607C317.467 694.607 316.784 695.29 316.784 696.134C316.784 696.969 317.467 697.661 318.312 697.661Z"
            fill="#EF4E05"
          />
          <path
            d="M308.577 675.563C308.577 674.747 307.913 674.092 307.106 674.092C306.29 674.092 305.635 674.756 305.635 675.563C305.635 676.369 306.299 677.033 307.106 677.033C307.922 677.033 308.577 676.379 308.577 675.563Z"
            fill="#EF4E05"
          />
          <path
            d="M283.451 675.667C284.337 675.667 285.055 674.949 285.055 674.064C285.055 673.178 284.337 672.46 283.451 672.46C282.566 672.46 281.847 673.178 281.847 674.064C281.847 674.949 282.566 675.667 283.451 675.667Z"
            fill="#EF4E05"
          />
          <path
            d="M279.503 649.28C278.669 649.28 278.005 649.953 278.005 650.779C278.005 651.614 278.678 652.279 279.503 652.279C280.339 652.279 281.003 651.604 281.003 650.779C281.012 649.953 280.339 649.28 279.503 649.28Z"
            fill="#EF4E05"
          />
          <path
            d="M267.511 632.466C268.334 632.466 269 631.799 269 630.976C269 630.153 268.334 629.487 267.511 629.487C266.688 629.487 266.021 630.153 266.021 630.976C266.021 631.799 266.688 632.466 267.511 632.466Z"
            fill="#EF4E05"
          />
          <path
            d="M252.282 613.442C253.116 613.442 253.79 612.768 253.79 611.933C253.79 611.098 253.116 610.425 252.282 610.425C251.446 610.425 250.773 611.098 250.773 611.933C250.773 612.768 251.446 613.442 252.282 613.442Z"
            fill="#EF4E05"
          />
          <path
            d="M241.265 594.493C242.109 594.493 242.793 593.81 242.793 592.965C242.793 592.121 242.109 591.438 241.265 591.438C240.42 591.438 239.737 592.121 239.737 592.965C239.737 593.81 240.42 594.493 241.265 594.493Z"
            fill="#EF4E05"
          />
          <path
            d="M236.72 570.079C235.895 570.079 235.23 570.743 235.23 571.569C235.23 572.395 235.895 573.059 236.72 573.059C237.546 573.059 238.21 572.395 238.21 571.569C238.21 570.743 237.546 570.079 236.72 570.079Z"
            fill="#EF4E05"
          />
          <path
            d="M227.867 549.944C227.867 550.77 228.541 551.434 229.357 551.434C230.173 551.434 230.847 550.76 230.847 549.944C230.847 549.119 230.173 548.455 229.357 548.455C228.541 548.455 227.867 549.119 227.867 549.944Z"
            fill="#EF4E05"
          />
          <path
            d="M201.669 547.733C201.669 548.663 202.419 549.422 203.358 549.422C204.289 549.422 205.048 548.673 205.048 547.733C205.048 546.794 204.298 546.045 203.358 546.045C202.419 546.054 201.669 546.804 201.669 547.733Z"
            fill="#EF4E05"
          />
          <path
            d="M197.58 529.8C198.519 529.8 199.279 529.041 199.279 528.101C199.279 527.162 198.519 526.403 197.58 526.403C196.641 526.403 195.882 527.162 195.882 528.101C195.882 529.051 196.641 529.8 197.58 529.8Z"
            fill="#EF4E05"
          />
          <path
            d="M183.366 514.989C183.366 513.992 182.559 513.176 181.554 513.176C180.548 513.176 179.742 513.982 179.742 514.989C179.742 515.985 180.548 516.801 181.554 516.801C182.559 516.801 183.366 515.985 183.366 514.989Z"
            fill="#EF4E05"
          />
          <path
            d="M164.161 499.57C164.161 500.623 165.015 501.486 166.078 501.486C167.141 501.486 167.995 500.632 167.995 499.57C167.995 498.507 167.141 497.653 166.078 497.653C165.015 497.653 164.161 498.507 164.161 499.57Z"
            fill="#EF4E05"
          />
          <path
            d="M172.796 476.627C172.796 475.602 171.961 474.766 170.936 474.766C169.911 474.766 169.077 475.602 169.077 476.627C169.077 477.651 169.911 478.486 170.936 478.486C171.97 478.486 172.796 477.651 172.796 476.627Z"
            fill="#EF4E05"
          />
          <path
            d="M189.543 493.326C190.492 493.326 191.261 492.557 191.261 491.609C191.261 490.66 190.492 489.891 189.543 489.891C188.594 489.891 187.826 490.66 187.826 491.609C187.826 492.557 188.594 493.326 189.543 493.326Z"
            fill="#EF4E05"
          />
          <path
            d="M209.222 506.572C209.222 505.69 208.51 504.978 207.628 504.978C206.746 504.978 206.034 505.69 206.034 506.572C206.034 507.455 206.746 508.166 207.628 508.166C208.51 508.166 209.222 507.455 209.222 506.572Z"
            fill="#EF4E05"
          />
          <path
            d="M222.155 528.88C222.981 528.88 223.645 528.207 223.645 527.39C223.645 526.565 222.972 525.901 222.155 525.901C221.339 525.901 220.666 526.574 220.666 527.39C220.666 528.207 221.339 528.88 222.155 528.88Z"
            fill="#EF4E05"
          />
          <path
            d="M233.883 505.025C233.883 505.785 234.5 506.402 235.259 506.402C236.018 506.402 236.634 505.785 236.634 505.025C236.634 504.266 236.018 503.649 235.259 503.649C234.5 503.649 233.883 504.266 233.883 505.025Z"
            fill="#EF4E05"
          />
          <path
            d="M245.725 481.19C246.433 481.19 247.006 480.617 247.006 479.909C247.006 479.202 246.433 478.628 245.725 478.628C245.017 478.628 244.444 479.202 244.444 479.909C244.444 480.617 245.017 481.19 245.725 481.19Z"
            fill="#EF4E05"
          />
          <path
            d="M255.934 454.3C255.934 454.974 256.475 455.514 257.149 455.514C257.822 455.514 258.363 454.974 258.363 454.3C258.363 453.626 257.822 453.085 257.149 453.085C256.475 453.076 255.934 453.626 255.934 454.3Z"
            fill="#EF4E05"
          />
          <path
            d="M288.499 456.283C289.068 456.283 289.533 455.818 289.533 455.249C289.533 454.68 289.068 454.215 288.499 454.215C287.93 454.215 287.465 454.68 287.465 455.249C287.465 455.818 287.93 456.283 288.499 456.283Z"
            fill="#EF4E05"
          />
          <path
            d="M277.293 481.485C277.293 480.868 276.79 480.374 276.183 480.374C275.575 480.374 275.073 480.878 275.073 481.485C275.073 482.102 275.575 482.595 276.183 482.595C276.79 482.595 277.293 482.102 277.293 481.485Z"
            fill="#EF4E05"
          />
          <path
            d="M263.62 508.137C264.284 508.137 264.815 507.597 264.815 506.942C264.815 506.278 264.275 505.747 263.62 505.747C262.965 505.747 262.424 506.287 262.424 506.942C262.415 507.606 262.956 508.137 263.62 508.137Z"
            fill="#EF4E05"
          />
          <path
            d="M247.812 530.54C247.812 531.271 248.4 531.859 249.131 531.859C249.861 531.859 250.45 531.271 250.45 530.54C250.45 529.81 249.861 529.221 249.131 529.221C248.4 529.212 247.812 529.81 247.812 530.54Z"
            fill="#EF4E05"
          />
          <path
            d="M257.51 556.368C257.51 557.09 258.098 557.678 258.818 557.678C259.54 557.678 260.128 557.09 260.128 556.368C260.128 555.647 259.54 555.059 258.818 555.059C258.098 555.059 257.51 555.647 257.51 556.368Z"
            fill="#EF4E05"
          />
          <path
            d="M265.973 584.284C266.712 584.284 267.311 583.684 267.311 582.945C267.311 582.207 266.712 581.607 265.973 581.607C265.234 581.607 264.635 582.207 264.635 582.945C264.635 583.684 265.234 584.284 265.973 584.284Z"
            fill="#EF4E05"
          />
          <path
            d="M276.544 605.898C275.794 605.898 275.196 606.506 275.196 607.245C275.196 607.995 275.803 608.593 276.544 608.593C277.284 608.593 277.89 607.986 277.89 607.245C277.901 606.506 277.293 605.898 276.544 605.898Z"
            fill="#EF4E05"
          />
          <path
            d="M297.466 593.108C298.121 593.108 298.652 592.577 298.652 591.922C298.652 591.267 298.121 590.736 297.466 590.736C296.81 590.736 296.28 591.267 296.28 591.922C296.28 592.577 296.81 593.108 297.466 593.108Z"
            fill="#EF4E05"
          />
          <path
            d="M289.059 564.727C289.704 564.727 290.216 564.205 290.216 563.57C290.216 562.934 289.694 562.412 289.059 562.412C288.413 562.412 287.901 562.934 287.901 563.57C287.901 564.205 288.423 564.727 289.059 564.727Z"
            fill="#EF4E05"
          />
          <path
            d="M278.792 535.721C279.437 535.721 279.95 535.199 279.95 534.563C279.95 533.928 279.428 533.406 278.792 533.406C278.147 533.406 277.635 533.928 277.635 534.563C277.635 535.199 278.157 535.721 278.792 535.721Z"
            fill="#EF4E05"
          />
          <path
            d="M295.634 507.246C295.065 507.246 294.61 507.701 294.61 508.27C294.61 508.84 295.065 509.295 295.634 509.295C296.203 509.295 296.659 508.84 296.659 508.27C296.659 507.71 296.203 507.246 295.634 507.246Z"
            fill="#EF4E05"
          />
          <path
            d="M310.408 477.917C310.408 478.429 310.825 478.847 311.338 478.847C311.85 478.847 312.268 478.429 312.268 477.917C312.268 477.404 311.85 476.987 311.338 476.987C310.825 476.987 310.408 477.404 310.408 477.917Z"
            fill="#EF4E05"
          />
          <path
            d="M322.914 439.877C322.914 439.394 322.516 438.995 322.032 438.995C321.548 438.995 321.149 439.394 321.149 439.877C321.149 440.361 321.548 440.76 322.032 440.76C322.516 440.76 322.914 440.361 322.914 439.877Z"
            fill="#EF4E05"
          />
          <path
            d="M347.783 470.098C347.783 469.662 347.433 469.311 346.996 469.311C346.559 469.311 346.208 469.662 346.208 470.098C346.208 470.535 346.559 470.886 346.996 470.886C347.433 470.886 347.783 470.535 347.783 470.098Z"
            fill="#EF4E05"
          />
          <path
            d="M330.191 510.282C330.676 510.282 331.074 509.883 331.074 509.4C331.074 508.916 330.676 508.517 330.191 508.517C329.707 508.517 329.309 508.916 329.309 509.4C329.309 509.883 329.698 510.282 330.191 510.282Z"
            fill="#EF4E05"
          />
          <path
            d="M367.017 509.741C367.017 510.159 367.349 510.491 367.767 510.491C368.184 510.491 368.516 510.159 368.516 509.741C368.516 509.324 368.184 508.992 367.767 508.992C367.349 508.992 367.017 509.324 367.017 509.741Z"
            fill="#EF4E05"
          />
          <path
            d="M407.476 522.551C407.476 522.181 407.182 521.887 406.812 521.887C406.441 521.887 406.147 522.181 406.147 522.551C406.147 522.922 406.441 523.216 406.812 523.216C407.182 523.216 407.476 522.922 407.476 522.551Z"
            fill="#EF4E05"
          />
          <path
            d="M389.201 460.914C389.201 460.543 388.898 460.239 388.527 460.239C388.157 460.239 387.854 460.543 387.854 460.914C387.854 461.283 388.157 461.587 388.527 461.587C388.898 461.587 389.201 461.283 389.201 460.914Z"
            fill="#EF4E05"
          />
          <path
            d="M357.68 423.471C357.68 423.889 358.021 424.23 358.439 424.23C358.857 424.23 359.198 423.889 359.198 423.471C359.198 423.054 358.857 422.712 358.439 422.712C358.021 422.712 357.68 423.054 357.68 423.471Z"
            fill="#EF4E05"
          />
          <path
            d="M334.281 391.733C333.807 391.733 333.418 392.121 333.418 392.596C333.418 393.07 333.807 393.459 334.281 393.459C334.755 393.459 335.145 393.07 335.145 392.596C335.145 392.121 334.755 391.733 334.281 391.733Z"
            fill="#EF4E05"
          />
          <path
            d="M306.452 406.762C306.452 407.294 306.879 407.721 307.41 407.721C307.941 407.721 308.368 407.294 308.368 406.762C308.368 406.231 307.941 405.804 307.41 405.804C306.879 405.804 306.452 406.231 306.452 406.762Z"
            fill="#EF4E05"
          />
          <path
            d="M288.452 427.903C289.031 427.903 289.495 427.438 289.495 426.859C289.495 426.28 289.031 425.815 288.452 425.815C287.873 425.815 287.408 426.28 287.408 426.859C287.399 427.438 287.873 427.903 288.452 427.903Z"
            fill="#EF4E05"
          />
          <path
            d="M263.791 424.648C263.791 423.993 263.259 423.462 262.605 423.462C261.951 423.462 261.418 423.993 261.418 424.648C261.418 425.303 261.951 425.834 262.605 425.834C263.259 425.834 263.791 425.312 263.791 424.648Z"
            fill="#EF4E05"
          />
          <path
            d="M239.244 433.036C239.244 432.296 238.647 431.708 237.915 431.708C237.176 431.708 236.587 432.305 236.587 433.036C236.587 433.777 237.185 434.364 237.915 434.364C238.647 434.364 239.244 433.777 239.244 433.036Z"
            fill="#EF4E05"
          />
          <path
            d="M223.427 459.006C223.427 459.794 224.063 460.429 224.85 460.429C225.637 460.429 226.274 459.794 226.274 459.006C226.274 458.219 225.637 457.583 224.85 457.583C224.072 457.573 223.427 458.209 223.427 459.006Z"
            fill="#EF4E05"
          />
          <path
            d="M217.392 485.384C218.214 485.384 218.882 484.718 218.882 483.895C218.882 483.072 218.214 482.405 217.392 482.405C216.569 482.405 215.902 483.072 215.902 483.895C215.902 484.718 216.569 485.384 217.392 485.384Z"
            fill="#EF4E05"
          />
          <path
            d="M197.086 470.459C197.988 470.459 198.728 469.728 198.728 468.817C198.728 467.916 197.997 467.175 197.086 467.175C196.176 467.175 195.445 467.906 195.445 468.817C195.445 469.718 196.185 470.459 197.086 470.459Z"
            fill="#EF4E05"
          />
          <path
            d="M194.326 448.275C195.236 448.275 195.986 447.534 195.986 446.615C195.986 445.703 195.246 444.953 194.326 444.953C193.414 444.953 192.665 445.694 192.665 446.615C192.675 447.534 193.414 448.275 194.326 448.275Z"
            fill="#EF4E05"
          />
          <path
            d="M212.296 436.471C212.296 437.306 212.971 437.97 213.796 437.97C214.622 437.97 215.295 437.296 215.295 436.471C215.295 435.645 214.622 434.972 213.796 434.972C212.971 434.972 212.296 435.636 212.296 436.471Z"
            fill="#EF4E05"
          />
          <path
            d="M218.882 413.528C219.699 413.528 220.362 412.865 220.362 412.047C220.362 411.23 219.699 410.567 218.882 410.567C218.064 410.567 217.402 411.23 217.402 412.047C217.402 412.865 218.064 413.528 218.882 413.528Z"
            fill="#EF4E05"
          />
          <path
            d="M219.442 385.622C218.606 385.622 217.933 386.296 217.933 387.13C217.933 387.966 218.606 388.639 219.442 388.639C220.277 388.639 220.95 387.966 220.95 387.13C220.96 386.296 220.286 385.622 219.442 385.622Z"
            fill="#EF4E05"
          />
          <path
            d="M219.982 362.555C219.982 361.692 219.28 360.989 218.417 360.989C217.554 360.989 216.851 361.692 216.851 362.555C216.851 363.419 217.554 364.121 218.417 364.121C219.28 364.111 219.982 363.41 219.982 362.555Z"
            fill="#EF4E05"
          />
          <path
            d="M195.065 372.585C194.116 372.585 193.339 373.353 193.339 374.312C193.339 375.261 194.107 376.038 195.065 376.038C196.014 376.038 196.792 375.27 196.792 374.312C196.783 373.353 196.014 372.585 195.065 372.585Z"
            fill="#EF4E05"
          />
          <path
            d="M195.331 399.693C196.264 399.693 197.02 398.937 197.02 398.005C197.02 397.072 196.264 396.316 195.331 396.316C194.399 396.316 193.642 397.072 193.642 398.005C193.642 398.937 194.399 399.693 195.331 399.693Z"
            fill="#EF4E05"
          />
          <path
            d="M195.844 420.929C195.844 420.008 195.094 419.259 194.173 419.259C193.253 419.259 192.503 420.008 192.503 420.929C192.503 421.849 193.253 422.599 194.173 422.599C195.094 422.599 195.844 421.849 195.844 420.929Z"
            fill="#EF4E05"
          />
          <path
            d="M173.745 433.045C174.76 433.045 175.586 432.22 175.586 431.205C175.586 430.19 174.76 429.364 173.745 429.364C172.729 429.364 171.904 430.19 171.904 431.205C171.904 432.22 172.729 433.045 173.745 433.045Z"
            fill="#EF4E05"
          />
          <path
            d="M173.375 408.745C173.375 407.711 172.53 406.866 171.496 406.866C170.462 406.866 169.617 407.711 169.617 408.745C169.617 409.779 170.452 410.624 171.496 410.624C172.54 410.624 173.375 409.779 173.375 408.745Z"
            fill="#EF4E05"
          />
          <path
            d="M173.156 384.36C172.113 384.36 171.268 385.204 171.268 386.248C171.268 387.292 172.113 388.137 173.156 388.137C174.2 388.137 175.045 387.292 175.045 386.248C175.045 385.204 174.2 384.36 173.156 384.36Z"
            fill="#EF4E05"
          />
          <path
            d="M171.173 361.255C170.101 361.255 169.228 362.128 169.228 363.2C169.228 364.273 170.101 365.145 171.173 365.145C172.246 365.145 173.118 364.273 173.118 363.2C173.118 362.128 172.246 361.255 171.173 361.255Z"
            fill="#EF4E05"
          />
          <path
            d="M192.608 349.708C191.621 349.708 190.814 350.505 190.814 351.501C190.814 352.497 191.612 353.294 192.608 353.294C193.595 353.294 194.401 352.497 194.401 351.501C194.401 350.505 193.595 349.708 192.608 349.708Z"
            fill="#EF4E05"
          />
          <path
            d="M189.913 327.068C188.879 327.068 188.044 327.903 188.044 328.937C188.044 329.972 188.879 330.807 189.913 330.807C190.947 330.807 191.783 329.972 191.783 328.937C191.773 327.903 190.938 327.068 189.913 327.068Z"
            fill="#EF4E05"
          />
          <path
            d="M188.898 306.877C188.898 305.786 188.015 304.903 186.924 304.903C185.833 304.903 184.951 305.786 184.951 306.877C184.951 307.968 185.833 308.85 186.924 308.85C188.015 308.85 188.898 307.968 188.898 306.877Z"
            fill="#EF4E05"
          />
          <path
            d="M165.736 317.096C165.736 318.254 166.676 319.202 167.842 319.202C169 319.202 169.949 318.263 169.949 317.096C169.949 315.929 169.01 314.99 167.842 314.99C166.685 314.99 165.736 315.929 165.736 317.096Z"
            fill="#EF4E05"
          />
          <path
            d="M147.214 307.465C145.933 307.465 144.899 308.499 144.899 309.78C144.899 311.061 145.933 312.095 147.214 312.095C148.496 312.095 149.53 311.061 149.53 309.78C149.53 308.499 148.496 307.465 147.214 307.465Z"
            fill="#EF4E05"
          />
          <path
            d="M124.508 304.979C124.508 306.374 125.647 307.512 127.042 307.512C128.437 307.512 129.576 306.374 129.576 304.979C129.576 303.584 128.437 302.446 127.042 302.446C125.647 302.446 124.508 303.575 124.508 304.979Z"
            fill="#EF4E05"
          />
          <path
            d="M127.469 320.806C126.103 320.806 124.992 321.915 124.992 323.282C124.992 324.648 126.103 325.759 127.469 325.759C128.835 325.759 129.945 324.648 129.945 323.282C129.945 321.915 128.835 320.806 127.469 320.806Z"
            fill="#EF4E05"
          />
          <path
            d="M129.993 343.957C131.319 343.957 132.393 342.883 132.393 341.557C132.393 340.231 131.319 339.157 129.993 339.157C128.667 339.157 127.593 340.231 127.593 341.557C127.593 342.883 128.667 343.957 129.993 343.957Z"
            fill="#EF4E05"
          />
          <path
            d="M149.15 333.748C150.387 333.748 151.39 332.745 151.39 331.509C151.39 330.272 150.387 329.269 149.15 329.269C147.914 329.269 146.911 330.272 146.911 331.509C146.911 332.745 147.914 333.748 149.15 333.748Z"
            fill="#EF4E05"
          />
          <path
            d="M170.661 341.339C170.661 340.228 169.76 339.318 168.64 339.318C167.53 339.318 166.619 340.219 166.619 341.339C166.619 342.459 167.521 343.36 168.64 343.36C169.76 343.36 170.661 342.449 170.661 341.339Z"
            fill="#EF4E05"
          />
          <path
            d="M146.74 353.351C145.536 353.351 144.558 354.328 144.558 355.534C144.558 356.738 145.536 357.716 146.74 357.716C147.946 357.716 148.923 356.738 148.923 355.534C148.923 354.328 147.946 353.351 146.74 353.351Z"
            fill="#EF4E05"
          />
          <path
            d="M154.294 375.726C154.294 374.577 153.364 373.637 152.205 373.637C151.048 373.637 150.118 374.567 150.118 375.726C150.118 376.873 151.048 377.813 152.205 377.813C153.364 377.813 154.294 376.873 154.294 375.726Z"
            fill="#EF4E05"
          />
          <path
            d="M133.352 378.391C133.352 377.12 132.327 376.095 131.056 376.095C129.784 376.095 128.759 377.12 128.759 378.391C128.759 379.663 129.784 380.688 131.056 380.688C132.318 380.688 133.352 379.654 133.352 378.391Z"
            fill="#EF4E05"
          />
          <path
            d="M109.716 371.209C108.321 371.209 107.192 372.338 107.192 373.732C107.192 375.128 108.321 376.257 109.716 376.257C111.11 376.257 112.24 375.128 112.24 373.732C112.24 372.338 111.11 371.209 109.716 371.209Z"
            fill="#EF4E05"
          />
          <path
            d="M121.985 360.306C123.332 360.306 124.423 359.215 124.423 357.868C124.423 356.521 123.332 355.43 121.985 355.43C120.637 355.43 119.546 356.521 119.546 357.868C119.546 359.215 120.637 360.306 121.985 360.306Z"
            fill="#EF4E05"
          />
          <path
            d="M97.4949 352.25C97.4949 353.731 98.7003 354.936 100.18 354.936C101.66 354.936 102.866 353.731 102.866 352.25C102.866 350.771 101.66 349.565 100.18 349.565C98.6902 349.565 97.4949 350.771 97.4949 352.25Z"
            fill="#EF4E05"
          />
          <path
            d="M107.828 332.021C106.367 332.021 105.181 333.207 105.181 334.669C105.181 336.13 106.367 337.316 107.828 337.316C109.289 337.316 110.475 336.13 110.475 334.669C110.475 333.207 109.289 332.021 107.828 332.021Z"
            fill="#EF4E05"
          />
          <path
            d="M105.209 313.044C105.209 314.552 106.433 315.767 107.932 315.767C109.441 315.767 110.656 314.543 110.656 313.044C110.656 311.535 109.431 310.321 107.932 310.321C106.433 310.311 105.209 311.535 105.209 313.044Z"
            fill="#EF4E05"
          />
          <path
            d="M117.943 291.277C117.943 289.778 116.728 288.564 115.229 288.564C113.73 288.564 112.515 289.778 112.515 291.277C112.515 292.777 113.73 293.991 115.229 293.991C116.728 293.991 117.943 292.777 117.943 291.277Z"
            fill="#EF4E05"
          />
          <path
            d="M127.63 274.274C127.63 272.794 126.426 271.589 124.945 271.589C123.465 271.589 122.26 272.794 122.26 274.274C122.26 275.754 123.465 276.96 124.945 276.96C126.426 276.96 127.63 275.754 127.63 274.274Z"
            fill="#EF4E05"
          />
          <path
            d="M141.711 289.674C141.711 288.307 140.601 287.198 139.235 287.198C137.869 287.198 136.758 288.307 136.758 289.674C136.758 291.04 137.869 292.151 139.235 292.151C140.601 292.151 141.711 291.04 141.711 289.674Z"
            fill="#EF4E05"
          />
          <path
            d="M162.624 295.68C163.858 295.68 164.854 294.683 164.854 293.45C164.854 292.217 163.858 291.22 162.624 291.22C161.391 291.22 160.395 292.217 160.395 293.45C160.395 294.683 161.391 295.68 162.624 295.68Z"
            fill="#EF4E05"
          />
          <path
            d="M186.203 286.191C186.203 285.053 185.283 284.133 184.144 284.133C183.006 284.133 182.085 285.053 182.085 286.191C182.085 287.33 183.006 288.25 184.144 288.25C185.283 288.25 186.203 287.33 186.203 286.191Z"
            fill="#EF4E05"
          />
          <path
            d="M170.49 269.179C169.247 269.179 168.232 270.184 168.232 271.437C168.232 272.689 169.238 273.695 170.49 273.695C171.733 273.695 172.748 272.689 172.748 271.437C172.748 270.184 171.733 269.179 170.49 269.179Z"
            fill="#EF4E05"
          />
          <path
            d="M146.532 273.173C146.532 274.52 147.623 275.621 148.98 275.621C150.336 275.621 151.428 274.53 151.428 273.173C151.428 271.817 150.336 270.725 148.98 270.725C147.623 270.725 146.532 271.817 146.532 273.173Z"
            fill="#EF4E05"
          />
          <path
            d="M133.884 260.648C135.363 260.648 136.569 259.444 136.569 257.963C136.569 256.483 135.363 255.278 133.884 255.278C132.404 255.278 131.198 256.483 131.198 257.963C131.189 259.453 132.393 260.648 133.884 260.648Z"
            fill="#EF4E05"
          />
          <path
            d="M140.705 243.711C142.186 243.711 143.39 242.507 143.39 241.026C143.39 239.546 142.186 238.341 140.705 238.341C139.225 238.341 138.021 239.546 138.021 241.026C138.021 242.507 139.225 243.711 140.705 243.711Z"
            fill="#EF4E05"
          />
          <path
            d="M148.249 224.526C148.249 223.017 147.025 221.793 145.516 221.793C144.007 221.793 142.783 223.017 142.783 224.526C142.783 226.034 144.007 227.259 145.516 227.259C147.025 227.259 148.249 226.034 148.249 224.526Z"
            fill="#EF4E05"
          />
          <path
            d="M145.441 199.191C147.053 199.191 148.363 197.882 148.363 196.269C148.363 194.656 147.053 193.346 145.441 193.346C143.828 193.346 142.518 194.656 142.518 196.269C142.509 197.882 143.817 199.191 145.441 199.191Z"
            fill="#EF4E05"
          />
          <path
            d="M134.576 212.959C136.189 212.959 137.498 211.65 137.498 210.037C137.498 208.424 136.189 207.114 134.576 207.114C132.963 207.114 131.654 208.424 131.654 210.037C131.654 211.65 132.963 212.959 134.576 212.959Z"
            fill="#EF4E05"
          />
          <path
            d="M125.229 228.017C126.852 228.017 128.162 226.708 128.162 225.086C128.162 223.462 126.852 222.153 125.229 222.153C123.607 222.153 122.297 223.462 122.297 225.086C122.297 226.708 123.616 228.017 125.229 228.017Z"
            fill="#EF4E05"
          />
          <path
            d="M122.326 242.62C122.326 241.016 121.026 239.717 119.423 239.717C117.819 239.717 116.519 241.016 116.519 242.62C116.519 244.224 117.819 245.524 119.423 245.524C121.026 245.524 122.326 244.224 122.326 242.62Z"
            fill="#EF4E05"
          />
          <path
            d="M110.921 260.781C112.534 260.781 113.834 259.472 113.834 257.868C113.834 256.255 112.524 254.955 110.921 254.955C109.308 254.955 108.009 256.264 108.009 257.868C108.009 259.472 109.317 260.781 110.921 260.781Z"
            fill="#EF4E05"
          />
          <path
            d="M103.72 276.494C105.334 276.494 106.642 275.186 106.642 273.572C106.642 271.958 105.334 270.649 103.72 270.649C102.105 270.649 100.797 271.958 100.797 273.572C100.797 275.186 102.105 276.494 103.72 276.494Z"
            fill="#EF4E05"
          />
          <path
            d="M96.8213 293.213C98.4336 293.213 99.7341 291.903 99.7341 290.3C99.7341 288.696 98.4244 287.387 96.8213 287.387C95.2082 287.387 93.9077 288.696 93.9077 290.3C93.9077 291.903 95.2082 293.213 96.8213 293.213Z"
            fill="#EF4E05"
          />
          <path
            d="M90.3691 309.562C91.9822 309.562 93.2818 308.252 93.2818 306.649C93.2818 305.036 91.973 303.736 90.3691 303.736C88.7559 303.736 87.4563 305.045 87.4563 306.649C87.4563 308.262 88.7559 309.562 90.3691 309.562Z"
            fill="#EF4E05"
          />
          <path
            d="M87.6462 327.078C89.2493 327.078 90.5497 325.777 90.5497 324.174C90.5497 322.57 89.2493 321.271 87.6462 321.271C86.0423 321.271 84.7427 322.57 84.7427 324.174C84.7427 325.777 86.0423 327.078 87.6462 327.078Z"
            fill="#EF4E05"
          />
          <path
            d="M82.1708 344.081C83.7839 344.081 85.093 342.771 85.093 341.159C85.093 339.546 83.7839 338.236 82.1708 338.236C80.5577 338.236 79.2483 339.546 79.2483 341.159C79.2388 342.771 80.5482 344.081 82.1708 344.081Z"
            fill="#EF4E05"
          />
          <path
            d="M76.9711 361.682C78.5842 361.682 79.8935 360.373 79.8935 358.76C79.8935 357.147 78.5842 355.837 76.9711 355.837C75.358 355.837 74.0486 357.147 74.0486 358.76C74.0486 360.373 75.358 361.682 76.9711 361.682Z"
            fill="#EF4E05"
          />
          <path
            d="M91.0996 366.474C89.5906 366.474 88.3667 367.698 88.3667 369.207C88.3667 370.715 89.5906 371.939 91.0996 371.939C92.6085 371.939 93.8324 370.715 93.8324 369.207C93.8324 367.698 92.6085 366.474 91.0996 366.474Z"
            fill="#EF4E05"
          />
          <path
            d="M91.1186 385.337C89.629 385.337 88.4143 386.552 88.4143 388.042C88.4143 389.531 89.629 390.746 91.1186 390.746C92.6082 390.746 93.8229 389.531 93.8229 388.042C93.8229 386.552 92.6174 385.337 91.1186 385.337Z"
            fill="#EF4E05"
          />
          <path
            d="M73.0902 381.333C74.7033 381.333 76.0127 380.024 76.0127 378.41C76.0127 376.797 74.7033 375.488 73.0902 375.488C71.4772 375.488 70.1677 376.797 70.1677 378.41C70.1677 380.024 71.4772 381.333 73.0902 381.333Z"
            fill="#EF4E05"
          />
          <path
            d="M71.278 402.378C72.891 402.378 74.1909 401.07 74.1909 399.466C74.1909 397.852 72.8816 396.553 71.278 396.553C69.6649 396.553 68.365 397.862 68.365 399.466C68.365 401.07 69.6649 402.378 71.278 402.378Z"
            fill="#EF4E05"
          />
          <path
            d="M69.6555 420.91C71.2685 420.91 72.5685 419.6 72.5685 417.996C72.5685 416.384 71.259 415.084 69.6555 415.084C68.0519 415.084 66.7424 416.393 66.7424 417.996C66.7424 419.61 68.0424 420.91 69.6555 420.91Z"
            fill="#EF4E05"
          />
          <path
            d="M69.2949 440.845C70.8985 440.845 72.1984 439.545 72.1984 437.942C72.1984 436.338 70.8985 435.038 69.2949 435.038C67.6913 435.038 66.3914 436.338 66.3914 437.942C66.3914 439.545 67.6913 440.845 69.2949 440.845Z"
            fill="#EF4E05"
          />
          <path
            d="M89.2206 427.817C90.7009 427.817 91.9064 426.613 91.9064 425.132C91.9064 423.652 90.7009 422.447 89.2206 422.447C87.7403 422.447 86.5356 423.652 86.5356 425.132C86.5356 426.613 87.7403 427.817 89.2206 427.817Z"
            fill="#EF4E05"
          />
          <path
            d="M86.8105 406.421C86.8105 407.911 88.016 409.116 89.5056 409.116C90.9952 409.116 92.2006 407.911 92.2006 406.421C92.2006 404.931 90.9952 403.726 89.5056 403.726C88.016 403.726 86.8105 404.931 86.8105 406.421Z"
            fill="#EF4E05"
          />
          <path
            d="M108.331 412.095C106.965 412.095 105.864 413.196 105.864 414.562C105.864 415.928 106.965 417.029 108.331 417.029C109.698 417.029 110.798 415.928 110.798 414.562C110.798 413.205 109.698 412.095 108.331 412.095Z"
            fill="#EF4E05"
          />
          <path
            d="M110.751 396.439C112.118 396.439 113.227 395.33 113.227 393.962C113.227 392.594 112.118 391.486 110.751 391.486C109.383 391.486 108.274 392.594 108.274 393.962C108.274 395.33 109.383 396.439 110.751 396.439Z"
            fill="#EF4E05"
          />
          <path
            d="M132.242 401.202C132.242 399.95 131.227 398.925 129.965 398.925C128.712 398.925 127.688 399.94 127.688 401.202C127.688 402.455 128.703 403.48 129.965 403.48C131.217 403.48 132.242 402.455 132.242 401.202Z"
            fill="#EF4E05"
          />
          <path
            d="M150.631 394.636C149.492 394.636 148.562 395.556 148.562 396.704C148.562 397.843 149.482 398.773 150.631 398.773C151.769 398.773 152.7 397.852 152.7 396.704C152.689 395.566 151.769 394.636 150.631 394.636Z"
            fill="#EF4E05"
          />
          <path
            d="M149.549 416.982C148.41 416.982 147.48 417.911 147.48 419.05C147.48 420.189 148.4 421.118 149.549 421.118C150.687 421.118 151.618 420.198 151.618 419.05C151.618 417.902 150.697 416.982 149.549 416.982Z"
            fill="#EF4E05"
          />
          <path
            d="M155.356 442.534C156.456 442.534 157.358 441.642 157.358 440.532C157.358 439.422 156.466 438.53 155.356 438.53C154.256 438.53 153.354 439.422 153.354 440.532C153.354 441.642 154.256 442.534 155.356 442.534Z"
            fill="#EF4E05"
          />
          <path
            d="M173.26 457.393C174.277 457.393 175.101 456.569 175.101 455.552C175.101 454.536 174.277 453.711 173.26 453.711C172.244 453.711 171.42 454.536 171.42 455.552C171.42 456.569 172.244 457.393 173.26 457.393Z"
            fill="#EF4E05"
          />
          <path
            d="M149.767 459.044C148.628 459.044 147.708 459.965 147.708 461.104C147.708 462.242 148.628 463.162 149.767 463.162C150.905 463.162 151.826 462.242 151.826 461.104C151.826 459.965 150.905 459.044 149.767 459.044Z"
            fill="#EF4E05"
          />
          <path
            d="M149.615 486.646C150.758 486.646 151.684 485.72 151.684 484.578C151.684 483.436 150.758 482.509 149.615 482.509C148.473 482.509 147.546 483.436 147.546 484.578C147.546 485.72 148.473 486.646 149.615 486.646Z"
            fill="#EF4E05"
          />
          <path
            d="M132.659 474.027C133.881 474.027 134.87 473.036 134.87 471.816C134.87 470.594 133.881 469.605 132.659 469.605C131.439 469.605 130.448 470.594 130.448 471.816C130.448 473.036 131.439 474.027 132.659 474.027Z"
            fill="#EF4E05"
          />
          <path
            d="M123.247 456.957C124.52 456.957 125.553 455.925 125.553 454.651C125.553 453.377 124.52 452.345 123.247 452.345C121.973 452.345 120.941 453.377 120.941 454.651C120.941 455.925 121.973 456.957 123.247 456.957Z"
            fill="#EF4E05"
          />
          <path
            d="M135.25 442.288C136.454 442.288 137.441 441.31 137.441 440.096C137.441 438.891 136.464 437.904 135.25 437.904C134.044 437.904 133.057 438.881 133.057 440.096C133.057 441.31 134.035 442.288 135.25 442.288Z"
            fill="#EF4E05"
          />
          <path
            d="M127.469 419.306C126.216 419.306 125.192 420.322 125.192 421.583C125.192 422.845 126.207 423.861 127.469 423.861C128.721 423.861 129.746 422.845 129.746 421.583C129.746 420.322 128.721 419.306 127.469 419.306Z"
            fill="#EF4E05"
          />
          <path
            d="M113.018 436.044C113.018 434.697 111.927 433.605 110.579 433.605C109.232 433.605 108.141 434.697 108.141 436.044C108.141 437.391 109.232 438.482 110.579 438.482C111.927 438.482 113.018 437.391 113.018 436.044Z"
            fill="#EF4E05"
          />
          <path
            d="M102.742 459.092C104.131 459.092 105.256 457.966 105.256 456.577C105.256 455.188 104.131 454.063 102.742 454.063C101.353 454.063 100.228 455.188 100.228 456.577C100.228 457.966 101.353 459.092 102.742 459.092Z"
            fill="#EF4E05"
          />
          <path
            d="M92.0106 442.249C92.0106 440.77 90.8152 439.574 89.3349 439.574C87.8546 439.574 86.6592 440.77 86.6592 442.249C86.6592 443.73 87.8546 444.925 89.3349 444.925C90.8152 444.935 92.0106 443.73 92.0106 442.249Z"
            fill="#EF4E05"
          />
          <path
            d="M82.4365 462.204C83.951 462.204 85.1789 460.976 85.1789 459.462C85.1789 457.947 83.951 456.72 82.4365 456.72C80.9221 456.72 79.6943 457.947 79.6943 459.462C79.6943 460.976 80.9221 462.204 82.4365 462.204Z"
            fill="#EF4E05"
          />
          <path
            d="M69.4372 459.11C69.4372 457.488 68.1277 456.178 66.5053 456.178C64.8827 456.178 63.5732 457.488 63.5732 459.11C63.5732 460.733 64.8827 462.043 66.5053 462.043C68.1277 462.043 69.4372 460.733 69.4372 459.11Z"
            fill="#EF4E05"
          />
          <path
            d="M72.7298 479.53C72.7298 477.917 71.4204 476.617 69.8168 476.617C68.2038 476.617 66.9038 477.926 66.9038 479.53C66.9038 481.134 68.2133 482.443 69.8168 482.443C71.4299 482.443 72.7298 481.143 72.7298 479.53Z"
            fill="#EF4E05"
          />
          <path
            d="M73.6595 500.5C73.6595 498.877 72.3502 497.567 70.7276 497.567C69.105 497.567 67.7957 498.877 67.7957 500.5C67.7957 502.122 69.105 503.432 70.7276 503.432C72.3502 503.432 73.6595 502.122 73.6595 500.5Z"
            fill="#EF4E05"
          />
          <path
            d="M89.3911 493.316C89.3911 491.818 88.1772 490.603 86.6776 490.603C85.1787 490.603 83.9641 491.818 83.9641 493.316C83.9641 494.816 85.1787 496.03 86.6776 496.03C88.1772 496.03 89.3911 494.816 89.3911 493.316Z"
            fill="#EF4E05"
          />
          <path
            d="M91.5929 478.23C93.064 478.23 94.2501 477.044 94.2501 475.573C94.2501 474.102 93.064 472.916 91.5929 472.916C90.1226 472.916 88.9365 474.102 88.9365 475.573C88.9365 477.044 90.1226 478.23 91.5929 478.23Z"
            fill="#EF4E05"
          />
          <path
            d="M112.382 476.465C113.718 476.465 114.802 475.382 114.802 474.046C114.802 472.709 113.718 471.626 112.382 471.626C111.046 471.626 109.963 472.709 109.963 474.046C109.963 475.382 111.046 476.465 112.382 476.465Z"
            fill="#EF4E05"
          />
          <path
            d="M109.138 492.643C109.138 491.267 108.018 490.147 106.642 490.147C105.266 490.147 104.146 491.267 104.146 492.643C104.146 494.019 105.266 495.139 106.642 495.139C108.018 495.139 109.138 494.019 109.138 492.643Z"
            fill="#EF4E05"
          />
          <path
            d="M125.372 491.058C125.372 492.32 126.397 493.345 127.659 493.345C128.921 493.345 129.946 492.32 129.946 491.058C129.946 489.797 128.921 488.772 127.659 488.772C126.397 488.772 125.372 489.797 125.372 491.058Z"
            fill="#EF4E05"
          />
          <path
            d="M119.907 510.083C119.907 511.373 120.96 512.426 122.25 512.426C123.541 512.426 124.594 511.373 124.594 510.083C124.594 508.792 123.541 507.739 122.25 507.739C120.96 507.739 119.907 508.783 119.907 510.083Z"
            fill="#EF4E05"
          />
          <path
            d="M133.485 525.749C133.485 526.992 134.491 527.997 135.733 527.997C136.977 527.997 137.982 526.992 137.982 525.749C137.982 524.505 136.977 523.5 135.733 523.5C134.491 523.5 133.485 524.505 133.485 525.749Z"
            fill="#EF4E05"
          />
          <path
            d="M141.929 506.022C141.929 507.198 142.879 508.148 144.055 508.148C145.231 508.148 146.18 507.198 146.18 506.022C146.18 504.846 145.231 503.896 144.055 503.896C142.879 503.896 141.929 504.846 141.929 506.022Z"
            fill="#EF4E05"
          />
          <path
            d="M161.732 522.162C161.732 521.052 160.831 520.15 159.72 520.15C158.611 520.15 157.709 521.052 157.709 522.162C157.709 523.272 158.611 524.173 159.72 524.173C160.831 524.164 161.732 523.272 161.732 522.162Z"
            fill="#EF4E05"
          />
          <path
            d="M152.368 540.702C152.368 539.526 151.418 538.577 150.242 538.577C149.065 538.577 148.116 539.526 148.116 540.702C148.116 541.879 149.065 542.828 150.242 542.828C151.418 542.828 152.368 541.87 152.368 540.702Z"
            fill="#EF4E05"
          />
          <path
            d="M164.389 557.137C164.389 556.008 163.469 555.087 162.34 555.087C161.21 555.087 160.29 556.008 160.29 557.137C160.29 558.266 161.21 559.186 162.34 559.186C163.478 559.186 164.389 558.266 164.389 557.137Z"
            fill="#EF4E05"
          />
          <path
            d="M138.495 560.22C139.747 560.22 140.772 559.205 140.772 557.943C140.772 556.681 139.756 555.666 138.495 555.666C137.242 555.666 136.217 556.681 136.217 557.943C136.217 559.205 137.242 560.22 138.495 560.22Z"
            fill="#EF4E05"
          />
          <path
            d="M149.254 577.48C150.486 577.48 151.485 576.482 151.485 575.25C151.485 574.019 150.486 573.02 149.254 573.02C148.023 573.02 147.025 574.019 147.025 575.25C147.025 576.482 148.023 577.48 149.254 577.48Z"
            fill="#EF4E05"
          />
          <path
            d="M169.987 578.315C171.107 578.315 172.018 577.404 172.018 576.284C172.018 575.165 171.107 574.254 169.987 574.254C168.867 574.254 167.957 575.165 167.957 576.284C167.957 577.413 168.867 578.315 169.987 578.315Z"
            fill="#EF4E05"
          />
          <path
            d="M166.344 596.599C167.521 596.599 168.469 595.651 168.469 594.474C168.469 593.297 167.521 592.349 166.344 592.349C165.167 592.349 164.219 593.297 164.219 594.474C164.219 595.651 165.167 596.599 166.344 596.599Z"
            fill="#EF4E05"
          />
          <path
            d="M176.61 609.409C176.61 610.557 177.54 611.486 178.688 611.486C179.836 611.486 180.766 610.557 180.766 609.409C180.766 608.261 179.836 607.331 178.688 607.331C177.54 607.331 176.61 608.261 176.61 609.409Z"
            fill="#EF4E05"
          />
          <path
            d="M190.435 588.686C191.478 588.686 192.333 587.841 192.333 586.788C192.333 585.736 191.489 584.891 190.435 584.891C189.382 584.891 188.537 585.736 188.537 586.788C188.537 587.841 189.391 588.686 190.435 588.686Z"
            fill="#EF4E05"
          />
          <path
            d="M185.843 564.225C186.867 564.225 187.693 563.399 187.693 562.375C187.693 561.35 186.867 560.524 185.843 560.524C184.817 560.524 183.992 561.35 183.992 562.375C183.992 563.399 184.827 564.225 185.843 564.225Z"
            fill="#EF4E05"
          />
          <path
            d="M176.629 541.594C177.673 541.594 178.518 540.75 178.518 539.706C178.518 538.663 177.673 537.818 176.629 537.818C175.586 537.818 174.741 538.663 174.741 539.706C174.741 540.75 175.586 541.594 176.629 541.594Z"
            fill="#EF4E05"
          />
          <path
            d="M209.63 570.724C210.57 570.724 211.329 569.965 211.329 569.026C211.329 568.087 210.57 567.328 209.63 567.328C208.691 567.328 207.932 568.087 207.932 569.026C207.941 569.965 208.7 570.724 209.63 570.724Z"
            fill="#EF4E05"
          />
          <path
            d="M214.612 589.474C214.612 590.413 215.371 591.172 216.31 591.172C217.249 591.172 218.009 590.413 218.009 589.474C218.009 588.534 217.249 587.775 216.31 587.775C215.371 587.785 214.612 588.544 214.612 589.474Z"
            fill="#EF4E05"
          />
          <path
            d="M200.104 607.066C200.104 608.1 200.939 608.934 201.973 608.934C203.008 608.934 203.842 608.1 203.842 607.066C203.842 606.031 203.008 605.196 201.973 605.196C200.939 605.196 200.104 606.041 200.104 607.066Z"
            fill="#EF4E05"
          />
          <path
            d="M190.786 626.897C191.905 626.897 192.817 625.985 192.817 624.866C192.817 623.746 191.905 622.836 190.786 622.836C189.666 622.836 188.756 623.746 188.756 624.866C188.756 625.985 189.666 626.897 190.786 626.897Z"
            fill="#EF4E05"
          />
          <path
            d="M210.721 630.151C210.721 631.185 211.557 632.03 212.6 632.03C213.635 632.03 214.479 631.185 214.479 630.151C214.479 629.117 213.644 628.272 212.6 628.272C211.557 628.272 210.721 629.107 210.721 630.151Z"
            fill="#EF4E05"
          />
          <path
            d="M227.241 611.619C227.241 610.671 226.473 609.902 225.524 609.902C224.575 609.902 223.806 610.671 223.806 611.619C223.806 612.568 224.575 613.337 225.524 613.337C226.473 613.337 227.241 612.568 227.241 611.619Z"
            fill="#EF4E05"
          />
          <path
            d="M238.457 633.225C239.395 633.225 240.155 632.465 240.155 631.527C240.155 630.589 239.395 629.828 238.457 629.828C237.519 629.828 236.758 630.589 236.758 631.527C236.758 632.465 237.519 633.225 238.457 633.225Z"
            fill="#EF4E05"
          />
          <path
            d="M252.367 652.563C253.296 652.563 254.055 651.804 254.055 650.874C254.055 649.944 253.306 649.185 252.367 649.185C251.428 649.185 250.678 649.944 250.678 650.874C250.678 651.813 251.437 652.563 252.367 652.563Z"
            fill="#EF4E05"
          />
          <path
            d="M260.043 669.965C259.094 669.965 258.316 670.734 258.316 671.692C258.316 672.65 259.085 673.419 260.043 673.419C260.991 673.419 261.77 672.65 261.77 671.692C261.77 670.734 261.001 669.965 260.043 669.965Z"
            fill="#EF4E05"
          />
          <path
            d="M248.277 690.84C248.277 689.777 247.414 688.913 246.351 688.913C245.289 688.913 244.425 689.777 244.425 690.84C244.425 691.902 245.289 692.766 246.351 692.766C247.414 692.766 248.277 691.902 248.277 690.84Z"
            fill="#EF4E05"
          />
          <path
            d="M235.715 672.546C236.758 672.546 237.612 671.701 237.612 670.648C237.612 669.595 236.768 668.751 235.715 668.751C234.67 668.751 233.816 669.595 233.816 670.648C233.816 671.701 234.67 672.546 235.715 672.546Z"
            fill="#EF4E05"
          />
          <path
            d="M226.956 649.042C225.922 649.042 225.087 649.878 225.087 650.912C225.087 651.946 225.922 652.781 226.956 652.781C227.991 652.781 228.826 651.946 228.826 650.912C228.826 649.878 227.991 649.042 226.956 649.042Z"
            fill="#EF4E05"
          />
          <path
            d="M207.761 650.409C207.761 649.299 206.859 648.388 205.74 648.388C204.63 648.388 203.719 649.289 203.719 650.409C203.719 651.529 204.621 652.43 205.74 652.43C206.859 652.43 207.761 651.52 207.761 650.409Z"
            fill="#EF4E05"
          />
          <path
            d="M211.869 673.419C213.008 673.419 213.938 672.498 213.938 671.35C213.938 670.202 213.018 669.282 211.869 669.282C210.731 669.282 209.802 670.202 209.802 671.35C209.802 672.498 210.731 673.419 211.869 673.419Z"
            fill="#EF4E05"
          />
          <path
            d="M223.474 692.073C224.632 692.073 225.581 691.134 225.581 689.966C225.581 688.809 224.641 687.861 223.474 687.861C222.317 687.861 221.368 688.8 221.368 689.966C221.368 691.134 222.307 692.073 223.474 692.073Z"
            fill="#EF4E05"
          />
          <path
            d="M215.618 711.866C216.87 711.866 217.895 710.851 217.895 709.589C217.895 708.337 216.88 707.312 215.618 707.312C214.365 707.312 213.341 708.327 213.341 709.589C213.341 710.851 214.365 711.866 215.618 711.866Z"
            fill="#EF4E05"
          />
          <path
            d="M237.071 711.468C238.229 711.468 239.177 710.528 239.177 709.361C239.177 708.194 238.238 707.255 237.071 707.255C235.914 707.255 234.965 708.194 234.965 709.361C234.965 710.528 235.914 711.468 237.071 711.468Z"
            fill="#EF4E05"
          />
          <path
            d="M258.647 715.605C259.739 715.605 260.621 714.723 260.621 713.632C260.621 712.54 259.739 711.658 258.647 711.658C257.556 711.658 256.675 712.54 256.675 713.632C256.675 714.723 257.556 715.605 258.647 715.605Z"
            fill="#EF4E05"
          />
          <path
            d="M270.775 694.037C270.775 693.041 269.968 692.234 268.971 692.234C267.975 692.234 267.169 693.041 267.169 694.037C267.169 695.034 267.975 695.84 268.971 695.84C269.968 695.84 270.775 695.034 270.775 694.037Z"
            fill="#EF4E05"
          />
          <path
            d="M280.576 717.388C281.601 717.388 282.426 716.563 282.426 715.538C282.426 714.513 281.601 713.688 280.576 713.688C279.552 713.688 278.726 714.513 278.726 715.538C278.726 716.563 279.552 717.388 280.576 717.388Z"
            fill="#EF4E05"
          />
          <path
            d="M293.007 698.156C293.926 698.156 294.676 697.406 294.676 696.485C294.676 695.565 293.926 694.815 293.007 694.815C292.086 694.815 291.336 695.565 291.336 696.485C291.336 697.415 292.086 698.156 293.007 698.156Z"
            fill="#EF4E05"
          />
          <path
            d="M305.341 719.419C306.29 719.419 307.058 718.651 307.058 717.702C307.058 716.753 306.29 715.984 305.341 715.984C304.392 715.984 303.624 716.753 303.624 717.702C303.633 718.651 304.402 719.419 305.341 719.419Z"
            fill="#EF4E05"
          />
          <path
            d="M315.456 738.472C314.46 738.472 313.644 739.278 313.644 740.284C313.644 741.281 314.45 742.097 315.456 742.097C316.452 742.097 317.269 741.29 317.269 740.284C317.258 739.278 316.452 738.472 315.456 738.472Z"
            fill="#EF4E05"
          />
          <path
            d="M337.82 742.524C338.76 742.524 339.528 741.765 339.528 740.816C339.528 739.867 338.769 739.108 337.82 739.108C336.881 739.108 336.112 739.867 336.112 740.816C336.112 741.765 336.871 742.524 337.82 742.524Z"
            fill="#EF4E05"
          />
          <path
            d="M331.188 718.11C331.188 717.227 330.477 716.506 329.585 716.506C328.702 716.506 327.981 717.218 327.981 718.11C327.981 718.992 328.693 719.714 329.585 719.714C330.477 719.714 331.188 718.992 331.188 718.11Z"
            fill="#EF4E05"
          />
          <path
            d="M351.759 721.592C351.759 722.427 352.433 723.091 353.258 723.091C354.084 723.091 354.757 722.418 354.757 721.592C354.757 720.767 354.084 720.093 353.258 720.093C352.433 720.093 351.759 720.758 351.759 721.592Z"
            fill="#EF4E05"
          />
          <path
            d="M364.768 701.723C365.517 701.723 366.115 701.115 366.115 700.376C366.115 699.626 365.508 699.028 364.768 699.028C364.028 699.028 363.421 699.635 363.421 700.376C363.421 701.115 364.028 701.723 364.768 701.723Z"
            fill="#EF4E05"
          />
          <path
            d="M377.967 721.44C377.967 722.208 378.593 722.835 379.361 722.835C380.13 722.835 380.756 722.208 380.756 721.44C380.756 720.672 380.13 720.046 379.361 720.046C378.593 720.036 377.967 720.663 377.967 721.44Z"
            fill="#EF4E05"
          />
          <path
            d="M405.664 720.349C404.933 720.349 404.345 720.937 404.345 721.668C404.345 722.398 404.933 722.987 405.664 722.987C406.394 722.987 406.983 722.398 406.983 721.668C406.992 720.937 406.394 720.349 405.664 720.349Z"
            fill="#EF4E05"
          />
          <path
            d="M426.368 738.339C426.368 737.58 425.751 736.963 424.991 736.963C424.232 736.963 423.616 737.58 423.616 738.339C423.616 739.098 424.232 739.715 424.991 739.715C425.751 739.715 426.368 739.098 426.368 738.339Z"
            fill="#EF4E05"
          />
          <path
            d="M439.206 759.887C440.022 759.887 440.677 759.223 440.677 758.416C440.677 757.609 440.012 756.945 439.206 756.945C438.4 756.945 437.735 757.609 437.735 758.416C437.735 759.223 438.389 759.887 439.206 759.887Z"
            fill="#EF4E05"
          />
          <path
            d="M418.464 769.621C419.349 769.621 420.067 768.907 420.067 768.016C420.067 767.133 419.349 766.419 418.464 766.419C417.578 766.419 416.86 767.133 416.86 768.016C416.86 768.907 417.578 769.621 418.464 769.621Z"
            fill="#EF4E05"
          />
          <path
            d="M402.21 753.284C403.064 753.284 403.747 752.591 403.747 751.746C403.747 750.902 403.054 750.21 402.21 750.21C401.365 750.21 400.672 750.902 400.672 751.746C400.672 752.591 401.356 753.284 402.21 753.284Z"
            fill="#EF4E05"
          />
          <path
            d="M382.521 742.088C381.667 742.088 380.964 742.78 380.964 743.644C380.964 744.498 381.657 745.2 382.521 745.2C383.374 745.2 384.077 744.507 384.077 743.644C384.067 742.78 383.374 742.088 382.521 742.088Z"
            fill="#EF4E05"
          />
          <path
            d="M358.61 745.02C358.61 745.921 359.34 746.661 360.252 746.661C361.153 746.661 361.893 745.93 361.893 745.02C361.893 744.108 361.162 743.378 360.252 743.378C359.34 743.387 358.61 744.118 358.61 745.02Z"
            fill="#EF4E05"
          />
          <path
            d="M356.598 765.771C356.598 764.763 355.782 763.947 354.777 763.947C353.77 763.947 352.955 764.763 352.955 765.771C352.955 766.78 353.77 767.595 354.777 767.595C355.792 767.595 356.598 766.78 356.598 765.771Z"
            fill="#EF4E05"
          />
          <path
            d="M334.955 761.551C334.955 760.517 334.111 759.668 333.077 759.668C332.042 759.668 331.198 760.517 331.198 761.551C331.198 762.585 332.042 763.426 333.077 763.426C334.111 763.418 334.955 762.585 334.955 761.551Z"
            fill="#EF4E05"
          />
          <path
            d="M312.486 766.612C313.596 766.612 314.498 765.712 314.498 764.603C314.498 763.493 313.596 762.594 312.486 762.594C311.376 762.594 310.475 763.493 310.475 764.603C310.475 765.712 311.376 766.612 312.486 766.612Z"
            fill="#EF4E05"
          />
          <path
            d="M299.563 752.676C299.563 751.576 298.671 750.684 297.57 750.684C296.469 750.684 295.577 751.576 295.577 752.676C295.577 753.777 296.469 754.669 297.57 754.669C298.68 754.669 299.563 753.777 299.563 752.676Z"
            fill="#EF4E05"
          />
          <path
            d="M292.494 734.667C292.494 733.614 291.64 732.751 290.577 732.751C289.524 732.751 288.66 733.605 288.66 734.667C288.66 735.721 289.514 736.584 290.577 736.584C291.64 736.584 292.494 735.73 292.494 734.667Z"
            fill="#EF4E05"
          />
          <path
            d="M267.643 737.191C268.781 737.191 269.702 736.27 269.702 735.132C269.702 733.993 268.781 733.073 267.643 733.073C266.504 733.073 265.584 733.993 265.584 735.132C265.584 736.27 266.504 737.191 267.643 737.191Z"
            fill="#EF4E05"
          />
          <path
            d="M245.108 731.63C246.313 731.63 247.29 730.653 247.29 729.448C247.29 728.243 246.313 727.266 245.108 727.266C243.903 727.266 242.926 728.243 242.926 729.448C242.926 730.653 243.903 731.63 245.108 731.63Z"
            fill="#EF4E05"
          />
          <path
            d="M250.791 745.162C249.549 745.162 248.533 746.167 248.533 747.42C248.533 748.663 249.539 749.678 250.791 749.678C252.035 749.678 253.05 748.672 253.05 747.42C253.05 746.177 252.035 745.162 250.791 745.162Z"
            fill="#EF4E05"
          />
          <path
            d="M259.265 764.628C260.546 764.628 261.59 763.586 261.59 762.308C261.59 761.03 260.546 759.979 259.265 759.979C257.984 759.979 256.94 761.03 256.94 762.308C256.95 763.586 257.984 764.628 259.265 764.628Z"
            fill="#EF4E05"
          />
          <path
            d="M277.113 756.373C278.297 756.373 279.257 755.417 279.257 754.232C279.257 753.049 278.297 752.088 277.113 752.088C275.928 752.088 274.969 753.049 274.969 754.232C274.969 755.417 275.928 756.373 277.113 756.373Z"
            fill="#EF4E05"
          />
          <path
            d="M293.594 771.504C293.594 770.294 292.617 769.319 291.412 769.319C290.207 769.319 289.229 770.294 289.229 771.504C289.229 772.707 290.207 773.682 291.412 773.682C292.617 773.682 293.594 772.698 293.594 771.504Z"
            fill="#EF4E05"
          />
          <path
            d="M304.905 786.61C304.905 785.375 303.909 784.374 302.675 784.374C301.441 784.374 300.445 785.375 300.445 786.61C300.445 787.838 301.441 788.838 302.675 788.838C303.909 788.838 304.905 787.838 304.905 786.61Z"
            fill="#EF4E05"
          />
          <path
            d="M312.533 804.768C313.814 804.768 314.848 803.734 314.848 802.456C314.848 801.17 313.814 800.136 312.533 800.136C311.252 800.136 310.218 801.17 310.218 802.456C310.218 803.734 311.261 804.768 312.533 804.768Z"
            fill="#EF4E05"
          />
          <path
            d="M306.129 823.581C307.514 823.581 308.634 822.463 308.634 821.076C308.634 819.697 307.514 818.571 306.129 818.571C304.743 818.571 303.624 819.697 303.624 821.076C303.624 822.463 304.743 823.581 306.129 823.581Z"
            fill="#EF4E05"
          />
          <path
            d="M322.297 833.568C322.297 832.156 321.149 831.004 319.735 831.004C318.321 831.004 317.174 832.156 317.174 833.568C317.174 834.98 318.321 836.132 319.735 836.132C321.149 836.132 322.297 834.98 322.297 833.568Z"
            fill="#EF4E05"
          />
          <path
            d="M313.151 852.171C314.67 852.171 315.902 850.943 315.902 849.422C315.902 847.9 314.67 846.673 313.151 846.673C311.631 846.673 310.398 847.9 310.398 849.422C310.398 850.943 311.631 852.171 313.151 852.171Z"
            fill="#EF4E05"
          />
          <path
            d="M333.759 848.573C333.759 847.127 332.582 845.95 331.13 845.95C329.679 845.95 328.503 847.127 328.503 848.573C328.503 850.027 329.679 851.204 331.13 851.204C332.582 851.204 333.759 850.027 333.759 848.573Z"
            fill="#EF4E05"
          />
          <path
            d="M349.861 840.948C348.476 840.948 347.356 842.066 347.356 843.453C347.356 844.841 348.476 845.959 349.861 845.959C351.247 845.959 352.366 844.841 352.366 843.453C352.366 842.066 351.247 840.948 349.861 840.948Z"
            fill="#EF4E05"
          />
          <path
            d="M362.984 858.122C364.399 858.122 365.546 856.979 365.546 855.559C365.546 854.146 364.399 852.995 362.984 852.995C361.57 852.995 360.422 854.146 360.422 855.559C360.422 856.979 361.57 858.122 362.984 858.122Z"
            fill="#EF4E05"
          />
          <path
            d="M345.687 861.426C345.687 859.913 344.463 858.702 342.963 858.702C341.454 858.702 340.24 859.921 340.24 861.426C340.24 862.922 341.464 864.15 342.963 864.15C344.463 864.15 345.687 862.931 345.687 861.426Z"
            fill="#EF4E05"
          />
          <path
            d="M360.431 870.681C360.431 869.177 359.207 867.949 357.699 867.949C356.19 867.949 354.967 869.177 354.967 870.681C354.967 872.194 356.19 873.422 357.699 873.422C359.207 873.422 360.431 872.194 360.431 870.681Z"
            fill="#EF4E05"
          />
          <path
            d="M377.018 874.952C377.018 873.447 375.794 872.228 374.294 872.228C372.786 872.228 371.572 873.455 371.572 874.952C371.572 876.465 372.795 877.675 374.294 877.675C375.803 877.675 377.018 876.456 377.018 874.952Z"
            fill="#EF4E05"
          />
          <path
            d="M381.088 855.827C379.703 855.827 378.583 856.946 378.583 858.333C378.583 859.72 379.703 860.838 381.088 860.838C382.473 860.838 383.593 859.72 383.593 858.333C383.593 856.946 382.473 855.827 381.088 855.827Z"
            fill="#EF4E05"
          />
          <path
            d="M400.046 859.282C401.382 859.282 402.466 858.198 402.466 856.861C402.466 855.525 401.382 854.44 400.046 854.44C398.71 854.44 397.626 855.525 397.626 856.861C397.626 858.198 398.71 859.282 400.046 859.282Z"
            fill="#EF4E05"
          />
          <path
            d="M410.996 868.017C409.601 868.017 408.472 869.151 408.472 870.547C408.472 871.934 409.601 873.069 410.996 873.069C412.391 873.069 413.52 871.934 413.52 870.547C413.52 869.151 412.391 868.017 410.996 868.017Z"
            fill="#EF4E05"
          />
          <path
            d="M419.583 852.658C419.583 853.936 420.618 854.97 421.898 854.97C423.179 854.97 424.214 853.936 424.214 852.658C424.214 851.372 423.179 850.338 421.898 850.338C420.618 850.338 419.583 851.389 419.583 852.658Z"
            fill="#EF4E05"
          />
          <path
            d="M429.404 867.453C428.037 867.453 426.937 868.555 426.937 869.916C426.937 871.287 428.037 872.388 429.404 872.388C430.77 872.388 431.871 871.287 431.871 869.916C431.871 868.555 430.77 867.453 429.404 867.453Z"
            fill="#EF4E05"
          />
          <path
            d="M445.648 873.212C446.99 873.212 448.078 872.119 448.078 870.782C448.078 869.437 446.99 868.353 445.648 868.353C444.306 868.353 443.219 869.437 443.219 870.782C443.219 872.119 444.306 873.212 445.648 873.212Z"
            fill="#EF4E05"
          />
          <path
            d="M443.02 851.608C444.254 851.608 445.259 850.599 445.259 849.363C445.259 848.127 444.254 847.127 443.02 847.127C441.786 847.127 440.781 848.127 440.781 849.363C440.781 850.599 441.777 851.608 443.02 851.608Z"
            fill="#EF4E05"
          />
          <path
            d="M429.233 834.425C430.391 834.425 431.33 833.492 431.33 832.332C431.33 831.172 430.391 830.239 429.233 830.239C428.076 830.239 427.136 831.172 427.136 832.332C427.136 833.492 428.076 834.425 429.233 834.425Z"
            fill="#EF4E05"
          />
          <path
            d="M448.57 823.766C447.47 823.766 446.578 824.657 446.578 825.758C446.578 826.86 447.47 827.751 448.57 827.751C449.671 827.751 450.563 826.86 450.563 825.758C450.563 824.657 449.671 823.766 448.57 823.766Z"
            fill="#EF4E05"
          />
          <path
            d="M463.818 837.813C462.661 837.813 461.722 838.754 461.722 839.914C461.722 841.075 462.661 842.008 463.818 842.008C464.977 842.008 465.916 841.075 465.916 839.914C465.916 838.754 464.967 837.813 463.818 837.813Z"
            fill="#EF4E05"
          />
          <path
            d="M480.214 851.717C478.991 851.717 478.004 852.7 478.004 853.928C478.004 855.155 478.991 856.139 480.214 856.139C481.438 856.139 482.425 855.155 482.425 853.928C482.425 852.7 481.438 851.717 480.214 851.717Z"
            fill="#EF4E05"
          />
          <path
            d="M461.618 858.719C460.336 858.719 459.302 859.753 459.302 861.039C459.302 862.317 460.336 863.351 461.618 863.351C462.899 863.351 463.933 862.317 463.933 861.039C463.933 859.753 462.899 858.719 461.618 858.719Z"
            fill="#EF4E05"
          />
          <path
            d="M476.675 873.867C475.338 873.867 474.247 874.952 474.247 876.297C474.247 877.633 475.338 878.718 476.675 878.718C478.014 878.718 479.105 877.633 479.105 876.297C479.105 874.952 478.014 873.867 476.675 873.867Z"
            fill="#EF4E05"
          />
          <path
            d="M459.558 879.953C458.163 879.953 457.034 881.088 457.034 882.475C457.034 883.871 458.163 885.006 459.558 885.006C460.953 885.006 462.082 883.871 462.082 882.475C462.082 881.088 460.953 879.953 459.558 879.953Z"
            fill="#EF4E05"
          />
          <path
            d="M457.376 899.019C457.376 897.506 456.152 896.287 454.644 896.287C453.135 896.287 451.911 897.506 451.911 899.019C451.911 900.523 453.135 901.751 454.644 901.751C456.152 901.751 457.376 900.523 457.376 899.019Z"
            fill="#EF4E05"
          />
          <path
            d="M473.44 901.255C474.918 901.255 476.116 900.061 476.116 898.582C476.116 897.102 474.918 895.909 473.44 895.909C471.962 895.909 470.764 897.102 470.764 898.582C470.764 900.061 471.962 901.255 473.44 901.255Z"
            fill="#EF4E05"
          />
          <path
            d="M490.215 888.772C488.783 888.772 487.625 889.932 487.625 891.361C487.625 892.79 488.783 893.95 490.215 893.95C491.649 893.95 492.806 892.79 492.806 891.361C492.806 889.932 491.649 888.772 490.215 888.772Z"
            fill="#EF4E05"
          />
          <path
            d="M504.487 900.381C502.997 900.381 501.782 901.6 501.782 903.087C501.782 904.575 502.997 905.794 504.487 905.794C505.976 905.794 507.191 904.575 507.191 903.087C507.191 901.6 505.986 900.381 504.487 900.381Z"
            fill="#EF4E05"
          />
          <path
            d="M508.519 879.331C507.163 879.331 506.062 880.433 506.062 881.786C506.062 883.148 507.163 884.241 508.519 884.241C509.876 884.241 510.977 883.148 510.977 881.786C510.977 880.433 509.876 879.331 508.519 879.331Z"
            fill="#EF4E05"
          />
          <path
            d="M494.637 870.959C495.922 870.959 496.962 869.916 496.962 868.639C496.962 867.352 495.922 866.31 494.637 866.31C493.353 866.31 492.313 867.352 492.313 868.639C492.313 869.916 493.353 870.959 494.637 870.959Z"
            fill="#EF4E05"
          />
          <path
            d="M517.059 860.611C517.059 859.375 516.063 858.375 514.829 858.375C513.596 858.375 512.6 859.375 512.6 860.611C512.6 861.838 513.596 862.838 514.829 862.838C516.063 862.838 517.059 861.83 517.059 860.611Z"
            fill="#EF4E05"
          />
          <path
            d="M502.542 846.69C502.542 845.53 501.602 844.58 500.435 844.58C499.278 844.58 498.329 845.521 498.329 846.69C498.329 847.858 499.268 848.791 500.435 848.791C501.602 848.791 502.542 847.858 502.542 846.69Z"
            fill="#EF4E05"
          />
          <path
            d="M487.417 832.088C487.417 830.987 486.525 830.096 485.424 830.096C484.324 830.096 483.432 830.987 483.432 832.088C483.432 833.189 484.324 834.081 485.424 834.081C486.525 834.081 487.417 833.189 487.417 832.088Z"
            fill="#EF4E05"
          />
          <path
            d="M471.742 817.344C471.742 816.301 470.897 815.452 469.853 815.452C468.81 815.452 467.965 816.301 467.965 817.344C467.965 818.386 468.81 819.227 469.853 819.227C470.897 819.227 471.742 818.386 471.742 817.344Z"
            fill="#EF4E05"
          />
          <path
            d="M453.865 803.902C454.85 803.902 455.649 803.104 455.649 802.12C455.649 801.136 454.85 800.338 453.865 800.338C452.88 800.338 452.081 801.136 452.081 802.12C452.081 803.104 452.88 803.902 453.865 803.902Z"
            fill="#EF4E05"
          />
          <path
            d="M433.589 806.819C432.554 806.819 431.71 807.651 431.71 808.694C431.71 809.744 432.544 810.577 433.589 810.577C434.623 810.577 435.467 809.744 435.467 808.694C435.467 807.651 434.623 806.819 433.589 806.819Z"
            fill="#EF4E05"
          />
          <path
            d="M414.82 814.191C413.72 814.191 412.828 815.082 412.828 816.184C412.828 817.285 413.72 818.176 414.82 818.176C415.921 818.176 416.813 817.285 416.813 816.184C416.813 815.082 415.921 814.191 414.82 814.191Z"
            fill="#EF4E05"
          />
          <path
            d="M409.364 840.486C410.588 840.486 411.575 839.494 411.575 838.275C411.575 837.048 410.588 836.064 409.364 836.064C408.14 836.064 407.153 837.048 407.153 838.275C407.153 839.494 408.149 840.486 409.364 840.486Z"
            fill="#EF4E05"
          />
          <path
            d="M399.145 823.606C399.145 822.429 398.196 821.48 397.019 821.48C395.843 821.48 394.894 822.429 394.894 823.606C394.894 824.783 395.843 825.725 397.019 825.725C398.186 825.725 399.145 824.766 399.145 823.606Z"
            fill="#EF4E05"
          />
          <path
            d="M379.124 821.816C380.31 821.816 381.278 820.849 381.278 819.664C381.278 818.479 380.31 817.512 379.124 817.512C377.938 817.512 376.97 818.479 376.97 819.664C376.97 820.849 377.938 821.816 379.124 821.816Z"
            fill="#EF4E05"
          />
          <path
            d="M388.745 842.958C390.019 842.958 391.051 841.924 391.051 840.654C391.051 839.385 390.019 838.351 388.745 838.351C387.472 838.351 386.439 839.385 386.439 840.654C386.439 841.924 387.472 842.958 388.745 842.958Z"
            fill="#EF4E05"
          />
          <path
            d="M372.842 838.906C372.842 837.611 371.789 836.544 370.48 836.544C369.18 836.544 368.117 837.603 368.117 838.906C368.117 840.217 369.17 841.268 370.48 841.268C371.78 841.268 372.842 840.217 372.842 838.906Z"
            fill="#EF4E05"
          />
          <path
            d="M355.602 824.893C355.602 826.145 356.618 827.171 357.879 827.171C359.132 827.171 360.157 826.153 360.157 824.893C360.157 823.64 359.141 822.614 357.879 822.614C356.618 822.614 355.602 823.632 355.602 824.893Z"
            fill="#EF4E05"
          />
          <path
            d="M341.255 828.877C341.255 827.549 340.174 826.473 338.845 826.473C337.517 826.473 336.435 827.549 336.435 828.877C336.435 830.205 337.517 831.29 338.845 831.29C340.174 831.29 341.255 830.205 341.255 828.877Z"
            fill="#EF4E05"
          />
          <path
            d="M325.951 813.931C324.65 813.931 323.588 814.99 323.588 816.301C323.588 817.596 324.641 818.663 325.951 818.663C327.25 818.663 328.313 817.604 328.313 816.301C328.313 814.99 327.259 813.931 325.951 813.931Z"
            fill="#EF4E05"
          />
          <path
            d="M330.913 796.505C329.707 796.505 328.73 797.48 328.73 798.69C328.73 799.892 329.707 800.867 330.913 800.867C332.117 800.867 333.095 799.892 333.095 798.69C333.095 797.48 332.117 796.505 330.913 796.505Z"
            fill="#EF4E05"
          />
          <path
            d="M321.662 783.862C322.814 783.862 323.749 782.928 323.749 781.777C323.749 780.625 322.814 779.692 321.662 779.692C320.509 779.692 319.574 780.625 319.574 781.777C319.574 782.928 320.509 783.862 321.662 783.862Z"
            fill="#EF4E05"
          />
          <path
            d="M341.901 785.139C343.001 785.139 343.893 784.248 343.893 783.147C343.893 782.046 343.001 781.155 341.901 781.155C340.8 781.155 339.908 782.046 339.908 783.147C339.908 784.248 340.8 785.139 341.901 785.139Z"
            fill="#EF4E05"
          />
          <path
            d="M362.699 787.048C362.699 785.972 361.826 785.097 360.754 785.097C359.682 785.097 358.809 785.972 358.809 787.048C358.809 788.115 359.682 788.989 360.754 788.989C361.826 788.989 362.699 788.115 362.699 787.048Z"
            fill="#EF4E05"
          />
          <path
            d="M346.796 809.803C348.002 809.803 348.989 808.828 348.989 807.618C348.989 806.407 348.011 805.424 346.796 805.424C345.592 805.424 344.605 806.399 344.605 807.618C344.605 808.828 345.582 809.803 346.796 809.803Z"
            fill="#EF4E05"
          />
          <path
            d="M366.457 804.406C365.309 804.406 364.37 805.34 364.37 806.491C364.37 807.643 365.299 808.584 366.457 808.584C367.605 808.584 368.544 807.651 368.544 806.491C368.544 805.34 367.605 804.406 366.457 804.406Z"
            fill="#EF4E05"
          />
          <path
            d="M396.042 805.18C397.105 805.18 397.978 804.314 397.978 803.238C397.978 802.17 397.114 801.305 396.042 801.305C394.97 801.305 394.107 802.17 394.107 803.238C394.107 804.314 394.97 805.18 396.042 805.18Z"
            fill="#EF4E05"
          />
          <path
            d="M382.293 791.326C382.293 790.276 381.449 789.427 380.395 789.427C379.351 789.427 378.498 790.267 378.498 791.326C378.498 792.377 379.342 793.218 380.395 793.218C381.439 793.209 382.293 792.369 382.293 791.326Z"
            fill="#EF4E05"
          />
          <path
            d="M377.596 768.814C378.545 768.814 379.315 768.049 379.315 767.1C379.315 766.15 378.545 765.385 377.596 765.385C376.648 765.385 375.879 766.15 375.879 767.1C375.879 768.049 376.657 768.814 377.596 768.814Z"
            fill="#EF4E05"
          />
          <path
            d="M397.741 779.541C398.7 779.541 399.477 778.759 399.477 777.801C399.477 776.842 398.7 776.069 397.741 776.069C396.782 776.069 396.004 776.842 396.004 777.801C396.004 778.759 396.782 779.541 397.741 779.541Z"
            fill="#EF4E05"
          />
          <path
            d="M415.55 794.672C416.541 794.672 417.344 793.873 417.344 792.881C417.344 791.889 416.541 791.083 415.55 791.083C414.56 791.083 413.757 791.889 413.757 792.881C413.757 793.873 414.56 794.672 415.55 794.672Z"
            fill="#EF4E05"
          />
          <path
            d="M437.944 784.442C437.944 783.517 437.194 782.76 436.264 782.76C435.335 782.76 434.585 783.517 434.585 784.442C434.585 785.375 435.335 786.123 436.264 786.123C437.194 786.123 437.944 785.375 437.944 784.442Z"
            fill="#EF4E05"
          />
          <path
            d="M456.351 778.347C456.351 779.221 457.063 779.936 457.946 779.936C458.828 779.936 459.54 779.221 459.54 778.347C459.54 777.464 458.828 776.75 457.946 776.75C457.063 776.758 456.351 777.473 456.351 778.347Z"
            fill="#EF4E05"
          />
          <path
            d="M475.812 795.109C476.739 795.109 477.492 794.361 477.492 793.428C477.492 792.503 476.739 791.747 475.812 791.747C474.885 791.747 474.133 792.503 474.133 793.428C474.133 794.361 474.885 795.109 475.812 795.109Z"
            fill="#EF4E05"
          />
          <path
            d="M492.123 810.947C493.101 810.947 493.897 810.148 493.897 809.173C493.897 808.198 493.101 807.399 492.123 807.399C491.146 807.399 490.348 808.198 490.348 809.173C490.348 810.156 491.146 810.947 492.123 810.947Z"
            fill="#EF4E05"
          />
          <path
            d="M506.138 824.884C506.138 825.927 506.982 826.784 508.035 826.784C509.088 826.784 509.933 825.935 509.933 824.884C509.933 823.833 509.088 822.984 508.035 822.984C506.982 822.984 506.138 823.842 506.138 824.884Z"
            fill="#EF4E05"
          />
          <path
            d="M522.734 837.838C521.604 837.838 520.693 838.746 520.693 839.872C520.693 841.007 521.604 841.915 522.734 841.915C523.863 841.915 524.773 841.007 524.773 839.872C524.773 838.746 523.863 837.838 522.734 837.838Z"
            fill="#EF4E05"
          />
          <path
            d="M537.659 855.298C538.859 855.298 539.832 854.323 539.832 853.121C539.832 851.919 538.859 850.952 537.659 850.952C536.458 850.952 535.486 851.919 535.486 853.121C535.486 854.323 536.458 855.298 537.659 855.298Z"
            fill="#EF4E05"
          />
          <path
            d="M528.559 871.261C527.25 871.261 526.187 872.329 526.187 873.64C526.187 874.943 527.25 876.011 528.559 876.011C529.869 876.011 530.931 874.943 530.931 873.64C530.931 872.329 529.869 871.261 528.559 871.261Z"
            fill="#EF4E05"
          />
          <path
            d="M549.842 870.328C551.115 870.328 552.148 869.294 552.148 868.016C552.148 866.747 551.115 865.713 549.842 865.713C548.568 865.713 547.536 866.747 547.536 868.016C547.536 869.294 548.568 870.328 549.842 870.328Z"
            fill="#EF4E05"
          />
          <path
            d="M560.82 845.345C560.82 844.185 559.881 843.243 558.723 843.243C557.566 843.243 556.626 844.185 556.626 845.345C556.626 846.497 557.566 847.438 558.723 847.438C559.881 847.43 560.82 846.497 560.82 845.345Z"
            fill="#EF4E05"
          />
          <path
            d="M544.595 828.65C543.522 828.65 542.649 829.524 542.649 830.6C542.649 831.668 543.522 832.542 544.595 832.542C545.666 832.542 546.54 831.668 546.54 830.6C546.54 829.524 545.666 828.65 544.595 828.65Z"
            fill="#EF4E05"
          />
          <path
            d="M527.733 815.898C527.733 816.907 528.55 817.722 529.555 817.722C530.561 817.722 531.377 816.907 531.377 815.898C531.377 814.889 530.561 814.074 529.555 814.074C528.55 814.074 527.733 814.889 527.733 815.898Z"
            fill="#EF4E05"
          />
          <path
            d="M514.174 801.809C515.104 801.809 515.863 801.061 515.863 800.119C515.863 799.186 515.114 798.43 514.174 798.43C513.244 798.43 512.485 799.178 512.485 800.119C512.485 801.052 513.244 801.809 514.174 801.809Z"
            fill="#EF4E05"
          />
          <path
            d="M498.205 785.19C499.075 785.19 499.78 784.492 499.78 783.618C499.78 782.752 499.075 782.046 498.205 782.046C497.336 782.046 496.63 782.752 496.63 783.618C496.63 784.492 497.336 785.19 498.205 785.19Z"
            fill="#EF4E05"
          />
          <path
            d="M481.249 766.074C480.433 766.074 479.778 766.738 479.778 767.545C479.778 768.352 480.442 769.016 481.249 769.016C482.066 769.016 482.72 768.352 482.72 767.545C482.72 766.738 482.066 766.074 481.249 766.074Z"
            fill="#EF4E05"
          />
          <path
            d="M501.659 754.404C502.4 754.404 502.997 753.806 502.997 753.065C502.997 752.326 502.4 751.728 501.659 751.728C500.919 751.728 500.322 752.326 500.322 753.065C500.322 753.806 500.919 754.404 501.659 754.404Z"
            fill="#EF4E05"
          />
          <path
            d="M478.83 736.641C478.83 737.343 479.399 737.903 480.092 737.903C480.785 737.903 481.354 737.334 481.354 736.641C481.354 735.948 480.785 735.379 480.092 735.379C479.399 735.379 478.83 735.948 478.83 736.641Z"
            fill="#EF4E05"
          />
          <path
            d="M461.693 752.99C460.916 752.99 460.289 753.616 460.289 754.394C460.289 755.172 460.916 755.802 461.693 755.802C462.472 755.802 463.098 755.172 463.098 754.394C463.098 753.616 462.472 752.99 461.693 752.99Z"
            fill="#EF4E05"
          />
          <path
            d="M453.144 731.82C453.846 731.82 454.406 731.251 454.406 730.558C454.406 729.866 453.837 729.297 453.144 729.297C452.442 729.297 451.882 729.866 451.882 730.558C451.882 731.251 452.442 731.82 453.144 731.82Z"
            fill="#EF4E05"
          />
          <path
            d="M435.97 711.297C435.97 711.951 436.501 712.473 437.146 712.473C437.801 712.473 438.323 711.942 438.323 711.297C438.323 710.652 437.792 710.12 437.146 710.12C436.492 710.12 435.97 710.652 435.97 711.297Z"
            fill="#EF4E05"
          />
          <path
            d="M416.898 694.616C417.521 694.616 418.027 694.111 418.027 693.487C418.027 692.863 417.521 692.358 416.898 692.358C416.274 692.358 415.769 692.863 415.769 693.487C415.769 694.111 416.274 694.616 416.898 694.616Z"
            fill="#EF4E05"
          />
          <path
            d="M389.542 696.514C390.217 696.514 390.766 695.963 390.766 695.29C390.766 694.616 390.217 694.066 389.542 694.066C388.869 694.066 388.318 694.616 388.318 695.29C388.309 695.963 388.86 696.514 389.542 696.514Z"
            fill="#EF4E05"
          />
          <path
            d="M394.562 667.707C395.159 667.707 395.634 667.222 395.634 666.635C395.634 666.036 395.15 665.562 394.562 665.562C393.973 665.562 393.49 666.046 393.49 666.635C393.49 667.222 393.964 667.707 394.562 667.707Z"
            fill="#EF4E05"
          />
          <path
            d="M427.24 665.514C427.24 664.964 426.794 664.527 426.253 664.527C425.713 664.527 425.267 664.974 425.267 665.514C425.267 666.056 425.713 666.501 426.253 666.501C426.794 666.501 427.24 666.056 427.24 665.514Z"
            fill="#EF4E05"
          />
          <path
            d="M452.907 646.661C452.907 647.136 453.296 647.524 453.77 647.524C454.245 647.524 454.633 647.136 454.633 646.661C454.633 646.187 454.245 645.797 453.77 645.797C453.287 645.797 452.907 646.177 452.907 646.661Z"
            fill="#EF4E05"
          />
          <path
            d="M451.911 685.498C452.477 685.498 452.936 685.039 452.936 684.473C452.936 683.907 452.477 683.448 451.911 683.448C451.345 683.448 450.886 683.907 450.886 684.473C450.886 685.039 451.345 685.498 451.911 685.498Z"
            fill="#EF4E05"
          />
          <path
            d="M473.839 705.831C473.839 706.439 474.332 706.923 474.93 706.923C475.527 706.923 476.021 706.429 476.021 705.831C476.021 705.224 475.527 704.74 474.93 704.74C474.332 704.74 473.839 705.224 473.839 705.831Z"
            fill="#EF4E05"
          />
          <path
            d="M503.471 722.608C504.1 722.608 504.611 722.098 504.611 721.469C504.611 720.84 504.1 720.331 503.471 720.331C502.843 720.331 502.333 720.84 502.333 721.469C502.333 722.098 502.843 722.608 503.471 722.608Z"
            fill="#EF4E05"
          />
          <path
            d="M509.402 691.352C509.952 691.352 510.389 690.906 510.389 690.366C510.389 689.824 509.942 689.379 509.402 689.379C508.851 689.379 508.415 689.824 508.415 690.366C508.415 690.906 508.851 691.352 509.402 691.352Z"
            fill="#EF4E05"
          />
          <path
            d="M483.906 671.416C484.409 671.416 484.817 671.009 484.817 670.506C484.817 670.002 484.409 669.595 483.906 669.595C483.403 669.595 482.995 670.002 482.995 670.506C482.995 671.009 483.403 671.416 483.906 671.416Z"
            fill="#EF4E05"
          />
          <path
            d="M489.77 633.519C490.197 633.519 490.538 633.178 490.538 632.751C490.538 632.324 490.197 631.983 489.77 631.983C489.343 631.983 489.001 632.324 489.001 632.751C489.001 633.178 489.343 633.519 489.77 633.519Z"
            fill="#EF4E05"
          />
          <path
            d="M523.814 654.584C523.814 655.039 524.185 655.41 524.641 655.41C525.096 655.41 525.466 655.039 525.466 654.584C525.466 654.129 525.096 653.759 524.641 653.759C524.185 653.759 523.814 654.129 523.814 654.584Z"
            fill="#EF4E05"
          />
          <path
            d="M545.401 686.228C545.401 685.697 544.975 685.27 544.442 685.27C543.911 685.27 543.484 685.697 543.484 686.228C543.484 686.759 543.911 687.186 544.442 687.186C544.975 687.186 545.401 686.75 545.401 686.228Z"
            fill="#EF4E05"
          />
          <path
            d="M533.645 714.988C534.253 714.988 534.746 714.495 534.746 713.887C534.746 713.28 534.253 712.787 533.645 712.787C533.038 712.787 532.544 713.28 532.544 713.887C532.544 714.495 533.038 714.988 533.645 714.988Z"
            fill="#EF4E05"
          />
          <path
            d="M526.936 742.552C526.936 741.85 526.367 741.291 525.675 741.291C524.972 741.291 524.412 741.86 524.412 742.552C524.412 743.245 524.982 743.814 525.675 743.814C526.367 743.814 526.936 743.245 526.936 742.552Z"
            fill="#EF4E05"
          />
          <path
            d="M555.003 734.724C555.003 734.05 554.463 733.51 553.789 733.51C553.115 733.51 552.575 734.05 552.575 734.724C552.575 735.398 553.115 735.938 553.789 735.938C554.463 735.938 555.003 735.398 555.003 734.724Z"
            fill="#EF4E05"
          />
          <path
            d="M570.309 709.541C570.906 709.541 571.381 709.058 571.381 708.469C571.381 707.872 570.897 707.397 570.309 707.397C569.711 707.397 569.237 707.881 569.237 708.469C569.227 709.058 569.711 709.541 570.309 709.541Z"
            fill="#EF4E05"
          />
          <path
            d="M583.289 735.853C582.596 735.853 582.046 736.413 582.046 737.097C582.046 737.789 582.606 738.339 583.289 738.339C583.972 738.339 584.532 737.779 584.532 737.097C584.532 736.413 583.981 735.853 583.289 735.853Z"
            fill="#EF4E05"
          />
          <path
            d="M606.934 749.185C607.683 749.185 608.282 748.577 608.282 747.837C608.282 747.097 607.674 746.49 606.934 746.49C606.194 746.49 605.587 747.097 605.587 747.837C605.587 748.577 606.185 749.185 606.934 749.185Z"
            fill="#EF4E05"
          />
          <path
            d="M605.483 718.641C606.127 718.641 606.64 718.119 606.64 717.483C606.64 716.848 606.118 716.326 605.483 716.326C604.837 716.326 604.325 716.848 604.325 717.483C604.325 718.119 604.837 718.641 605.483 718.641Z"
            fill="#EF4E05"
          />
          <path
            d="M631.491 707.208C632.125 707.208 632.639 706.693 632.639 706.059C632.639 705.425 632.125 704.911 631.491 704.911C630.857 704.911 630.343 705.425 630.343 706.059C630.343 706.693 630.857 707.208 631.491 707.208Z"
            fill="#EF4E05"
          />
          <path
            d="M636.586 735.084C637.315 735.084 637.905 734.494 637.905 733.765C637.905 733.037 637.315 732.446 636.586 732.446C635.858 732.446 635.267 733.037 635.267 733.765C635.267 734.494 635.858 735.084 636.586 735.084Z"
            fill="#EF4E05"
          />
          <path
            d="M629.384 759.593C630.191 759.593 630.836 758.937 630.836 758.138C630.836 757.348 630.181 756.693 629.384 756.693C628.577 756.693 627.933 757.348 627.933 758.138C627.933 758.937 628.577 759.593 629.384 759.593Z"
            fill="#EF4E05"
          />
          <path
            d="M652.176 758.878C652.176 759.727 652.859 760.4 653.694 760.4C654.529 760.4 655.213 759.719 655.213 758.878C655.213 758.046 654.529 757.365 653.694 757.365C652.859 757.365 652.176 758.038 652.176 758.878Z"
            fill="#EF4E05"
          />
          <path
            d="M667.016 739.459C667.016 740.247 667.651 740.882 668.439 740.882C669.227 740.882 669.862 740.247 669.862 739.459C669.862 738.671 669.227 738.036 668.439 738.036C667.651 738.036 667.016 738.671 667.016 739.459Z"
            fill="#EF4E05"
          />
          <path
            d="M660.507 715.367C661.199 715.367 661.759 714.807 661.759 714.115C661.759 713.423 661.199 712.862 660.507 712.862C659.815 712.862 659.254 713.423 659.254 714.115C659.254 714.807 659.815 715.367 660.507 715.367Z"
            fill="#EF4E05"
          />
          <path
            d="M689.286 722.816C690.045 722.816 690.661 722.199 690.661 721.44C690.661 720.681 690.045 720.064 689.286 720.064C688.527 720.064 687.91 720.681 687.91 721.44C687.91 722.199 688.517 722.816 689.286 722.816Z"
            fill="#EF4E05"
          />
          <path
            d="M696.259 748.416C697.129 748.416 697.835 747.711 697.835 746.841C697.835 745.971 697.129 745.266 696.259 745.266C695.389 745.266 694.684 745.971 694.684 746.841C694.684 747.711 695.389 748.416 696.259 748.416Z"
            fill="#EF4E05"
          />
          <path
            d="M702.807 773.16C703.784 773.16 704.572 772.379 704.572 771.395C704.572 770.42 703.784 769.63 702.807 769.63C701.83 769.63 701.042 770.42 701.042 771.395C701.042 772.379 701.839 773.16 702.807 773.16Z"
            fill="#EF4E05"
          />
          <path
            d="M720.759 756.583C721.708 756.583 722.486 755.818 722.486 754.859C722.486 753.91 721.717 753.132 720.759 753.132C719.81 753.132 719.032 753.9 719.032 754.859C719.032 755.818 719.81 756.583 720.759 756.583Z"
            fill="#EF4E05"
          />
          <path
            d="M715.36 731.128C716.204 731.128 716.888 730.445 716.888 729.6C716.888 728.756 716.204 728.073 715.36 728.073C714.515 728.073 713.832 728.756 713.832 729.6C713.823 730.445 714.515 731.128 715.36 731.128Z"
            fill="#EF4E05"
          />
          <path
            d="M711.052 701.666C710.303 701.666 709.704 702.274 709.704 703.014C709.704 703.763 710.312 704.361 711.052 704.361C711.802 704.361 712.399 703.754 712.399 703.014C712.399 702.274 711.792 701.666 711.052 701.666Z"
            fill="#EF4E05"
          />
          <path
            d="M736.614 712.568C737.442 712.568 738.113 711.897 738.113 711.069C738.113 710.241 737.442 709.57 736.614 709.57C735.786 709.57 735.115 710.241 735.115 711.069C735.115 711.897 735.786 712.568 736.614 712.568Z"
            fill="#EF4E05"
          />
          <path
            d="M734.536 686.579C735.285 686.579 735.893 685.973 735.893 685.223C735.893 684.473 735.285 683.866 734.536 683.866C733.787 683.866 733.18 684.473 733.18 685.223C733.18 685.973 733.787 686.579 734.536 686.579Z"
            fill="#EF4E05"
          />
          <path
            d="M757.451 669.946C757.451 670.714 758.077 671.331 758.837 671.331C759.605 671.331 760.222 670.705 760.222 669.946C760.222 669.178 759.596 668.561 758.837 668.561C758.077 668.551 757.451 669.178 757.451 669.946Z"
            fill="#EF4E05"
          />
          <path
            d="M760.535 643.899C761.237 643.899 761.806 643.33 761.806 642.628C761.806 641.926 761.237 641.356 760.535 641.356C759.832 641.356 759.263 641.926 759.263 642.628C759.254 643.33 759.823 643.899 760.535 643.899Z"
            fill="#EF4E05"
          />
          <path
            d="M735.818 627.485C736.424 627.485 736.918 626.992 736.918 626.384C736.918 625.777 736.424 625.284 735.818 625.284C735.21 625.284 734.716 625.777 734.716 626.384C734.716 626.992 735.21 627.485 735.818 627.485Z"
            fill="#EF4E05"
          />
          <path
            d="M733.322 657.933C733.995 657.933 734.536 657.392 734.536 656.719C734.536 656.045 733.995 655.504 733.322 655.504C732.648 655.504 732.107 656.045 732.107 656.719C732.107 657.392 732.658 657.933 733.322 657.933Z"
            fill="#EF4E05"
          />
          <path
            d="M705.53 644.431C706.108 644.431 706.583 643.957 706.583 643.378C706.583 642.8 706.108 642.325 705.53 642.325C704.951 642.325 704.477 642.8 704.477 643.378C704.477 643.966 704.951 644.431 705.53 644.431Z"
            fill="#EF4E05"
          />
          <path
            d="M706.185 674.415C706.185 675.079 706.725 675.61 707.38 675.61C708.035 675.61 708.575 675.07 708.575 674.415C708.575 673.751 708.035 673.219 707.38 673.219C706.725 673.219 706.185 673.751 706.185 674.415Z"
            fill="#EF4E05"
          />
          <path
            d="M683.412 694.976C684.078 694.976 684.617 694.438 684.617 693.772C684.617 693.106 684.078 692.566 683.412 692.566C682.746 692.566 682.207 693.106 682.207 693.772C682.207 694.438 682.746 694.976 683.412 694.976Z"
            fill="#EF4E05"
          />
          <path
            d="M677.756 663.949C678.336 663.949 678.8 663.484 678.8 662.906C678.8 662.327 678.336 661.862 677.756 661.862C677.178 661.862 676.713 662.327 676.713 662.906C676.713 663.484 677.178 663.949 677.756 663.949Z"
            fill="#EF4E05"
          />
          <path
            d="M673.098 630.796C673.595 630.796 674 630.393 674 629.894C674 629.397 673.595 628.993 673.098 628.993C672.6 628.993 672.197 629.397 672.197 629.894C672.197 630.393 672.6 630.796 673.098 630.796Z"
            fill="#EF4E05"
          />
          <path
            d="M673.677 594.759C674.117 594.759 674.474 594.402 674.474 593.962C674.474 593.521 674.117 593.165 673.677 593.165C673.237 593.165 672.88 593.521 672.88 593.962C672.88 594.402 673.237 594.759 673.677 594.759Z"
            fill="#EF4E05"
          />
          <path
            d="M706.906 609.722C706.384 609.722 705.966 610.14 705.966 610.662C705.966 611.183 706.384 611.601 706.906 611.601C707.428 611.601 707.845 611.183 707.845 610.662C707.845 610.14 707.428 609.722 706.906 609.722Z"
            fill="#EF4E05"
          />
          <path
            d="M713.832 576.047C713.832 575.573 713.443 575.184 712.969 575.184C712.494 575.184 712.105 575.573 712.105 576.047C712.105 576.521 712.494 576.911 712.969 576.911C713.443 576.92 713.832 576.532 713.832 576.047Z"
            fill="#EF4E05"
          />
          <path
            d="M746.378 561.919C746.907 561.919 747.337 561.49 747.337 560.961C747.337 560.431 746.907 560.002 746.378 560.002C745.849 560.002 745.42 560.431 745.42 560.961C745.42 561.49 745.849 561.919 746.378 561.919Z"
            fill="#EF4E05"
          />
          <path
            d="M741.131 596.106C741.697 596.106 742.156 595.647 742.156 595.082C742.156 594.515 741.697 594.057 741.131 594.057C740.565 594.057 740.106 594.515 740.106 595.082C740.106 595.647 740.565 596.106 741.131 596.106Z"
            fill="#EF4E05"
          />
          <path
            d="M766.484 611.676C765.82 611.676 765.289 612.218 765.289 612.873C765.289 613.537 765.83 614.068 766.484 614.068C767.139 614.068 767.68 613.527 767.68 612.873C767.68 612.208 767.149 611.676 766.484 611.676Z"
            fill="#EF4E05"
          />
          <path
            d="M770.906 582.357C770.906 582.983 771.409 583.486 772.035 583.486C772.661 583.486 773.164 582.983 773.164 582.357C773.164 581.731 772.661 581.228 772.035 581.228C771.409 581.228 770.906 581.741 770.906 582.357Z"
            fill="#EF4E05"
          />
          <path
            d="M776.922 547.914C776.324 547.914 775.84 548.397 775.84 548.995C775.84 549.594 776.324 550.077 776.922 550.077C777.519 550.077 778.004 549.594 778.004 548.995C778.013 548.397 777.529 547.914 776.922 547.914Z"
            fill="#EF4E05"
          />
          <path
            d="M806.374 542.05C807.038 542.05 807.579 541.509 807.579 540.844C807.579 540.18 807.038 539.64 806.374 539.64C805.71 539.64 805.169 540.18 805.169 540.844C805.159 541.509 805.7 542.05 806.374 542.05Z"
            fill="#EF4E05"
          />
          <path
            d="M800.624 570.885C800.624 570.193 800.064 569.643 799.381 569.643C798.698 569.643 798.138 570.202 798.138 570.885C798.138 571.578 798.698 572.128 799.381 572.128C800.064 572.128 800.624 571.568 800.624 570.885Z"
            fill="#EF4E05"
          />
          <path
            d="M795.804 601.135C795.073 601.135 794.485 601.723 794.485 602.454C794.485 603.184 795.073 603.773 795.804 603.773C796.534 603.773 797.123 603.184 797.123 602.454C797.123 601.733 796.534 601.135 795.804 601.135Z"
            fill="#EF4E05"
          />
          <path
            d="M819.003 589.626C819.003 590.413 819.639 591.039 820.417 591.039C821.204 591.039 821.83 590.403 821.83 589.626C821.83 588.838 821.195 588.212 820.417 588.212C819.63 588.212 819.003 588.847 819.003 589.626Z"
            fill="#EF4E05"
          />
          <path
            d="M826.661 562.403C827.425 562.403 828.046 561.783 828.046 561.018C828.046 560.252 827.425 559.632 826.661 559.632C825.896 559.632 825.275 560.252 825.275 561.018C825.275 561.783 825.896 562.403 826.661 562.403Z"
            fill="#EF4E05"
          />
          <path
            d="M843.542 579.862C844.391 579.862 845.08 579.169 845.08 578.315C845.08 577.461 844.383 576.778 843.542 576.778C842.685 576.778 842.004 577.47 842.004 578.315C842.004 579.169 842.685 579.862 843.542 579.862Z"
            fill="#EF4E05"
          />
          <path
            d="M847.081 600.765C847.081 601.676 847.821 602.425 848.737 602.425C849.662 602.425 850.402 601.686 850.402 600.765C850.402 599.854 849.662 599.105 848.737 599.105C847.821 599.105 847.081 599.845 847.081 600.765Z"
            fill="#EF4E05"
          />
          <path
            d="M828.748 615.055C829.611 615.055 830.314 614.353 830.314 613.489C830.314 612.625 829.611 611.924 828.748 611.924C827.885 611.924 827.183 612.625 827.183 613.489C827.192 614.353 827.885 615.055 828.748 615.055Z"
            fill="#EF4E05"
          />
          <path
            d="M811.612 625.73C811.612 624.914 810.948 624.259 810.141 624.259C809.325 624.259 808.67 624.923 808.67 625.73C808.67 626.536 809.335 627.2 810.141 627.2C810.948 627.2 811.612 626.545 811.612 625.73Z"
            fill="#EF4E05"
          />
          <path
            d="M786.923 633.481C787.682 633.481 788.289 632.865 788.289 632.115C788.289 631.356 787.672 630.748 786.923 630.748C786.163 630.748 785.556 631.365 785.556 632.115C785.556 632.865 786.173 633.481 786.923 633.481Z"
            fill="#EF4E05"
          />
          <path
            d="M784.902 659.869C785.717 659.869 786.381 659.204 786.381 658.389C786.381 657.573 785.717 656.909 784.902 656.909C784.086 656.909 783.421 657.573 783.421 658.389C783.412 659.204 784.076 659.869 784.902 659.869Z"
            fill="#EF4E05"
          />
          <path
            d="M783.45 683.372C783.45 682.499 782.738 681.788 781.865 681.788C780.992 681.788 780.281 682.499 780.281 683.372C780.281 684.245 780.992 684.957 781.865 684.957C782.738 684.957 783.45 684.245 783.45 683.372Z"
            fill="#EF4E05"
          />
          <path
            d="M760.107 697.994C760.962 697.994 761.645 697.302 761.645 696.457C761.645 695.613 760.952 694.92 760.107 694.92C759.254 694.92 758.571 695.613 758.571 696.457C758.571 697.302 759.254 697.994 760.107 697.994Z"
            fill="#EF4E05"
          />
          <path
            d="M760.877 721.507C761.806 721.507 762.565 720.757 762.565 719.818C762.565 718.878 761.806 718.129 760.877 718.129C759.947 718.129 759.188 718.888 759.188 719.818C759.188 720.747 759.947 721.507 760.877 721.507Z"
            fill="#EF4E05"
          />
          <path
            d="M762.034 740.693C761.019 740.693 760.193 741.519 760.193 742.533C760.193 743.549 761.019 744.374 762.034 744.374C763.05 744.374 763.875 743.549 763.875 742.533C763.875 741.519 763.05 740.693 762.034 740.693Z"
            fill="#EF4E05"
          />
          <path
            d="M739.148 733.415C738.218 733.415 737.469 734.165 737.469 735.094C737.469 736.024 738.218 736.774 739.148 736.774C740.078 736.774 740.827 736.024 740.827 735.094C740.827 734.165 740.078 733.415 739.148 733.415Z"
            fill="#EF4E05"
          />
          <path
            d="M743.294 759.198C744.31 759.198 745.135 758.374 745.135 757.357C745.135 756.34 744.31 755.514 743.294 755.514C742.279 755.514 741.453 756.34 741.453 757.357C741.453 758.374 742.279 759.198 743.294 759.198Z"
            fill="#EF4E05"
          />
          <path
            d="M746.112 779.314C747.213 779.314 748.114 778.423 748.114 777.305C748.114 776.195 747.222 775.304 746.112 775.304C745.011 775.304 744.11 776.195 744.11 777.305C744.11 778.423 745.011 779.314 746.112 779.314Z"
            fill="#EF4E05"
          />
          <path
            d="M727.306 778.894C727.306 777.834 726.443 776.969 725.379 776.969C724.317 776.969 723.454 777.834 723.454 778.894C723.454 779.953 724.317 780.819 725.379 780.819C726.443 780.819 727.306 779.953 727.306 778.894Z"
            fill="#EF4E05"
          />
          <path
            d="M727.012 801.246C727.012 802.423 727.96 803.373 729.137 803.373C730.314 803.373 731.262 802.423 731.262 801.246C731.262 800.069 730.314 799.119 729.137 799.119C727.96 799.136 727.012 800.077 727.012 801.246Z"
            fill="#EF4E05"
          />
          <path
            d="M707.864 796.58C708.954 796.58 709.837 795.698 709.837 794.605C709.837 793.52 708.954 792.629 707.864 792.629C706.774 792.629 705.89 793.52 705.89 794.605C705.89 795.698 706.774 796.58 707.864 796.58Z"
            fill="#EF4E05"
          />
          <path
            d="M684.067 787.88C684.067 788.897 684.892 789.721 685.907 789.721C686.922 789.721 687.748 788.897 687.748 787.88C687.748 786.863 686.922 786.039 685.907 786.039C684.892 786.039 684.067 786.863 684.067 787.88Z"
            fill="#EF4E05"
          />
          <path
            d="M678.886 762.535C677.984 762.535 677.245 763.266 677.245 764.174C677.245 765.074 677.975 765.822 678.886 765.822C679.788 765.822 680.528 765.09 680.528 764.174C680.518 763.266 679.788 762.535 678.886 762.535Z"
            fill="#EF4E05"
          />
          <path
            d="M662.623 784.568C663.572 784.568 664.34 783.803 664.34 782.853C664.34 781.903 663.572 781.13 662.623 781.13C661.674 781.13 660.905 781.903 660.905 782.853C660.905 783.803 661.674 784.568 662.623 784.568Z"
            fill="#EF4E05"
          />
          <path
            d="M638.351 782.197C639.252 782.197 639.992 781.466 639.992 780.55C639.992 779.642 639.262 778.91 638.351 778.91C637.449 778.91 636.709 779.642 636.709 780.55C636.709 781.466 637.449 782.197 638.351 782.197Z"
            fill="#EF4E05"
          />
          <path
            d="M623.957 801.515C624.932 801.515 625.722 800.985 625.722 800.329C625.722 799.674 624.932 799.144 623.957 799.144C622.982 799.144 622.192 799.674 622.192 800.329C622.192 800.985 622.982 801.515 623.957 801.515Z"
            fill="#EF4E05"
          />
          <path
            d="M647.877 800.388C646.862 800.388 646.036 801.212 646.036 802.229C646.036 803.238 646.862 804.07 647.877 804.07C648.893 804.07 649.718 803.238 649.718 802.229C649.718 801.212 648.893 800.388 647.877 800.388Z"
            fill="#EF4E05"
          />
          <path
            d="M670.517 807.349C671.579 807.349 672.453 806.483 672.453 805.415C672.453 804.348 671.59 803.482 670.517 803.482C669.454 803.482 668.582 804.339 668.582 805.415C668.582 806.483 669.445 807.349 670.517 807.349Z"
            fill="#EF4E05"
          />
          <path
            d="M658.913 823.085C658.913 821.95 658.002 821.042 656.873 821.042C655.744 821.042 654.833 821.95 654.833 823.085C654.833 824.212 655.744 825.12 656.873 825.12C658.002 825.12 658.913 824.203 658.913 823.085Z"
            fill="#EF4E05"
          />
          <path
            d="M643.854 842.159C645.049 842.159 646.018 841.192 646.018 839.999C646.018 838.805 645.049 837.838 643.854 837.838C642.66 837.838 641.691 838.805 641.691 839.999C641.691 841.192 642.66 842.159 643.854 842.159Z"
            fill="#EF4E05"
          />
          <path
            d="M634.366 822.606C635.448 822.606 636.33 821.723 636.33 820.639C636.33 819.563 635.448 818.68 634.366 818.68C633.284 818.68 632.401 819.563 632.401 820.639C632.401 821.732 633.284 822.606 634.366 822.606Z"
            fill="#EF4E05"
          />
          <path
            d="M612.295 821.816C613.354 821.816 614.212 820.958 614.212 819.899C614.212 818.848 613.354 817.983 612.295 817.983C611.237 817.983 610.379 818.848 610.379 819.899C610.379 820.958 611.237 821.816 612.295 821.816Z"
            fill="#EF4E05"
          />
          <path
            d="M590.225 821.984C591.259 821.984 592.103 821.135 592.103 820.101C592.103 819.067 591.269 818.226 590.225 818.226C589.191 818.226 588.347 819.067 588.347 820.101C588.347 821.135 589.191 821.984 590.225 821.984Z"
            fill="#EF4E05"
          />
          <path
            d="M577.52 800.783C578.45 800.783 579.199 800.035 579.199 799.102C579.199 798.177 578.45 797.421 577.52 797.421C576.59 797.421 575.841 798.177 575.841 799.102C575.85 800.027 576.6 800.783 577.52 800.783Z"
            fill="#EF4E05"
          />
          <path
            d="M559.739 782.828C559.739 783.685 560.431 784.391 561.295 784.391C562.149 784.391 562.841 783.693 562.841 782.828C562.841 781.979 562.149 781.272 561.295 781.272C560.431 781.272 559.739 781.979 559.739 782.828Z"
            fill="#EF4E05"
          />
          <path
            d="M602 797.522C602 796.58 601.241 795.815 600.293 795.815C599.353 795.815 598.585 796.572 598.585 797.522C598.585 798.472 599.344 799.228 600.293 799.228C601.241 799.22 602 798.455 602 797.522Z"
            fill="#EF4E05"
          />
          <path
            d="M612.505 777.885C613.368 777.885 614.07 777.187 614.07 776.321C614.07 775.455 613.368 774.758 612.505 774.758C611.641 774.758 610.938 775.455 610.938 776.321C610.948 777.195 611.641 777.885 612.505 777.885Z"
            fill="#EF4E05"
          />
          <path
            d="M588.099 773.564C588.925 773.564 589.589 772.9 589.589 772.068C589.589 771.244 588.916 770.58 588.099 770.58C587.283 770.58 586.61 771.252 586.61 772.068C586.61 772.883 587.274 773.564 588.099 773.564Z"
            fill="#EF4E05"
          />
          <path
            d="M566.504 758.155C566.504 758.912 567.121 759.526 567.88 759.526C568.639 759.526 569.255 758.912 569.255 758.155C569.255 757.39 568.639 756.777 567.88 756.777C567.121 756.777 566.504 757.382 566.504 758.155Z"
            fill="#EF4E05"
          />
          <path
            d="M543.02 764.905C543.807 764.905 544.433 764.275 544.433 763.493C544.433 762.703 543.798 762.081 543.02 762.081C542.233 762.081 541.606 762.711 541.606 763.493C541.606 764.275 542.242 764.905 543.02 764.905Z"
            fill="#EF4E05"
          />
          <path
            d="M519.384 770.731C518.568 770.731 517.913 771.395 517.913 772.202C517.913 773.018 518.577 773.673 519.384 773.673C520.2 773.673 520.855 773.009 520.855 772.202C520.864 771.395 520.2 770.731 519.384 770.731Z"
            fill="#EF4E05"
          />
          <path
            d="M536.568 792.007C537.459 792.007 538.181 791.284 538.181 790.393C538.181 789.502 537.459 788.779 536.568 788.779C535.676 788.779 534.954 789.502 534.954 790.393C534.954 791.284 535.676 792.007 536.568 792.007Z"
            fill="#EF4E05"
          />
          <path
            d="M554.444 807.298C554.444 806.331 553.656 805.55 552.688 805.55C551.721 805.55 550.933 806.331 550.933 807.298C550.933 808.273 551.721 809.055 552.688 809.055C553.656 809.047 554.444 808.257 554.444 807.298Z"
            fill="#EF4E05"
          />
          <path
            d="M565.925 822.816C565.925 823.85 566.76 824.691 567.804 824.691C568.848 824.691 569.682 823.85 569.682 822.816C569.682 821.782 568.848 820.933 567.804 820.933C566.76 820.933 565.925 821.774 565.925 822.816Z"
            fill="#EF4E05"
          />
          <path
            d="M579.987 839.494C578.849 839.494 577.919 840.419 577.919 841.562C577.919 842.714 578.839 843.63 579.987 843.63C581.126 843.63 582.056 842.705 582.056 841.562C582.056 840.427 581.126 839.494 579.987 839.494Z"
            fill="#EF4E05"
          />
          <path
            d="M601.374 843.008C602.522 843.008 603.452 842.075 603.452 840.932C603.452 839.78 602.522 838.855 601.374 838.855C600.226 838.855 599.296 839.78 599.296 840.932C599.296 842.075 600.226 843.008 601.374 843.008Z"
            fill="#EF4E05"
          />
          <path
            d="M622.325 842.403C623.492 842.403 624.441 841.453 624.441 840.284C624.441 839.116 623.492 838.166 622.325 838.166C621.158 838.166 620.209 839.116 620.209 840.284C620.209 841.453 621.158 842.403 622.325 842.403Z"
            fill="#EF4E05"
          />
          <path
            d="M632.496 860.501C633.775 860.501 634.811 859.467 634.811 858.19C634.811 856.912 633.775 855.87 632.496 855.87C631.218 855.87 630.181 856.912 630.181 858.19C630.181 859.467 631.218 860.501 632.496 860.501Z"
            fill="#EF4E05"
          />
          <path
            d="M613.871 860.602C613.871 859.341 612.846 858.316 611.584 858.316C610.322 858.316 609.297 859.341 609.297 860.602C609.297 861.863 610.322 862.889 611.584 862.889C612.846 862.889 613.871 861.872 613.871 860.602Z"
            fill="#EF4E05"
          />
          <path
            d="M590.168 858.265C588.925 858.265 587.91 859.274 587.91 860.527C587.91 861.779 588.916 862.779 590.168 862.779C591.412 862.779 592.426 861.779 592.426 860.527C592.426 859.274 591.412 858.265 590.168 858.265Z"
            fill="#EF4E05"
          />
          <path
            d="M569.569 864.948C570.812 864.948 571.827 863.94 571.827 862.687C571.827 861.443 570.821 860.426 569.569 860.426C568.326 860.426 567.31 861.434 567.31 862.687C567.31 863.931 568.326 864.948 569.569 864.948Z"
            fill="#EF4E05"
          />
          <path
            d="M578.678 878.398C577.33 878.398 576.23 879.499 576.23 880.844C576.23 882.198 577.321 883.299 578.678 883.299C580.025 883.299 581.126 882.206 581.126 880.844C581.126 879.499 580.035 878.398 578.678 878.398Z"
            fill="#EF4E05"
          />
          <path
            d="M560.725 881.845C559.359 881.845 558.249 882.954 558.249 884.316C558.249 885.686 559.359 886.796 560.725 886.796C562.092 886.796 563.202 885.686 563.202 884.316C563.202 882.954 562.092 881.845 560.725 881.845Z"
            fill="#EF4E05"
          />
          <path
            d="M572.074 898.775C570.593 898.775 569.388 899.977 569.388 901.457C569.388 902.936 570.593 904.138 572.074 904.138C573.554 904.138 574.759 902.936 574.759 901.457C574.759 899.969 573.563 898.775 572.074 898.775Z"
            fill="#EF4E05"
          />
          <path
            d="M589.257 896.354C587.777 896.354 586.582 897.548 586.582 899.027C586.582 900.507 587.777 901.7 589.257 901.7C590.738 901.7 591.933 900.507 591.933 899.027C591.933 897.548 590.738 896.354 589.257 896.354Z"
            fill="#EF4E05"
          />
          <path
            d="M606.497 895.799C605.018 895.799 603.812 897.01 603.812 898.489C603.812 899.969 605.018 901.171 606.497 901.171C607.978 901.171 609.183 899.969 609.183 898.489C609.183 897.01 607.978 895.799 606.497 895.799Z"
            fill="#EF4E05"
          />
          <path
            d="M596.374 876.868C595.007 876.868 593.906 877.97 593.906 879.34C593.906 880.702 595.007 881.803 596.374 881.803C597.74 881.803 598.84 880.702 598.84 879.34C598.84 877.978 597.74 876.868 596.374 876.868Z"
            fill="#EF4E05"
          />
          <path
            d="M612.78 878.197C611.403 878.197 610.284 879.323 610.284 880.693C610.284 882.072 611.403 883.19 612.78 883.19C614.155 883.19 615.274 882.072 615.274 880.693C615.274 879.323 614.165 878.197 612.78 878.197Z"
            fill="#EF4E05"
          />
          <path
            d="M628.568 874.557C627.183 874.557 626.063 875.675 626.063 877.062C626.063 878.449 627.183 879.567 628.568 879.567C629.953 879.567 631.073 878.449 631.073 877.062C631.073 875.675 629.953 874.557 628.568 874.557Z"
            fill="#EF4E05"
          />
          <path
            d="M645.544 877.65C646.942 877.65 648.076 876.515 648.076 875.12C648.076 873.716 646.942 872.581 645.544 872.581C644.144 872.581 643.01 873.716 643.01 875.12C643.01 876.515 644.144 877.65 645.544 877.65Z"
            fill="#EF4E05"
          />
          <path
            d="M650.838 857.551C650.838 858.854 651.891 859.913 653.2 859.913C654.5 859.913 655.563 858.862 655.563 857.551C655.563 856.256 654.51 855.189 653.2 855.189C651.891 855.189 650.838 856.248 650.838 857.551Z"
            fill="#EF4E05"
          />
          <path
            d="M663.04 871.287C661.626 871.287 660.487 872.421 660.487 873.834C660.487 875.246 661.626 876.389 663.04 876.389C664.454 876.389 665.592 875.246 665.592 873.834C665.592 872.421 664.444 871.287 663.04 871.287Z"
            fill="#EF4E05"
          />
          <path
            d="M676.95 861.687C676.95 860.325 675.84 859.215 674.474 859.215C673.108 859.215 671.997 860.325 671.997 861.687C671.997 863.057 673.108 864.167 674.474 864.167C675.84 864.167 676.95 863.057 676.95 861.687Z"
            fill="#EF4E05"
          />
          <path
            d="M665.592 840.528C664.349 840.528 663.344 841.537 663.344 842.781C663.344 844.025 664.349 845.025 665.592 845.025C666.836 845.025 667.841 844.025 667.841 842.781C667.841 841.537 666.836 840.528 665.592 840.528Z"
            fill="#EF4E05"
          />
          <path
            d="M685.727 850.094C687.037 850.094 688.099 849.035 688.099 847.724C688.099 846.413 687.037 845.353 685.727 845.353C684.417 845.353 683.355 846.413 683.355 847.724C683.355 849.035 684.417 850.094 685.727 850.094Z"
            fill="#EF4E05"
          />
          <path
            d="M703.233 851.221C701.849 851.221 700.729 852.347 700.729 853.726C700.729 855.113 701.849 856.231 703.233 856.231C704.619 856.231 705.738 855.113 705.738 853.726C705.738 852.347 704.619 851.221 703.233 851.221Z"
            fill="#EF4E05"
          />
          <path
            d="M691.449 868.017C690.007 868.017 688.83 869.193 688.83 870.639C688.83 872.077 690.007 873.254 691.449 873.254C692.891 873.254 694.068 872.077 694.068 870.639C694.068 869.193 692.9 868.017 691.449 868.017Z"
            fill="#EF4E05"
          />
          <path
            d="M709.467 869.387C707.969 869.387 706.754 870.597 706.754 872.102C706.754 873.598 707.969 874.817 709.467 874.817C710.967 874.817 712.182 873.598 712.182 872.102C712.182 870.597 710.967 869.387 709.467 869.387Z"
            fill="#EF4E05"
          />
          <path
            d="M722.097 856.147C720.645 856.147 719.468 857.324 719.468 858.778C719.468 860.232 720.645 861.409 722.097 861.409C723.549 861.409 724.726 860.232 724.726 858.778C724.726 857.324 723.549 856.147 722.097 856.147Z"
            fill="#EF4E05"
          />
          <path
            d="M742.696 860.258C742.696 858.753 741.472 857.526 739.963 857.526C738.455 857.526 737.23 858.753 737.23 860.258C737.23 861.762 738.455 862.99 739.963 862.99C741.472 862.99 742.696 861.762 742.696 860.258Z"
            fill="#EF4E05"
          />
          <path
            d="M758.049 849.212C758.049 847.715 756.834 846.497 755.335 846.497C753.835 846.497 752.622 847.715 752.622 849.212C752.622 850.708 753.835 851.927 755.335 851.927C756.834 851.927 758.049 850.708 758.049 849.212Z"
            fill="#EF4E05"
          />
          <path
            d="M750.269 829.575C748.883 829.575 747.764 830.693 747.764 832.08C747.764 833.458 748.883 834.585 750.269 834.585C751.653 834.585 752.773 833.458 752.773 832.08C752.773 830.693 751.653 829.575 750.269 829.575Z"
            fill="#EF4E05"
          />
          <path
            d="M735.106 845.74C736.515 845.74 737.658 844.597 737.658 843.185C737.658 841.781 736.515 840.637 735.106 840.637C733.696 840.637 732.553 841.781 732.553 843.185C732.553 844.597 733.696 845.74 735.106 845.74Z"
            fill="#EF4E05"
          />
          <path
            d="M716.489 841.1C717.817 841.1 718.89 840.024 718.89 838.696C718.89 837.367 717.817 836.3 716.489 836.3C715.161 836.3 714.088 837.367 714.088 838.696C714.088 840.024 715.161 841.1 716.489 841.1Z"
            fill="#EF4E05"
          />
          <path
            d="M700.444 832.097C700.444 830.844 699.428 829.827 698.176 829.827C696.923 829.827 695.908 830.844 695.908 832.097C695.908 833.349 696.923 834.366 698.176 834.366C699.428 834.366 700.444 833.349 700.444 832.097Z"
            fill="#EF4E05"
          />
          <path
            d="M678.269 829.02C679.446 829.02 680.404 828.062 680.404 826.885C680.404 825.708 679.446 824.75 678.269 824.75C677.093 824.75 676.134 825.708 676.134 826.885C676.134 828.062 677.093 829.02 678.269 829.02Z"
            fill="#EF4E05"
          />
          <path
            d="M689.94 810.459C689.94 811.594 690.86 812.51 691.99 812.51C693.119 812.51 694.039 811.594 694.039 810.459C694.039 809.333 693.119 808.408 691.99 808.408C690.851 808.408 689.94 809.324 689.94 810.459Z"
            fill="#EF4E05"
          />
          <path
            d="M714.468 816.486C714.468 815.284 713.49 814.301 712.286 814.301C711.08 814.301 710.103 815.284 710.103 816.486C710.103 817.688 711.08 818.672 712.286 818.672C713.49 818.672 714.468 817.688 714.468 816.486Z"
            fill="#EF4E05"
          />
          <path
            d="M730.675 825.994C731.964 825.994 733.009 824.951 733.009 823.657C733.009 822.371 731.964 821.328 730.675 821.328C729.385 821.328 728.34 822.371 728.34 823.657C728.34 824.951 729.385 825.994 730.675 825.994Z"
            fill="#EF4E05"
          />
          <path
            d="M750.088 813.435C750.088 812.14 749.044 811.098 747.754 811.098C746.463 811.098 745.42 812.14 745.42 813.435C745.42 814.721 746.463 815.763 747.754 815.763C749.044 815.763 750.088 814.721 750.088 813.435Z"
            fill="#EF4E05"
          />
          <path
            d="M750.126 798.135C751.331 798.135 752.318 797.16 752.318 795.941C752.318 794.739 751.341 793.756 750.126 793.756C748.921 793.756 747.934 794.731 747.934 795.941C747.934 797.16 748.911 798.135 750.126 798.135Z"
            fill="#EF4E05"
          />
          <path
            d="M766.902 786.333C768.107 786.333 769.084 785.358 769.084 784.147C769.084 782.945 768.107 781.97 766.902 781.97C765.697 781.97 764.719 782.945 764.719 784.147C764.728 785.366 765.706 786.333 766.902 786.333Z"
            fill="#EF4E05"
          />
          <path
            d="M765.82 766.351C766.93 766.351 767.841 765.452 767.841 764.325C767.841 763.207 766.94 762.308 765.82 762.308C764.701 762.308 763.799 763.207 763.799 764.325C763.809 765.452 764.71 766.351 765.82 766.351Z"
            fill="#EF4E05"
          />
          <path
            d="M787.767 774.371C788.99 774.371 789.977 773.379 789.977 772.16C789.977 770.933 788.99 769.949 787.767 769.949C786.543 769.949 785.556 770.933 785.556 772.16C785.556 773.379 786.553 774.371 787.767 774.371Z"
            fill="#EF4E05"
          />
          <path
            d="M785.044 750.921C785.044 749.811 784.143 748.9 783.023 748.9C781.912 748.9 781.002 749.801 781.002 750.921C781.002 752.031 781.903 752.942 783.023 752.942C784.143 752.942 785.044 752.041 785.044 750.921Z"
            fill="#EF4E05"
          />
          <path
            d="M782.804 731.726C783.839 731.726 784.674 730.891 784.674 729.856C784.674 728.822 783.839 727.988 782.804 727.988C781.77 727.988 780.936 728.822 780.936 729.856C780.936 730.891 781.77 731.726 782.804 731.726Z"
            fill="#EF4E05"
          />
          <path
            d="M784.949 710.699C785.916 710.699 786.704 709.912 786.704 708.944C786.704 707.976 785.916 707.188 784.949 707.188C783.981 707.188 783.193 707.976 783.193 708.944C783.203 709.921 783.981 710.699 784.949 710.699Z"
            fill="#EF4E05"
          />
          <path
            d="M800.633 694.341C800.633 695.319 801.421 696.106 802.398 696.106C803.376 696.106 804.163 695.319 804.163 694.341C804.163 693.364 803.376 692.576 802.398 692.576C801.421 692.576 800.633 693.364 800.633 694.341Z"
            fill="#EF4E05"
          />
          <path
            d="M811.09 715.272C812.159 715.272 813.026 714.406 813.026 713.337C813.026 712.268 812.159 711.401 811.09 711.401C810.02 711.401 809.154 712.268 809.154 713.337C809.154 714.406 810.02 715.272 811.09 715.272Z"
            fill="#EF4E05"
          />
          <path
            d="M805.909 734.135C805.909 733.016 804.999 732.105 803.879 732.105C802.759 732.105 801.848 733.016 801.848 734.135C801.848 735.255 802.759 736.166 803.879 736.166C804.999 736.166 805.909 735.255 805.909 734.135Z"
            fill="#EF4E05"
          />
          <path
            d="M802.208 756.911C803.398 756.911 804.362 755.944 804.362 754.754C804.362 753.565 803.398 752.6 802.208 752.6C801.019 752.6 800.055 753.565 800.055 754.754C800.055 755.944 801.019 756.911 802.208 756.911Z"
            fill="#EF4E05"
          />
          <path
            d="M824.07 752.875C824.07 751.624 823.055 750.599 821.792 750.599C820.541 750.599 819.515 751.614 819.515 752.875C819.515 754.128 820.531 755.153 821.792 755.153C823.055 755.153 824.07 754.128 824.07 752.875Z"
            fill="#EF4E05"
          />
          <path
            d="M809.43 774.884C810.724 774.884 811.773 773.833 811.773 772.538C811.773 771.244 810.724 770.193 809.43 770.193C808.135 770.193 807.086 771.244 807.086 772.538C807.086 773.833 808.135 774.884 809.43 774.884Z"
            fill="#EF4E05"
          />
          <path
            d="M829.308 775.825C830.681 775.825 831.794 774.716 831.794 773.345C831.794 771.967 830.681 770.857 829.308 770.857C827.935 770.857 826.822 771.967 826.822 773.345C826.822 774.716 827.935 775.825 829.308 775.825Z"
            fill="#EF4E05"
          />
          <path
            d="M849.452 782.474C849.452 780.978 848.25 779.776 846.753 779.776C845.265 779.776 844.063 780.978 844.063 782.474C844.063 783.962 845.265 785.165 846.753 785.165C848.25 785.165 849.452 783.954 849.452 782.474Z"
            fill="#EF4E05"
          />
          <path
            d="M846.081 761.543C846.081 760.156 844.963 759.038 843.576 759.038C842.197 759.038 841.071 760.156 841.071 761.543C841.071 762.922 842.197 764.048 843.576 764.048C844.963 764.048 846.081 762.922 846.081 761.543Z"
            fill="#EF4E05"
          />
          <path
            d="M839.1 741.176C837.81 741.176 836.766 742.22 836.766 743.511C836.766 744.801 837.81 745.844 839.1 745.844C840.39 745.844 841.432 744.801 841.432 743.511C841.424 742.22 840.381 741.176 839.1 741.176Z"
            fill="#EF4E05"
          />
          <path
            d="M825.266 727.816C824.08 727.816 823.122 728.775 823.122 729.961C823.122 731.147 824.08 732.105 825.266 732.105C826.452 732.105 827.41 731.147 827.41 729.961C827.41 728.784 826.452 727.816 825.266 727.816Z"
            fill="#EF4E05"
          />
          <path
            d="M835.978 712.796C837.131 712.796 838.065 711.861 838.065 710.709C838.065 709.555 837.131 708.621 835.978 708.621C834.825 708.621 833.891 709.555 833.891 710.709C833.891 711.861 834.825 712.796 835.978 712.796Z"
            fill="#EF4E05"
          />
          <path
            d="M824.791 693.051C825.835 693.051 826.689 692.207 826.689 691.153C826.689 690.1 825.844 689.255 824.791 689.255C823.748 689.255 822.894 690.1 822.894 691.153C822.894 692.207 823.748 693.051 824.791 693.051Z"
            fill="#EF4E05"
          />
          <path
            d="M807.522 674.69C808.452 674.69 809.211 673.931 809.211 673.002C809.211 672.071 808.461 671.312 807.522 671.312C806.582 671.312 805.833 672.062 805.833 673.002C805.833 673.941 806.582 674.69 807.522 674.69Z"
            fill="#EF4E05"
          />
          <path
            d="M810.91 652.525C811.791 652.525 812.513 651.813 812.513 650.921C812.513 650.039 811.801 649.318 810.91 649.318C810.027 649.318 809.306 650.029 809.306 650.921C809.315 651.813 810.027 652.525 810.91 652.525Z"
            fill="#EF4E05"
          />
          <path
            d="M832.857 670.24C833.871 670.24 834.697 669.415 834.697 668.399C834.697 667.385 833.871 666.559 832.857 666.559C831.841 666.559 831.016 667.385 831.016 668.399C831.025 669.424 831.841 670.24 832.857 670.24Z"
            fill="#EF4E05"
          />
          <path
            d="M858.412 676.294C859.539 676.294 860.455 675.373 860.455 674.244C860.455 673.115 859.539 672.195 858.412 672.195C857.278 672.195 856.361 673.115 856.361 674.244C856.37 675.373 857.278 676.294 858.412 676.294Z"
            fill="#EF4E05"
          />
          <path
            d="M873.973 661.776C875.116 661.776 876.058 660.846 876.058 659.689C876.058 658.531 875.124 657.601 873.973 657.601C872.813 657.601 871.88 658.531 871.88 659.689C871.88 660.846 872.821 661.776 873.973 661.776Z"
            fill="#EF4E05"
          />
          <path
            d="M890.037 647.363C891.222 647.363 892.189 646.395 892.189 645.209C892.189 644.023 891.222 643.055 890.037 643.055C888.852 643.055 887.876 644.023 887.876 645.209C887.876 646.404 888.835 647.363 890.037 647.363Z"
            fill="#EF4E05"
          />
          <path
            d="M869.484 639.231C869.484 638.159 868.609 637.286 867.533 637.286C866.466 637.286 865.592 638.159 865.592 639.231C865.592 640.304 866.466 641.176 867.533 641.176C868.609 641.176 869.484 640.304 869.484 639.231Z"
            fill="#EF4E05"
          />
          <path
            d="M852.663 653.075C852.663 652.031 851.814 651.187 850.771 651.187C849.729 651.187 848.88 652.031 848.88 653.075C848.88 654.12 849.729 654.964 850.771 654.964C851.814 654.954 852.663 654.12 852.663 653.075Z"
            fill="#EF4E05"
          />
          <path
            d="M832.477 641.746C833.406 641.746 834.165 640.986 834.165 640.057C834.165 639.127 833.417 638.368 832.477 638.368C831.538 638.368 830.788 639.127 830.788 640.057C830.797 640.997 831.547 641.746 832.477 641.746Z"
            fill="#EF4E05"
          />
          <path
            d="M852.352 622.949C851.377 622.949 850.586 623.737 850.586 624.714C850.586 625.691 851.377 626.479 852.352 626.479C853.335 626.479 854.117 625.691 854.117 624.714C854.117 623.737 853.335 622.949 852.352 622.949Z"
            fill="#EF4E05"
          />
          <path
            d="M871.703 609.542C872.72 609.542 873.552 608.717 873.552 607.692C873.552 606.667 872.728 605.842 871.703 605.842C870.677 605.842 869.854 606.667 869.854 607.692C869.854 608.717 870.677 609.542 871.703 609.542Z"
            fill="#EF4E05"
          />
          <path
            d="M880.572 625.445C880.572 626.555 881.479 627.466 882.597 627.466C883.715 627.466 884.615 626.565 884.615 625.445C884.615 624.334 883.715 623.423 882.597 623.423C881.479 623.423 880.572 624.325 880.572 625.445Z"
            fill="#EF4E05"
          />
          <path
            d="M898.384 614.628C899.544 614.628 900.494 613.685 900.494 612.521C900.494 611.358 899.544 610.415 898.384 610.415C897.224 610.415 896.274 611.358 896.274 612.521C896.274 613.685 897.224 614.628 898.384 614.628Z"
            fill="#EF4E05"
          />
          <path
            d="M915.441 604.56C916.668 604.56 917.652 603.574 917.652 602.35C917.652 601.126 916.668 600.139 915.441 600.139C914.222 600.139 913.23 601.126 913.23 602.35C913.23 603.574 914.23 604.56 915.441 604.56Z"
            fill="#EF4E05"
          />
          <path
            d="M931.597 592.197C932.875 592.197 933.909 591.16 933.909 589.882C933.909 588.603 932.875 587.566 931.597 587.566C930.32 587.566 929.277 588.603 929.277 589.882C929.277 591.16 930.32 592.197 931.597 592.197Z"
            fill="#EF4E05"
          />
          <path
            d="M936.843 612.835C938.188 612.835 939.289 611.744 939.289 610.386C939.289 609.029 938.196 607.938 936.843 607.938C935.498 607.938 934.397 609.029 934.397 610.386C934.397 611.744 935.498 612.835 936.843 612.835Z"
            fill="#EF4E05"
          />
          <path
            d="M924.696 622.304C924.696 621.004 923.645 619.941 922.334 619.941C921.022 619.941 919.972 620.995 919.972 622.304C919.972 623.604 921.022 624.667 922.334 624.667C923.645 624.667 924.696 623.613 924.696 622.304Z"
            fill="#EF4E05"
          />
          <path
            d="M905.622 634.05C906.858 634.05 907.85 633.053 907.85 631.821C907.85 630.59 906.858 629.591 905.622 629.591C904.395 629.591 903.395 630.59 903.395 631.821C903.395 633.053 904.395 634.05 905.622 634.05Z"
            fill="#EF4E05"
          />
          <path
            d="M914.541 650.39C914.541 649.071 913.482 648.008 912.162 648.008C910.842 648.008 909.783 649.071 909.783 650.39C909.783 651.709 910.842 652.771 912.162 652.771C913.482 652.771 914.541 651.709 914.541 650.39Z"
            fill="#EF4E05"
          />
          <path
            d="M937.23 662.725C937.23 661.245 936.036 660.049 934.557 660.049C933.077 660.049 931.875 661.245 931.875 662.725C931.875 664.205 933.077 665.401 934.557 665.401C936.036 665.401 937.23 664.196 937.23 662.725Z"
            fill="#EF4E05"
          />
          <path
            d="M946.602 645.029C945.106 645.029 943.887 646.244 943.887 647.743C943.887 649.241 945.106 650.456 946.602 650.456C948.107 650.456 949.318 649.241 949.318 647.743C949.318 646.244 948.107 645.029 946.602 645.029Z"
            fill="#EF4E05"
          />
          <path
            d="M928.462 639.051C927.075 639.051 925.957 640.171 925.957 641.556C925.957 642.942 927.075 644.061 928.462 644.061C929.849 644.061 930.967 642.942 930.967 641.556C930.967 640.171 929.84 639.051 928.462 639.051Z"
            fill="#EF4E05"
          />
          <path
            d="M947.452 628.605C947.452 627.162 946.283 625.996 944.837 625.996C943.4 625.996 942.231 627.162 942.231 628.605C942.231 630.047 943.4 631.214 944.837 631.214C946.283 631.214 947.452 630.047 947.452 628.605Z"
            fill="#EF4E05"
          />
          <path
            d="M961.532 614.998C961.532 613.508 960.321 612.303 958.834 612.303C957.346 612.303 956.144 613.508 956.144 614.998C956.144 616.487 957.346 617.693 958.834 617.693C960.321 617.693 961.532 616.487 961.532 614.998Z"
            fill="#EF4E05"
          />
          <path
            d="M952.437 597.548C952.437 596.153 951.31 595.024 949.915 595.024C948.519 595.024 947.393 596.153 947.393 597.548C947.393 598.943 948.519 600.072 949.915 600.072C951.31 600.072 952.437 598.943 952.437 597.548Z"
            fill="#EF4E05"
          />
          <path
            d="M967.164 597.397C968.661 597.397 969.871 596.186 969.871 594.692C969.871 593.199 968.661 591.988 967.164 591.988C965.676 591.988 964.466 593.199 964.466 594.692C964.466 596.186 965.676 597.397 967.164 597.397Z"
            fill="#EF4E05"
          />
          <path
            d="M973.881 577.281C973.881 575.8 972.679 574.596 971.199 574.596C969.72 574.596 968.518 575.8 968.518 577.281C968.518 578.761 969.72 579.966 971.199 579.966C972.679 579.966 973.881 578.761 973.881 577.281Z"
            fill="#EF4E05"
          />
          <path
            d="M972.09 563.067C973.562 563.067 974.747 561.881 974.747 560.41C974.747 558.94 973.562 557.754 972.09 557.754C970.619 557.754 969.434 558.94 969.434 560.41C969.434 561.881 970.628 563.067 972.09 563.067Z"
            fill="#EF4E05"
          />
          <path
            d="M981.867 546.899C983.371 546.899 984.59 545.68 984.59 544.175C984.59 542.671 983.371 541.452 981.867 541.452C980.362 541.452 979.143 542.671 979.143 544.175C979.143 545.68 980.362 546.899 981.867 546.899Z"
            fill="#EF4E05"
          />
          <path
            d="M979.16 527.115C979.16 525.682 977.992 524.515 976.562 524.515C975.125 524.515 973.957 525.682 973.957 527.115C973.957 528.548 975.125 529.715 976.562 529.715C977.992 529.715 979.16 528.557 979.16 527.115Z"
            fill="#EF4E05"
          />
          <path
            d="M962.045 545.637C963.432 545.637 964.55 544.517 964.55 543.132C964.55 541.746 963.432 540.627 962.045 540.627C960.658 540.627 959.54 541.746 959.54 543.132C959.54 544.517 960.658 545.637 962.045 545.637Z"
            fill="#EF4E05"
          />
          <path
            d="M949.747 561.767C951.075 561.767 952.142 560.695 952.142 559.367C952.142 558.038 951.075 556.966 949.747 556.966C948.418 556.966 947.342 558.038 947.342 559.367C947.342 560.695 948.418 561.767 949.747 561.767Z"
            fill="#EF4E05"
          />
          <path
            d="M951.083 580.792C952.453 580.792 953.563 579.681 953.563 578.315C953.563 576.948 952.453 575.839 951.083 575.839C949.721 575.839 948.603 576.948 948.603 578.315C948.603 579.681 949.705 580.792 951.083 580.792Z"
            fill="#EF4E05"
          />
          <path
            d="M929.975 572.907C931.211 572.907 932.211 571.904 932.211 570.667C932.211 569.431 931.211 568.428 929.975 568.428C928.731 568.428 927.731 569.431 927.731 570.667C927.731 571.904 928.731 572.907 929.975 572.907Z"
            fill="#EF4E05"
          />
          <path
            d="M926.301 554.252C927.495 554.252 928.462 553.284 928.462 552.089C928.462 550.893 927.495 549.926 926.301 549.926C925.108 549.926 924.133 550.893 924.133 552.089C924.133 553.284 925.108 554.252 926.301 554.252Z"
            fill="#EF4E05"
          />
          <path
            d="M944.223 539.327C944.223 538.075 943.206 537.05 941.945 537.05C940.684 537.05 939.667 538.064 939.667 539.327C939.667 540.589 940.684 541.604 941.945 541.604C943.206 541.604 944.223 540.58 944.223 539.327Z"
            fill="#EF4E05"
          />
          <path
            d="M957.413 523.87C957.413 522.551 956.354 521.488 955.034 521.488C953.714 521.488 952.647 522.551 952.647 523.87C952.647 525.189 953.714 526.251 955.034 526.251C956.354 526.251 957.413 525.179 957.413 523.87Z"
            fill="#EF4E05"
          />
          <path
            d="M948.94 506.904C950.209 506.904 951.235 505.876 951.235 504.608C951.235 503.34 950.209 502.312 948.94 502.312C947.67 502.312 946.645 503.34 946.645 504.608C946.645 505.876 947.67 506.904 948.94 506.904Z"
            fill="#EF4E05"
          />
          <path
            d="M944.972 487.387C946.208 487.387 947.216 486.384 947.216 485.147C947.216 483.911 946.208 482.908 944.972 482.908C943.736 482.908 942.736 483.911 942.736 485.147C942.736 486.384 943.736 487.387 944.972 487.387Z"
            fill="#EF4E05"
          />
          <path
            d="M966.98 491.638C966.98 490.29 965.887 489.19 964.525 489.19C963.18 489.19 962.079 490.281 962.079 491.638C962.079 492.994 963.171 494.086 964.525 494.086C965.887 494.086 966.98 492.994 966.98 491.638Z"
            fill="#EF4E05"
          />
          <path
            d="M969.341 511.611C970.728 511.611 971.855 510.481 971.855 509.096C971.855 507.71 970.728 506.581 969.341 506.581C967.954 506.581 966.828 507.71 966.828 509.096C966.828 510.481 967.954 511.611 969.341 511.611Z"
            fill="#EF4E05"
          />
          <path
            d="M987.793 514.4C989.298 514.4 990.508 513.186 990.508 511.687C990.508 510.188 989.298 508.973 987.793 508.973C986.297 508.973 985.078 510.188 985.078 511.687C985.078 513.186 986.297 514.4 987.793 514.4Z"
            fill="#EF4E05"
          />
          <path
            d="M1003.26 515.975C1004.87 515.975 1006.19 514.667 1006.19 513.053C1006.19 511.439 1004.87 510.131 1003.26 510.131C1001.65 510.131 1000.34 511.439 1000.34 513.053C1000.34 514.667 1001.65 515.975 1003.26 515.975Z"
            fill="#EF4E05"
          />
          <path
            d="M996.754 530.312C998.318 530.312 999.579 529.051 999.579 527.485C999.579 525.919 998.318 524.657 996.754 524.657C995.182 524.657 993.921 525.919 993.921 527.485C993.921 529.051 995.182 530.312 996.754 530.312Z"
            fill="#EF4E05"
          />
          <path
            d="M997.065 539.981C995.443 539.981 994.131 541.291 994.131 542.913C994.131 544.536 995.443 545.845 997.065 545.845C998.688 545.845 999.999 544.536 999.999 542.913C1000.01 541.3 998.688 539.981 997.065 539.981Z"
            fill="#EF4E05"
          />
          <path
            d="M993.333 562.63C994.922 562.63 996.208 561.343 996.208 559.755C996.208 558.167 994.922 556.88 993.333 556.88C991.753 556.88 990.458 558.167 990.458 559.755C990.458 561.343 991.753 562.63 993.333 562.63Z"
            fill="#EF4E05"
          />
          <path
            d="M991.383 573.637C989.769 573.637 988.466 574.947 988.466 576.551C988.466 578.163 989.777 579.464 991.383 579.464C992.988 579.464 994.291 578.154 994.291 576.551C994.291 574.938 992.997 573.637 991.383 573.637Z"
            fill="#EF4E05"
          />
          <path
            d="M986.91 596.211C988.524 596.211 989.827 594.906 989.827 593.298C989.827 591.689 988.524 590.384 986.91 590.384C985.305 590.384 984.002 591.689 984.002 593.298C984.002 594.906 985.305 596.211 986.91 596.211Z"
            fill="#EF4E05"
          />
          <path
            d="M978.773 605.642C977.176 605.642 975.89 606.933 975.89 608.527C975.89 610.12 977.176 611.412 978.773 611.412C980.362 611.412 981.657 610.12 981.657 608.527C981.657 606.942 980.362 605.642 978.773 605.642Z"
            fill="#EF4E05"
          />
          <path
            d="M974.747 627.58C976.361 627.58 977.663 626.276 977.663 624.667C977.663 623.058 976.361 621.754 974.747 621.754C973.141 621.754 971.838 623.058 971.838 624.667C971.838 626.276 973.141 627.58 974.747 627.58Z"
            fill="#EF4E05"
          />
          <path
            d="M964.878 633.946C963.306 633.946 962.02 635.227 962.02 636.803C962.02 638.378 963.306 639.658 964.878 639.658C966.458 639.658 967.736 638.378 967.736 636.803C967.736 635.227 966.458 633.946 964.878 633.946Z"
            fill="#EF4E05"
          />
          <path
            d="M963.491 650.143C961.885 650.143 960.582 651.453 960.582 653.056C960.582 654.669 961.893 655.969 963.491 655.969C965.096 655.969 966.408 654.66 966.408 653.056C966.408 651.453 965.105 650.143 963.491 650.143Z"
            fill="#EF4E05"
          />
          <path
            d="M954.009 664.907C952.403 664.907 951.108 666.208 951.108 667.802C951.108 669.405 952.411 670.696 954.009 670.696C955.606 670.696 956.9 669.395 956.9 667.802C956.9 666.198 955.606 664.907 954.009 664.907Z"
            fill="#EF4E05"
          />
          <path
            d="M946.224 686.038C947.83 686.038 949.133 684.738 949.133 683.135C949.133 681.531 947.83 680.231 946.224 680.231C944.619 680.231 943.324 681.531 943.324 683.135C943.324 684.738 944.619 686.038 946.224 686.038Z"
            fill="#EF4E05"
          />
          <path
            d="M927.781 679.748C926.293 679.748 925.074 680.962 925.074 682.452C925.074 683.942 926.293 685.155 927.781 685.155C929.269 685.155 930.488 683.942 930.488 682.452C930.488 680.952 929.269 679.748 927.781 679.748Z"
            fill="#EF4E05"
          />
          <path
            d="M937.045 696.893C935.431 696.893 934.128 698.203 934.128 699.807C934.128 701.419 935.439 702.719 937.045 702.719C938.642 702.719 939.953 701.41 939.953 699.807C939.953 698.194 938.642 696.893 937.045 696.893Z"
            fill="#EF4E05"
          />
          <path
            d="M925.444 711.193C923.864 711.193 922.569 712.483 922.569 714.068C922.569 715.652 923.864 716.943 925.444 716.943C927.033 716.943 928.319 715.652 928.319 714.068C928.327 712.483 927.041 711.193 925.444 711.193Z"
            fill="#EF4E05"
          />
          <path
            d="M916.206 700.423C917.685 700.423 918.887 699.218 918.887 697.738C918.887 696.258 917.685 695.053 916.206 695.053C914.726 695.053 913.516 696.258 913.516 697.738C913.516 699.218 914.718 700.423 916.206 700.423Z"
            fill="#EF4E05"
          />
          <path
            d="M905.109 710.386C903.63 710.386 902.436 711.581 902.436 713.062C902.436 714.542 903.63 715.737 905.109 715.737C906.589 715.737 907.791 714.542 907.791 713.062C907.791 711.581 906.589 710.386 905.109 710.386Z"
            fill="#EF4E05"
          />
          <path
            d="M916.802 727.902C915.188 727.902 913.885 729.211 913.885 730.815C913.885 732.428 915.197 733.728 916.802 733.728C918.416 733.728 919.711 732.418 919.711 730.815C919.728 729.202 918.416 727.902 916.802 727.902Z"
            fill="#EF4E05"
          />
          <path
            d="M904.008 742.675C902.419 742.675 901.125 743.966 901.125 745.56C901.125 747.154 902.419 748.444 904.008 748.444C905.605 748.444 906.891 747.154 906.891 745.56C906.891 743.966 905.605 742.675 904.008 742.675Z"
            fill="#EF4E05"
          />
          <path
            d="M890.407 758.601C888.793 758.601 887.49 759.904 887.49 761.509C887.49 763.123 888.801 764.426 890.407 764.426C892.021 764.426 893.315 763.115 893.315 761.509C893.324 759.895 892.021 758.601 890.407 758.601Z"
            fill="#EF4E05"
          />
          <path
            d="M884.775 740.266C883.287 740.266 882.076 741.48 882.076 742.969C882.076 744.46 883.287 745.674 884.775 745.674C886.271 745.674 887.481 744.46 887.481 742.969C887.481 741.471 886.271 740.266 884.775 740.266Z"
            fill="#EF4E05"
          />
          <path
            d="M894.862 725.368C893.374 725.368 892.172 726.573 892.172 728.063C892.172 729.553 893.374 730.758 894.862 730.758C896.35 730.758 897.56 729.553 897.56 728.063C897.56 726.573 896.35 725.368 894.862 725.368Z"
            fill="#EF4E05"
          />
          <path
            d="M870.879 732.646C872.258 732.646 873.376 731.528 873.376 730.151C873.376 728.772 872.258 727.655 870.879 727.655C869.5 727.655 868.382 728.772 868.382 730.151C868.382 731.528 869.5 732.646 870.879 732.646Z"
            fill="#EF4E05"
          />
          <path
            d="M883.968 713.185C883.968 711.818 882.867 710.718 881.505 710.718C880.134 710.718 879.033 711.818 879.033 713.185C879.033 714.551 880.134 715.652 881.505 715.652C882.867 715.652 883.968 714.551 883.968 713.185Z"
            fill="#EF4E05"
          />
          <path
            d="M892.752 699.92C894.114 699.92 895.215 698.82 895.215 697.453C895.215 696.087 894.114 694.987 892.752 694.987C891.382 694.987 890.281 696.087 890.281 697.453C890.281 698.82 891.39 699.92 892.752 699.92Z"
            fill="#EF4E05"
          />
          <path
            d="M904.782 685.081C906.152 685.081 907.278 683.961 907.278 682.585C907.278 681.209 906.152 680.089 904.782 680.089C903.403 680.089 902.285 681.209 902.285 682.585C902.285 683.961 903.403 685.081 904.782 685.081Z"
            fill="#EF4E05"
          />
          <path
            d="M916.6 670.439C917.996 670.439 919.122 669.31 919.122 667.915C919.122 666.52 917.996 665.391 916.6 665.391C915.205 665.391 914.079 666.52 914.079 667.915C914.079 669.31 915.205 670.439 916.6 670.439Z"
            fill="#EF4E05"
          />
          <path
            d="M896.224 666.416C897.493 666.416 898.527 665.384 898.527 664.11C898.527 662.837 897.493 661.805 896.224 661.805C894.946 661.805 893.912 662.837 893.912 664.11C893.912 665.384 894.946 666.416 896.224 666.416Z"
            fill="#EF4E05"
          />
          <path
            d="M882.48 681.199C883.732 681.199 884.758 680.184 884.758 678.922C884.758 677.67 883.741 676.645 882.48 676.645C881.219 676.645 880.202 677.66 880.202 678.922C880.21 680.175 881.227 681.199 882.48 681.199Z"
            fill="#EF4E05"
          />
          <path
            d="M870.248 696.333C871.493 696.333 872.501 695.327 872.501 694.085C872.501 692.843 871.493 691.836 870.248 691.836C869.013 691.836 868.004 692.843 868.004 694.085C868.004 695.327 869.013 696.333 870.248 696.333Z"
            fill="#EF4E05"
          />
          <path
            d="M847.081 693.354C848.216 693.354 849.141 692.434 849.141 691.294C849.141 690.156 848.216 689.236 847.081 689.236C845.938 689.236 845.021 690.156 845.021 691.294C845.021 692.434 845.938 693.354 847.081 693.354Z"
            fill="#EF4E05"
          />
          <path
            d="M859.64 712.976C860.892 712.976 861.91 711.961 861.91 710.709C861.91 709.456 860.892 708.441 859.64 708.441C858.387 708.441 857.379 709.456 857.379 710.709C857.379 711.961 858.387 712.976 859.64 712.976Z"
            fill="#EF4E05"
          />
          <path
            d="M849.401 729.657C850.679 729.657 851.704 728.633 851.704 727.361C851.704 726.09 850.679 725.065 849.401 725.065C848.132 725.065 847.106 726.09 847.106 727.361C847.106 728.633 848.132 729.657 849.401 729.657Z"
            fill="#EF4E05"
          />
          <path
            d="M858.53 749.194C859.917 749.194 861.044 748.069 861.044 746.68C861.044 745.291 859.917 744.165 858.53 744.165C857.143 744.165 856.017 745.291 856.017 746.68C856.017 748.069 857.143 749.194 858.53 749.194Z"
            fill="#EF4E05"
          />
          <path
            d="M873.048 759.374C874.544 759.374 875.755 758.164 875.755 756.667C875.755 755.178 874.544 753.966 873.048 753.966C871.56 753.966 870.349 755.178 870.349 756.667C870.349 758.164 871.56 759.374 873.048 759.374Z"
            fill="#EF4E05"
          />
          <path
            d="M863.221 769.638C863.221 768.142 862.002 766.931 860.506 766.931C859.009 766.931 857.791 768.142 857.791 769.638C857.791 771.143 859.009 772.353 860.506 772.353C862.002 772.353 863.221 771.143 863.221 769.638Z"
            fill="#EF4E05"
          />
          <path
            d="M877.411 774.447C875.813 774.447 874.519 775.741 874.519 777.338C874.519 778.927 875.822 780.23 877.411 780.23C879.016 780.23 880.311 778.927 880.311 777.338C880.311 775.741 879.016 774.447 877.411 774.447Z"
            fill="#EF4E05"
          />
          <path
            d="M864.188 793.655C865.785 793.655 867.079 792.36 867.079 790.763C867.079 789.166 865.785 787.871 864.188 787.871C862.591 787.871 861.296 789.166 861.296 790.763C861.296 792.36 862.591 793.655 864.188 793.655Z"
            fill="#EF4E05"
          />
          <path
            d="M851.007 799.766C849.401 799.766 848.106 801.069 848.106 802.675C848.106 804.272 849.401 805.575 851.007 805.575C852.612 805.575 853.915 804.272 853.915 802.675C853.915 801.069 852.612 799.766 851.007 799.766Z"
            fill="#EF4E05"
          />
          <path
            d="M834.441 799.581C835.94 799.581 837.155 798.362 837.155 796.866C837.155 795.37 835.94 794.151 834.441 794.151C832.942 794.151 831.728 795.37 831.728 796.866C831.728 798.362 832.942 799.581 834.441 799.581Z"
            fill="#EF4E05"
          />
          <path
            d="M819.734 791.957C821.148 791.957 822.296 790.814 822.296 789.401C822.296 787.981 821.148 786.837 819.734 786.837C818.32 786.837 817.172 787.981 817.172 789.401C817.172 790.814 818.32 791.957 819.734 791.957Z"
            fill="#EF4E05"
          />
          <path
            d="M815.739 805.474C814.259 805.474 813.054 806.676 813.054 808.156C813.054 809.635 814.259 810.837 815.739 810.837C817.219 810.837 818.424 809.635 818.424 808.156C818.424 806.676 817.219 805.474 815.739 805.474Z"
            fill="#EF4E05"
          />
          <path
            d="M835.105 812.964C833.492 812.964 832.193 814.275 832.193 815.881C832.193 817.495 833.502 818.79 835.105 818.79C836.719 818.79 838.018 817.487 838.018 815.881C838.018 814.275 836.719 812.964 835.105 812.964Z"
            fill="#EF4E05"
          />
          <path
            d="M821.223 824.733C819.61 824.733 818.301 826.044 818.301 827.658C818.301 829.264 819.61 830.575 821.223 830.575C822.836 830.575 824.146 829.264 824.146 827.658C824.146 826.044 822.836 824.733 821.223 824.733Z"
            fill="#EF4E05"
          />
          <path
            d="M804.932 836.569C803.319 836.569 802.009 837.872 802.009 839.486C802.009 841.1 803.319 842.411 804.932 842.411C806.544 842.411 807.854 841.1 807.854 839.486C807.854 837.872 806.544 836.569 804.932 836.569Z"
            fill="#EF4E05"
          />
          <path
            d="M806.26 823.035C806.26 821.522 805.036 820.303 803.527 820.303C802.018 820.303 800.794 821.522 800.794 823.035C800.794 824.539 802.018 825.767 803.527 825.767C805.036 825.767 806.26 824.556 806.26 823.035Z"
            fill="#EF4E05"
          />
          <path
            d="M787.872 827.044C787.872 825.573 786.686 824.388 785.214 824.388C783.744 824.388 782.558 825.573 782.558 827.044C782.558 828.516 783.744 829.701 785.214 829.701C786.675 829.718 787.872 828.516 787.872 827.044Z"
            fill="#EF4E05"
          />
          <path
            d="M792.369 806.374C790.974 806.374 789.835 807.508 789.835 808.904C789.835 810.299 790.974 811.443 792.369 811.443C793.764 811.443 794.903 810.299 794.903 808.904C794.903 807.508 793.773 806.374 792.369 806.374Z"
            fill="#EF4E05"
          />
          <path
            d="M804.732 791.671C804.732 790.318 803.632 789.216 802.275 789.216C800.918 789.216 799.818 790.318 799.818 791.671C799.818 793.033 800.918 794.134 802.275 794.134C803.632 794.134 804.732 793.033 804.732 791.671Z"
            fill="#EF4E05"
          />
          <path
            d="M784.266 793.663C785.546 793.663 786.59 792.621 786.59 791.343C786.59 790.065 785.546 789.015 784.266 789.015C782.985 789.015 781.941 790.065 781.941 791.343C781.941 792.621 782.975 793.663 784.266 793.663Z"
            fill="#EF4E05"
          />
          <path
            d="M770.896 807.668C772.215 807.668 773.288 806.6 773.288 805.281C773.288 803.961 772.215 802.893 770.896 802.893C769.577 802.893 768.505 803.961 768.505 805.281C768.505 806.6 769.577 807.668 770.896 807.668Z"
            fill="#EF4E05"
          />
          <path
            d="M766.21 824.186C767.593 824.186 768.714 823.06 768.714 821.681C768.714 820.294 767.593 819.176 766.21 819.176C764.826 819.176 763.705 820.294 763.705 821.681C763.705 823.06 764.826 824.186 766.21 824.186Z"
            fill="#EF4E05"
          />
          <path
            d="M773.43 839.688C773.43 838.2 772.225 836.989 770.735 836.989C769.245 836.989 768.041 838.2 768.041 839.688C768.041 841.175 769.245 842.377 770.735 842.377C772.225 842.377 773.43 841.175 773.43 839.688Z"
            fill="#EF4E05"
          />
          <path
            d="M788.944 844.168C787.359 844.168 786.069 845.454 786.069 847.043C786.069 848.623 787.359 849.918 788.944 849.918C790.528 849.918 791.819 848.623 791.819 847.043C791.819 845.454 790.528 844.168 788.944 844.168Z"
            fill="#EF4E05"
          />
          <path
            d="M774.881 855.231C773.268 855.231 771.969 856.542 771.969 858.139C771.969 859.753 773.278 861.056 774.881 861.056C776.485 861.056 777.794 859.745 777.794 858.139C777.794 856.542 776.485 855.231 774.881 855.231Z"
            fill="#EF4E05"
          />
          <path
            d="M759.292 863.301C757.688 863.301 756.388 864.604 756.388 866.209C756.388 867.806 757.688 869.109 759.292 869.109C760.895 869.109 762.195 867.806 762.195 866.209C762.195 864.604 760.905 863.301 759.292 863.301Z"
            fill="#EF4E05"
          />
          <path
            d="M744.793 871.11C743.181 871.11 741.871 872.421 741.871 874.035C741.871 875.649 743.181 876.961 744.793 876.961C746.406 876.961 747.716 875.649 747.716 874.035C747.716 872.421 746.406 871.11 744.793 871.11Z"
            fill="#EF4E05"
          />
          <path
            d="M729.137 880.954C730.725 880.954 732.012 879.668 732.012 878.079C732.012 876.49 730.725 875.204 729.137 875.204C727.549 875.204 726.262 876.49 726.262 878.079C726.262 879.668 727.549 880.954 729.137 880.954Z"
            fill="#EF4E05"
          />
          <path
            d="M716.603 885.182C714.99 885.182 713.69 886.493 713.69 888.099C713.69 889.713 715 891.008 716.603 891.008C718.207 891.008 719.516 889.696 719.516 888.099C719.526 886.493 718.216 885.182 716.603 885.182Z"
            fill="#EF4E05"
          />
          <path
            d="M700.283 887.166C698.698 887.166 697.417 888.444 697.417 890.033C697.417 891.613 698.698 892.899 700.283 892.899C701.867 892.899 703.148 891.613 703.148 890.033C703.148 888.444 701.867 887.166 700.283 887.166Z"
            fill="#EF4E05"
          />
          <path
            d="M686.847 894.58C685.243 894.58 683.943 895.875 683.943 897.48C683.943 899.086 685.243 900.381 686.847 900.381C688.451 900.381 689.75 899.086 689.75 897.48C689.75 895.875 688.451 894.58 686.847 894.58Z"
            fill="#EF4E05"
          />
          <path
            d="M678.194 880.693C676.694 880.693 675.48 881.912 675.48 883.408C675.48 884.905 676.694 886.124 678.194 886.124C679.692 886.124 680.907 884.905 680.907 883.408C680.907 881.912 679.692 880.693 678.194 880.693Z"
            fill="#EF4E05"
          />
          <path
            d="M670.783 900.12C669.169 900.12 667.87 901.431 667.87 903.029C667.87 904.643 669.18 905.946 670.783 905.946C672.387 905.946 673.696 904.634 673.696 903.029C673.696 901.431 672.387 900.12 670.783 900.12Z"
            fill="#EF4E05"
          />
          <path
            d="M653.675 905.357C652.062 905.357 650.762 906.668 650.762 908.266C650.762 909.871 652.071 911.183 653.675 911.183C655.288 911.183 656.588 909.871 656.588 908.266C656.588 906.668 655.279 905.357 653.675 905.357Z"
            fill="#EF4E05"
          />
          <path
            d="M659.017 887.687C657.508 887.687 656.293 888.914 656.293 890.411C656.293 891.907 657.517 893.134 659.017 893.134C660.525 893.134 661.74 891.907 661.74 890.411C661.74 888.914 660.525 887.687 659.017 887.687Z"
            fill="#EF4E05"
          />
          <path
            d="M641.293 890.932C639.793 890.932 638.578 892.151 638.578 893.647C638.578 895.143 639.793 896.362 641.293 896.362C642.791 896.362 644.006 895.143 644.006 893.647C644.006 892.151 642.791 890.932 641.293 890.932Z"
            fill="#EF4E05"
          />
          <path
            d="M624.327 893.967C622.838 893.967 621.632 895.177 621.632 896.665C621.632 898.153 622.838 899.363 624.327 899.363C625.817 899.363 627.022 898.153 627.022 896.665C627.022 895.177 625.817 893.967 624.327 893.967Z"
            fill="#EF4E05"
          />
          <path
            d="M636.263 909.241C634.66 909.241 633.36 910.535 633.36 912.141C633.36 913.746 634.66 915.041 636.263 915.041C637.867 915.041 639.167 913.746 639.167 912.141C639.167 910.535 637.867 909.241 636.263 909.241Z"
            fill="#EF4E05"
          />
          <path
            d="M618.757 912.057C617.144 912.057 615.844 913.368 615.844 914.966C615.844 916.571 617.153 917.882 618.757 917.882C620.37 917.882 621.67 916.571 621.67 914.966C621.67 913.368 620.361 912.057 618.757 912.057Z"
            fill="#EF4E05"
          />
          <path
            d="M601.45 914.36C599.846 914.36 598.547 915.663 598.547 917.269C598.547 918.866 599.846 920.169 601.45 920.169C603.053 920.169 604.354 918.866 604.354 917.269C604.354 915.663 603.053 914.36 601.45 914.36Z"
            fill="#EF4E05"
          />
          <path
            d="M584.618 916.142C583.014 916.142 581.714 917.445 581.714 919.051C581.714 920.648 583.014 921.951 584.618 921.951C586.221 921.951 587.521 920.648 587.521 919.051C587.521 917.445 586.221 916.142 584.618 916.142Z"
            fill="#EF4E05"
          />
          <path
            d="M567.642 917.807C566.029 917.807 564.729 919.118 564.729 920.715C564.729 922.321 566.039 923.632 567.642 923.632C569.255 923.632 570.556 922.321 570.556 920.715C570.556 919.118 569.246 917.807 567.642 917.807Z"
            fill="#EF4E05"
          />
          <path
            d="M550.373 918.656C548.769 918.656 547.47 919.959 547.47 921.564C547.47 923.17 548.769 924.465 550.373 924.465C551.977 924.465 553.277 923.17 553.277 921.564C553.277 919.959 551.977 918.656 550.373 918.656Z"
            fill="#EF4E05"
          />
          <path
            d="M554.093 900.599C552.603 900.599 551.398 901.81 551.398 903.298C551.398 904.786 552.603 905.996 554.093 905.996C555.583 905.996 556.788 904.786 556.788 903.298C556.788 901.81 555.583 900.599 554.093 900.599Z"
            fill="#EF4E05"
          />
          <path
            d="M541.274 885.14C539.889 885.14 538.759 886.267 538.759 887.654C538.759 889.041 539.889 890.167 541.274 890.167C542.66 890.167 543.789 889.041 543.789 887.654C543.789 886.267 542.66 885.14 541.274 885.14Z"
            fill="#EF4E05"
          />
          <path
            d="M521.756 892.344C520.313 892.344 519.146 893.513 519.146 894.959C519.146 896.396 520.313 897.564 521.756 897.564C523.198 897.564 524.365 896.396 524.365 894.959C524.365 893.513 523.198 892.344 521.756 892.344Z"
            fill="#EF4E05"
          />
          <path
            d="M535.438 903.508C533.93 903.508 532.715 904.727 532.715 906.231C532.715 907.728 533.939 908.955 535.438 908.955C536.947 908.955 538.161 907.728 538.161 906.231C538.161 904.727 536.937 903.508 535.438 903.508Z"
            fill="#EF4E05"
          />
          <path
            d="M533.455 925.457C535.059 925.457 536.358 924.153 536.358 922.548C536.358 920.942 535.059 919.648 533.455 919.648C531.852 919.648 530.551 920.942 530.551 922.548C530.551 924.153 531.852 925.457 533.455 925.457Z"
            fill="#EF4E05"
          />
          <path
            d="M518.112 920.741C519.689 920.741 520.968 919.463 520.968 917.882C520.968 916.302 519.689 915.024 518.112 915.024C516.534 915.024 515.256 916.302 515.256 917.882C515.256 919.463 516.534 920.741 518.112 920.741Z"
            fill="#EF4E05"
          />
          <path
            d="M502.114 918.336C500.501 918.336 499.202 919.648 499.202 921.253C499.202 922.851 500.51 924.162 502.114 924.162C503.728 924.162 505.027 922.851 505.027 921.253C505.027 919.648 503.717 918.336 502.114 918.336Z"
            fill="#EF4E05"
          />
          <path
            d="M487.521 912.191C485.937 912.191 484.656 913.469 484.656 915.05C484.656 916.638 485.937 917.916 487.521 917.916C489.106 917.916 490.387 916.638 490.387 915.05C490.387 913.469 489.106 912.191 487.521 912.191Z"
            fill="#EF4E05"
          />
          <path
            d="M472.311 915.008C470.698 915.008 469.398 916.319 469.398 917.916C469.398 919.53 470.707 920.833 472.311 920.833C473.914 920.833 475.224 919.522 475.224 917.916C475.224 916.319 473.914 915.008 472.311 915.008Z"
            fill="#EF4E05"
          />
          <path
            d="M456.295 912.225C454.681 912.225 453.382 913.536 453.382 915.142C453.382 916.739 454.691 918.051 456.295 918.051C457.898 918.051 459.207 916.739 459.207 915.142C459.207 913.536 457.908 912.225 456.295 912.225Z"
            fill="#EF4E05"
          />
          <path
            d="M440.211 914.293C441.815 914.293 443.114 912.998 443.114 911.393C443.114 909.787 441.815 908.484 440.211 908.484C438.608 908.484 437.307 909.787 437.307 911.393C437.307 912.998 438.608 914.293 440.211 914.293Z"
            fill="#EF4E05"
          />
          <path
            d="M441.644 890.268C441.644 888.797 440.458 887.612 438.987 887.612C437.517 887.612 436.331 888.797 436.331 890.268C436.331 891.739 437.517 892.924 438.987 892.924C440.458 892.924 441.644 891.739 441.644 890.268Z"
            fill="#EF4E05"
          />
          <path
            d="M424.631 903.642C423.046 903.642 421.756 904.928 421.756 906.517C421.756 908.098 423.046 909.392 424.631 909.392C426.215 909.392 427.506 908.098 427.506 906.517C427.506 904.928 426.225 903.642 424.631 903.642Z"
            fill="#EF4E05"
          />
          <path
            d="M408.785 907.425C410.389 907.425 411.689 906.122 411.689 904.525C411.689 902.919 410.389 901.616 408.785 901.616C407.182 901.616 405.882 902.919 405.882 904.525C405.882 906.122 407.182 907.425 408.785 907.425Z"
            fill="#EF4E05"
          />
          <path
            d="M424.384 887.78C424.384 886.3 423.18 885.098 421.699 885.098C420.219 885.098 419.014 886.3 419.014 887.78C419.014 889.268 420.219 890.47 421.699 890.47C423.18 890.47 424.384 889.268 424.384 887.78Z"
            fill="#EF4E05"
          />
          <path
            d="M407.191 887.082C407.191 885.56 405.967 884.341 404.449 884.341C402.931 884.341 401.707 885.56 401.707 887.082C401.707 888.603 402.931 889.822 404.449 889.822C405.967 889.822 407.191 888.603 407.191 887.082Z"
            fill="#EF4E05"
          />
          <path
            d="M393.803 876.54C393.803 875.078 392.616 873.892 391.155 873.892C389.694 873.892 388.508 875.078 388.508 876.54C388.508 878.003 389.694 879.188 391.155 879.188C392.616 879.188 393.803 878.003 393.803 876.54Z"
            fill="#EF4E05"
          />
          <path
            d="M392.228 896.228C390.615 896.228 389.314 897.539 389.314 899.145C389.314 900.742 390.624 902.053 392.228 902.053C393.841 902.053 395.141 900.742 395.141 899.145C395.141 897.539 393.841 896.228 392.228 896.228Z"
            fill="#EF4E05"
          />
          <path
            d="M377.321 890.781C375.717 890.781 374.418 892.084 374.418 893.689C374.418 895.286 375.717 896.589 377.321 896.589C378.925 896.589 380.225 895.286 380.225 893.689C380.225 892.084 378.925 890.781 377.321 890.781Z"
            fill="#EF4E05"
          />
          <path
            d="M362.301 884.997C360.697 884.997 359.397 886.292 359.397 887.897C359.397 889.503 360.697 890.798 362.301 890.798C363.904 890.798 365.204 889.503 365.204 887.897C365.214 886.292 363.914 884.997 362.301 884.997Z"
            fill="#EF4E05"
          />
          <path
            d="M346.484 879.718C344.871 879.718 343.57 881.029 343.57 882.635C343.57 884.232 344.88 885.544 346.484 885.544C348.087 885.544 349.396 884.232 349.396 882.635C349.396 881.029 348.097 879.718 346.484 879.718Z"
            fill="#EF4E05"
          />
          <path
            d="M333.38 872.27C331.767 872.27 330.467 873.581 330.467 875.187C330.467 876.793 331.776 878.096 333.38 878.096C334.992 878.096 336.293 876.784 336.293 875.187C336.302 873.573 334.992 872.27 333.38 872.27Z"
            fill="#EF4E05"
          />
          <path
            d="M321.69 863.662C320.106 863.662 318.825 864.948 318.825 866.529C318.825 868.118 320.106 869.395 321.69 869.395C323.275 869.395 324.555 868.118 324.555 866.529C324.555 864.948 323.275 863.662 321.69 863.662Z"
            fill="#EF4E05"
          />
          <path
            d="M307.191 860.611C305.578 860.611 304.269 861.922 304.269 863.528C304.269 865.142 305.578 866.453 307.191 866.453C308.804 866.453 310.114 865.142 310.114 863.528C310.114 861.922 308.814 860.611 307.191 860.611Z"
            fill="#EF4E05"
          />
          <path
            d="M294.125 851.414C292.522 851.414 291.222 852.717 291.222 854.314C291.222 855.92 292.522 857.223 294.125 857.223C295.729 857.223 297.029 855.92 297.029 854.314C297.029 852.717 295.739 851.414 294.125 851.414Z"
            fill="#EF4E05"
          />
          <path
            d="M299.62 835.123C298.12 835.123 296.906 836.333 296.906 837.838C296.906 839.334 298.12 840.553 299.62 840.553C301.119 840.553 302.333 839.334 302.333 837.838C302.333 836.333 301.119 835.123 299.62 835.123Z"
            fill="#EF4E05"
          />
          <path
            d="M286.421 823.539C284.941 823.539 283.736 824.741 283.736 826.221C283.736 827.7 284.941 828.911 286.421 828.911C287.901 828.911 289.106 827.7 289.106 826.221C289.106 824.741 287.901 823.539 286.421 823.539Z"
            fill="#EF4E05"
          />
          <path
            d="M280.756 841.865C279.153 841.865 277.863 843.168 277.863 844.765C277.863 846.354 279.162 847.657 280.756 847.657C282.36 847.657 283.651 846.354 283.651 844.765C283.651 843.168 282.36 841.865 280.756 841.865Z"
            fill="#EF4E05"
          />
          <path
            d="M270.965 829.743C269.38 829.743 268.099 831.021 268.099 832.609C268.099 834.19 269.38 835.476 270.965 835.476C272.549 835.476 273.829 834.19 273.829 832.609C273.839 831.021 272.549 829.743 270.965 829.743Z"
            fill="#EF4E05"
          />
          <path
            d="M256.314 828.835C254.701 828.835 253.401 830.138 253.401 831.744C253.401 833.358 254.71 834.661 256.314 834.661C257.917 834.661 259.227 833.349 259.227 831.744C259.227 830.138 257.927 828.835 256.314 828.835Z"
            fill="#EF4E05"
          />
          <path
            d="M245.174 816.646C243.562 816.646 242.261 817.957 242.261 819.563C242.261 821.177 243.571 822.472 245.174 822.472C246.787 822.472 248.088 821.169 248.088 819.563C248.088 817.957 246.787 816.646 245.174 816.646Z"
            fill="#EF4E05"
          />
          <path
            d="M232.697 808.324C231.084 808.324 229.784 809.635 229.784 811.241C229.784 812.855 231.094 814.149 232.697 814.149C234.31 814.149 235.61 812.846 235.61 811.241C235.61 809.635 234.31 808.324 232.697 808.324Z"
            fill="#EF4E05"
          />
          <path
            d="M248.79 797.866C247.3 797.866 246.085 799.085 246.085 800.573C246.085 802.061 247.3 803.28 248.79 803.28C250.279 803.28 251.494 802.061 251.494 800.573C251.494 799.085 250.279 797.866 248.79 797.866Z"
            fill="#EF4E05"
          />
          <path
            d="M234.215 795.504C234.215 793.949 232.953 792.697 231.406 792.697C229.85 792.697 228.598 793.957 228.598 795.504C228.598 797.059 229.86 798.312 231.406 798.312C232.953 798.312 234.215 797.051 234.215 795.504Z"
            fill="#EF4E05"
          />
          <path
            d="M216.984 798.152C215.361 798.152 214.052 799.464 214.052 801.086C214.052 802.708 215.361 804.02 216.984 804.02C218.606 804.02 219.916 802.708 219.916 801.086C219.916 799.472 218.597 798.152 216.984 798.152Z"
            fill="#EF4E05"
          />
          <path
            d="M207.923 787.779C209.516 787.779 210.807 786.484 210.807 784.887C210.807 783.298 209.516 782.004 207.923 782.004C206.33 782.004 205.038 783.298 205.038 784.887C205.038 786.484 206.33 787.779 207.923 787.779Z"
            fill="#EF4E05"
          />
          <path
            d="M195.464 781.138C197.083 781.138 198.396 779.827 198.396 778.213C198.396 776.59 197.083 775.279 195.464 775.279C193.845 775.279 192.532 776.59 192.532 778.213C192.532 779.827 193.845 781.138 195.464 781.138Z"
            fill="#EF4E05"
          />
          <path
            d="M223.36 776.8C221.852 776.8 220.628 778.019 220.628 779.532C220.628 781.037 221.852 782.264 223.36 782.264C224.869 782.264 226.093 781.037 226.093 779.532C226.093 778.019 224.869 776.8 223.36 776.8Z"
            fill="#EF4E05"
          />
          <path
            d="M216.833 766.469C218.294 766.469 219.48 765.284 219.48 763.83C219.48 762.367 218.294 761.182 216.833 761.182C215.371 761.182 214.185 762.367 214.185 763.83C214.185 765.284 215.371 766.469 216.833 766.469Z"
            fill="#EF4E05"
          />
          <path
            d="M199.478 757.079C197.95 757.079 196.707 758.323 196.707 759.853C196.707 761.375 197.95 762.619 199.478 762.619C201.005 762.619 202.248 761.375 202.248 759.853C202.258 758.323 201.014 757.079 199.478 757.079Z"
            fill="#EF4E05"
          />
          <path
            d="M183.281 761.459C181.658 761.459 180.339 762.779 180.339 764.401C180.339 766.024 181.658 767.343 183.281 767.343C184.903 767.343 186.222 766.024 186.222 764.401C186.222 762.779 184.903 761.459 183.281 761.459Z"
            fill="#EF4E05"
          />
          <path
            d="M175.225 745.683C173.622 745.683 172.331 746.983 172.331 748.577C172.331 750.181 173.631 751.472 175.225 751.472C176.819 751.472 178.119 750.171 178.119 748.577C178.119 746.983 176.829 745.683 175.225 745.683Z"
            fill="#EF4E05"
          />
          <path
            d="M162.188 737.02C160.565 737.02 159.246 738.339 159.246 739.962C159.246 741.584 160.565 742.903 162.188 742.903C163.81 742.903 165.129 741.584 165.129 739.962C165.139 738.339 163.82 737.02 162.188 737.02Z"
            fill="#EF4E05"
          />
          <path
            d="M162.273 724.306C162.273 722.721 160.982 721.431 159.398 721.431C157.813 721.431 156.523 722.721 156.523 724.306C156.523 725.89 157.813 727.181 159.398 727.181C160.982 727.181 162.273 725.89 162.273 724.306Z"
            fill="#EF4E05"
          />
          <path
            d="M172.521 712.028C172.521 713.489 173.697 714.666 175.158 714.666C176.619 714.666 177.796 713.489 177.796 712.028C177.796 710.566 176.619 709.39 175.158 709.39C173.697 709.39 172.521 710.566 172.521 712.028Z"
            fill="#EF4E05"
          />
          <path
            d="M159.645 701.125C158.127 701.125 156.903 702.35 156.903 703.868C156.903 705.386 158.127 706.61 159.645 706.61C161.163 706.61 162.387 705.386 162.387 703.868C162.387 702.35 161.154 701.125 159.645 701.125Z"
            fill="#EF4E05"
          />
          <path
            d="M145.687 712.986C144.074 712.986 142.764 714.296 142.764 715.908C142.764 717.521 144.074 718.831 145.687 718.831C147.3 718.831 148.61 717.521 148.61 715.908C148.61 714.296 147.3 712.986 145.687 712.986Z"
            fill="#EF4E05"
          />
          <path
            d="M143.903 699.673C143.903 698.088 142.613 696.798 141.028 696.798C139.444 696.798 138.153 698.088 138.153 699.673C138.153 701.258 139.444 702.548 141.028 702.548C142.613 702.548 143.903 701.258 143.903 699.673Z"
            fill="#EF4E05"
          />
          <path
            d="M129.69 687.063C128.067 687.063 126.758 688.372 126.758 689.995C126.758 691.617 128.067 692.927 129.69 692.927C131.312 692.927 132.621 691.617 132.621 689.995C132.621 688.372 131.312 687.063 129.69 687.063Z"
            fill="#EF4E05"
          />
          <path
            d="M140.563 681.389C142.091 681.389 143.324 680.155 143.324 678.628C143.324 677.1 142.091 675.867 140.563 675.867C139.035 675.867 137.802 677.1 137.802 678.628C137.802 680.146 139.035 681.389 140.563 681.389Z"
            fill="#EF4E05"
          />
          <path
            d="M160.205 686.237C160.205 684.776 159.019 683.59 157.558 683.59C156.096 683.59 154.91 684.776 154.91 686.237C154.91 687.699 156.096 688.884 157.558 688.884C159.019 688.884 160.205 687.699 160.205 686.237Z"
            fill="#EF4E05"
          />
          <path
            d="M157.462 666.027C157.462 664.613 156.314 663.466 154.9 663.466C153.486 663.466 152.339 664.613 152.339 666.027C152.339 667.441 153.486 668.589 154.9 668.589C156.314 668.589 157.462 667.441 157.462 666.027Z"
            fill="#EF4E05"
          />
          <path
            d="M171.752 652.279C171.752 650.978 170.699 649.925 169.399 649.925C168.099 649.925 167.046 650.978 167.046 652.279C167.046 653.578 168.099 654.631 169.399 654.631C170.699 654.631 171.752 653.569 171.752 652.279Z"
            fill="#EF4E05"
          />
          <path
            d="M153.183 646.139C153.183 644.763 152.063 643.644 150.687 643.644C149.312 643.644 148.192 644.763 148.192 646.139C148.192 647.515 149.312 648.634 150.687 648.634C152.063 648.634 153.183 647.524 153.183 646.139Z"
            fill="#EF4E05"
          />
          <path
            d="M143.723 632.304C145.099 632.304 146.209 631.195 146.209 629.819C146.209 628.443 145.099 627.333 143.723 627.333C142.347 627.333 141.237 628.443 141.237 629.819C141.237 631.185 142.347 632.304 143.723 632.304Z"
            fill="#EF4E05"
          />
          <path
            d="M138.077 612.303C138.077 610.917 136.958 609.798 135.572 609.798C134.187 609.798 133.067 610.917 133.067 612.303C133.067 613.688 134.187 614.808 135.572 614.808C136.958 614.808 138.077 613.679 138.077 612.303Z"
            fill="#EF4E05"
          />
          <path
            d="M115.2 610.149C113.711 610.149 112.496 611.364 112.496 612.853C112.496 614.343 113.711 615.558 115.2 615.558C116.691 615.558 117.904 614.343 117.904 612.853C117.904 611.364 116.7 610.149 115.2 610.149Z"
            fill="#EF4E05"
          />
          <path
            d="M122.819 625.758C121.33 625.758 120.125 626.963 120.125 628.453C120.125 629.942 121.33 631.148 122.819 631.148C124.309 631.148 125.515 629.942 125.515 628.453C125.515 626.963 124.309 625.758 122.819 625.758Z"
            fill="#EF4E05"
          />
          <path
            d="M130.97 644.991C130.97 643.502 129.755 642.287 128.266 642.287C126.776 642.287 125.562 643.502 125.562 644.991C125.562 646.481 126.776 647.695 128.266 647.695C129.755 647.695 130.97 646.481 130.97 644.991Z"
            fill="#EF4E05"
          />
          <path
            d="M136.749 662.849C138.243 662.849 139.453 661.638 139.453 660.144C139.453 658.65 138.243 657.44 136.749 657.44C135.256 657.44 134.044 658.65 134.044 660.144C134.044 661.638 135.256 662.849 136.749 662.849Z"
            fill="#EF4E05"
          />
          <path
            d="M122.582 668.551C120.979 668.551 119.679 669.851 119.679 671.454C119.679 673.058 120.979 674.358 122.582 674.358C124.186 674.358 125.486 673.058 125.486 671.454C125.486 669.851 124.186 668.551 122.582 668.551Z"
            fill="#EF4E05"
          />
          <path
            d="M113.901 653.369C112.278 653.369 110.969 654.679 110.969 656.301C110.969 657.923 112.278 659.233 113.901 659.233C115.523 659.233 116.833 657.923 116.833 656.301C116.833 654.688 115.523 653.369 113.901 653.369Z"
            fill="#EF4E05"
          />
          <path
            d="M106.177 641.821C107.786 641.821 109.09 640.517 109.09 638.908C109.09 637.299 107.786 635.996 106.177 635.996C104.568 635.996 103.264 637.299 103.264 638.908C103.264 640.517 104.568 641.821 106.177 641.821Z"
            fill="#EF4E05"
          />
          <path
            d="M99.3163 619.714C97.7031 619.714 96.3943 621.022 96.3943 622.636C96.3943 624.249 97.7031 625.558 99.3163 625.558C100.929 625.558 102.239 624.249 102.239 622.636C102.239 621.022 100.929 619.714 99.3163 619.714Z"
            fill="#EF4E05"
          />
          <path
            d="M92.0768 603.649C90.4544 603.649 89.1355 604.968 89.1355 606.591C89.1355 608.213 90.4544 609.532 92.0768 609.532C93.6992 609.532 95.0182 608.213 95.0182 606.591C95.0182 604.968 93.6992 603.649 92.0768 603.649Z"
            fill="#EF4E05"
          />
          <path
            d="M106.803 594.683C105.294 594.683 104.07 595.907 104.07 597.415C104.07 598.924 105.294 600.148 106.803 600.148C108.312 600.148 109.536 598.924 109.536 597.415C109.536 595.907 108.312 594.683 106.803 594.683Z"
            fill="#EF4E05"
          />
          <path
            d="M108.359 577.119C106.898 577.119 105.712 578.305 105.712 579.767C105.712 581.228 106.898 582.414 108.359 582.414C109.82 582.414 111.006 581.228 111.006 579.767C111.006 578.305 109.82 577.119 108.359 577.119Z"
            fill="#EF4E05"
          />
          <path
            d="M92.0485 588.81C92.0485 587.206 90.748 585.906 89.145 585.906C87.5411 585.906 86.2415 587.206 86.2415 588.81C86.2415 590.413 87.5411 591.713 89.145 591.713C90.748 591.713 92.0485 590.413 92.0485 588.81Z"
            fill="#EF4E05"
          />
          <path
            d="M82.8636 570.857C81.2505 570.857 79.9412 572.166 79.9412 573.779C79.9412 575.392 81.2505 576.702 82.8636 576.702C84.477 576.702 85.7859 575.392 85.7859 573.779C85.7859 572.166 84.477 570.857 82.8636 570.857Z"
            fill="#EF4E05"
          />
          <path
            d="M83.5942 555.238C83.5942 553.635 82.2942 552.345 80.7002 552.345C79.0966 552.345 77.8062 553.645 77.8062 555.238C77.8062 556.833 79.1061 558.133 80.7002 558.133C82.2942 558.133 83.5942 556.833 83.5942 555.238Z"
            fill="#EF4E05"
          />
          <path
            d="M97.6562 567.66C99.1701 567.66 100.398 566.431 100.398 564.917C100.398 563.403 99.1701 562.175 97.6562 562.175C96.1414 562.175 94.9141 563.403 94.9141 564.917C94.9141 566.431 96.1414 567.66 97.6562 567.66Z"
            fill="#EF4E05"
          />
          <path
            d="M98.7566 546.367C98.7566 547.828 99.9427 549.015 101.404 549.015C102.866 549.015 104.052 547.828 104.052 546.367C104.052 544.906 102.866 543.719 101.404 543.719C99.9427 543.719 98.7566 544.896 98.7566 546.367Z"
            fill="#EF4E05"
          />
          <path
            d="M91.8014 533.074C93.3103 533.074 94.525 531.85 94.525 530.35C94.525 528.851 93.3011 527.627 91.8014 527.627C90.3026 527.627 89.0786 528.851 89.0786 530.35C89.0786 531.85 90.3026 533.074 91.8014 533.074Z"
            fill="#EF4E05"
          />
          <path
            d="M75.3677 536.622C73.7547 536.622 72.4453 537.932 72.4453 539.545C72.4453 541.158 73.7547 542.467 75.3677 542.467C76.9808 542.467 78.2903 541.158 78.2903 539.545C78.2903 537.932 76.9808 536.622 75.3677 536.622Z"
            fill="#EF4E05"
          />
          <path
            d="M74.7604 522.665C74.7604 521.042 73.4415 519.724 71.819 519.724C70.1964 519.724 68.8774 521.042 68.8774 522.665C68.8774 524.288 70.1964 525.606 71.819 525.606C73.4415 525.606 74.7604 524.288 74.7604 522.665Z"
            fill="#EF4E05"
          />
          <path
            d="M85.0553 514.599C86.5827 514.599 87.8168 513.366 87.8168 511.839C87.8168 510.31 86.5827 509.077 85.0553 509.077C83.5276 509.077 82.2942 510.31 82.2942 511.839C82.2942 513.366 83.5276 514.599 85.0553 514.599Z"
            fill="#EF4E05"
          />
          <path
            d="M105.114 511.439C105.114 510.026 103.975 508.887 102.561 508.887C101.147 508.887 100.009 510.026 100.009 511.439C100.009 512.853 101.147 513.992 102.561 513.992C103.975 513.992 105.114 512.853 105.114 511.439Z"
            fill="#EF4E05"
          />
          <path
            d="M112.496 531.053C113.863 531.053 114.972 529.942 114.972 528.576C114.972 527.21 113.863 526.1 112.496 526.1C111.13 526.1 110.019 527.21 110.019 528.576C110.019 529.942 111.13 531.053 112.496 531.053Z"
            fill="#EF4E05"
          />
          <path
            d="M125.002 545.475C126.317 545.475 127.383 544.409 127.383 543.093C127.383 541.778 126.317 540.712 125.002 540.712C123.687 540.712 122.62 541.778 122.62 543.093C122.62 544.409 123.687 545.475 125.002 545.475Z"
            fill="#EF4E05"
          />
          <path
            d="M116.662 558.237C115.277 558.237 114.148 559.366 114.148 560.751C114.148 562.137 115.277 563.266 116.662 563.266C118.047 563.266 119.176 562.137 119.176 560.751C119.176 559.366 118.057 558.237 116.662 558.237Z"
            fill="#EF4E05"
          />
          <path
            d="M131.265 576.797C131.265 575.45 130.173 574.359 128.826 574.359C127.478 574.359 126.387 575.45 126.387 576.797C126.387 578.144 127.478 579.235 128.826 579.235C130.173 579.235 131.265 578.144 131.265 576.797Z"
            fill="#EF4E05"
          />
          <path
            d="M125.353 597.652C126.748 597.652 127.887 596.514 127.887 595.119C127.887 593.724 126.748 592.586 125.353 592.586C123.959 592.586 122.82 593.724 122.82 595.119C122.82 596.514 123.959 597.652 125.353 597.652Z"
            fill="#EF4E05"
          />
          <path
            d="M147.765 594.427C147.765 593.136 146.722 592.092 145.431 592.092C144.14 592.092 143.097 593.136 143.097 594.427C143.097 595.717 144.14 596.761 145.431 596.761C146.722 596.761 147.765 595.717 147.765 594.427Z"
            fill="#EF4E05"
          />
          <path
            d="M156.456 615.577C157.719 615.577 158.744 614.551 158.744 613.29C158.744 612.028 157.719 611.003 156.456 611.003C155.194 611.003 154.17 612.028 154.17 613.29C154.17 614.551 155.194 615.577 156.456 615.577Z"
            fill="#EF4E05"
          />
          <path
            d="M169.57 630.664C169.57 629.411 168.554 628.396 167.302 628.396C166.049 628.396 165.034 629.411 165.034 630.664C165.034 631.916 166.049 632.932 167.302 632.932C168.554 632.932 169.57 631.916 169.57 630.664Z"
            fill="#EF4E05"
          />
          <path
            d="M188.651 641.983C188.651 640.797 187.693 639.839 186.507 639.839C185.321 639.839 184.363 640.797 184.363 641.983C184.363 643.17 185.321 644.128 186.507 644.128C187.693 644.128 188.651 643.159 188.651 641.983Z"
            fill="#EF4E05"
          />
          <path
            d="M188.822 663.608C190.049 663.608 191.042 662.614 191.042 661.388C191.042 660.161 190.049 659.167 188.822 659.167C187.596 659.167 186.602 660.161 186.602 661.388C186.602 662.614 187.596 663.608 188.822 663.608Z"
            fill="#EF4E05"
          />
          <path
            d="M174.94 673.561C174.94 672.223 173.859 671.141 172.521 671.141C171.182 671.141 170.101 672.223 170.101 673.561C170.101 674.899 171.182 675.981 172.521 675.981C173.849 675.981 174.94 674.899 174.94 673.561Z"
            fill="#EF4E05"
          />
          <path
            d="M180.937 693.553C180.937 692.187 179.827 691.077 178.461 691.077C177.095 691.077 175.984 692.187 175.984 693.553C175.984 694.92 177.095 696.03 178.461 696.03C179.827 696.03 180.937 694.92 180.937 693.553Z"
            fill="#EF4E05"
          />
          <path
            d="M189.705 679.198C189.705 680.45 190.719 681.475 191.982 681.475C193.234 681.475 194.259 680.46 194.259 679.198C194.259 677.945 193.244 676.921 191.982 676.921C190.729 676.921 189.705 677.935 189.705 679.198Z"
            fill="#EF4E05"
          />
          <path
            d="M202.163 696.229C203.424 696.229 204.449 695.204 204.449 693.942C204.449 692.681 203.424 691.656 202.163 691.656C200.901 691.656 199.876 692.681 199.876 693.942C199.876 695.204 200.901 696.229 202.163 696.229Z"
            fill="#EF4E05"
          />
          <path
            d="M193.595 713.716C194.961 713.716 196.062 712.616 196.062 711.25C196.062 709.883 194.961 708.783 193.595 708.783C192.228 708.783 191.128 709.883 191.128 711.25C191.128 712.616 192.238 713.716 193.595 713.716Z"
            fill="#EF4E05"
          />
          <path
            d="M205.93 726.887C205.93 725.51 204.81 724.391 203.435 724.391C202.058 724.391 200.939 725.51 200.939 726.887C200.939 728.263 202.058 729.382 203.435 729.382C204.81 729.382 205.93 728.272 205.93 726.887Z"
            fill="#EF4E05"
          />
          <path
            d="M183.101 728.993C183.101 727.504 181.895 726.299 180.406 726.299C178.916 726.299 177.711 727.504 177.711 728.993C177.711 730.483 178.916 731.688 180.406 731.688C181.895 731.688 183.101 730.483 183.101 728.993Z"
            fill="#EF4E05"
          />
          <path
            d="M195.009 743.046C195.009 741.566 193.803 740.36 192.323 740.36C190.843 740.36 189.638 741.566 189.638 743.046C189.638 744.526 190.843 745.731 192.323 745.731C193.803 745.731 195.009 744.526 195.009 743.046Z"
            fill="#EF4E05"
          />
          <path
            d="M212.097 741.632C210.703 741.632 209.564 742.77 209.564 744.165C209.564 745.56 210.703 746.698 212.097 746.698C213.493 746.698 214.631 745.56 214.631 744.165C214.631 742.77 213.493 741.632 212.097 741.632Z"
            fill="#EF4E05"
          />
          <path
            d="M224.84 725.254C223.559 725.254 222.525 726.288 222.525 727.569C222.525 728.85 223.559 729.884 224.84 729.884C226.121 729.884 227.155 728.85 227.155 727.569C227.155 726.288 226.121 725.254 224.84 725.254Z"
            fill="#EF4E05"
          />
          <path
            d="M230.724 749.535C232.055 749.535 233.134 748.456 233.134 747.125C233.134 745.795 232.055 744.715 230.724 744.715C229.392 744.715 228.314 745.795 228.314 747.125C228.314 748.456 229.392 749.535 230.724 749.535Z"
            fill="#EF4E05"
          />
          <path
            d="M240.819 764.721C240.819 763.35 239.709 762.241 238.342 762.241C236.976 762.241 235.866 763.35 235.866 764.721C235.866 766.082 236.976 767.192 238.342 767.192C239.709 767.192 240.819 766.082 240.819 764.721Z"
            fill="#EF4E05"
          />
          <path
            d="M240.23 779.978C238.789 779.978 237.621 781.138 237.621 782.584C237.621 784.03 238.789 785.19 240.23 785.19C241.673 785.19 242.84 784.03 242.84 782.584C242.84 781.138 241.673 779.978 240.23 779.978Z"
            fill="#EF4E05"
          />
          <path
            d="M255.451 782.945C256.817 782.945 257.927 781.836 257.927 780.466C257.927 779.104 256.817 777.994 255.451 777.994C254.084 777.994 252.974 779.104 252.974 780.466C252.974 781.844 254.084 782.945 255.451 782.945Z"
            fill="#EF4E05"
          />
          <path
            d="M273.251 778.372C274.541 778.372 275.586 777.33 275.586 776.035C275.586 774.749 274.541 773.707 273.251 773.707C271.962 773.707 270.917 774.749 270.917 776.035C270.917 777.33 271.962 778.372 273.251 778.372Z"
            fill="#EF4E05"
          />
          <path
            d="M283.983 794.445C285.301 794.445 286.374 793.369 286.374 792.058C286.374 790.738 285.301 789.662 283.983 789.662C282.664 789.662 281.592 790.738 281.592 792.058C281.592 793.369 282.664 794.445 283.983 794.445Z"
            fill="#EF4E05"
          />
          <path
            d="M291.952 807.248C291.952 808.593 293.044 809.694 294.401 809.694C295.758 809.694 296.849 808.601 296.849 807.248C296.849 805.886 295.758 804.793 294.401 804.793C293.044 804.793 291.952 805.894 291.952 807.248Z"
            fill="#EF4E05"
          />
          <path
            d="M276.905 814.494C278.35 814.494 279.523 813.326 279.523 811.871C279.523 810.425 278.35 809.257 276.905 809.257C275.458 809.257 274.285 810.425 274.285 811.871C274.285 813.326 275.458 814.494 276.905 814.494Z"
            fill="#EF4E05"
          />
          <path
            d="M266.03 793.512C264.626 793.512 263.487 794.655 263.487 796.059C263.487 797.463 264.626 798.598 266.03 798.598C267.435 798.598 268.573 797.463 268.573 796.059C268.573 794.655 267.435 793.512 266.03 793.512Z"
            fill="#EF4E05"
          />
          <path
            d="M261.314 812.821C259.787 812.821 258.553 814.057 258.553 815.587C258.553 817.117 259.787 818.344 261.314 818.344C262.842 818.344 264.076 817.117 264.076 815.587C264.076 814.057 262.842 812.821 261.314 812.821Z"
            fill="#EF4E05"
          />
          <path
            d="M538 991C835.129 991 1076 750.129 1076 453C1076 155.871 835.129 -85 538 -85C240.871 -85 0 155.871 0 453C0 750.129 240.871 991 538 991Z"
            fill="url(#paint0_radial_6_2259)"
          />
        </g>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6_2259"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(538 453) rotate(90) scale(538)"
        >
          <stop stop-color="#EF4E05" stop-opacity="0" />
          <stop offset="0.5" stop-color="#0C0400" stop-opacity="0" />
          <stop offset="0.847247" />
        </radialGradient>
        <clipPath id="clip0_6_2259">
          <rect
            width="1076"
            height="1076"
            fill="white"
            transform="translate(0 -85)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Round;
