import { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import Round from './img/Round';
import RoundSmall from './img/RoundSmall';
import Modal from './Modal';
import { useModal } from './context';
// import Round from './img/Round';
// import RoundSmall from './img/RoundSmall';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [active, setActive] = useState('');
  const logo = require('./img/logo.png');
  const logo2 = require('./img/logo2.png');
  // const L1 = require('./img/l1.png');
  // const L2 = require('./img/l2.png');
  // const L3 = require('./img/l3.png');
  // const L4 = require('./img/l4.png');
  // const L5 = require('./img/l5.png');
  // const L6 = require('./img/l6.png');
  // const L7 = require('./img/l7.png');
  // const L8 = require('./img/l8.png');
  // const L9 = require('./img/l9.png');
  const I1 = require('./img/i1.png');
  const I2 = require('./img/i2.png');
  const I3 = require('./img/i3.png');
  const banner = require('./img/banner.png');

  const binance = require('./img/binance.png');
  const Telegram = require('./img/telegramInactive.png');
  const X = require('./img/XInactive.png');
  const TelegramActive = require('./img/telegramActive.png');
  const XActive = require('./img/XActive.png');

  const { isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (sidebarOpen || isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen, sidebarOpen]);

  const openLinkInNewTab = (url: string) => {
    // Check if the URL is provided
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      console.warn('URL is required to open a new tab.');
    }
  };

  return (
    <div>
      <div className="max-w-[1200px] mx-auto px-4">
        <Fade>
          <div className="bg-black relative overflow-hidden">
            <span className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-0">
              {/* <img src={placeholder} alt="" className="" /> */}
              <span className="md:block hidden">
                <Round />
              </span>
              <span className="md:hidden block">
                <RoundSmall />
              </span>
            </span>

            <div className="relative z-20">
              <div className="flex justify-between items-center my-[50px]">
                {/* Logo */}
                <div className="md:w-2/12 w-10/12">
                  <img src={logo} alt="Logo" className="hidden md:block" />
                  <img
                    src={logo2}
                    alt="Logo2"
                    className="h-[48px] block md:hidden"
                  />
                </div>

                {/* Links for Medium and Large Screens */}
                <div className="w-8/12 hidden md:flex justify-center">
                  <span className="flex gap-4 text-white">
                    <span
                      className="cursor-pointer hover:opacity-50"
                      onClick={() =>
                        openLinkInNewTab(
                          'https://drive.google.com/file/d/1bzSkxx0JhJ2jR3d6sqLSLkjc4qyr5I3t/view?usp=drivesdk'
                        )
                      }
                    >
                      Whitepaper
                    </span>
                    <span
                      className="cursor-pointer hover:opacity-50"
                      onClick={() =>
                        openLinkInNewTab(
                          'https://drive.google.com/file/d/1dmBUVBchWLU1JAXgtMetS4Qqzmdmmn3W/view'
                        )
                      }
                    >
                      Tokenomics
                    </span>
                    <span>Features</span>
                  </span>
                </div>

                {/* Launch Apps Button for Medium and Large Screens */}
                <div className="w-2/12 hidden md:block">
                  <button
                    onClick={openModal}
                    className="px-6 py-3 rounded-md border border-[#ddd] text-white font-medium transition-transform duration-300 ease-in-out hover:bg-white hover:text-black"
                  >
                    Launch App
                  </button>
                </div>
                <Modal isOpen={isModalOpen} onClose={closeModal} title={''} />

                {/* Hamburger Menu for Small Screens */}
                <div className="md:hidden block">
                  <button onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* Sidebar for Small Screens */}
              <div
                className={`fixed z-40 top-0 left-0 h-full w-[100%] bg-black text-white transition-transform duration-300 ease-in-out ${
                  sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                }`}
              >
                <div className="flex justify-end p-4">
                  <button onClick={() => setSidebarOpen(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-col items-start p-6 gap-4">
                  <span
                    className="py-2 w-full text-left hover:bg-gray-800 cursor-pointer"
                    onClick={() =>
                      openLinkInNewTab(
                        'https://drive.google.com/file/d/1bzSkxx0JhJ2jR3d6sqLSLkjc4qyr5I3t/view?usp=drivesdk'
                      )
                    }
                  >
                    Whitepaper
                  </span>
                  <span
                    className="py-2 w-full text-left hover:bg-gray-800 cursor-pointer"
                    onClick={() =>
                      openLinkInNewTab(
                        'https://drive.google.com/file/d/1dmBUVBchWLU1JAXgtMetS4Qqzmdmmn3W/view?usp=drivesdk'
                      )
                    }
                  >
                    Tokenomics
                  </span>
                  <span className="py-2 w-full text-left hover:bg-gray-800">
                    Features
                  </span>
                  <button
                    onClick={openModal}
                    className="mt-2 px-6 py-3 rounded-md border border-[#ddd] text-white font-medium transition-transform duration-300 ease-in-out hover:bg-white hover:text-black"
                  >
                    Launch App
                  </button>
                </div>
              </div>
              {sidebarOpen && (
                <div
                  className="fixed inset-0 bg-black opacity-75 z-30"
                  onClick={() => setSidebarOpen(false)}
                />
              )}
            </div>

            <section className="flex flex-col items-center mx-auto my-[128px] md:my-[256px] relative z-10">
              <h3 className="text-center text-white text-[40px] md:text-[80px] font-medium gorilla">
                Gorilla Finance
              </h3>
              <p className="text-center text-[#838383] text-sm md:text-base">
                Gorilla Finance is a Solana-based lending protocol with
                leveraged
                <br className="hidden md:block" />
                yield farming and real-time liquidation.
              </p>
              <div className="flex justify-center mt-[40px]">
                <button
                  onClick={openModal}
                  className="bg-[#EF4E05] text-white px-[30px] md:px-[50px] rounded-md py-[15px] md:py-[20px]"
                >
                  Launch App
                </button>
              </div>
            </section>
          </div>
        </Fade>
        {/* <Fade> */}
        <section className="mb-[150px] bg-gradient-to-r from-black via-[#0D0D0D] to-black py-10 -z-0">
          <p className="text-center text-[#727272] mb-[20px] text-sm md:text-base">
            Backed by:
          </p>
          <img src={banner} alt="" className="w-full" />
          {/* <div className="flex flex-wrap justify-center gap-[30px] md:gap-[50px] items-center">
            <img src={L1} alt="" className="h-[40px]" />
            <img src={L2} alt="" className="h-[40px]" />
            <img src={L3} alt="" className="h-[40px]" />
            <img src={L4} alt="" className="h-[60px]" />
          </div>
          <div className="flex flex-wrap justify-center gap-[30px] md:gap-[50px] items-center mt-4">
            <img src={L5} alt="" className="h-[60px]" />
            <img src={L6} alt="" className="h-[60px]" />
            <img src={L7} alt="" className="h-[60px]" />
            <img src={L8} alt="" className="h-[40px]" />
            <img src={L9} alt="" className="h-[60px]" />
          </div> */}
          {/* </section>
        <section className="md:hidden block mb-[150px] bg-gradient-to-r from-black via-[#0D0D0D] to-black py-10">
          <p className="text-center text-[#727272] mb-[20px] text-sm md:text-base">
            Backed by:
          </p>
          <img src={L1} alt="" className="h-[40px] mx-auto mb-3 " />
          <img src={L2} alt="" className="h-[30px] flex mx-auto mb-3" />
          <div className="flex justify-between gap-[20px] items-center mb-3">
            <img src={L3} alt="" className="h-[40px]  w-6/12" />
            <img src={L4} alt="" className="h-[60px]  w-6/12" />
          </div>
          <img src={L5} alt="" className="h-[60px]  w-6/12 flex mx-auto mb-3" />
          <div className="flex justify-center gap-[20px] items-center mb-3">
            <img src={L6} alt="" className="h-[60px]  w-6/12" />
            <img src={L7} alt="" className="h-[60px]  w-6/12" />
          </div>
          <div className="flex justify-center gap-[20px] items-center mb-3">
            <img src={L8} alt="" className="h-[40px]  w-6/12" />
            <img src={L9} alt="" className="h-[40px] " />
          </div>

          {/* <img src={L4} alt="" className="h-[60px]  w-6/12" />
              <img src={L5} alt="" className="h-[60px]  w-6/12" />
              <img src={L6} alt="" className="h-[60px]  w-6/12" />
              <img src={L7} alt="" className="h-[60px]  w-6/12" />
              <img src={L8} alt="" className="h-[40px]  w-6/12" />
              <img src={L9} alt="" className="h-[60px]  w-6/12" /> 
       */}
        </section>
        {/* </Fade> */}
        {/* <Fade> */}
        <p className="text-center text-white font-medium text-[16px] md:text-[18px]">
          We help you boost yield while reducing risk.
        </p>
        <div className="mt-[28px]">
          <div className="flex md:flex-nowrap flex-wrap gap-[15px] justify-center">
            {/* Card 1 */}
            <div className="relative md:w-4/12 w-full p-[25px] bg-[#0d0d0d] rounded-[20px]">
              <div
                className="absolute inset-0 rounded-[20px] border-[1px] border-transparent"
                style={{
                  borderImage:
                    'linear-gradient(to bottom, #ef4e05 0.5%, black) 1',
                  borderRadius: '20px',
                }}
              />
              <div className="relative z-10 text-white">
                <img
                  src={I1}
                  alt=""
                  className="h-[74px] mx-auto mt-[32px] mb-[20px]"
                />
                <p className="text-center font-medium text-white text-[18px] md:text-[22px]">
                  Gorilla Lend
                </p>
                <div className="text-center text-[#6A6A6A] mt-[8px] mb-[33px] text-[14px] md:text-[17px]">
                  Lend and borrow: supply to earn interest
                  <br /> and borrow against collateral.
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="relative md:w-4/12 w-full p-[25px] bg-[#0d0d0d] rounded-[20px]">
              <div
                className="absolute inset-0 rounded-[20px] border-[1px] border-transparent"
                style={{
                  borderImage:
                    'linear-gradient(to bottom, #ef4e05 0.5%, black) 1',
                  borderRadius: '20px',
                }}
              />
              <div className="relative z-10 text-white">
                <img
                  src={I2}
                  alt=""
                  className="h-[74px] mx-auto mt-[32px] mb-[20px]"
                />
                <p className="text-center font-medium text-white text-[18px] md:text-[22px]">
                  Gorilla Farm
                </p>
                <div className="text-center text-[#6A6A6A] mt-[8px] mb-[33px] text-[14px] md:text-[17px]">
                  First-in-market cross-margin leveraged
                  <br /> yield farming
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="relative md:w-4/12 w-full p-[25px] bg-[#0d0d0d] rounded-[20px]">
              <div
                className="absolute inset-0 rounded-[20px] border-[1px] border-transparent"
                style={{
                  borderImage:
                    'linear-gradient(to bottom, #ef4e05 0.5%, black) 1',
                  borderRadius: '20px',
                }}
              />
              <div className="relative z-10 text-white">
                <img
                  src={I3}
                  alt=""
                  className="h-[74px] mx-auto mt-[32px] mb-[20px]"
                />
                <p className="text-center font-medium text-white text-[18px] md:text-[22px]">
                  Gorilla Assist
                </p>
                <div className="text-center text-[#6A6A6A] mt-[8px] mb-[33px] text-[14px] md:text-[17px]">
                  Auto-deleveraging to reduce liquidation
                  <br /> risks
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Fade> */}
        <div className="mt-[140px] mb-[100px]">
          <p className="text-center text-white font-medium text-[16px] md:text-[18px]">
            Join Community
          </p>
          <div className="mt-[28px] mb-[100px] bg-gradient-to-r from-black via-[#0D0D0D] to-black w-full flex flex-wrap justify-center gap-[30px] md:gap-[75px] text-white py-[58px]">
            <div
              className="w-[80px] text-center group cursor-pointer"
              onMouseEnter={() => setActive('telegram')}
              onMouseLeave={() => setActive('')}
              onClick={() => openLinkInNewTab('https://t.me/gorilla_discuss')}
            >
              <img
                src={active === 'telegram' ? TelegramActive : Telegram}
                alt="Telegram"
                className="transition-all duration-300"
              />
              <p className="text-[#878787] text-sm group-hover:text-orange-500 transition-colors duration-300">
                Telegram
              </p>
            </div>
            <div
              className="w-[80px] text-center group cursor-pointer"
              onMouseEnter={() => setActive('twitter')}
              onMouseLeave={() => setActive('')}
              onClick={() => openLinkInNewTab('https://x.com/gorillafinance_')}
            >
              <img
                src={active === 'twitter' ? XActive : X}
                alt="Twitter"
                className="transition-all duration-300"
              />
              <p className="text-[#878787] text-sm group-hover:text-orange-500 transition-colors duration-300">
                Twitter
              </p>
            </div>
          </div>
        </div>
        {/* <Fade> */}
        <div className="relative mx-auto md:h-[40px] h-[60px] flex items-center mb-[80px]">
          <section className="h-[1px] bg-[#828282] w-full z-0"></section>
          <span className="absolute left-1/2 md:top-1/2 top-[30px] transform -translate-x-1/2 -translate-y-1/2 bg-black text-white md:h-[10px] px-[20px] md:flex block items-center text-center justify-center z-10">
            Audited by:
            <img src={binance} alt="" className="h-[32px] md:ml-[12px]" />
          </span>
        </div>
        {/* </Fade> */}
      </div>
    </div>
  );
}

export default App;
